// eslint-disable-next-line import/prefer-default-export
export const ORGANISATION_PAYLOAD = `
id
name
type
url
orgKey
currency
role
canManageUsers
isDefault
direct
companySettings {
  field
  status
  sorting
}
`;

export const ORGANISATION_PAYLOAD_SHORT = `
id
name
orgKey
type
role
`;

export const COMPANY_PAYLOAD_SHORT = `
id
name
shortDescription
logoUrl
url
companyOrganisation
organisation {
  id
  name
  role
}
`;

export const COMPANY_PAYLOAD_LONG = `
id
contactEmail
employees
foundedDate
industryId
legalName
location
logoUrl
name
team
shortDescription
url
`;

export const INVESTMENT_PAYLOAD = `
id
name
investedAmount {
  amount
  currency
}
ownership
involvement
exitStatus
date
description
shares
companyValuation
roundSize
investors
totalInvestments
reserved
`;

export const INVESTMENT_COUNT_PAYLOAD = `
totalCount
totalInvested
totalInvestedCurrency
totalInvestedToPercentage
`;

export const PORTFOLIO_PAYLOAD = `
id
name
isDefault
size {
  amount
  currency
}
investments {
  ${INVESTMENT_COUNT_PAYLOAD}
  items {
    ${INVESTMENT_PAYLOAD}
    company {
      id
      name
    }
  }
}
sizeCurrency
creationYear
targetNumberOfInvestments
url
`;

export const CONTACT_PAYLOAD = `
id
userId
firstName
lastName
contactType
email
`;

export const RUNWAY_PAYLOAD = `
cash
cashFlow
runway
lastUpdate
currency
`;

export const USER_PAYLOAD = `
id
firstName
lastName
email
phone
picture
`;

export const COMPANIED_PAYLOAD = `
${ORGANISATION_PAYLOAD}
portfolios {
  totalCount
  url
  items {
    ${PORTFOLIO_PAYLOAD}
    investments {
      ${INVESTMENT_COUNT_PAYLOAD}
      items {
        ${INVESTMENT_PAYLOAD}
        company {
          id
          name
        }
      }
    }
  }
}
companies {
  ${COMPANY_PAYLOAD_SHORT}
  organisation {
    id
    name
  }
  portfolio {
    id
    name
  }
}`;
