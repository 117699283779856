import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createMuiTheme' {
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    custom: any;
  }
}

export function createMyTheme(options: ThemeOptions) {
  return createMuiTheme({
    ...options,
  });
}
