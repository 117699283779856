/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/prefer-default-export

import { getOrganizationsAction } from '../reducers';
import {
  ACTION_TYPES_ORGANIZATION,
  ACTION_TYPES_LOADING,
} from '../actionTypes';
import { actionCreator } from '../common/actionCreator';
import { CompanyAction } from './company';
import { logActionError } from '../common/checkActionBadData';

export class OrganizationAction {
  // static getOrganizations() {
  //   return async (dispatch) => {
  //     try {
  //       dispatch(actionCreator(ACTION_TYPES_ORGANIZATION.GET_ORGANIZATIONS));
  //       dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, true));
  //       const { data: { organisations = [] } = {} } = await client.query({
  //         query: GET_ORGANISATIONS_QUERY,
  //         fetchPolicy: 'network-only',
  //       });
  //       dispatch(
  //         getOrganizationsAction(JSON.parse(JSON.stringify(organisations))),
  //       );
  //       dispatch(CompanyAction.getAllOrganizationsCompanies(organisations));
  //     } catch (error) {
  //       logActionError('log', error, 'getOrganizations');
  //       dispatch(
  //         actionCreator(ACTION_TYPES_ORGANIZATION.GET_ORGANIZATIONS_FAIL),
  //       );
  //     } finally {
  //       dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, false));
  //     }
  //   };
  // }

  static deleteOrganistaion(organizationId) {
    return async (dispatch, getState) => {
      try {
        dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, true));
        const { organization = {} } = getState();
        const { organizations } = organization;
        const data = JSON.parse(JSON.stringify(organizations)).filter(
          (ele) => ele.id !== organizationId
        );
        dispatch(getOrganizationsAction(JSON.parse(JSON.stringify(data))));
        dispatch(CompanyAction.getAllOrganizationsCompanies(data));
      } catch (error) {
        logActionError('log', error, 'deleteOrganistaion');
      } finally {
        dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, false));
      }
    };
  }

  // static getSelectedOrganization(organizationId) {
  //   return async (dispatch, getState) => {
  //     try {
  //       const { organization = {} } = getState();
  //       const { organizations } = organization;
  //       dispatch(
  //         actionCreator(ACTION_TYPES_ORGANIZATION.GET_SELECTED_ORGANIZATION),
  //       );
  //       const filteredOrg = filterSelectedOrganization(
  //         organizationId,
  //         organizations,
  //       );
  //       let organisationFinal = null;
  //       if (filteredOrg) {
  //         organisationFinal = JSON.parse(JSON.stringify(filteredOrg));
  //         dispatch(
  //           actionCreator(
  //             ACTION_TYPES_ORGANIZATION.GET_SELECTED_ORGANIZATION_SUCCESS,
  //             organisationFinal,
  //           ),
  //         );
  //       } else {
  //         dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, true));
  //       }
  //       const { data: { organisation = {} } = {} } = await client.query({
  //         query: GET_SELECTED_ORGANISATION_WITH_INVESTMENT_QUERY,
  //         fetchPolicy: 'network-only',
  //         variables: {
  //           organisationId: organizationId,
  //         },
  //       });
  //       const { companies } = organisation;
  //       organisationFinal = JSON.parse(JSON.stringify(organisation));
  //       dispatch(
  //         actionCreator(
  //           ACTION_TYPES_ORGANIZATION.GET_SELECTED_ORGANIZATION_SUCCESS,
  //           organisationFinal,
  //         ),
  //       );
  //       dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, false));
  //       dispatch(
  //         CompanyAction.getCompaniesByOrganisation(organizationId, companies),
  //       );
  //       dispatch(
  //         PortfolioAction.getSelectedOrganizationPortfolios(organisationFinal),
  //       );
  //     } catch (error) {
  //       logActionError('log', error, 'getSelectedOrganization');
  //       dispatch(
  //         actionCreator(
  //           ACTION_TYPES_ORGANIZATION.GET_SELECTED_ORGANIZATION_FAIL,
  //         ),
  //       );
  //     } finally {
  //       dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, false));
  //     }
  //   };
  // }

  // static createOrganization(newOrganization = {}, onSuccessMsg) {
  //   return async (dispatch) => {
  //     try {
  //       const { name, type } = newOrganization;
  //       if (!name || !type) {
  //         throw new Error('Bad data received while creating organization');
  //       }
  //       dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, true));
  //       dispatch(actionCreator(ACTION_TYPES_ORGANIZATION.CREATE_ORGANIZATION));
  //       const {
  //         data: { createOrganisation },
  //       } = await client.mutate({
  //         mutation: CREATE_ORGANISATION,
  //         variables: {
  //           name,
  //           type,
  //         },
  //       });
  //       if (type !== AllowedOrganisationTypes.COMPANY) {
  //         dispatch(
  //           actionCreator(
  //             ACTION_TYPES_ORGANIZATION.GET_SELECTED_ORGANIZATION_SUCCESS,
  //             JSON.parse(JSON.stringify(createOrganisation)),
  //           ),
  //         );
  //       }
  //       dispatch(
  //         actionCreator(
  //           ACTION_TYPES_ORGANIZATION.ADD_ORGANIZATIONS_SUCCESS,
  //           JSON.parse(JSON.stringify(createOrganisation)),
  //         ),
  //       );
  //       dispatch(
  //         CommonAction.setAlert({
  //           open: true,
  //           severity: 'success',
  //           message: onSuccessMsg || 'Organization create success',
  //         }),
  //       );
  //     } catch (error) {
  //       logActionError('log', error);
  //       dispatch(
  //         actionCreator(ACTION_TYPES_ORGANIZATION.CREATE_ORGANIZATION_FAIL),
  //       );
  //       dispatch(
  //         CommonAction.setAlert({
  //           open: true,
  //           severity: 'error',
  //           message: handleGraphqlServerError(error),
  //         }),
  //       );
  //     } finally {
  //       dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, false));
  //     }
  //   };
  // }

  static addPortfolioToOrganization(newPortfolio, organizationId) {
    return (dispatch, getState) => {
      const {
        organization: { organizations = [], organization },
      } = getState();
      const orgIndex = organizations.findIndex(
        (value) => value.id === organizationId
      );
      if (orgIndex >= 0) {
        const org = organizations[orgIndex];
        if (org.portfolios && Array.isArray(org.portfolios.items)) {
          org.portfolios.items = org.portfolios.items.concat(
            JSON.parse(JSON.stringify(newPortfolio))
          );
          organizations[orgIndex] = org;
        }
      }
      dispatch(
        actionCreator(
          ACTION_TYPES_ORGANIZATION.ADD_PORTFOLIO_TO_ORGANIZATION,
          JSON.parse(JSON.stringify(organizations))
        )
      );
      if (organizationId === organization.id) {
        const {
          portfolios: { items = [], ...portfolioPayload },
          ...rest
        } = organization;
        const portfolioItems = items.concat(
          JSON.parse(JSON.stringify(newPortfolio))
        );
        const updatePortfolio = {
          ...portfolioPayload,
          totalCount: portfolioItems.length,
          items: portfolioItems,
        };
        const selectedOrganization = {
          ...rest,
          portfolios: {
            ...updatePortfolio,
          },
        };
        dispatch(
          actionCreator(
            ACTION_TYPES_ORGANIZATION.GET_SELECTED_ORGANIZATION_SUCCESS,
            JSON.parse(JSON.stringify(selectedOrganization))
          )
        );
      }
    };
  }

  static updatePortfolioToOrganization(updatedPortfolio) {
    return (dispatch, getState) => {
      const {
        organization: { organizations = [], organization },
      } = getState();
      const orgIndex = organizations.findIndex(
        (value) => value.id === organization.id
      );
      if (orgIndex >= 0) {
        const org = organizations[orgIndex];
        if (org.portfolios && Array.isArray(org.portfolios.items)) {
          const selectedPortfolioIndex = org.portfolios.items.findIndex(
            (portfolio) => portfolio.id === updatedPortfolio.id
          );
          const selectedPortfolio =
            org.portfolios.items[selectedPortfolioIndex];
          const newUpdatedPortfolio = {
            ...selectedPortfolio,
            ...updatedPortfolio,
            size: {
              ...selectedPortfolio.size,
              amount: updatedPortfolio.size,
            },
          };
          org.portfolios.items[selectedPortfolioIndex] = newUpdatedPortfolio;
          organizations[orgIndex] = org;
        }
      }
      dispatch(
        actionCreator(
          ACTION_TYPES_ORGANIZATION.ADD_PORTFOLIO_TO_ORGANIZATION,
          JSON.parse(JSON.stringify(organizations))
        )
      );
      // update selected org
      const {
        portfolios: { items = [], ...portfolioPayload },
        ...rest
      } = organization;
      const selectedPortfolioIndex = items.findIndex(
        (portfolio) => portfolio.id === updatedPortfolio.id
      );
      const selectedPortfolio = items[selectedPortfolioIndex];
      const newUpdatePortfolio = {
        ...selectedPortfolio,
        ...updatedPortfolio,
        size: {
          ...selectedPortfolio.size,
          amount: updatedPortfolio.size,
        },
      };
      items[selectedPortfolioIndex] = newUpdatePortfolio;
      const selectedOrganization = {
        ...rest,
        portfolios: {
          ...portfolioPayload,
          items,
        },
      };
      dispatch(
        actionCreator(
          ACTION_TYPES_ORGANIZATION.GET_SELECTED_ORGANIZATION_SUCCESS,
          JSON.parse(JSON.stringify(selectedOrganization))
        )
      );
    };
  }

  static removePortfoliosFromOrganization(portfolioIds) {
    return (dispatch, getState) => {
      const {
        organization: { organizations = [], organization },
      } = getState();
      const orgIndex = organizations.findIndex(
        (value) => value.id === organization.id
      );
      if (orgIndex >= 0) {
        const org = organizations[orgIndex];
        if (org.portfolios && Array.isArray(org.portfolios.items)) {
          const filteredPorfolios = org.portfolios.items.filter(
            (portfolio) => !portfolioIds.includes(portfolio.id)
          );
          const newUpdatedPortfolio = {
            ...org.portfolios,
            items: filteredPorfolios,
            totalCount: filteredPorfolios.length,
          };
          org.portfolios = newUpdatedPortfolio;
          organizations[orgIndex] = org;
        }
      }
      dispatch(
        actionCreator(
          ACTION_TYPES_ORGANIZATION.ADD_PORTFOLIO_TO_ORGANIZATION,
          JSON.parse(JSON.stringify(organizations))
        )
      );
      // remore from selected org
      const {
        portfolios: { items = [], ...portfolioPayload },
        ...rest
      } = organization;
      const filteredPorfolios = items.filter(
        (portfolio) => !portfolioIds.includes(portfolio.id)
      );
      const newUpdatedPortfolio = {
        ...portfolioPayload,
        items: filteredPorfolios,
        totalCount: filteredPorfolios.length,
      };
      const updateOrg = {
        ...rest,
        portfolios: {
          ...newUpdatedPortfolio,
        },
      };
      dispatch(
        actionCreator(
          ACTION_TYPES_ORGANIZATION.GET_SELECTED_ORGANIZATION_SUCCESS,
          JSON.parse(JSON.stringify(updateOrg))
        )
      );
    };
  }

  // if update flag is set true company within organisation will be update
  static addCompanyToOrganization(newCompany, organizationId, update = false) {
    return (dispatch, getState) => {
      const {
        organization: { organizations = [], organization = {} } = {},
      } = getState();
      const filterOrgIndex = organizations.findIndex(
        (value) => value.id === organizationId
      );
      if (filterOrgIndex < 0) {
        return;
      }
      const { companies = [], ...rest } = organizations[filterOrgIndex];
      const updateOrg = {
        ...rest,
        companies: update
          ? companies.map((elem) => {
              // update logic of companies with org
              if (elem.id === newCompany.id) {
                return {
                  ...elem,
                  ...newCompany,
                };
              }
              return elem;
            })
          : companies.concat(JSON.parse(JSON.stringify(newCompany))), // add new company logic
      };
      organizations[filterOrgIndex] = updateOrg;
      dispatch(
        actionCreator(
          ACTION_TYPES_ORGANIZATION.GET_ORGANIZATIONS_SUCCESS,
          JSON.parse(JSON.stringify(organizations))
        )
      );
      if (organization.id === organizationId) {
        dispatch(
          actionCreator(
            ACTION_TYPES_ORGANIZATION.GET_SELECTED_ORGANIZATION_SUCCESS,
            JSON.parse(JSON.stringify(updateOrg))
          )
        );
      }
    };
  }
}
