/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { useIntl } from 'react-intl';
import { Button } from '@material-ui/core';

import { Checkbox } from 'components/Common/Checkbox';
import messages from './messages';
import { userRoles } from '../../../user/userConstants';

const CheckBox = ({ checked, onChange }) => {
  const [check, setChecked] = useState(checked);
  useEffect(() => {
    setChecked(checked);
  }, [checked]);
  return (
    <Checkbox
      checked={check}
      onChange={(_, value) => {
        const status = onChange(value);
        if (status) setChecked(value);
      }}
    />
  );
};

const ManageUserTable = ({
  showContacts,
  data,
  deleteRecord,
  onUpdateUser,
  onUpdateRole,
  onUpdateContact,
  onDeleteContact,
}) => {
  const intl = useIntl();
  const [tableData, setTableData] = useState(data);
  const [checked, setChecked] = useState([]);
  const [editingMode, setEditingMode] = useState('Save');
  const editRef = useRef(editingMode);
  const handleCheckedChange = (id, value) => {
    if (editRef.current !== 'Edit') return false;
    const tempChecked = JSON.parse(JSON.stringify(checked));
    tempChecked[id] = value;
    setChecked(tempChecked);
    return true;
  };
  useEffect(() => {
    if (data && Array.isArray(data)) {
      setTableData(data);
      const newCheckedData = [];
      data.forEach((elem) => {
        newCheckedData.push(Boolean(elem.isContact));
      });
      setChecked(newCheckedData);
    }
  }, [data]);
  const columns = [
    {
      title: intl.formatMessage(messages.emailId),
      field: 'email',
      defaultSort: 'asc',
      editable: 'never',
    },
    {
      title: intl.formatMessage(messages.firstName),
      field: 'firstName',
      editable: (_, rowData) => {
        return !rowData.firstName;
      },
    },
    {
      title: intl.formatMessage(messages.lastName),
      field: 'lastName',
      editable: (_, rowData) => {
        return !rowData.lastName;
      },
    },
    {
      title: `${intl.formatMessage(messages.role)}`,
      field: 'role',
      editable: 'onUpdate',
      lookup: Object.values(userRoles),
      cellStyle: { textTransform: 'capitalize' },
    },
    {
      title: intl.formatMessage(messages.companyContact),
      hidden: !showContacts,
      editable: 'never',
      render: (row) => (
        <CheckBox
          checked={checked[row.tableData.id] || false}
          onChange={(value) => handleCheckedChange(row.tableData.id, value)}
        />
      ),
    },
  ];
  const changeEdit = (value) => {
    setEditingMode(value);
    editRef.current = value;
  };

  const update = (newData, oldData) => {
    const promises = [];
    const { firstName, lastName, id, role, isContact, email } = newData;
    const {
      tableData: { id: index },
    } = oldData;
    setEditingMode('Save');
    if (firstName !== oldData.firstName || lastName !== oldData.lastName) {
      promises.push(
        onUpdateUser({
          firstName,
          lastName,
          id,
        })
      );
    }
    if (role !== oldData.role) {
      promises.push(
        onUpdateRole({
          role,
          id,
        })
      );
    }
    if (checked[index] !== Boolean(isContact)) {
      if (checked[index]) {
        onUpdateContact({
          userId: id,
          id: isContact && isContact.id ? isContact.id : null,
          firstName,
          lastName,
          email,
        });
      } else onDeleteContact(isContact);
    }
    return Promise.all(promises);
  };

  return (
    <Table
      columns={columns}
      tableData={tableData}
      setEditingMode={changeEdit}
      onUpdateUser={onUpdateUser}
      onUpdateRole={onUpdateRole}
      onUpdateContact={onUpdateContact}
      onDeleteContact={onDeleteContact}
      deleteRecord={deleteRecord}
      checked={checked}
      editingMode={editingMode}
      update={update}
    />
  );
};

// https://trello.com/c/GuffnktP/252-bug-on-updating-user-information

const Table = memo(
  ({
    // eslint-disable-next-line no-unused-vars
    columns,
    tableData,
    setEditingMode,
    onDeleteContact,
    deleteRecord,
    update,
  }) => (
    <MaterialTable
      columns={columns}
      data={tableData}
      class
      title=""
      options={{
        sorting: false,
        selection: false,
        search: false,
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        grouping: false,
        draggable: false,
        debounceInterval: 0,
        toolbar: false,
      }}
      components={{
        Action: ({ data, action }) => {
          const { tooltip, onClick } =
            typeof action === 'function' ? action(data) : action;
          return (
            <Button
              variant="text"
              color={
                tooltip === 'Edit' || tooltip === 'Save'
                  ? 'primary'
                  : 'secondary'
              }
              onClick={(e) => {
                try {
                  setEditingMode(tooltip);
                  onClick(e, data);
                } catch (err) {
                  console.log({ err });
                }
              }}
            >
              {tooltip}
            </Button>
          );
        },
      }}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(async () => {
              await update(newData, oldData);
              resolve();
            }, 1000);
          }),
        onRowDelete: (newData) =>
          new Promise((resolve) => {
            setTimeout(async () => {
              const { isContact } = newData;
              if (isContact) {
                await onDeleteContact(isContact);
              }
              await deleteRecord({
                ...newData,
                type: 'user',
              });
              resolve();
            }, 1000);
          }),
      }}
    />
  ),
  (prev, next) => next.editingMode === 'Edit' || next.editingMode === 'Delete'
);

ManageUserTable.propTypes = {
  showContacts: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  deleteRecord: PropTypes.func,
  onUpdateUser: PropTypes.func,
  onUpdateContact: PropTypes.func,
  onDeleteContact: PropTypes.func,
  onUpdateRole: PropTypes.func,
};

ManageUserTable.defaultProps = {
  showContacts: false,
  data: [],
  deleteRecord: () => {},
  onUpdateUser: () => {},
  onUpdateContact: () => {},
  onDeleteContact: () => {},
  onUpdateRole: () => {},
};

export default ManageUserTable;
