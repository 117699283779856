/* eslint-disable import/prefer-default-export */
import { handleActions, createAction } from 'redux-actions';
import { createSelector } from 'reselect';

import { ACTION_TYPES_ORGANIZATION } from '../actionTypes';

const initialState = {
  organizations: [],
  organization: {},
  isLoading: true,
};

export const organization = handleActions(
  {
    [ACTION_TYPES_ORGANIZATION.GET_ORGANIZATIONS]: (state) => ({
      ...state,
      organizations: [],
      isLoading: true,
    }),
    [ACTION_TYPES_ORGANIZATION.GET_ORGANIZATIONS_SUCCESS]: (
      state,
      { payload }
    ) => ({
      ...state,
      organizations: payload,
      isLoading: false,
    }),
    [ACTION_TYPES_ORGANIZATION.GET_ORGANIZATIONS_FAIL]: (state) => ({
      ...state,
      organizations: [],
      isLoading: false,
    }),
    [ACTION_TYPES_ORGANIZATION.GET_SELECTED_ORGANIZATION]: (state) => ({
      ...state,
      organization: {},
    }),
    [ACTION_TYPES_ORGANIZATION.GET_SELECTED_ORGANIZATION_SUCCESS]: (
      state,
      { payload }
    ) => ({
      ...state,
      organization: payload,
    }),
    [ACTION_TYPES_ORGANIZATION.GET_SELECTED_ORGANIZATION_FAIL]: (state) => ({
      ...state,
      organization: {},
    }),
    [ACTION_TYPES_ORGANIZATION.ADD_ORGANIZATIONS_SUCCESS]: (
      state,
      { payload }
    ) => ({
      ...state,
      organizations: state.organizations.concat({ ...payload, direct: true }),
    }),
    [ACTION_TYPES_ORGANIZATION.ADD_PORTFOLIO_TO_ORGANIZATION]: (
      state,
      { payload }
    ) => ({
      ...state,
      organizations: payload,
    }),
  },
  initialState
);

const getRoot = (state) => state.organization;

const getOrganizationsAction = createAction(
  ACTION_TYPES_ORGANIZATION.GET_ORGANIZATIONS_SUCCESS
);

const getOrganizations = createSelector(
  getRoot,
  (state) => state.organizations
);

const getSelectedOrganization = createSelector(
  getRoot,
  (state) => state.organization
);
const getOrganisationLoadingState = createSelector(
  getRoot,
  (state) => state.isLoading
);

export {
  getOrganizationsAction,
  getOrganizations,
  getSelectedOrganization,
  getOrganisationLoadingState,
};
