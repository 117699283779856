/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable operator-linebreak */
import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Grid, Divider, InputLabel, Typography } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Alert from '@material-ui/lab/Alert';
import translations from 'translations';
import { CompanySearched } from 'components/Common/Company';
import { Button } from 'components/Common/Buttons';
import { SelectInput } from 'components/Common/Select';
import { normalizeContacts } from 'common/normalizeContactName';
import { useStyles } from './styles';
import SharedCompaniesTableHead from './SharedCompaniesTable';
import { INVALID_TEXT } from '../../Investment/InvestmentTable';
import AddUser from '../../User/UserAccessModal/AddUser';
import { COMPANY_CONTACT_TYPE } from '../../../constants';
import { userRoles } from '../../../user/userConstants';
import ContactList from '../Reuseable/ListItemWithDelete';
import InvestmentTable from '../Reuseable/InvestmentTable';

const CompanyApiTab = ({
  showShared,
  organisation,
  onCreateCompany,
  sharedCompanies,
  unshareOrganisations,
  addCompanyFromShared,
  portfolios: propPortfolios,
  setAlert,
  investmentManagers,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [portfolios, setPortfolios] = useState([]);
  const [selectedSharedCompany, setSelectedSharedCompany] = useState(null);
  const [showInvestmentDetails, setShowInvestmentDetails] = useState(false);
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [showInvestmentManagers, setShowInvestmentManagers] = useState(false);
  const [isCompanyAdd, setIsCompanyAdd] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [selectedInvestmentManagers, setSelectedInvestmentManagers] = useState(
    []
  );
  const getCompanySearchedSate = useRef(null);
  const [edit, setEdit] = useState(true);
  const [signal, setSignal] = useState(1);

  const onAddCompany = async (investments = [], companySearchedSate) => {
    const { id, companyName, shortDescription } = companySearchedSate;
    const companyObj = {
      name: companyName,
      organisationId: organisation.id,
      apiCompanyId: id,
      shortDescription,
      investments,
    };
    const investmentData = selectedInvestmentManagers.map((value) => {
      const { email } = value;
      return {
        email,
        contactType: COMPANY_CONTACT_TYPE.INVESTOR,
        role: userRoles.ADMIN,
      };
    });
    await onCreateCompany(companyObj, contacts.concat(investmentData));
    return setContacts([]);
  };

  const onAddCompanyFromShared = async (investments = []) => {
    // Selected Shared Company is Target
    if (!selectedSharedCompany) {
      // handle error that no shared company selected
      return;
    }
    await addCompanyFromShared(selectedSharedCompany, investments);
  };

  const onInvestmentsSave = (investments) => {
    setEdit(true);
    if (investments === null) {
      setIsCompanyAdd(false);
      setAlert({
        open: true,
        message: {
          id: 'Company.CompanyApiTab.validation',
          defaultMessage: 'Please fill up all highlighted fields',
        },
        severity: 'warning',
      });
      return;
    }

    setIsCompanyAdd(true);
    setShowInvestmentDetails(false);
    setShowInvestmentManagers(false);
    setSelectedInvestmentManagers([]);

    if (showShared) {
      if (selectedSharedCompany) {
        onAddCompanyFromShared(investments);
      }
      return;
    }

    // to get state from crunchbase component
    const companySearchedSate = getCompanySearchedSate.current();
    if (!companySearchedSate) {
      return;
    }
    onAddCompany(investments, companySearchedSate);
  };

  const handleAddCompany = () => {
    setEdit(false);
  };

  const dropLabel = (text, onChange, value) => (
    <div onClick={() => onChange(!value)} className={classes.detaislContainer}>
      <InputLabel className={classes.detailsWrapper}>
        {intl.formatMessage(text)}
      </InputLabel>
      <div className={classes.dropArrow}>
        {value ? (
          <ArrowDropUpIcon className={classes.icon} />
        ) : (
          <ArrowDropDownIcon className={classes.icon} />
        )}
      </div>
    </div>
  );

  const getBtnEnable = () => {
    if (showShared) return !selectedSharedCompany;
    return false;
  };

  const getSignal = (res) => {
    setSignal(res);
  };
  useEffect(() => {
    const newPortfoliosStr = JSON.stringify(propPortfolios);
    if (newPortfoliosStr !== JSON.stringify(portfolios)) {
      setPortfolios(JSON.parse(newPortfoliosStr));
    }
  }, [propPortfolios]);

  const addContacts = (contact) => {
    if (contacts.some((elem) => elem.email === contact.email)) {
      setAlert({
        open: true,
        message: translations.Company.CompanyApiTab.repeatedContactMessage,
        severity: 'info',
      });
      return;
    }
    const newContacts = JSON.parse(JSON.stringify(contacts));
    newContacts.push(contact);
    setContacts(newContacts);
  };

  const deleteContact = (index) => {
    const newContacts = contacts.filter((_, i) => i !== index);
    setContacts(newContacts);
  };

  const AddButton = () =>
    signal === 2 ? (
      <Button
        variant="contained"
        color="primary"
        classes={{
          root: classes.addButton,
        }}
        onClick={handleAddCompany}
        disabled={getBtnEnable()}
      >
        {`${intl.formatMessage(
          translations.Company.CompanyApiTab.addBtnTitle
        )}`}
      </Button>
    ) : (
      <Button
        variant="contained"
        color="primary"
        classes={{
          root: classes.addButton,
        }}
        onClick={handleAddCompany}
        disabled={getBtnEnable()}
      >
        {`${intl.formatMessage(
          translations.Company.CompanyApiTab.addBtnTitle
        )}`}
      </Button>
    );

  const handleOnChangeInvestmentManagers = (e) => {
    setSelectedInvestmentManagers(e.target.value);
  };

  const renderInvestmentManagers = (values) => normalizeContacts(values, 1);

  return (
    <>
      <Grid className={classes.grid} container>
        {showShared && (
          <>
            <Typography
              component="h2"
              variant="h5"
              color="primary"
              gutterBottom
              className={classes.title}
            >
              {intl.formatMessage(
                translations.Company.CompanyApiTab.sharedWithYouLabel
              )}
            </Typography>
            <SharedCompaniesTableHead
              data={sharedCompanies}
              order="asc"
              onSelectAllClick={() => {}}
              onRequestSort={() => {}}
              unshareOrganisations={unshareOrganisations}
              onSelectSharedCompany={(value) => setSelectedSharedCompany(value)}
            />
          </>
        )}
        {!showShared && (
          <CompanySearched
            getSignal={getSignal}
            showShared={showShared}
            getCompanySearchedSate={(callback) => {
              getCompanySearchedSate.current = callback;
            }}
            from={INVALID_TEXT.INVESTMENT_COMPANY_TAB}
          />
        )}
        <Grid container item xs={12} direction="row" alignItems="center">
          {AddButton()}
        </Grid>
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <InputLabel className={classes.subHeader}>
            {`${intl
              .formatMessage(
                translations.Company.CompanyApiTab.asInvestmentLabel
              )
              .toUpperCase()}`}
          </InputLabel>

          <Grid item xs={12}>
            {dropLabel(
              translations.Company.CompanyApiTab.investmentDetails,
              setShowInvestmentDetails,
              showInvestmentDetails
            )}
            <div style={{ display: showInvestmentDetails ? '' : 'none' }}>
              <InvestmentTable
                investments={null}
                edit={edit}
                hideExits
                onSave={onInvestmentsSave}
              />
            </div>

            <Divider className={classes.divider} />
            {dropLabel(
              translations.Company.CompanyApiTab.addInviteContactsLabel,
              setShowContactDetails,
              showContactDetails
            )}
            <div
              style={{
                display: showContactDetails ? '' : 'none',
              }}
            >
              <div style={{ marginBottom: 10 }}>
                <Alert severity="info">
                  {intl.formatMessage(
                    translations.Company.CompanyApiTab.addContactMessage
                  )}
                </Alert>
              </div>
              <AddUser
                onCreateUser={addContacts}
                showRoles={false}
                showAddToContact={false}
                isCompanyAdd={isCompanyAdd}
                useAsModal={false}
                showHeader={false}
                from="addCompanies"
              />

              {/* contact list */}
              {Boolean(contacts.length) && (
                <ContactList
                  title={intl.formatMessage(
                    translations.Company.CompanyApiTab.contactList
                  )}
                  list={contacts}
                  deleteOption={deleteContact}
                />
              )}
            </div>
            <Divider className={classes.divider} />
            {dropLabel(
              translations.Company.CompanyApiTab.investmentManagers,
              setShowInvestmentManagers,
              showInvestmentManagers
            )}
            {showInvestmentManagers && (
              <Grid item xs={12}>
                <SelectInput
                  label={`${intl.formatMessage(
                    translations.Company.CompanyApiTab.investmentManagers
                  )}`}
                  lableStyle={classes.labelStyle}
                  value={selectedInvestmentManagers}
                  fullWidth
                  onChange={handleOnChangeInvestmentManagers}
                  options={investmentManagers}
                  formControlStyle={classes.selectInput}
                  multiSelect
                  useId
                  renderSelectValues={renderInvestmentManagers}
                  renderKey="index"
                />
              </Grid>
            )}
            <Divider className={classes.divider} />

            <Grid container item xs={12} direction="row" alignItems="center">
              {AddButton()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyApiTab;

CompanyApiTab.propTypes = {
  showShared: PropTypes.bool,
  organisation: PropTypes.object,
  onCreateCompany: PropTypes.func,
  sharedCompanies: PropTypes.arrayOf(PropTypes.object),
  unshareOrganisations: PropTypes.func,
  addCompanyFromShared: PropTypes.func,
  portfolios: PropTypes.arrayOf(PropTypes.object).isRequired,
  setAlert: PropTypes.func,
  investmentManagers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

CompanyApiTab.defaultProps = {
  organisation: {},
  showShared: false,
  onCreateCompany: null,
  sharedCompanies: [],
  unshareOrganisations: async () => {},
  addCompanyFromShared: async () => {},
  setAlert: () => {},
};
