import { AppDispatch, AppThunk } from 'store';
import {
  GraphqlService,
  logActionError,
  handleGraphqlServerError,
} from 'utils/api';
import {
  Subscriptions,
  Subscription,
  OnSuccessPayload,
  UpdateSubscriptionPayload,
  GraphqlResponse,
  CancelSubscriptionPaylod,
} from 'types';
import { setAlert } from 'containers/Config';
import {
  getSubscriptionsByOrg,
  getSubscriptionsByOrgSucess,
  getSubscriptionsByOrgFail,
  updateSubscription as updateSubscriptionAction,
  updateSubscriptionSucess,
  updateSubscriptionFail,
  cancelSubscription as cancelSubscriptionAction,
  cancelSubscriptionSuccess,
  cancelSubscriptionFail,
} from 'containers/Organisation';
import {
  GET_SUBSCRIPTION_BY_ORGANISATION,
  CANCEL_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
} from './query';

const getSubscriptionsByOrganization = (organisationId: string): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    try {
      dispatch(getSubscriptionsByOrg());
      const service = new GraphqlService(global.apolloClient);
      const { data: { subscriptionByOrganisation } = {} } = await service.query<
        { subscriptionByOrganisation: Subscriptions },
        { organisationId: string }
      >(GET_SUBSCRIPTION_BY_ORGANISATION, {
        organisationId,
      });
      if (!subscriptionByOrganisation) {
        throw new Error('Unable to get subscriptions');
      }
      dispatch(
        getSubscriptionsByOrgSucess(
          JSON.parse(JSON.stringify(subscriptionByOrganisation))
        )
      );
    } catch (error) {
      logActionError('log', error, 'getSubscriptionsByOrganization');
      dispatch(getSubscriptionsByOrgFail());
      dispatch(
        setAlert({
          open: true,
          severity: 'error',
          message: handleGraphqlServerError(error),
        })
      );
    }
  };
};

const updateSubscription = (
  payload: UpdateSubscriptionPayload,
  onSuccess?: OnSuccessPayload
): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { resolve, reject, successMsg } = onSuccess || {};
    try {
      dispatch(updateSubscriptionAction());
      const service = new GraphqlService(global.apolloClient);
      const {
        data: { updateSubscription: updatedData },
      } = await service.mutation<
        GraphqlResponse<{ updateSubscription: Subscription }>
      >(UPDATE_SUBSCRIPTION, {
        ...payload,
      });
      dispatch(
        setAlert({
          open: true,
          message: successMsg || 'Subscription updated',
          severity: 'success',
        })
      );
      dispatch(
        updateSubscriptionSucess(JSON.parse(JSON.stringify(updatedData)))
      );
      if (resolve) resolve(updatedData);
    } catch (error) {
      logActionError('log', error, 'updateSubscription');
      dispatch(
        setAlert({
          open: true,
          severity: 'error',
          message: handleGraphqlServerError(error),
        })
      );
      dispatch(updateSubscriptionFail());
      if (reject) reject(error);
    }
  };
};

const cancelSubscription = (
  { subscriptionId }: CancelSubscriptionPaylod,
  onSuccess?: OnSuccessPayload
): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { resolve, reject, successMsg } = onSuccess || {};
    try {
      dispatch(cancelSubscriptionAction());
      const service = new GraphqlService(global.apolloClient);
      const {
        data: { deleteSubscription: isDeleted },
      } = await service.mutation<
        GraphqlResponse<{ deleteSubscription: boolean }>
      >(CANCEL_SUBSCRIPTION, {
        subscriptionId,
      });
      dispatch(
        setAlert({
          open: true,
          message: successMsg || 'Subscription canceled',
          severity: 'success',
        })
      );
      if (!isDeleted) {
        throw new Error('Unable to cancel subscription, something went wrong');
      }
      dispatch(cancelSubscriptionSuccess({ subscriptionId }));
      if (resolve) resolve(isDeleted);
    } catch (error) {
      logActionError('log', error, 'cancelSubscription');
      dispatch(
        setAlert({
          open: true,
          severity: 'error',
          message: handleGraphqlServerError(error),
        })
      );
      dispatch(cancelSubscriptionFail());
      if (reject) reject(error);
    }
  };
};

export const SubscriptionThunk = {
  getSubscriptionsByOrganization,
  updateSubscription,
  cancelSubscription,
};
