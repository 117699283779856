import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Organization } from 'types';
import { EmailVerificationContainer } from 'containers/EmailVerification';
import { useAuth0 } from 'components/Common/Auth';
import SpinnerContainer from 'containers/Common/SpinnerContainer';
import { OrganizationThunk } from 'containers/Organisation';
import AlertContainer from 'containers/Common/AlertContainer';

import Layout from '../Layout';
import { DownloadProgressModal } from '../UI/DownloadProgressModal';
import { ORGANISATION_TYPES } from '../../../constants';

// an abstraction for having some components on all the screens
const App = () => {
  const {
    isAuthenticated,
    isEmailVerified,
    loading: authLoading,
  } = useAuth0()!;
  const history = useHistory();
  const dispatch = useDispatch();
  const [orgLoading, setOrgLoading] = useState(true);
  const [orgCalled, setOrgCalled] = useState(false);

  if (!authLoading && !orgCalled) {
    setOrgCalled(true);
    new Promise((resolve, reject) => {
      dispatch(
        OrganizationThunk.getOrganizations({
          resolve,
          reject,
        })
      );
    }).then((defaultOrg) => {
      const org = defaultOrg as Organization;
      if (org) {
        if (org.type === ORGANISATION_TYPES.COMPANY) {
          history.push(org.companies[0].url as string);
        } else {
          history.push(`${org.url}/updates`);
        }
      }
      setOrgLoading(false);
    });
  }

  if (isAuthenticated && !isEmailVerified) {
    // redirect the man to other things buddy
    return <EmailVerificationContainer />;
  }

  return authLoading || orgLoading ? (
    <SpinnerContainer />
  ) : (
    <>
      <SpinnerContainer />
      <CssBaseline />
      <DownloadProgressModal />
      <Layout />
      <AlertContainer />
    </>
  );
};

export default App;
