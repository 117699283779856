/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import config from 'config';
import { AlertState } from 'types';

export interface ConfigState {
  locale: string;
  alert: AlertState;
  loading: boolean;
}

const initialState = {
  locale: config.common.defaultLocale,
  alert: {
    open: false,
    message: '',
    severity: 'success',
  },
  loading: false,
} as ConfigState;

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setLocale(state, { payload }: PayloadAction<string>): void {
      state.locale = payload;
    },
    setAlert(state, { payload }: PayloadAction<AlertState>): void {
      state.alert = payload;
    },
    setLoading(state, { payload }: PayloadAction<boolean>): void {
      state.loading = payload;
    },
  },
});

const { actions, reducer } = slice;

export const { setLocale, setAlert, setLoading } = actions;

export type ConfigReducer = ReturnType<typeof reducer>;

export default reducer;
