import { VIEW_CONTENT_TYPE } from '../constants';

// eslint-disable-next-line consistent-return
export default (shared, organisationName) => {
  switch (shared) {
    case VIEW_CONTENT_TYPE[0].name.toLowerCase():
      return [VIEW_CONTENT_TYPE[0].name, '#636363'];
    case VIEW_CONTENT_TYPE[1].name.toLowerCase():
      return [organisationName, '#004b4f'];
    case VIEW_CONTENT_TYPE[2].name.toLowerCase():
      return [VIEW_CONTENT_TYPE[2].name, '#22c6bb'];
    default:
      break;
  }
};
