import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, InputLabel, Button } from '@material-ui/core';
import * as MaterialIcons from '@material-ui/icons';
import Dropzone from 'react-dropzone';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import messages from './messages';

const useStyles = makeStyles((theme) => ({
  uploader: {
    height: 110,
    width: 120,
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: '#cccc',
    '&:hover': {
      backgroundColor: '#ccc',
      cursor: 'pointer',
    },
  },
  imageUploadLabel: {
    paddingTop: theme.spacing(0.7),
    fontSize: 14,
    textTransform: 'capitalize',
  },
}));

const UploadButton = ({ uploadRef, onClickHandler, handleOnChangeUpload }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Dropzone
      onDrop={(acceptedFiles) =>
        handleOnChangeUpload({
          target: {
            files: acceptedFiles,
          },
        })
      }
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input
            type="file"
            onChange={handleOnChangeUpload}
            ref={uploadRef}
            id="input-file"
            {...getInputProps()}
          />
          <Button
            id="upload-file"
            className={classes.uploader}
            onClick={onClickHandler}
          >
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="space-evenly"
              height="100%"
            >
              <MaterialIcons.Add />
              <InputLabel className={classes.imageUploadLabel}>
                {intl.formatMessage(messages.dragText)}
              </InputLabel>
            </Box>
          </Button>
        </div>
      )}
    </Dropzone>
  );
};

UploadButton.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  handleOnChangeUpload: PropTypes.func.isRequired,
  uploadRef: PropTypes.any.isRequired,
};

// eslint-disable-next-line import/prefer-default-export
export { UploadButton };
