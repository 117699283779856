import React from 'react';
import PropTypes from 'prop-types';
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { ArrowDropUp } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import { CompanyAction } from '../../actions/company';
import { getComparator, stableSort } from './helpers';
import CompaniesEditRow from './CompaniesEditRow';
import { GET_COMPANY_METRICS_QUERY } from '../../graphql';

const themeSelector = createMuiTheme({
  palette: {
    primary: {
      main: '#3F51B5',
    },
    secondary: {
      main: 'rgba(0,0,0,0.56)',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  iconButtons: {
    display: 'flex',
  },
  table: {
    minWidth: 750,
  },
  trow: {
    '& td': {
      borderBottom: 'none',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  label: {
    '& svg': {
      width: '22px',
      height: '22px',
    },
  },
  draftText: {
    color: themeSelector.palette.primary.main,
    cursor: 'pointer',
  },
  reducedPadding: {
    paddingLeft: '7px',
    paddingRight: '7px',
  },
  runway: {
    borderRadius: '16px',
    color: '#fff',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function CompaniesTableHead(props) {
  const { classes, onRequestSort, orderBy, order, columns } = props;

  const headCells = columns;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.label}
              IconComponent={ArrowDropUp}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

CompaniesTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default function CompaniesTable({
  data,
  columns,
  companies,
  handleDraftRequestClick,
  onUpdateMetricValue,
}) {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [currentRow, setCurrentRow] = React.useState(null);
  const [currencyVal, setCurrencyVal] = React.useState('USD');
  const [inputValue, setInputValue] = React.useState('');
  const [currentCompanyId, setCurrentCompanyId] = React.useState('');
  const [metricName, setMetricName] = React.useState(null);
  const [cashFlowMetricId, setCashFlowMetricId] = React.useState(null);
  const [balanceSheetId, setBalanceSheetId] = React.useState(null);

  const { data: { getAllCompanyMetrics = [] } = {} } = useQuery(
    GET_COMPANY_METRICS_QUERY,
    {
      variables: {
        companyId: currentCompanyId,
        periodicity: 'Monthly',
        metricCategory: metricName,
      },
    }
  );

  const handleCashFlow = (name) => {
    setMetricName(name);
    if (name === 'Cashflow') {
      setCashFlowMetricId(getAllCompanyMetrics);
    }
  };

  const handleBalanceSheet = (name) => {
    setMetricName(name);
    if (name === 'BalanceSheet') {
      setBalanceSheetId(getAllCompanyMetrics);
    }
  };

  const handleSaveChanges = async () => {
    const getYear = new Date().getFullYear();
    const getMonth = new Date().getMonth() + 1;
    const dateForSortingMetrics = `${getYear}-0${getMonth}`;
    const config = {
      sortingNumber: 1,
      metricUnit: currencyVal,
    };
    const updateMetric = [];
    if (inputValue.cash) {
      const balanceSheetIdWithDate = balanceSheetId.find(
        (item) => item.date.slice(0, 7) === dateForSortingMetrics
      );
      updateMetric.push(
        onUpdateMetricValue({
          variables: {
            ...config,
            companyMetricId: balanceSheetIdWithDate.id,
            metricValue: inputValue.cash.replace(/[\s.,$€%]/g, ''),
            metricName: 'Cash',
          },
        })
      );
    }
    if (inputValue.cashFlow) {
      const cashFlowIdWithDate = cashFlowMetricId.find(
        (item) => item.date.slice(0, 7) === dateForSortingMetrics
      );
      updateMetric.push(
        onUpdateMetricValue({
          variables: {
            ...config,
            companyMetricId: cashFlowIdWithDate.id,
            metricValue: inputValue.cashFlow.replace(/[\s.,$€%]/g, ''),
            metricName: 'Operating Cashflow ("Burn")',
          },
        })
      );
    }

    await Promise.all(updateMetric);
    dispatch(CompanyAction.getCompaniesByOrganisation(params.id));
    setCurrentRow(null);
  };

  const setedCompanyId = (name) => {
    const filteredCompany = companies.find((item) => item.name === name);
    if (filteredCompany.runwayData !== null) {
      setCurrencyVal(filteredCompany.runwayData.currency);
    }
    setCurrentCompanyId(filteredCompany.id);
  };

  const handleStartEditing = (item) => {
    setCurrentRow(item.name);
    setedCompanyId(item.name);
  };

  const handleStopEditing = () => {
    setCurrentRow(null);
  };

  const handleChangeInputValue = (fieldName) => (event) => {
    setInputValue({
      ...inputValue,
      [fieldName]: event.target.value,
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredData = data.filter((item) => item.cashFlow !== 'N/A');

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <CompaniesTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              columns={columns}
            />
            <TableBody>
              {stableSort(filteredData, getComparator(order, orderBy)).map(
                (row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const isEditing = currentRow && currentRow === row.name;
                  return (
                    <TableRow
                      className={classes.trow}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.name}
                    >
                      {Object.entries(row).map((elem, i) => {
                        const ele = elem[1];
                        if (i === 0) {
                          return (
                            <TableCell id={labelId} scope="row">
                              <ThemeProvider theme={themeSelector}>
                                {ele}
                              </ThemeProvider>
                            </TableCell>
                          );
                        }
                        if (elem[0] === 'runway') {
                          return (
                            <TableCell align="left">
                              {typeof elem[1] === 'number' ? (
                                <div
                                  style={{
                                    backgroundColor:
                                      // eslint-disable-next-line no-nested-ternary
                                      elem[1] < 6
                                        ? '#EB5757'
                                        : elem[1] < 12
                                        ? '#F2994A'
                                        : '#27AE60',
                                  }}
                                  className={classes.runway}
                                >
                                  {`${elem[1]} months`}
                                </div>
                              ) : (
                                <div
                                  className={classes.runway}
                                  style={{ backgroundColor: '#828282' }}
                                >
                                  N/A
                                </div>
                              )}
                            </TableCell>
                          );
                        }
                        return isEditing ? (
                          <CompaniesEditRow
                            column={elem}
                            currencyVal={currencyVal}
                            columnValue={ele}
                            handleChangeInputValue={handleChangeInputValue}
                            handleCashFlow={handleCashFlow}
                            handleBalanceSheet={handleBalanceSheet}
                          />
                        ) : (
                          <TableCell align="left">{ele}</TableCell>
                        );
                      })}
                      <TableCell align="left">
                        <Collapse in={!isEditing}>
                          <IconButton
                            disabled={row.cashFlow === 'N/A'}
                            onClick={() => handleStartEditing(row)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDraftRequestClick(
                                companies.find((item) => item.name === row.name)
                              );
                            }}
                          >
                            <MailOutlineRoundedIcon />
                          </IconButton>
                        </Collapse>
                        <Collapse in={isEditing}>
                          <div className={classes.iconButtons}>
                            <IconButton onClick={handleStopEditing}>
                              <CloseIcon />
                            </IconButton>
                            <IconButton onClick={handleSaveChanges}>
                              <DoneIcon />
                            </IconButton>
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

CompaniesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  companies: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  handleDraftRequestClick: PropTypes.func,
  onUpdateMetricValue: PropTypes.func,
};

CompaniesTable.defaultProps = {
  companies: [],
  columns: [],
  handleDraftRequestClick: () => {},
  onUpdateMetricValue: () => {},
};
