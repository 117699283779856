import { gql } from 'apollo-boost';

export const UPDATE_SUBSCRIPTION = gql`
  mutation(
    $id: ID
    $organisationId: ID!
    $type: SUBSCRIPTION_TYPE!
    $billingInfo: String
    $contactEmail: String
  ) {
    updateSubscription(
      input: {
        id: $id
        organisationId: $organisationId
        type: $type
        billingInfo: $billingInfo
        contactEmail: $contactEmail
      }
    ) {
      organisation {
        id
      }
      type
      booking {
        billingInfo
        contactEmail
      }
      user {
        id
      }
      id
      dateExpire
      createdAt
      updatedAt
    }
  }
`;

export const GET_SUBSCRIPTION_BY_ORGANISATION = gql`
  query($organisationId: ID!) {
    subscriptionByOrganisation(organisationId: $organisationId) {
      id
      type
      createdAt
      updatedAt
      dateExpire
      booking {
        billingInfo
        contactEmail
      }
      user {
        id
        firstName
        email
      }
      organisation {
        id
      }
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation($subscriptionId: ID!) {
    deleteSubscription(subscriptionId: $subscriptionId)
  }
`;
