import React, { MouseEvent } from 'react';
import { Paper, Typography, Button, Box } from '@material-ui/core';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { KeytrackLogoWhite } from 'components/Common/Assets';

import { useAuth0 } from '../Auth';
import messages from './messages';
import { useStyles } from './styles';

interface Props {
  resendEmailVerification(userId: string): void;
}

export const EmailVerification = ({
  resendEmailVerification,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { user, logout } = useAuth0()!;

  const handleResendEmailVerification = () => {
    resendEmailVerification(user.id);
  };

  const handleNavigateSignup = (e: MouseEvent) => {
    e.preventDefault();
    logout();
  };

  return (
    <div className={classes.container}>
      <Paper elevation={3} className={classes.paper}>
        <div className={classes.logoWrapper}>
          <KeytrackLogoWhite />
        </div>
        <div className={classes.contentWrapper}>
          <Typography
            component="h2"
            variant="h5"
            color="primary"
            gutterBottom
            className={classes.title}
          >
            {formatMessage(messages.title)}
          </Typography>
          <Typography
            component="p"
            variant="body2"
            color="primary"
            gutterBottom
            className={classes.title}
          >
            {formatMessage(messages.direction)}
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Button
              color="primary"
              variant="contained"
              onClick={handleResendEmailVerification}
              className={classes.actionBtn}
            >
              {`${formatMessage(messages.resendEmailVerification)}`}
            </Button>
            <Button onClick={handleNavigateSignup}>
              Sign in to another account?
            </Button>
          </Box>
        </div>
      </Paper>
    </div>
  );
};

EmailVerification.propTypes = {
  resendEmailVerification: PropTypes.func.isRequired,
};
