import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Colors } from 'constants/colors';

// eslint-disable-next-line import/prefer-default-export
export const DeleteButton = withStyles(() => ({
  root: {
    width: '70%',
    background: Colors.DELETE,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    '&:hover': {
      background: Colors.DELETE_DARK,
    },
  },
  label: {
    color: Colors.WHITE,
    textTransform: 'capitalize',
  },
}))(Button);
