/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import Backdrop from '@material-ui/core/Backdrop';
import ListSubheader from '@material-ui/core/ListSubheader';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import { Button } from 'components/Common/Buttons';
import translations from 'translations';
import { COMPANY_CONTACT_TYPE, CONTACT_PLACEHOLDERS } from 'constants/index';

import { useStyles } from './styles';
import messages from './messages';
import MailContactRow from './MailContactRow';

export default function MailPopup({
  open,
  handleToggleModal,
  draftRequestItem = {},
  handleSubmit,
  companyContacts,
  selectedBulkRequest,
}) {
  const classes = useStyles();
  const intl = useIntl();

  const [draftRequestItemLocal, setDraftRequestItemLocal] = useState({});
  const [carbonCopyingItemLocal, setCarbonCopyingItemLocal] = useState({});
  const [mainText, setMainText] = useState('');
  const [isBulkRequest, setIsBulkRequest] = useState(false);
  const [placeholderEl, setPlaceholderEl] = React.useState(null);
  const drafts = {
    subject: 'Kindly can you review data for [[company.name]] on Keytrack',
    text: () =>
      `Hi [[contact.first_name]],
      \n` +
      'We are using Keytrack to organise our portfolio company data. Kindy can you review if the data on' +
      ' [[company.name]] ' +
      'is up to date. Your information is treated confidential.\n\n' +
      'Many thanks for your help.\n\n' +
      `Kind regards,\n[[user.first_name]] \n\n` +
      'You can update data by logging into (https://app.keytrack.io) Keytrack.\n' +
      `If you need help, please contact me or Keytrack at [[keytrack.email]].\n`,
  };
  const [subject, setSubject] = useState(drafts.subject);

  const handleChangeContactMail = (value, target) => {
    switch (target) {
      case 'DraftItem':
        setDraftRequestItemLocal({
          ...draftRequestItemLocal,
          contact: value,
        });
        break;
      case 'CarbonCopyingItem': {
        setCarbonCopyingItemLocal({
          ...carbonCopyingItemLocal,
          contact: value,
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleClick = (event) => {
    setPlaceholderEl(event.currentTarget);
  };

  const handleClose = () => {
    setPlaceholderEl(null);
  };

  const formateBulkContact = (data, contactOption) =>
    data.reduce((contacts, contact) => {
      const contactType =
        contact.contactType === COMPANY_CONTACT_TYPE.CONTACT ? 0 : 1;
      if (contactOption.includes(contactType)) {
        contacts.push({
          id: contact.id,
          email: contact.email,
          firstName: contact.firstName,
          lastName: contact.lastName,
          contactType: contact.contactType,
        });
      }
      return contacts;
    }, []);

  const handleSendRequests = () => {
    let recipients = [];
    let ccRecipients = [];
    let companyName = null;
    let companyId = null;
    let bulkData = null;
    if (isArray(selectedBulkRequest) && selectedBulkRequest.length > 1) {
      bulkData = selectedBulkRequest.map((obj) => ({
        id: obj.id,
        companyName: obj.name,
        contacts: formateBulkContact(
          obj.contacts,
          draftRequestItemLocal.contact
        ),
        managers: formateBulkContact(
          obj.contacts,
          carbonCopyingItemLocal.contact
        ),
      }));
    } else {
      recipients =
        draftRequestItemLocal.contact && draftRequestItemLocal.contact.length
          ? draftRequestItemLocal.contact
          : [];
      ccRecipients =
        carbonCopyingItemLocal &&
        carbonCopyingItemLocal.contact &&
        carbonCopyingItemLocal.contact.length
          ? carbonCopyingItemLocal.contact
          : [];
      companyName = draftRequestItem.name;
      companyId = draftRequestItem.id;
    }
    const dataForSend = {
      companyId,
      companyName,
      recipients,
      mainText,
      subject,
      bulkData,
    };

    if (isArray(ccRecipients)) {
      dataForSend.cc = ccRecipients;
    }
    handleSubmit(dataForSend);
  };

  useEffect(() => {
    setSubject(drafts.subject);
    setMainText(drafts.text());
  }, [draftRequestItem]);

  useEffect(() => {
    if (!open) {
      return;
    }
    if (!isBulkRequest) {
      const item = { ...draftRequestItem };
      item.contact = item.firstCompanyContact;
      setDraftRequestItemLocal(item);
      setCarbonCopyingItemLocal((cc) => ({
        ...cc,
        contact: item.secCompanyContact,
      }));
    }
  }, [open]);

  useEffect(() => {
    const bool = isArray(selectedBulkRequest) && selectedBulkRequest.length > 1;
    setSubject(drafts.subject);
    setMainText(drafts.text());
    setIsBulkRequest(bool);
  }, [JSON.stringify(selectedBulkRequest)]);

  return (
    <div>
      <Modal
        onClose={handleToggleModal}
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.paperModal}>
            <Typography
              component="h4"
              variant="h4"
              color="primary"
              gutterBottom
              className={classes.sectionTitles}
            >
              {intl.formatMessage(messages.requestData)}
            </Typography>
            <div className={classes.emptySpace} />
            <MailContactRow
              data={draftRequestItemLocal}
              useContact="first"
              onChangeName={(value) =>
                handleChangeContactMail(value, 'DraftItem')
              }
              // onChangeField={(fieldName) => handleChangeContactType(fieldName, 'DraftItem')}
              contactsData={companyContacts}
              isBulkRequest={isBulkRequest}
            />
            <MailContactRow
              data={{ ...draftRequestItemLocal, ...carbonCopyingItemLocal }}
              useContact="second"
              onChangeName={(value) =>
                handleChangeContactMail(value, 'CarbonCopyingItem')
              }
              // onChangeField={(fieldName) => handleChangeContactType(fieldName, 'CarbonCopyingItem')}
              contactsData={companyContacts}
              isBulkRequest={isBulkRequest}
            />
            <Typography
              component="h5"
              color="primary"
              gutterBottom
              className={classes.sectionSubtitles}
            >
              {intl.formatMessage(messages.subject)}
            </Typography>
            <div className={classes.subjectContainer}>
              <div className={classes.subjectWrapper}>
                <TextField
                  variant="outlined"
                  onChange={(e) => setSubject(e.target.value)}
                  className={`${classes.subjectInput} ${classes.subjectField}`}
                  value={subject}
                  size="small"
                  fullWidth
                  error={!subject}
                  helperText={
                    !subject
                      ? intl.formatMessage(
                          translations.RequestData.subjectEmptyError
                        )
                      : ''
                  }
                />
              </div>
            </div>
            <TextField
              variant="outlined"
              onChange={(e) => setMainText(e.target.value)}
              multiline
              style={{ marginTop: '30px' }}
              rows={8}
              className={classes.subjectInput}
              value={mainText}
              fullWidth
              // disabled={isBulkRequest}
              error={!mainText}
              helperText={
                !mainText
                  ? intl.formatMessage(
                      translations.RequestData.mainTextEmptyError
                    )
                  : ''
              }
            />
            <div className={classes.placeholderContainer}>
              <Button variant="text" typeColor="default" onClick={handleClick}>
                <Typography>
                  <FormattedMessage
                    {...translations.RequestData.paceHoldersButton}
                  />
                </Typography>
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={placeholderEl}
                keepMounted
                open={Boolean(placeholderEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                getContentAnchorEl={null}
              >
                <List style={{ outline: 'none' }}>
                  <ListSubheader style={{ fontSize: 24 }}>
                    Placeholders
                  </ListSubheader>
                  {Object.entries(CONTACT_PLACEHOLDERS).map((ele) => (
                    <ListItem>
                      <ListItemText primary={`${ele[0]}: ${ele[1]}`} />
                    </ListItem>
                  ))}
                </List>
              </Menu>
            </div>
            <Button
              className={classes.draftRequestButton}
              onClick={handleSendRequests}
              typeColor="primary"
              disabled={
                !isBulkRequest
                  ? !draftRequestItemLocal.contact ||
                    !draftRequestItemLocal.contact.length ||
                    !subject ||
                    !mainText
                  : !selectedBulkRequest || selectedBulkRequest.length <= 1
              }
            >
              <FormattedMessage {...messages.send} />
            </Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

MailPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleToggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  draftRequestItem: PropTypes.object,
  companyContacts: PropTypes.arrayOf(PropTypes.object),
  selectedBulkRequest: PropTypes.arrayOf(PropTypes.object).isRequired,
};

MailPopup.defaultProps = {
  draftRequestItem: {},
  companyContacts: [],
};
