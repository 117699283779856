import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    noPadding: {
      padding: 0,
    },
    lesspadding: {
      padding: 13,
    },
    head: {
      height: 55,
    },
    input: {
      marginTop: 0,
      marginBottam: 0,
    },
    name: {
      width: 105,
      padding: '0px 12px 0px 12px',
    },
    unit: {
      width: 80,
      padding: '0px 12px 0px 12px',
      '& > div': {
        minWidth: 80,
        width: '100%',
        '& .MuiInputBase-root': {
          flexBasis: '100%',
        },
      },
    },
    metricValue: {
      width: 81,
      padding: '0px 12px 0px 12px',
    },
    emptySpaceForIcon: {
      width: 48,
      height: 47,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    columnAction: {
      marginTop: '12px',
      '& .MuiSvgIcon-root': {
        fontSize: '2rem',
      },
    },
  })
);

export const useToolbarStyles = makeStyles(() => ({
  root: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    minHeight: '50px',
  },
}));
