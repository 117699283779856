import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { selectSelectedOrganization } from 'containers/Organisation';
import DefaultKpiScreen from 'components/Organisation/OrganizationSettings/DefualtKpi';
import { DefaultKpi, KpiRow } from 'types';
import {
  GET_KPI_DEFAULT_VALUES,
  UPDATE_DEFAULT_KPI,
} from 'containers/Organisation/ducks/query';
import { setAlert } from 'containers/Config';
import translations from 'translations';
import { initialValue } from 'constants/index';
import getGraphqlErrorMessage from 'common/getGraphqlErrorMessage';

const DefaultKpiContainer = (): JSX.Element => {
  const organisation = useSelector(selectSelectedOrganization);
  const dispatch = useDispatch();

  const {
    data: { getKpiDefaultValues: defaultKpiValues = initialValue } = {},
    refetch: refetchDefaultkpi,
  } = useQuery(GET_KPI_DEFAULT_VALUES, {
    variables: {
      input: {
        orgId: organisation.id,
      },
    },
  });

  const [UpdateDefaultKpi] = useMutation(UPDATE_DEFAULT_KPI, {
    onCompleted: () => {
      dispatch(
        setAlert({
          open: true,
          message: translations.OrganisationSettings.onSuccessUpdateDefaultKpi,
          severity: 'success',
        })
      );
    },
    onError: (error) => {
      const message =
        getGraphqlErrorMessage(error) ||
        translations.OrganisationSettings.onErrorUpdateDefaultKpi;
      dispatch(
        setAlert({
          open: true,
          message,
          severity: 'error',
        })
      );
    },
  });

  const handleUpdateDefaultKpi = async (
    values: KpiRow
  ): Promise<DefaultKpi> => {
    const newDefaultKpi = {
      periodicity: defaultKpiValues.periodicity,
      values: JSON.parse(JSON.stringify(defaultKpiValues.data)),
    };
    newDefaultKpi.values[values.id!] = {
      metric: values.metric,
      unit: values.unit,
    };
    const {
      data: { updateDefaultKPI: defaultValues },
    } = await UpdateDefaultKpi({
      variables: {
        input: {
          orgId: organisation.id,
          data: newDefaultKpi,
        },
      },
    });
    await refetchDefaultkpi();
    return defaultValues;
  };

  const handledeleteDefaultKpi = async (id: number): Promise<DefaultKpi> => {
    const newDefaultKpi = {
      periodicity: defaultKpiValues.periodicity,
      values: JSON.parse(JSON.stringify(defaultKpiValues.data)),
    };
    newDefaultKpi.values.splice(id, 1);
    const {
      data: { updateDefaultKPI: defaultValues = initialValue } = {},
    } = await UpdateDefaultKpi({
      variables: {
        input: {
          orgId: organisation.id,
          data: newDefaultKpi,
        },
      },
    });
    await refetchDefaultkpi();
    return defaultValues;
  };

  const handleAddDefaultkpi = async (values: KpiRow): Promise<DefaultKpi> => {
    const newDefaultKpi = {
      periodicity: defaultKpiValues.periodicity,
      values: JSON.parse(JSON.stringify(defaultKpiValues.data)),
    };
    newDefaultKpi.values.push({
      metric: values.metric,
      unit: values.unit,
    });
    const {
      data: { updateDefaultKPI: defaultValues = initialValue } = {},
    } = await UpdateDefaultKpi({
      variables: {
        input: {
          orgId: organisation.id,
          data: newDefaultKpi,
        },
      },
    });
    await refetchDefaultkpi();
    return defaultValues;
  };

  const UpdatePeriodicity = async (
    periodicity: string
  ): Promise<DefaultKpi> => {
    const newDefaultKpi = {
      periodicity,
      values: JSON.parse(JSON.stringify(defaultKpiValues.data)),
    };
    const {
      data: { updateDefaultKPI: defaultValues = initialValue } = {},
    } = await UpdateDefaultKpi({
      variables: {
        input: {
          orgId: organisation.id,
          data: newDefaultKpi,
        },
      },
    });
    await refetchDefaultkpi();
    return defaultValues;
  };

  return (
    <DefaultKpiScreen
      handleUpdateDefaultKpi={handleUpdateDefaultKpi}
      handleAddDefaultkpi={handleAddDefaultkpi}
      handledeleteDefaultKpi={handledeleteDefaultKpi}
      UpdatePeriodicity={UpdatePeriodicity}
      defaultKpiValues={JSON.parse(JSON.stringify(defaultKpiValues))}
    />
  );
};

export default DefaultKpiContainer;
