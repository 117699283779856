/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable operator-linebreak */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { getImagesUrl } from 'common/getDownloads';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  Grid,
  Avatar,
  Switch,
  TextField,
  FormGroup,
  InputLabel,
  FormControlLabel,
} from '@material-ui/core';
import translations from 'translations';
import { useStyles } from './styles';
import { validateInput } from './inputValidation';

import {
  GET_API_COMPANIES_QUERY,
  GET_COMPANY_OTHER_DATA,
} from '../../../graphql';

const initialState = {
  id: '',
  companyName: '',
  location: '',
  website: '',
  shortDescription: '',
};

const CompanySearched = ({
  showShared,
  getCompanySearchedSate,
  from,
  getSignal,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [searchDb, setSearchDb] = useState(true);
  // const [crunchbaseCompany, setCrunchbaseCompany] = useState({});
  const [companySearchedState, setCompanySearchedState] = useState(
    initialState
  );
  const [skeletonSiginal, setSkeletonSiginal] = useState(1);

  const [valid, setValid] = useState({
    companyNameValid: false,
    companyNameHelperText: '',
    locationValid: false,
    locationHelperText: '',
    websiteValid: false,
    websiteHelperText: '',
  });

  const handleCheckDbChange = () => setSearchDb(!searchDb);

  const [
    loadCrunchbaseCompanies,
    { data: { crunchbaseCompanies = [] } = {} },
  ] = useLazyQuery(GET_API_COMPANIES_QUERY, {
    variables: { searchTerm: '' },
    fetchPolicy: 'no-cache',
  });

  const { refetch: getCompanyOtherData } = useQuery(GET_COMPANY_OTHER_DATA, {
    fetchPolicy: 'no-cache',
    variables: { apiCompanyId: '', logoUrl: '' },
  });

  const handleOnChange = (key, byPropValue, validate = true) => (
    event,
    propValue
  ) => {
    const value = byPropValue ? propValue : event.target.value;

    setCompanySearchedState({
      ...companySearchedState,
      [key]: value,
    });

    if (validate) {
      const validation = validateInput(key, value);
      return setValid({
        ...valid,
        ...validation,
      });
    }
  };

  const getCrunchbaseCompany = async (company) => {
    validateInput('companyName', company.value);
    setSkeletonSiginal(2);
    getSignal(2);

    const {
      data: { crunchbaseCompaniesById: otherData = {} } = {},
    } = async () => {
      await getCompanyOtherData({
        apiCompanyId: company.uuid,
        logoUrl: company.logoUrl,
      });
      return null;
    };

    let check = null;
    check = async () => {
      await getCompanyOtherData({
        apiCompanyId: company.uuid,
        logoUrl: company.logoUrl,
      });
      return null;
    };

    if (check) {
      setSkeletonSiginal(3);
      getSignal(3);
    }
    // setCrunchbaseCompany(company);
    setCompanySearchedState({
      ...companySearchedState,
      id: company.uuid,
      location: otherData.location,
      website: otherData.website,
      companyName: company.value,
      imageUrl: getImagesUrl(company.logoUrl),
      shortDescription: company.shortDescription,
    });
  };

  // eslint-disable-next-line consistent-return
  const sendStateToParent = () => {
    // companySearchedState it not returning updated value
    // therefor i had to do this

    let obj = {};
    setCompanySearchedState((prev) => {
      obj = { ...prev };
      return prev;
    });

    const validation = validateInput(
      searchDb ? 'companyName-crunchbase' : 'companyName',
      obj.companyName,
      from
    );

    if (validation.companyNameValid) {
      setValid({
        ...valid,
        ...validation,
      });
    } else {
      setValid({
        ...valid,
        companyNameValid: false,
        companyNameHelperText: '',
      });

      // to reset all fields to initial
      setCompanySearchedState(initialState);

      return obj;
    }
  };

  useEffect(() => {
    getCompanySearchedSate(sendStateToParent);
  }, [getCompanySearchedSate, sendStateToParent]);

  return (
    <>
      {!showShared && (
        <Grid item xs={12}>
          <InputLabel className={classes.label}>
            {`${intl.formatMessage(
              translations.Company.companySearched.companyNameLabel
            )}`}
          </InputLabel>
          <FormGroup row className={classes.companyNameRow}>
            {!searchDb ? (
              <TextField
                className={classes.formControlCol}
                value={companySearchedState.companyName}
                onChange={handleOnChange('companyName')}
                variant="outlined"
                size="small"
                error={valid.companyNameValid}
                helperText={valid.companyNameHelperText}
              />
            ) : (
              <Autocomplete
                loadingText="No options selected"
                className={classes.formControlCol}
                id="autocomplete"
                disableClearable
                options={crunchbaseCompanies}
                getOptionLabel={(option) => option.value}
                onChange={(_, value) => {
                  if (value) {
                    getCrunchbaseCompany(value);
                  }
                }}
                inputValue={companySearchedState.companyName}
                onInputChange={(event, value) => {
                  const valids = !handleOnChange('companyName', true)(
                    event,
                    value
                  );
                  if (searchDb && valids && value) {
                    loadCrunchbaseCompanies({
                      variables: {
                        searchTerm: value,
                      },
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.autoCompleteTextField}
                    size="small"
                    error={valid.companyNameValid}
                    helperText={valid.companyNameHelperText}
                    label=""
                    variant="outlined"
                  />
                )}
              />
            )}
            <FormControlLabel
              control={
                <Switch
                  name="findInDB"
                  color="primary"
                  classes={{
                    switchBase: classes.switchBase,
                    track: classes.track,
                    checked: classes.checked,
                  }}
                />
              }
              label={`${intl.formatMessage(
                translations.Company.companySearched.findDbLabel
              )}`}
              className={classes.formControlCol}
              checked={searchDb === true}
              onChange={handleCheckDbChange}
            />
          </FormGroup>
        </Grid>
      )}
      <Grid container item xs={12} alignItems="center">
        {!showShared && (
          <div style={{ display: searchDb ? 'initial' : 'none' }}>
            <InputLabel className={classes.previewLabel}>
              {`${intl.formatMessage(
                translations.Company.companySearched.previewLabel
              )}`}
            </InputLabel>
            {skeletonSiginal === 2 ? (
              <Grid
                container
                item
                xs={12}
                alignItems="flex-start"
                justify="space-between"
                className={classes.row}
                style={{ alignItems: 'flex-start' }}
              >
                <Skeleton variant="circle" width={40} height={40} />
                <Skeleton
                  variant="text"
                  style={{ marginLeft: 10 }}
                  width={100}
                />
                <Skeleton variant="text" width={100} />
                <div>
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={100} />
                </div>
              </Grid>
            ) : (
              <Grid
                container
                item
                xs={12}
                alignItems="flex-start"
                justify="space-between"
                className={classes.row}
                style={{ alignItems: 'flex-start' }}
              >
                <div className={classes.formControlAvatar}>
                  <Avatar
                    variant="square"
                    // alt={selectedCompany && selectedCompany.value}
                    src={companySearchedState.imageUrl}
                    className={classes.large}
                  />
                </div>
                <div className={classes.detailsDiv}>
                  <InputLabel className={classes.label}>
                    {`${intl.formatMessage(
                      translations.Company.companySearched.locationLabel
                    )}`}
                  </InputLabel>
                  <InputLabel className={classes.label}>
                    {`${intl.formatMessage(
                      translations.Company.companySearched.websiteLabel
                    )}`}
                  </InputLabel>
                </div>
                <div className={classes.detailsDiv}>
                  <InputLabel className={classes.label}>
                    {companySearchedState.location}
                  </InputLabel>
                  <InputLabel className={classes.label}>
                    {companySearchedState.website}
                  </InputLabel>
                </div>
                <div className={classes.descriptionDiv}>
                  <div style={{ paddingRight: 20 }}>
                    <InputLabel className={classes.label}>
                      {`${intl.formatMessage(
                        translations.Company.companySearched.descriptionLabel
                      )}`}
                    </InputLabel>
                  </div>
                  <div className={classes.descriptionWrapper}>
                    <InputLabel className={classes.label}>
                      {companySearchedState.shortDescription}
                    </InputLabel>
                  </div>
                </div>
              </Grid>
            )}
          </div>
        )}
      </Grid>
    </>
  );
};

CompanySearched.propTypes = {
  loading: PropTypes.bool,
  from: PropTypes.string,
  showShared: PropTypes.bool,
  organisation: PropTypes.object,
  onCreateCompany: PropTypes.func,
  crunchbaseCompanies: PropTypes.arrayOf(PropTypes.object),
  selectedOrganisation: PropTypes.object,
  sharedCompanies: PropTypes.arrayOf(PropTypes.object),
  unshareOrganisations: PropTypes.func,
  addCompanyFromShared: PropTypes.func,
  getCompanySearchedSate: PropTypes.func.isRequired,
  getSignal: PropTypes.func,
};

CompanySearched.defaultProps = {
  loading: false,
  from: '',
  organisation: {},
  showShared: false,
  onCreateCompany: null,
  crunchbaseCompanies: [],
  selectedOrganisation: {},
  sharedCompanies: [],
  unshareOrganisations: async () => {},
  addCompanyFromShared: async () => {},
  getSignal: () => {},
};

// eslint-disable-next-line import/prefer-default-export
export { CompanySearched };
