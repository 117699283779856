/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import {
  createSlice,
  Action,
  CombinedState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import {
  Organization,
  Portfolio,
  UpdatePortfolioPayload,
  Company,
  CompaniesShort,
  Investment,
  User,
  OrganizationSliceState,
  OrganizationShort,
  Companies,
  DeleteContact,
  CompanySettings,
  Subscriptions,
  Subscription,
  SubscriptionLimitResponse,
} from 'types';
import { ORGANISATION_TYPES } from 'constants/index';
import buildUrn from 'common/buildUrn';

interface UpdateContact {
  organisationId: string;
  contactType: string;
  email: string;
  id: string;
  firstName: string;
  lastName: string;
  userId: string;
}

const initialState = {
  organization: -1,
  isLoading: true,
  organizations: [],
  accessLimit: {
    companies: false,
    users: false,
    portfolios: false,
    hasLimit: false,
    hasTrial: false,
  },
} as OrganizationSliceState;

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    /* ORGANIZATION REDUCERS */
    getOrganizations(state): void {
      state.isLoading = true;
    },
    getOrganizationsSuccess(
      state,
      { payload }: PayloadAction<Array<Organization>>
    ): void {
      state.organizations = payload;
      state.isLoading = false;
    },
    getOrganizationsFail(state): void {
      state.isLoading = false;
    },
    getSelectedOrganization(state): void {
      state.isLoading = true;
    },
    getSelectedOrganizationSuccess(
      state,
      { payload }: PayloadAction<number>
    ): void {
      state.organization = payload;
      state.isLoading = false;
    },
    getSelectedOrganizationFail(state): void {
      state.isLoading = false;
    },
    createOrganization(state): void {
      state.isLoading = true;
    },
    createOrganizationSuccess(
      state,
      { payload }: PayloadAction<Organization>
    ): void {
      state.isLoading = false;
      state.organizations.push(payload);
      state.organization = state.organizations.length - 1;
    },
    createOrganizationFail(state): void {
      state.isLoading = false;
    },
    addOrganization(state, { payload }: PayloadAction<Organization>): void {
      state.isLoading = false;
      state.organizations.push(payload);
      if (payload.type !== ORGANISATION_TYPES.COMPANY) {
        state.organization = state.organizations.length - 1;
      }
    },
    updateSelectedOrganization(
      state,
      { payload }: PayloadAction<Organization>
    ): void {
      const { portfolios, companies, users } = payload;
      state.organizations[state.organization].portfolios = portfolios;
      state.organizations[state.organization].companies = companies;
      state.organizations[state.organization].users = users;
    },

    updateCompanySettings(state): void {
      state.isLoading = true;
    },

    updateCompanySettingSuccess(
      state,
      { payload }: PayloadAction<Array<CompanySettings>>
    ): void {
      state.organizations[state.organization].companySettings = payload.sort(
        (a, b) => a.sorting - b.sorting
      );
      state.isLoading = false;
    },

    updateCompanySettingsFail(state): void {
      state.isLoading = false;
    },

    /* END OF ORGANIZATION REDUCERS */

    /* COMPANY REDUCERS */

    updateOrgCompanies(
      state,
      { payload }: PayloadAction<Array<Organization>>
    ): void {
      state.organizations = payload;
    },

    updateCompany(state): void {
      state.isLoading = true;
    },
    updateCompanySuccess(state, { payload }: PayloadAction<Company>): void {
      state.isLoading = false;
      const { organization: index, organizations } = state;
      const companyIndex = organizations[index].companies.findIndex(
        (ele) => ele.id === payload.id
      );
      if (companyIndex === -1) return;
      organizations[index].companies[companyIndex] = {
        ...organizations[index].companies[companyIndex],
        ...payload,
      };
    },
    updateCompanyFail(state): void {
      state.isLoading = false;
    },
    updateContacts(state): void {
      state.isLoading = true;
    },
    updateContactsSuccess(state, { payload }: PayloadAction<Companies>): void {
      state.organizations[state.organization].companies = payload;
      state.isLoading = false;
    },
    updateContactsFail(state): void {
      state.isLoading = false;
    },
    addCompanyFromShared(state): void {
      state.isLoading = true;
    },
    addCompanyFromSharedSuccess(
      state,
      { payload }: PayloadAction<Organization>
    ): void {
      const {
        companies: [newCompany],
      } = payload;
      const index = state.organization;
      if (index < 0 || !state.organizations.length) {
        return;
      }
      const selectedOrg = state.organizations[index];
      selectedOrg.companies.push(newCompany);
      state.organizations.push(payload);
      state.organizations[index] = selectedOrg;
      state.isLoading = false;
    },
    addCompanyFromSharedFail(state): void {
      state.isLoading = false;
    },
    deleteCompanies(state): void {
      state.isLoading = true;
    },
    deleteCompaniesSuccess(
      state,
      {
        payload,
      }: PayloadAction<
        Array<{
          id: string;
          organisation: string;
        }>
      >
    ): void {
      const orgIndex = state.organization;
      const selectedOrg = state.organizations[orgIndex];
      const newCompanies: CompaniesShort = [];
      const deletingOrgs: Array<string | undefined> = [];
      selectedOrg.companies.forEach((company) => {
        if (!payload.some((ele) => ele.id === (company.id as string))) {
          newCompanies.push(company);
        } else {
          const {
            organisation: { id: companyOrganisation } = { id: '' },
          } = company;
          let companyId = companyOrganisation || '';
          if (!companyId.includes('urn::'))
            companyId = buildUrn('organisation', companyId);
          deletingOrgs.push(companyId);
        }
      });
      const newOrgs = state.organizations.filter(
        (org) => !deletingOrgs.includes(org.id)
      );
      selectedOrg.companies = JSON.parse(JSON.stringify(newCompanies));
      state.organizations = JSON.parse(JSON.stringify(newOrgs));
      state.organizations[orgIndex] = selectedOrg;
      state.isLoading = false;
    },
    deleteCompaniesFail(state): void {
      state.isLoading = false;
    },

    /* END OF COMPANY REDUCERS */

    /* PORTFOLIO REDUCERS */

    addPortfolio(state, { payload }: PayloadAction<Portfolio>): void {
      state.isLoading = false;
      const { organization: index, organizations } = state;
      organizations[index].portfolios.items?.push(payload);
      const {
        portfolios: { totalCount: count },
      } = organizations[index];
      organizations[index].portfolios.totalCount = count ? count + 1 : 1;
    },
    deletePortfolio(state, { payload }: PayloadAction<Array<string>>): void {
      state.isLoading = false;
      const { organization: index, organizations } = state;
      const newPortfolios: Array<Portfolio> = [];
      organizations[index].portfolios.items?.forEach((portfolio) => {
        if (!payload.includes(portfolio.id)) newPortfolios.push(portfolio);
      });
      organizations[index].portfolios.items = newPortfolios;
      organizations[index].portfolios.totalCount = newPortfolios.length;
    },
    updatePortfolioReducer(
      state,
      { payload }: PayloadAction<UpdatePortfolioPayload>
    ): void {
      state.isLoading = false;
      const { organization: index, organizations } = state;
      const { defaultPortfolioId } = payload;
      if (defaultPortfolioId) {
        state.organizations[index].portfolios.items = state.organizations[
          index
        ].portfolios.items.map((ele) => {
          if (ele.id === defaultPortfolioId)
            return {
              ...ele,
              isDefault: true,
            };
          return {
            ...ele,
            isDefault: false,
          };
        });
        return;
      }
      const portfolioIndex = organizations[index].portfolios.items.findIndex(
        (ele) => ele.id === payload.id
      );
      if (portfolioIndex === -1) return;
      const prevData = organizations[index].portfolios.items[portfolioIndex];
      const {
        creationYear = prevData.creationYear,
        name = prevData.name,
        size = prevData.size,
        sizeCurrency = prevData.sizeCurrency,
        targetNumberOfInvestments = prevData.targetNumberOfInvestments,
      } = payload;
      organizations[index].portfolios.items[portfolioIndex] = {
        ...prevData,
        creationYear,
        name,
        size: {
          amount: size as number,
          currency: sizeCurrency as string,
        },
        targetNumberOfInvestments,
      };
    },
    /* END OF PORTFOLIO REDUCERS */

    /* INVESTMENT REDUCERS */
    deleteInvestmentReducer(
      state,
      {
        payload,
      }: PayloadAction<
        Array<{
          portfolioId: string;
          id: string;
        }>
      >
    ): void {
      state.isLoading = false;
      const { organization: index, organizations } = state;
      payload.forEach((ele) => {
        const { portfolioId, id } = ele;
        const portfolioIndex = organizations[index].portfolios.items.findIndex(
          (elem) => elem.id === portfolioId
        );
        let removedInvestment = {} as Investment;
        const investments: Array<Investment> = [];
        organizations[index].portfolios.items[
          portfolioIndex
        ].investments.items.forEach((elem) => {
          if (elem.id !== id) {
            return investments.push(elem);
          }
          removedInvestment = elem;
          return elem;
        });
        const oldInvestments =
          organizations[index].portfolios.items[portfolioIndex].investments;

        organizations[index].portfolios.items[portfolioIndex].investments = {
          ...organizations[index].portfolios.items[portfolioIndex].investments,
          totalInvested:
            oldInvestments.totalInvested -
            removedInvestment.totalInvestments *
              +(removedInvestment.exitStatus === 'current'),
          totalCount: investments.length,
          totalReserves:
            oldInvestments.totalReserves - removedInvestment.reserved,
          items: [...investments],
        };
      });
    },

    addInvestmentsReducer(
      state,
      { payload: investments }: PayloadAction<Investment[]>
    ): void {
      const { organization: index, organizations } = state;

      investments.forEach((investment: Investment) => {
        const portfolioIndex = organizations[index].portfolios.items.findIndex(
          (ele) => ele.id === investment.portfolio?.id || null
        );
        const {
          id,
          name,
          investedAmount,
          involvement,
          ownership,
          reserved,
          exitStatus,
          totalInvestments,
          date,
          description,
          shares,
          roundSize,
          companyValuation,
          investors,
          portfolio,
          company,
        } = investment;
        const previousObj =
          organizations[index].portfolios.items[portfolioIndex].investments;
        const investmentIndex = previousObj.items.findIndex(
          (ele) => ele.id === id
        );
        if (investmentIndex !== -1) {
          organizations[index].portfolios.items[
            portfolioIndex
          ].investments.items[investmentIndex] = {
            id,
            name,
            investedAmount,
            involvement,
            ownership,
            reserved,
            exitStatus,
            totalInvestments,
            date,
            description,
            shares,
            roundSize,
            companyValuation,
            investors,
            portfolio,
            company,
          };
        } else {
          previousObj.items.push({
            id,
            name,
            investedAmount,
            involvement,
            ownership,
            reserved,
            exitStatus,
            totalInvestments,
            date,
            description,
            shares,
            roundSize,
            companyValuation,
            investors,
            portfolio,
            company,
          });
          organizations[index].portfolios.items[portfolioIndex].investments = {
            ...previousObj,
            totalCount: previousObj.totalCount + 1,
            totalInvested:
              previousObj.totalInvested +
              totalInvestments * +(exitStatus === 'current'),
            totalReserves: previousObj.totalReserves + reserved,
            totalInvestedToPercentage:
              previousObj.totalInvestedToPercentage + ownership,
            items: previousObj.items,
          };
        }
      });
    },

    deleteOrganisation(state): void {
      state.isLoading = true;
    },

    deleteOrganisationSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
      }>
    ): void {
      const { id } = payload;
      const { organizations } = state;
      const index = organizations.findIndex((ele) => ele.id === id);
      state.organizations.splice(index, 1);
      if (state.organization === index) {
        state.organization = 0;
      }
      state.isLoading = true;
    },

    deleteOrganisationFail(state): void {
      state.isLoading = false;
    },

    updateOrganisation(state): void {
      state.isLoading = true;
    },

    updateOrganisationFail(state): void {
      state.isLoading = false;
    },

    updateOrganisationSuccess(
      state,
      { payload }: PayloadAction<OrganizationShort>
    ): void {
      const { id, ...updateFields } = payload;
      const { organizations } = state;
      const orgIndex = organizations.findIndex((ele) => ele.id === id);
      state.organizations[orgIndex] = {
        ...state.organizations[orgIndex],
        ...updateFields,
      };
      state.isLoading = false;
    },

    AddInvestmentReducer(
      state,
      {
        payload,
      }: PayloadAction<{
        portfolioId: string;
        investment: Investment;
        update: boolean;
      }>
    ): void {
      const { organization: index, organizations } = state;
      const portfolioIndex = organizations[index].portfolios.items.findIndex(
        (ele) => ele.id === payload.portfolioId
      );
      const {
        id,
        name,
        investedAmount,
        involvement,
        ownership,
        reserved,
        exitStatus,
        totalInvestments,
        date,
        description,
        shares,
        roundSize,
        companyValuation,
        investors,
        portfolio,
        company,
      } = payload.investment;
      const previousObj =
        organizations[index].portfolios.items[portfolioIndex].investments;
      if (payload.update) {
        const investmentIndex = previousObj.items.findIndex(
          (ele) => ele.id === id
        );
        organizations[index].portfolios.items[portfolioIndex].investments.items[
          investmentIndex
        ] = {
          id,
          name,
          investedAmount,
          involvement,
          ownership,
          reserved,
          exitStatus,
          totalInvestments,
          date,
          description,
          shares,
          roundSize,
          companyValuation,
          investors,
          portfolio,
          company,
        };
      } else {
        previousObj.items.push({
          id,
          name,
          investedAmount,
          involvement,
          ownership,
          reserved,
          exitStatus,
          totalInvestments,
          date,
          description,
          shares,
          roundSize,
          companyValuation,
          investors,
          portfolio,
          company,
        });
        organizations[index].portfolios.items[portfolioIndex].investments = {
          ...previousObj,
          totalCount: previousObj.totalCount + 1,
          totalInvested:
            previousObj.totalInvested +
            totalInvestments * +(exitStatus === 'current'),
          totalReserves: previousObj.totalReserves + reserved,
          totalInvestedToPercentage:
            previousObj.totalInvestedToPercentage + ownership,
          items: previousObj.items,
        };
      }
      state.isLoading = false;
    },
    /* END OF INVESTMENT REDUCERS */

    /* USER REDUCERS */
    createUser(state): void {
      state.isLoading = true;
    },
    createUserSuccess(state, { payload }: PayloadAction<User>): void {
      const index = state.organizations.findIndex(
        (org) => org.id === payload.organisationId
      );
      const selectedOrg = state.organizations[index];
      if (!Array.isArray(selectedOrg.users)) {
        selectedOrg.users = [];
      }
      selectedOrg.users.push(payload);
      state.organizations[index] = selectedOrg;
      state.isLoading = false;
    },
    createUserFail(state): void {
      state.isLoading = false;
    },
    updateUser(state): void {
      state.isLoading = true;
    },
    updateUserSuccess(state, { payload }: PayloadAction<User>): void {
      const index = state.organizations.findIndex(
        (org) => org.id === payload.organisationId
      );
      const selectedOrg = state.organizations[index];
      const userIndex = selectedOrg.users.findIndex(
        (user) => user.id === payload.id
      );
      const selectedUser = selectedOrg.users[userIndex];
      const updateUser = {
        ...selectedUser,
        ...payload,
      };
      selectedOrg.users[userIndex] = updateUser;
      state.organizations[index] = selectedOrg;
      state.isLoading = false;
    },
    updateUserFail(state): void {
      state.isLoading = false;
    },
    deleteUser(state): void {
      state.isLoading = true;
    },
    deleteUserSuccess(
      state,
      { payload }: PayloadAction<{ ids: Array<string>; organisationId: string }>
    ): void {
      const index = state.organizations.findIndex(
        (org) => org.id === payload.organisationId
      );
      const selectedOrg = state.organizations[index];
      selectedOrg.users = selectedOrg.users.filter(
        (user) => !payload.ids.includes(user.id)
      );
      state.organizations[index] = selectedOrg;
      state.isLoading = false;
    },
    deleteUserFail(state): void {
      state.isLoading = false;
    },
    createContact(state): void {
      state.isLoading = true;
    },
    createContactSuccess(
      state,
      { payload }: PayloadAction<UpdateContact>
    ): void {
      const {
        contactType = '',
        email = '',
        firstName = '',
        lastName = '',
        id,
        userId,
        organisationId,
      } = payload;
      const index = state.organizations.findIndex(
        (org) => org.id === organisationId
      );
      const selectedOrg = state.organizations[index];
      const company = selectedOrg.companies[0];
      if (!Array.isArray(company.contacts)) company.contacts = [];
      company.contacts.push({
        id,
        firstName,
        lastName,
        email,
        contactType,
        userId,
      });
      selectedOrg.companies[0] = company;
      state.organizations[index] = selectedOrg;
      state.isLoading = false;
    },
    createContactFail(state): void {
      state.isLoading = false;
    },
    deleteContact(state): void {
      state.isLoading = true;
    },
    deleteContactSuccess(
      state,
      { payload }: PayloadAction<DeleteContact>
    ): void {
      const { ids, organisationId } = payload;
      const index = state.organizations.findIndex(
        (org) => org.id === organisationId
      );
      const selectedOrg = state.organizations[index];
      const company = selectedOrg.companies[0];
      if (!Array.isArray(company.contacts)) company.contacts = [];
      const newContacts = company.contacts.filter(
        (contact) => !ids.includes(contact.id)
      );
      company.contacts = newContacts;
      selectedOrg.companies[0] = company;
      state.organizations[index] = selectedOrg;
      state.isLoading = false;
    },
    deleteContactFail(state): void {
      state.isLoading = false;
    },
    /* END OF USER REDUCERS */

    /* SUBSCRIPTION REDUCERS */
    getSubscriptionsByOrg(): void {
      //
    },
    getSubscriptionsByOrgSucess(
      state,
      { payload }: PayloadAction<Subscriptions>
    ): void {
      const index = state.organization;
      const org = state.organizations[index];
      if (org) {
        state.organizations[index] = {
          ...org,
          subscriptions: payload,
        };
      }
    },
    getSubscriptionsByOrgFail(): void {
      //
    },
    updateSubscription(state): void {
      state.isLoading = true;
    },
    updateSubscriptionSucess(
      state,
      { payload }: PayloadAction<Subscription>
    ): void {
      const index = state.organization;
      const org = state.organizations[index];
      if (!org) return;
      const subscriptionIndex = org.subscriptions.findIndex(
        (val) => val.id === payload.id
      );
      const subscription = org.subscriptions[subscriptionIndex];
      if (!subscription) {
        org.subscriptions.push(payload);
      } else {
        const updatedSubscription = {
          ...subscription,
          ...payload,
        };
        org.subscriptions[subscriptionIndex] = updatedSubscription;
      }
      state.organizations[index] = org;
      state.isLoading = false;
    },
    updateSubscriptionFail(state): void {
      state.isLoading = false;
    },
    cancelSubscription(state): void {
      state.isLoading = true;
    },
    cancelSubscriptionSuccess(
      state,
      { payload: { subscriptionId } }: PayloadAction<{ subscriptionId: string }>
    ): void {
      state.isLoading = false;
      const index = state.organization;
      const org = state.organizations[index];
      if (!org) return;
      const subscriptions = org.subscriptions.filter(
        (val) => val.id !== subscriptionId
      );
      org.subscriptions = subscriptions;
      state.organizations[index] = org;
    },
    cancelSubscriptionFail(state): void {
      state.isLoading = false;
    },
    setAccessLimit(
      state,
      { payload }: PayloadAction<SubscriptionLimitResponse>
    ): void {
      state.accessLimit = payload;
    },
    /* END OF SUBSCRIPTION REDUCERS */
  },
});

const { reducer, actions } = slice;

export const {
  getSelectedOrganization,
  getSelectedOrganizationFail,
  getSelectedOrganizationSuccess,
  getOrganizations,
  getOrganizationsFail,
  getOrganizationsSuccess,
  createOrganization,
  createOrganizationSuccess,
  createOrganizationFail,
  updateSelectedOrganization,
  updateOrgCompanies,
  addOrganization,
  addPortfolio,
  deletePortfolio,
  updatePortfolioReducer,
  updateCompany,
  updateCompanySuccess,
  updateCompanyFail,
  updateContacts,
  updateContactsSuccess,
  updateContactsFail,
  addCompanyFromShared,
  addCompanyFromSharedSuccess,
  addCompanyFromSharedFail,
  deleteCompanies,
  deleteCompaniesSuccess,
  deleteCompaniesFail,
  addInvestmentsReducer,
  AddInvestmentReducer,
  deleteInvestmentReducer,
  createUser,
  createUserSuccess,
  createUserFail,
  updateUser,
  updateUserSuccess,
  updateUserFail,
  deleteOrganisation,
  deleteOrganisationSuccess,
  deleteOrganisationFail,
  deleteUser,
  deleteUserSuccess,
  deleteUserFail,
  updateOrganisation,
  updateOrganisationSuccess,
  updateOrganisationFail,
  createContact,
  createContactSuccess,
  createContactFail,
  deleteContact,
  deleteContactSuccess,
  deleteContactFail,
  updateCompanySettings,
  updateCompanySettingSuccess,
  updateCompanySettingsFail,
  getSubscriptionsByOrg,
  getSubscriptionsByOrgSucess,
  getSubscriptionsByOrgFail,
  updateSubscription,
  updateSubscriptionSucess,
  updateSubscriptionFail,
  cancelSubscription,
  cancelSubscriptionSuccess,
  cancelSubscriptionFail,
  setAccessLimit,
} = actions;

export type RootOrganizationState = ReturnType<typeof reducer>;

export type OrganizationReducer = CombinedState<{
  organization: RootOrganizationState;
}>;

export type OrganizationThunkType = ThunkAction<
  void,
  OrganizationReducer,
  unknown,
  Action<string>
>;

export default reducer;
