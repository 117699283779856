/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import { handleActions, createAction } from 'redux-actions';
import { createSelector } from 'reselect';

import { ACTION_TYPES_COMPANY } from '../actionTypes';
import { KPI_DATA, KPI_VALUES } from '../constants/metrics';

const initialState = {
  companies: [],
  allCompanies: [],
  requestData: [],
  kpiPageData: [],
  kpiColumnValue: KPI_VALUES,
  loading: false,
};

const company = handleActions(
  {
    [ACTION_TYPES_COMPANY.GET_COMPANIES]: (state) => ({
      ...state,
      companies: [],
    }),
    [ACTION_TYPES_COMPANY.GET_KPI_DATA]: (state, { payload }) => {
      const { periodicity, data } = payload;
      const newData = (data.length ? data : KPI_DATA.slice(1)).map(
        (ele, index) => ({
          id: `${index}`,
          metricName: ele.metric || ele.metricName,
          periodicity,
          metricCategory: 'KPI',
          date: ele.date || 'Latest',
        })
      );
      return {
        ...state,
        kpiPageData: [KPI_DATA[0]].concat(newData),
      };
    },
    [ACTION_TYPES_COMPANY.UPDATE_COMPANY]: (state, { payload }) => {
      const { companies } = state;
      const newCompany = companies.map((elem) => {
        if (elem.id === payload.id) {
          return {
            ...elem,
            ...payload,
          };
        }
        return elem;
      });
      return {
        ...state,
        companies: newCompany,
      };
    },
    [ACTION_TYPES_COMPANY.UPDATE_KPI_DATA]: (state, { payload }) => {
      const { kpiPageData } = state;

      const newKpiData = kpiPageData.map((col) => ({ ...col }));
      newKpiData.map((col) => {
        if (col.id === payload.currentColumnId) {
          col[payload.key] = payload.value;
          col[payload.category] = payload.categoryValue;
        }
        return col;
      });

      return {
        ...state,
        kpiPageData: newKpiData,
      };
    },
    [ACTION_TYPES_COMPANY.GET_KPI_COLUMN_VALUES]: (state) => ({
      ...state,
      kpiColumnValue: [...state.kpiColumnValue],
    }),
    [ACTION_TYPES_COMPANY.DELETE_KPI_COLUMN]: (state, { payload }) => {
      const { kpiPageData } = state;
      const filteredKpiPageData = kpiPageData.filter(
        (item) => item.id !== payload
      );

      return {
        ...state,
        kpiPageData: filteredKpiPageData,
      };
    },
    [ACTION_TYPES_COMPANY.ADD_KPI_DATA_COLUMN]: (state) => {
      const { kpiPageData } = state;
      const newKpiData = kpiPageData.map((item) => ({ ...item }));
      const lastId = kpiPageData.length + 1;
      const newColumn = {
        id: lastId,
        metricName: 'Metric',
        periodicity: 'Period',
        metricCategory: '',
        date: '',
      };
      return {
        ...state,
        kpiPageData: newKpiData.concat(newColumn),
      };
    },
    [ACTION_TYPES_COMPANY.UPDATE_KPI_PERIODICITY]: (state, { payload }) => {
      const { kpiPageData } = state;
      const newKpiData = kpiPageData
        .map((col) => ({ ...col }))
        .map((col) => {
          if (col.id === payload.currentColumnId) {
            col[payload.key] = payload.value;
          }
          return col;
        });

      return {
        ...state,
        kpiPageData: newKpiData,
      };
    },
    [ACTION_TYPES_COMPANY.UPDATE_KPI_PERIODICITY_DATE]: (
      state,
      { payload }
    ) => {
      const { kpiPageData } = state;
      const newKpiData = kpiPageData
        .map((col) => ({ ...col }))
        .map((col) => {
          if (col.id === payload.currentColumnId) {
            col[payload.key] = payload.value;
          }
          return col;
        });

      return {
        ...state,
        kpiPageData: newKpiData,
      };
    },
    [ACTION_TYPES_COMPANY.GET_COMPANIES_SUCCESS]: (state, { payload }) => ({
      ...state,
      companies: payload,
    }),
    [ACTION_TYPES_COMPANY.GET_COMPANIES_FAIL]: (state) => ({
      ...state,
      companies: [],
    }),
    [ACTION_TYPES_COMPANY.ADD_COMPANIES]: (state, { payload }) => ({
      ...state,
      companies: state.companies.concat(payload),
    }),
    [ACTION_TYPES_COMPANY.GET_ALL_COMPANIES]: (state) => ({
      ...state,
      allCompanies: [],
    }),
    [ACTION_TYPES_COMPANY.GET_ALL_COMPANIES_SUCCESS]: (state, { payload }) => ({
      ...state,
      allCompanies: payload,
    }),
    [ACTION_TYPES_COMPANY.GET_ALL_COMPANIES_FAIL]: (state) => ({
      ...state,
      allCompanies: [],
    }),
    [ACTION_TYPES_COMPANY.ADD_ALL_COMPANIES_SUCCESS]: (state, { payload }) => ({
      ...state,
      allCompanies: state.allCompanies.concat(payload),
    }),
    [ACTION_TYPES_COMPANY.CREATE_COMPANY_SUCCESS]: (state, { payload }) => ({
      ...state,
      companies: state.companies.concat(payload),
    }),
    [ACTION_TYPES_COMPANY.DELETE_CONTACTS_SUCCESS]: (state, { payload }) => ({
      ...state,
      companies: payload,
    }),
    [ACTION_TYPES_COMPANY.GET_REQUEST_DATA_BY_COMPANIES]: (state) => ({
      ...state,
      loading: true,
    }),
    [ACTION_TYPES_COMPANY.GET_REQUEST_DATA_BY_COMPANIES_SUCCESS]: (
      state,
      { payload }
    ) => ({
      ...state,
      requestData: payload,
      loading: false,
    }),
    [ACTION_TYPES_COMPANY.GET_REQUEST_DATA_BY_COMPANIES_FAIL]: (state) => ({
      ...state,
      loading: false,
    }),
  },

  initialState
);

const getRoot = (state) => state.company;

const getCompanies = createSelector(getRoot, (state) => state.companies);

const getKpiData = createSelector(getRoot, (state) => state.kpiPageData);

const getColumnKpiData = createSelector(
  getRoot,
  (state) => state.kpiColumnValue
);

const getCompaniesAction = createAction(
  ACTION_TYPES_COMPANY.GET_COMPANIES_SUCCESS
);

const addCompanies = createAction(ACTION_TYPES_COMPANY.ADD_COMPANIES);
const addKpiDataColumn = createAction(ACTION_TYPES_COMPANY.ADD_KPI_DATA_COLUMN);
const getUpdateKpiData = createAction(ACTION_TYPES_COMPANY.UPDATE_KPI_DATA);
const getUpdateKpiPeriodicity = createAction(
  ACTION_TYPES_COMPANY.UPDATE_KPI_PERIODICITY
);
const getUpdateKpiPeriodicityDate = createAction(
  ACTION_TYPES_COMPANY.UPDATE_KPI_PERIODICITY_DATE
);
const getUpdateKpiColumnValue = createAction(
  ACTION_TYPES_COMPANY.UPDATE_KPI_VALUES
);
const deleteKpiColumn = createAction(ACTION_TYPES_COMPANY.DELETE_KPI_COLUMN);

const getAllCompanies = createSelector(getRoot, (state) => state.allCompanies);

const getRequestData = createSelector(getRoot, (state) => state.requestData);

const getCompaniesLoading = createSelector(getRoot, (state) => state.loading);

export {
  company,
  getKpiData,
  getCompanies,
  addCompanies,
  addKpiDataColumn,
  getCompaniesAction,
  getAllCompanies,
  getRequestData,
  getCompaniesLoading,
  getUpdateKpiData,
  deleteKpiColumn,
  getUpdateKpiPeriodicity,
  getUpdateKpiPeriodicityDate,
  getColumnKpiData,
  getUpdateKpiColumnValue,
};
