function descendingComparator(a, b, orderBy) {
  if (typeof b[orderBy] === 'string' && typeof a[orderBy] === 'string') {
    if (typeof b[orderBy] === 'string') {
      return 1;
    }
    if (typeof a[orderBy] === 'string') {
      return -1;
    }
    return 0;
  }
  if (typeof b[orderBy] === 'number' && typeof a[orderBy] === 'number') {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  if (typeof b[orderBy] === 'string' || b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (typeof a[orderBy] === 'string' || b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

export function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxWidth: '100vw',
    maxHeight: '100%',
    position: 'fixed',
    overflowY: 'auto',
  };
}
