import {
  ResponsiveContainer,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from 'recharts';
import React from 'react';
import PropType from 'prop-types';

const IndustryChart = ({ data }) => (
  <ResponsiveContainer>
    <RadarChart data={data}>
      <PolarGrid />
      <PolarAngleAxis dataKey="name" />
      <PolarRadiusAxis />
      <Radar
        name="Mike"
        dataKey="count"
        stroke="#8884d8"
        fill="#8884d8"
        fillOpacity={0.6}
      />
    </RadarChart>
  </ResponsiveContainer>
);

export default IndustryChart;

IndustryChart.propTypes = {
  data: PropType.arrayOf(PropType.object).isRequired,
};
