/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable default-case */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { Button } from 'components/Common/Buttons';
import { Input } from 'components/Common/Input';
import { userRoles } from 'user/userConstants';
import { isTextValid } from 'utils';
import translations from 'translations';
import RoleRadio from './RoleRadio';

const AddOrganisation = ({ classes, onShareOrganisation, closeModal }) => {
  const { formatMessage } = useIntl();
  const [state, setState] = useState({
    orgkey: '',
    role: userRoles.ADMIN,
  });
  const [valid, setValid] = useState({
    orgkeyValid: true,
    orgkeyHelperText: '',
  });
  const validateInput = (key, value) => {
    switch (key) {
      case 'orgkey':
        if (!isTextValid(value)) {
          return setValid({
            ...valid,
            orgkeyValid: false,
            orgkeyHelperText: 'invalid format',
          });
        }
        setValid({
          ...valid,
          orgkeyValid: true,
          orgkeyHelperText: '',
        });
        break;
    }
  };
  const handleOnChange = (key, value) =>
    setState({
      ...state,
      [key]: value,
    });
  const handleAdd = async () => {
    const { orgkey, role } = state;
    await onShareOrganisation(orgkey, role);
    return closeModal();
  };
  return (
    <div style={{ width: 580 }} onClick={(e) => e.stopPropagation()}>
      <Grid
        container
        className={`${classes.modelWidth} ${classes.paperModal}`}
        direction="column"
        spacing={1}
      >
        <Grid item>
          <Typography variant="h5" className={classes.title}>
            {formatMessage(translations.Organization.Add.AddOrg)}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Input
            variant="outlined"
            label="Organisation ID*"
            value={state.orgkey}
            onBlur={(e) => validateInput('orgkey', e.target.value)}
            onChange={(e) => handleOnChange('orgkey', e.target.value)}
            gridStyle={{ xs: 12 }}
            error={!valid.orgkeyValid}
            errorText={valid.orgkeyHelperText}
            fullWidth
            lableStyle={classes.labelStyle}
          />
        </Grid>
        <Grid item xs={12}>
          <RoleRadio role={state.role} handleOnChange={handleOnChange} />
        </Grid>
        <Grid xs={3}>
          <Button
            typeColor="common"
            className={classes.addButton}
            classes={{
              label: classes.lowerCapText,
            }}
            onClick={handleAdd}
            variant="contained"
            color="primary"
          >
            {formatMessage(translations.Common.common.add)}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

AddOrganisation.propTypes = {
  classes: PropTypes.object,
  onShareOrganisation: PropTypes.func,
  closeModal: PropTypes.func,
};

AddOrganisation.defaultProps = {
  classes: {},
  onShareOrganisation: () => {},
  closeModal: () => {},
};

export default AddOrganisation;
