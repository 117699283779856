/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { Paper, Grid, Box, Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import PropType from 'prop-types';
import translations from 'translations';
import { GridTitle } from '../../Common/Grid';
import { CompanyProfileItem } from './CompanyProfileItem';

import { useStyles } from './styles';
import { urlRegex, emailRegex, validateDate } from '../../../utils';
import { userRoles } from '../../../user/userConstants';

const data = {
  legalName: {
    title: 'Legal Name',
    description: null,
  },
  team: {
    title: 'Team',
    description: null,
  },
  contactEmail: {
    title: 'Email',
    description: null,
  },
  industryId: {
    title: 'Industry',
    description: null,
  },
  employees: {
    title: 'Employees',
    description: null,
  },
  websiteUrl: {
    title: 'Website',
    description: null,
  },
  foundedDate: {
    title: 'Founded',
    description: null,
  },
};

const CompanyProfile = ({ profile: propsData, onEdit, role }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [state, setState] = useState(data);
  const [isEdit, setIsEdit] = useState(false);

  const handleUpdateItem = (index) => (updatedItem) => {
    const item = state[index];
    if (item) {
      const newItem = { ...item, ...updatedItem };
      state[index] = newItem;
      setState(JSON.parse(JSON.stringify(state)));
    }
  };

  const handleActionClick = () => {
    if (isEdit) {
      if (!Object.keys(state).some((key) => state[key].isValid === false)) {
        onEdit({ ...state });
      }
    }
    setIsEdit(!isEdit);
  };

  const validateEmail = (value) => emailRegex.test(value);

  const validateUrl = (value) => urlRegex.test(value);

  useEffect(() => {
    if (isEdit) {
      setIsEdit(false);
    }
    if (
      propsData &&
      Object.keys(propsData).length &&
      JSON.stringify(propsData) !== JSON.stringify(state)
    ) {
      setState(propsData);
    }
  }, [propsData]);

  return (
    <>
      <Grid container className={classes.root}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          className={classes.titleBox}
        >
          <GridTitle
            textId={translations.CompanyProfile.companyProfileTitle.id}
            text={
              translations.CompanyProfile.companyProfileTitle.defaultMessage
            }
            classStyles={classes.gridTitle}
          />
          <Button
            className={!role || role === userRoles.VIEWER ? classes.hidden : ''}
            color="primary"
            onClick={handleActionClick}
          >
            {`${formatMessage(
              isEdit
                ? translations.CompanyProfile.companyProfileSaveLable
                : translations.CompanyProfile.companyProfileEditLable
            )}`}
          </Button>
        </Box>

        {/* ROW ONE */}
        <Paper className={classes.paper}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              <CompanyProfileItem
                editable={isEdit}
                item={state.legalName}
                placeholder="legal name"
                saveChanges={handleUpdateItem('legalName')}
              />
              <CompanyProfileItem
                editable={isEdit}
                item={state.foundedDate}
                placeholder="YYYY-MM-DD"
                saveChanges={handleUpdateItem('foundedDate')}
                validator={validateDate}
                helperText="Should be a valid date"
              />
              <CompanyProfileItem
                editable={isEdit}
                item={state.industryId}
                placeholder="industry"
                saveChanges={handleUpdateItem('industryId')}
              />
              <CompanyProfileItem
                editable={isEdit}
                item={state.websiteUrl}
                placeholder="https//exampple.com"
                saveChanges={handleUpdateItem('websiteUrl')}
                validator={validateUrl}
                helperText="Should be a valid url"
              />
              <CompanyProfileItem
                editable={isEdit}
                item={state.team}
                placeholder="team"
                saveChanges={handleUpdateItem('team')}
              />
              <CompanyProfileItem
                editable={isEdit}
                item={state.contactEmail}
                placeholder="example@email.com"
                saveChanges={handleUpdateItem('contactEmail')}
                validator={validateEmail}
                helperText="Should be a valid email"
              />
              <CompanyProfileItem
                editable={isEdit}
                item={state.employees}
                placeholder="50-100"
                saveChanges={handleUpdateItem('employees')}
              />
            </Box>
          </Grid>
        </Paper>
        {/* END OF ROW ONE */}
      </Grid>
    </>
  );
};

export { CompanyProfile };

CompanyProfile.propTypes = {
  profile: PropType.object,
  onEdit: PropType.func,
  role: PropType.string,
};

CompanyProfile.defaultProps = {
  onEdit: async () => {},
  profile: data,
  role: userRoles.VIEWER,
};
