import React from 'react';
import {
  Typography,
  Grid,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { SelectInput } from 'components/Common/Select';
import messages from '../messages';
import { useStyle } from '../styles';
import { SECTIONS } from '../../../../constants';

const sections = SECTIONS;
const CompanySpecificSelector = ({
  value,
  handleChange,
  optionValue,
  handleOptionsChange,
  showAllWithDataOption,
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyle();
  return (
    <Grid container spacing={5} className={classes.rootExportTab} xs={12}>
      <Grid item xs={3} sm={3}>
        <Typography style={{ paddingTop: 8 }}>
          {formatMessage(messages.sections)}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="All"
            name="All"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="All"
              control={<Radio color="gray" />}
              label="All"
            />
            {showAllWithDataOption && (
              <FormControlLabel
                value="All with data"
                control={<Radio color="gray" />}
                label="All with data"
              />
            )}

            <div style={{ display: 'flex', width: 250 }}>
              <FormControlLabel
                value="Specific"
                control={<Radio color="gray" />}
                label="Specific"
              />
              <div className={classes.selectInput}>
                <SelectInput
                  value={optionValue}
                  renderSelectValues={(ele) =>
                    ele.length
                      ? ele.length > 1
                        ? 'Selected'
                        : ele[0].value
                      : 'None'
                  }
                  onChange={handleOptionsChange}
                  disabled={value !== 'Specific'}
                  useId
                  fullWidth
                  options={sections}
                  customClasses={{
                    select: classes.select,
                  }}
                  multiSelect
                />
              </div>
            </div>
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

CompanySpecificSelector.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  optionValue: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleOptionsChange: PropTypes.func.isRequired,
  showAllWithDataOption: PropTypes.bool,
};

CompanySpecificSelector.defaultProps = {
  showAllWithDataOption: false,
};

export default CompanySpecificSelector;
