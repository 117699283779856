import { AppDispatch, AppThunk } from 'store';
import {
  GraphqlService,
  logActionError,
  handleGraphqlServerError,
} from 'utils/api';
import {
  GraphqlResponse,
  Portfolio,
  PortfolioPayload,
  OnSuccessPayload,
  UpdatePortfolioPayload,
  UpdateInvestmentsPayload,
  Investment,
  UpdateInvestmentPayload,
} from 'types';
import {
  addPortfolio,
  deletePortfolio,
  updatePortfolioReducer,
  AddInvestmentReducer,
  deleteInvestmentReducer,
  addInvestmentsReducer,
} from 'containers/Organisation';
import { setAlert } from 'containers/Config';
import { setIsLoadingInvestments } from 'reducers';
import {
  CREATE_PORTFOLIO,
  DELETE_PORTFOLIOS,
  UPDATE_PORTFOLIO,
  UPDATE_INVESTMENT_MUTATION,
  DELETE_INVESTMENTS_MUTATION,
  UPDATE_INVESTMENTS_MUTATION,
} from './query';
import {
  createPortfolio as createPortfolioAction,
  createPortfolioSuccess,
  createPortfolioFail,
  deletePortfolio as deletePortfolioAction,
  deletePortfolioSuccess,
  deletePortfolioFail,
  updatePortfolio as updatePortfolioAction,
  updatePortfolioSuccess,
  updatePortfolioFail,
  updateInvestment as updateInvestmentAction,
  updateInvestmentSuccess,
  updateInvestmentFail,
  deleteInvestment as deleteInvestmentAction,
  deleteInvestmentSuccess,
  deleteInvestmentFail,
} from './reducer';

const updatePortfolio = (
  payload: UpdatePortfolioPayload,
  onSuccess?: OnSuccessPayload
): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { successMsg, reject, resolve } = onSuccess || {};
    try {
      dispatch(updatePortfolioAction());
      const service = new GraphqlService(global.apolloClient);
      await service.mutation<GraphqlResponse<{ updatePortfolio: Portfolio }>>(
        UPDATE_PORTFOLIO,
        {
          input: payload,
        }
      );
      dispatch(updatePortfolioReducer(payload));
      dispatch(updatePortfolioSuccess());
      dispatch(
        setAlert({
          open: true,
          message: successMsg || 'Portfolio updated',
          severity: 'success',
        })
      );
      if (resolve) {
        resolve();
      }
    } catch (error) {
      logActionError('log', error, 'updatePortfolio');
      dispatch(updatePortfolioFail());
      dispatch(
        setAlert({
          open: true,
          message: handleGraphqlServerError(error),
          severity: 'error',
        })
      );
      if (reject) {
        reject(error);
      }
    }
  };
};

const deletePortfolios = (
  payload: Array<string>,
  onSuccess: OnSuccessPayload
): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { successMsg, reject, resolve } = onSuccess || {};
    try {
      dispatch(deletePortfolioAction());
      const service = new GraphqlService(global.apolloClient);
      await service.mutation<GraphqlResponse<{ deletePortfolio: boolean }>>(
        DELETE_PORTFOLIOS,
        {
          portfolioIds: payload,
        }
      );
      dispatch(deletePortfolio(payload));
      dispatch(deletePortfolioSuccess());
      dispatch(
        setAlert({
          open: true,
          severity: 'success',
          message: successMsg || 'Portfolio deleted',
        })
      );
      if (resolve) {
        resolve();
      }
    } catch (error) {
      logActionError('log', error, 'deletePortfolio');
      dispatch(deletePortfolioFail());
      dispatch(
        setAlert({
          open: true,
          message: handleGraphqlServerError(error),
          severity: 'error',
        })
      );
      if (reject) {
        reject(error);
      }
    }
  };
};

const updateInvestments = (
  payload: UpdateInvestmentsPayload,
  onSuccess: OnSuccessPayload
): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { reject, resolve } = onSuccess || {};
    try {
      dispatch(setIsLoadingInvestments(true));
      dispatch(updateInvestmentAction());
      const service = new GraphqlService(global.apolloClient);
      const {
        data: { updateInvestments: newInvestments },
      } = await service.mutation<
        GraphqlResponse<{ updateInvestments: Investment[] }>
      >(UPDATE_INVESTMENTS_MUTATION, {
        ...payload,
      });
      dispatch(addInvestmentsReducer(newInvestments));
      dispatch(updateInvestmentSuccess());
      if (resolve) {
        resolve();
      }
    } catch (error) {
      logActionError('log', error, 'update investment');
      dispatch(updateInvestmentFail());
      dispatch(
        setAlert({
          open: true,
          message: handleGraphqlServerError(error),
          severity: 'error',
        })
      );
      if (reject) {
        reject(error);
      }
    }
    dispatch(setIsLoadingInvestments(false));
  };
};

const updateInvestment = (
  payload: UpdateInvestmentPayload,
  onSuccess: OnSuccessPayload
): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { reject, resolve } = onSuccess || {};
    try {
      dispatch(updateInvestmentAction());
      const service = new GraphqlService(global.apolloClient);
      const {
        data: { updateInvestment: newInvestment },
      } = await service.mutation<
        GraphqlResponse<{ updateInvestment: Investment }>
      >(UPDATE_INVESTMENT_MUTATION, {
        ...payload,
      });
      dispatch(
        AddInvestmentReducer(
          JSON.parse(
            JSON.stringify({
              portfolioId: payload.investment.portfolioId,
              investment: newInvestment,
              update: Boolean(payload.investment.id),
            })
          )
        )
      );
      dispatch(updateInvestmentSuccess());
      if (resolve) {
        resolve();
      }
    } catch (error) {
      logActionError('log', error, 'update investment');
      dispatch(updateInvestmentFail());
      dispatch(
        setAlert({
          open: true,
          message: handleGraphqlServerError(error),
          severity: 'error',
        })
      );
      if (reject) {
        reject(error);
      }
    }
  };
};

const deleteInvestment = (
  payload: Array<{
    id: string;
    portfolioId: string;
  }>,
  onSuccess: OnSuccessPayload
): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { reject, resolve } = onSuccess || {};
    try {
      dispatch(setIsLoadingInvestments(true));
      dispatch(deleteInvestmentAction());
      const service = new GraphqlService(global.apolloClient);
      await service.mutation<GraphqlResponse<{ deleteInvestments: boolean }>>(
        DELETE_INVESTMENTS_MUTATION,
        {
          investmentIds: payload.map((ele) => ele.id),
        }
      );
      dispatch(deleteInvestmentReducer(payload));
      dispatch(deleteInvestmentSuccess());
      if (resolve) {
        resolve();
      }
    } catch (error) {
      logActionError('log', error, 'delete investment');
      dispatch(deleteInvestmentFail());
      dispatch(
        setAlert({
          open: true,
          message: handleGraphqlServerError(error),
          severity: 'error',
        })
      );
      if (reject) {
        reject(error);
      }
    }
    dispatch(setIsLoadingInvestments(false));
  };
};

const createPortfolio = (
  payload: PortfolioPayload,
  onSuccess: OnSuccessPayload
): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { successMsg, reject, resolve } = onSuccess || {};
    try {
      dispatch(createPortfolioAction());
      const service = new GraphqlService(global.apolloClient);
      const {
        data: { createPortfolio: newPortfolio },
      } = await service.mutation<
        GraphqlResponse<{ createPortfolio: Portfolio }>
      >(CREATE_PORTFOLIO, {
        ...payload,
      });
      dispatch(addPortfolio(JSON.parse(JSON.stringify(newPortfolio))));
      dispatch(createPortfolioSuccess());
      dispatch(
        setAlert({
          message: successMsg || 'Portfolio created successfully',
          open: true,
          severity: 'success',
        })
      );
      if (resolve) {
        resolve(JSON.parse(JSON.stringify(newPortfolio)));
      }
    } catch (error) {
      logActionError('log', error, 'createPortfolio');
      dispatch(createPortfolioFail());
      dispatch(
        setAlert({
          open: true,
          message: handleGraphqlServerError(error),
          severity: 'error',
        })
      );
      if (reject) {
        reject(error);
      }
    }
  };
};

const PortfolioThunk = {
  createPortfolio,
  deletePortfolios,
  updatePortfolio,
  updateInvestment,
  updateInvestments,
  deleteInvestment,
};

export { PortfolioThunk };
