import { ResponsiveContainer, PieChart, Pie, Legend, Cell } from 'recharts';
import React from 'react';
import PropType from 'prop-types';
import Box from '@material-ui/core/Box';
import { Colors } from '../../constants';

const COLORS = [
  Colors.BLUE_DODGER,
  Colors.GREEN_CARIBBEAN,
  Colors.SUPERNOVA,
  Colors.CORAL,
];

const FoundingChart = ({ data }) => (
  <Box paddingTop="" height="100%" width="100%">
    <ResponsiveContainer>
      <PieChart>
        <Pie
          isAnimationActive={false}
          dataKey="value"
          data={data}
          innerRadius={40}
          outerRadius={50}
          label
        >
          {
            // eslint-disable-next-line react/no-array-index-key
            data.map((entry, index) => (
              <Cell
                key={index.toString()}
                fill={COLORS[index % COLORS.length]}
              />
            ))
          }
        </Pie>
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  </Box>
);

export default FoundingChart;

FoundingChart.propTypes = {
  data: PropType.arrayOf(PropType.object).isRequired,
};

FoundingChart.defaultProps = {};
