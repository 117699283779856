import React, { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@material-ui/core';

import { SelectInput } from 'components/Common/Select';
import { Periodicity } from 'constants/index';
import { OnChangeEvent } from 'types';
import translations from 'translations';
import { useStyles } from './styles';
import { KpiSelectProps } from './type';

interface Options {
  id: string;
  value: string;
}

const options: Array<Options> = Object.values(Periodicity).map((val) => ({
  id: val,
  value: val,
}));

const TypeOptions: Array<Options> = [{ id: 'Actual', value: 'actual' }];
const compareOptions: Array<Options> = [{ id: 'None', value: 'none' }];

export const KpiSelect = ({
  onChangePeriodicity,
  isLoading,
  periodicity: propsPeriodicity,
}: KpiSelectProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [compare, setCompare] = useState(compareOptions[0].id);
  const [periodicity, setPeriodicity] = useState(options[0].id);
  const [type, setType] = useState(TypeOptions[0].id);
  const perRef = useRef(periodicity);

  const handleSearch = (newPeriodicity: string): void => {
    if (perRef.current === newPeriodicity) return;
    perRef.current = newPeriodicity;
    onChangePeriodicity(newPeriodicity);
  };

  const handleOnChangePeriodicity = (event: OnChangeEvent): void => {
    setPeriodicity(event.target.value);
    handleSearch(event.target.value);
  };

  useEffect(() => {
    setPeriodicity(propsPeriodicity);
  }, [propsPeriodicity]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        className={classes.kpiActionWrapper}
      >
        <SelectInput
          onChange={handleOnChangePeriodicity}
          options={options}
          value={periodicity}
          label={formatMessage(translations.Common.kpis.periodicity)}
          useId
          lableStyle={classes.label}
          wrapperClassName={classes.input}
          isDisabled={isLoading}
        />
        <SelectInput
          onChange={(event: OnChangeEvent): void => {
            setType(event.target.value);
          }}
          options={TypeOptions}
          value={type}
          label={formatMessage(translations.Common.common.type)}
          useId
          lableStyle={classes.label}
          wrapperClassName={classes.input}
        />
        <SelectInput
          onChange={(event: OnChangeEvent): void => {
            setCompare(event.target.value);
          }}
          options={compareOptions}
          value={compare}
          label={formatMessage(translations.Common.common.compare)}
          useId
          lableStyle={classes.label}
          wrapperClassName={classes.input}
        />
        {/* <Button
          onClick={handleSearch}
          shape="round"
          typeColor="common"
          className={classes.searchBtn}
          disabled={isLoading}
        >
          {formatMessage(translations.Common.common.search)}
        </Button> */}
      </Box>
    </>
  );
};
