import { SECTIONS } from '../constants';

export const mapCompanyOptionsToKeys = (values) => {
  const keys = {
    company: false,
    profile: false,
    kpi: false,
    matric: false,
    feed: false,
  };
  values.forEach((ele) => {
    switch (ele.value) {
      case SECTIONS[0].value:
        keys.company = true;
        break;
      case SECTIONS[1].value:
        keys.feed = true;
        break;
      case SECTIONS[2].value:
        keys.kpi = true;
        break;
      case SECTIONS[3].value:
        keys.matric = true;
        break;
      case SECTIONS[4].value:
        keys.profile = true;
        break;
    }
  });
  return keys;
};

export const normalizeNameList = (nameArray) => {
  return `${nameArray.slice(0, 1).join(', ')}${
    nameArray.length > 1 ? ` +${nameArray.length - 1}` : ''
  }`;
};
