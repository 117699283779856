import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { CompanyDefaultText } from 'components/Company/Reuseable/CompanyDefault';
import { BlockTitle } from '../../Common/Summary';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '40px',
  },
  paperWrapper: {
    padding: '20px',
  },
  cell: {
    padding: '12px',
  },
  bodyCell: {
    padding: '12px',
    borderBottom: 'none',
  },
});

const PortfolioInvestments = ({ companies }) => {
  const classes = useStyles();
  const intl = useIntl();
  const exits = useMemo(
    () =>
      companies.find((item) =>
        item.investments.find((status) => status.exitStatus === 'current')
      ),
    [companies]
  );

  const createRow = ({ id, name, investments }) => {
    const totalInvested =
      investments &&
      investments.reduce(
        (total, curr) =>
          total + (curr.exitStatus === 'current') * curr.totalInvestments,
        0
      );

    if (+totalInvested === 0) return null;

    const { currency } =
      (investments && investments[0].investedAmount) || 'USD';
    return (
      <TableRow key={id}>
        <TableCell className={classes.bodyCell}>{name}</TableCell>
        <TableCell className={classes.bodyCell}>
          {intl.formatNumber(totalInvested, { style: 'currency', currency })}
        </TableCell>
      </TableRow>
    );
  };
  createRow.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    investments: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  return (
    <div className={classes.root}>
      <BlockTitle title="Total Investment" />
      <Paper className={classes.paperWrapper} elevation={2}>
        {exits ? (
          <Table>
            <colgroup>
              <col width="20%" />
              <col width="20%" />
              <col width="60%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cell}>Company Name</TableCell>
                <TableCell className={classes.cell}>Total Investment</TableCell>
                <TableCell className={classes.cell} />
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map((company) => createRow(company))}
            </TableBody>
          </Table>
        ) : (
          <CompanyDefaultText text="No Investments added yet" />
        )}
      </Paper>
    </div>
  );
};

PortfolioInvestments.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PortfolioInvestments;
