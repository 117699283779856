import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

import {
  Subscription,
  UpdateSubscriptionPayload,
  CancelSubscriptionPaylod,
} from 'types';
import translations from 'translations';
import { SUBSCRIPTION_TYPES, ORGANISATION_TYPES } from 'constants/index';

import { useStyles } from './styles';
import { CancelSubscriptionModal } from './cancelModal';
import { BillingComponent } from './billing';
import { SubscriptionComponentProps } from './type';

const SubscriptionComponent = ({
  subscriptions,
  updateSubscription,
  cancelSubscription: onCancelSubscription,
  selectedOrganization,
  subscriptionAlert,
}: SubscriptionComponentProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [
    cancelSubscription,
    setCancelSubscription,
  ] = useState<Subscription | null>(null);
  const [paidSubscription, setPaidSubscription] = useState<Subscription | null>(
    null
  );

  const handleAbortCancelSubscription = () => {
    setCancelSubscription(null);
  };

  const handleOnCancelSubscription = (subscription: Subscription): void => {
    setCancelSubscription(subscription);
  };

  const handleUpdateSubscription = async (
    payload: UpdateSubscriptionPayload
  ): Promise<void> => {
    await updateSubscription(payload);
  };

  const handleCancelSubscription = async (
    payload: CancelSubscriptionPaylod
  ): Promise<void> => {
    await onCancelSubscription({
      subscriptionId: payload.subscriptionId,
    });
  };

  useEffect(() => {
    const paidSub = subscriptions.find(
      (val) => val.type !== SUBSCRIPTION_TYPES.FREE
    );
    if (!paidSub) {
      setPaidSubscription(null);
    } else if (JSON.stringify(paidSub) !== JSON.stringify(paidSubscription)) {
      setPaidSubscription(JSON.parse(JSON.stringify(paidSub)));
    }
  }, [subscriptions]);

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper} elevation={0} variant="outlined">
        {subscriptionAlert && (
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            className={classes.box}
          >
            <Grid item md={2} sm={4}>
              <Typography variant="body2" color="textPrimary">
                {formatMessage(
                  translations.OrganisationSettings.Subscription.Billing
                    .subscriptionStatus
                )}
              </Typography>
            </Grid>
            <Grid item md={10} sm={8}>
              <div
                className={classes.statusBar}
                style={{
                  backgroundColor: subscriptionAlert.color,
                }}
              >
                {subscriptionAlert.message}
              </div>
            </Grid>
          </Grid>
        )}
        <div className={classes.space} />
        {selectedOrganization.type === ORGANISATION_TYPES.FUND ? (
          <BillingComponent
            onCancelSubscription={handleOnCancelSubscription}
            portfolios={[]}
            updateSubscription={handleUpdateSubscription}
            onUpdateSubscription={() => {
              //
            }}
            selectedOrganization={selectedOrganization}
            paidSubscription={paidSubscription}
          />
        ) : null}
        {selectedOrganization.type === ORGANISATION_TYPES.FUND ? (
          <CancelSubscriptionModal
            isOpened={Boolean(cancelSubscription)}
            selectedSubscription={cancelSubscription}
            onClose={handleAbortCancelSubscription}
            cancelSubscription={handleCancelSubscription}
          />
        ) : null}
      </Paper>
    </Grid>
  );
};

export { SubscriptionComponent };
