/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';

export const GET_STATUS_DATA = gql`
  query($input: StatusInput!) {
    getStatus(input: $input) {
      companyTracked
      totalManageProfiles
      tolalPoints
      lastCompanyUpdate
      updates {
        company
        time
        source
        companyId
      }
    }
  }
`;
