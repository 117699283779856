import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import GroupAddRoundedIcon from '@material-ui/icons/GroupAddRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import ForumRoundedIcon from '@material-ui/icons/ForumRounded';
import ListRoundedIcon from '@material-ui/icons/ListRounded';
import ImportExport from '@material-ui/icons/ImportExport';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import EqualizerRoundedIcon from '@material-ui/icons/EqualizerRounded';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import translations from 'translations';
import { userRoles } from 'user/userConstants';
import { Input } from 'components/Common/Input';

const appBarHeight = 64;
const drawerWidth = 240;
const drawerHeight = `calc(100% - ${appBarHeight}px)`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    height: drawerHeight,
    marginTop: appBarHeight,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  SearchField: {
    padding: '5px 20px 3px',
  },
  drawer: {
    width: drawerWidth,
    height: drawerHeight,
    marginTop: appBarHeight,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    height: drawerHeight,
    marginTop: appBarHeight,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    marginTop: appBarHeight,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    height: '50px',
    minHeight: '40px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  padding: {
    marginLeft: '20px',
    marginRight: '20px',
  },
  iconSize: {
    ...theme.custom.iconSize,
  },
  links: {
    ...theme.custom.side_bar_text,
  },
  iconContainer: {
    minWidth: '35px',
  },
}));

const routes = [
  'Updates',
  'companies',
  'cash-runway',
  'Statistics',
  'export-import',
];

export default function Sidebar({
  sidebarStatus,
  onChangeSidebarStatus,
  selectedOrganisation,
  companies,
  trialStatus,
}) {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const [search, setSearch] = useState('');
  const handleManageRoutes = (route) => {
    switch (route) {
      case routes[0]: {
        // Updates screen
        history.push(`/organisations/${selectedOrganisation.id}/updates`);
        break;
      }
      case routes[1]: {
        // All Companies screen
        history.push(`/organisations/${selectedOrganisation.id}/companies`);
        break;
      }
      case routes[2]: {
        // Kpi`s Page screen
        history.push(`/organisations/${selectedOrganisation.id}/kpi-page`);
        break;
      }
      case routes[3]: {
        // Cash Runway screen
        history.push(`/organisations/${selectedOrganisation.id}/cash-runway`);
        break;
      }
      case routes[4]: {
        // Statistics screen
        history.push(`/organisations/${selectedOrganisation.id}/statistics`);
        break;
      }
      case routes[5]: {
        // Statistics screen
        history.push(`/organisations/${selectedOrganisation.id}/export-import`);
        break;
      }
      default: {
        // updates screen
        history.push(`/organisations/${selectedOrganisation.id}/updates`);
        break;
      }
    }
  };
  const handleManageCompaniesClick = () => {
    history.push(`/organisations/${selectedOrganisation.id}/companies/add`);
  };

  const handleRequestDataClick = () => {
    history.push(`/organisations/${selectedOrganisation.id}/request-data`);
  };

  const handleAddUserClick = () => {
    // const { id } = extractUrn(selectedOrganisation.id);
    history.push(`/organisations/${selectedOrganisation.id}/users`, {
      companyOrganisation: selectedOrganisation.id,
    });
  };

  const onPortfolioClick = (url) => {
    history.push(url);
  };

  const dividerText = (text) => (
    <Typography
      variant="subtitle1"
      style={{
        letterSpacing: '2px',
        marginLeft: '20px',
        fontSize: '13px',
        fontWeight: '500',
      }}
      className={classes.padding}
    >
      {text}
    </Typography>
  );

  const SearchField = (placeholder) => (
    <Input
      placeholder={placeholder}
      className={classes.SearchField}
      value={search}
      onChange={(event) => setSearch(event.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: sidebarStatus,
          [classes.drawerClose]: !sidebarStatus,
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: sidebarStatus,
            [classes.drawerClose]: !sidebarStatus,
          }),
        }}
      >
        <div
          style={{ marginTop: trialStatus ? '30px' : '0px' }}
          className={classes.toolbar}
        >
          {dividerText('VIEWS')}
          <IconButton onClick={() => onChangeSidebarStatus(false)}>
            {sidebarStatus && <ChevronLeftIcon className={classes.iconSize} />}
          </IconButton>
        </div>
        <Divider />

        {selectedOrganisation.type !== 'company' && (
          <>
            <List>
              <ListItem
                onClick={() => handleManageRoutes(routes[0])}
                button
                key={0}
              >
                <ListItemIcon className={classes.iconContainer}>
                  <HomeRoundedIcon className={classes.iconSize} />
                </ListItemIcon>
                <ListItemText className={classes.links} primary="Status" />
              </ListItem>

              <ListItem
                onClick={() => handleManageRoutes(routes[1])}
                button
                key={1}
              >
                <ListItemIcon className={classes.iconContainer}>
                  <ListRoundedIcon className={classes.iconSize} />
                </ListItemIcon>
                <ListItemText
                  className={classes.links}
                  primary={intl.formatMessage(translations.Sidebar.allReports)}
                />
              </ListItem>

              <ListItem
                onClick={() => handleManageRoutes(routes[2])}
                button
                key={2}
              >
                <ListItemIcon className={classes.iconContainer}>
                  <EqualizerRoundedIcon className={classes.iconSize} />
                </ListItemIcon>
                <ListItemText
                  className={classes.links}
                  primary={intl.formatMessage(translations.Sidebar.kpiPageItem)}
                />
              </ListItem>

              <ListItem
                onClick={() => handleManageRoutes(routes[3])}
                button
                key={3}
              >
                <ListItemIcon className={classes.iconContainer}>
                  <AttachMoneyRoundedIcon className={classes.iconSize} />
                </ListItemIcon>
                <ListItemText
                  className={classes.links}
                  primary={intl.formatMessage(
                    translations.Sidebar.cashRunwayItem
                  )}
                />
              </ListItem>

              <ListItem
                onClick={() => handleManageRoutes(routes[4])}
                button
                key={4}
              >
                <ListItemIcon className={classes.iconContainer}>
                  <TrendingUpIcon className={classes.iconSize} />
                </ListItemIcon>
                <ListItemText className={classes.links} primary="Statistics" />
              </ListItem>
              <ListItem
                onClick={() => handleManageRoutes(routes[5])}
                button
                key={5}
              >
                <ListItemIcon className={classes.iconContainer}>
                  <ImportExport className={classes.iconSize} />
                </ListItemIcon>
                <ListItemText
                  className={classes.links}
                  primary={intl.formatMessage(
                    translations.Sidebar.exportImport
                  )}
                />
              </ListItem>

              <br />
              <br />
              <br />
              {dividerText('FLOWS')}
              <Divider />
              {selectedOrganisation.role === userRoles.ADMIN && (
                <ListItem onClick={handleAddUserClick} button>
                  <ListItemIcon className={classes.iconContainer}>
                    <GroupAddRoundedIcon className={classes.iconSize} />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.links}
                    primary={intl.formatMessage(
                      translations.Sidebar.addUsersItem
                    )}
                  />
                </ListItem>
              )}
              {selectedOrganisation.role !== userRoles.VIEWER && (
                <ListItem onClick={handleManageCompaniesClick} button>
                  <ListItemIcon className={classes.iconContainer}>
                    <AddCircleRoundedIcon className={classes.iconSize} />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.links}
                    primary={intl.formatMessage(
                      translations.Sidebar.addCompaniesItem
                    )}
                  />
                </ListItem>
              )}
              <ListItem onClick={handleRequestDataClick} button>
                <ListItemIcon className={classes.iconContainer}>
                  <ForumRoundedIcon className={classes.iconSize} />
                </ListItemIcon>
                <ListItemText
                  className={classes.links}
                  primary={intl.formatMessage(translations.Sidebar.requestData)}
                />
              </ListItem>

              <br />
              <br />
              <br />
            </List>

            {dividerText('COMPANIES')}
            <Divider />
            <br />

            {companies && companies.length > 0 && (
              <>
                {SearchField('Search')}
                <List>
                  {companies
                    .sort((a, b) =>
                      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                    )
                    .filter((ele) =>
                      ele.name.toLowerCase().includes(search.toLowerCase())
                    )
                    .map(({ id, name, url }) => (
                      <ListItem
                        button
                        key={id}
                        id={`sidebar-company-${name}`}
                        onClick={() => onPortfolioClick(url)}
                      >
                        <ListItemIcon className={classes.iconContainer}>
                          <BusinessRoundedIcon className={classes.iconSize} />
                        </ListItemIcon>
                        <ListItemText
                          className={classes.links}
                          primary={name}
                        />
                      </ListItem>
                    ))}
                </List>
              </>
            )}
          </>
        )}
      </Drawer>
    </div>
  );
}

Sidebar.propTypes = {
  sidebarStatus: PropTypes.bool,
  onChangeSidebarStatus: PropTypes.func,
  selectedOrganisation: PropTypes.object,
  companies: PropTypes.arrayOf(PropTypes.object),
  trialStatus: PropTypes.any,
};

Sidebar.defaultProps = {
  sidebarStatus: false,
  onChangeSidebarStatus: null,
  selectedOrganisation: {},
  trialStatus: '',
  companies: undefined,
};
