/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import {
  Grid,
  Menu,
  AppBar,
  Select,
  Button,
  Toolbar,
  MenuItem,
  Typography,
  IconButton,
  FormControl,
  CircularProgress,
  Divider,
} from '@material-ui/core';
import {
  ExitToApp,
  VpnKey,
  ArrowDropUp,
  ArrowDropDown,
  Menu as MenuIcon,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { KeytrackLogoWhite } from 'components/Common/Assets';

import translations from 'translations';
import { userRoles } from 'user/userConstants';
import { ORGANISATION_TYPES } from 'constants/index';
import { OrganizationShort, User, Organization } from 'types';
import { useAuth0 } from 'components/Common/Auth';
import useStyles from './Styles';
import ButtonDropDownWithSearch from './ButtonDropDownWithSearch';

interface HeaderProps {
  onSettings(obj: object): void;
  companies: Array<{}>;
  isSelectedTypeCompany: boolean;
  portfolios: Array<{}>;
  organisations: Array<OrganizationShort>;
  selectedOrganisation: OrganizationShort;
  sidebarStatus: boolean;
  trialStatus: string;
  user: User;
  onManageUsersAccess({
    companyOrganisation,
    url,
  }: {
    companyOrganisation: string;
    url?: string;
  }): void;
  onChangeSidebarStatus(status: boolean): void;
  onChangeSelectedOrganisation(obj: object): void;
  onAddOrganisation(obj: object): void;
  onPortfolio(obj: object): void;
  onUserSettings(): void;
  onManagePortfolio(obj: object): void;
  onAddCompanies(obj: object): void;
  onCompany(obj: object): void;
  handleCloseNotification(obj: object): void;
  onCompanyProfile(): void;
  onManageCompanies(obj: object): void;
}

const Header = ({
  onSettings,
  user,
  sidebarStatus,
  organisations,
  onManageUsersAccess,
  selectedOrganisation,
  onChangeSidebarStatus,
  onChangeSelectedOrganisation,
  onAddOrganisation,
  onPortfolio,
  onUserSettings,
  onManagePortfolio,
  onAddCompanies,
  onManageCompanies,
  onCompany,
  trialStatus,
  handleCloseNotification,
  portfolios,
  // companyProfileData,
  onCompanyProfile,
  companies,
}: HeaderProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const DEFAULT_MENU_ITEM = intl.formatMessage(translations.Header.myAccount);
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()!;
  const [organisation, setOrganisation] = useState<OrganizationShort | null>(
    null
  );
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [anchorElCompanies, setAnchorElCompanies] = useState(null);
  const [anchorElPortfolios, setAnchorElPortfolios] = useState(null);
  const [anchorElLogin, setAnchorElLogin] = useState(null);
  const history = useHistory();
  const isSelectedTypeCompany =
    organisation?.type === ORGANISATION_TYPES.COMPANY;
  const [orgSelecter, setOrgSelecter] = useState<
    [Array<OrganizationShort>, Array<OrganizationShort>]
  >([[], []]);

  useEffect(() => {
    const orgCompanies: Array<OrganizationShort> = [];
    const orgFund: Array<OrganizationShort> = [];
    organisations.forEach((value): void => {
      if (value.type === ORGANISATION_TYPES.COMPANY) {
        if (!value.direct) return;
        orgCompanies.push(value);
      } else {
        orgFund.push(value);
      }
    });
    setOrgSelecter([orgFund, orgCompanies]);
  }, [organisations]);

  useEffect(() => {
    if (selectedOrganisation) {
      setOrganisation(selectedOrganisation);
    }
  }, [selectedOrganisation]);

  const handleSettingsMenuClick = (event: any): void => {
    setAnchorElSettings(event.currentTarget);
  };

  const handleSettingsMenuClose = (): void => {
    setAnchorElSettings(null);
  };

  const handleCompaniesMenuClick = (event: any): void => {
    setAnchorElCompanies(event.currentTarget);
  };

  const handleCompaniesMenuClose = (): void => {
    setAnchorElCompanies(null);
  };

  const handlePortfoliosMenuClick = (event: any): void => {
    setAnchorElPortfolios(event.currentTarget);
  };

  const handlePortfoliosMenuClose = (): void => {
    setAnchorElPortfolios(null);
  };

  const handleMenuItemSelect = (event: any, url: string): void => {
    const menuItem = event.currentTarget.getAttribute('value');

    if (menuItem === 'usersAccess') {
      onManageUsersAccess({
        url: selectedOrganisation.url,
        companyOrganisation: selectedOrganisation.id,
      });
    }
    if (menuItem === 'addOrganisation') {
      onAddOrganisation({ url: selectedOrganisation.url });
    }
    if (menuItem === 'organizationSettings') {
      onSettings({ url: selectedOrganisation.url });
    }
    if (url) {
      history.push(url);
    }
  };

  const handleSubHeaderClick = (_event: any, id: string): void => {
    if (id === 'simple-portfolio-menu') {
      onManagePortfolio({ url: selectedOrganisation.url });
    }
    if (id === 'simple-companies-menu') {
      onAddCompanies({ url: selectedOrganisation.url });
    }
  };

  const handleSubHeader2Click = (_event: any, id: string): void => {
    if (id === 'simple-companies-menu2') {
      onManageCompanies({ url: selectedOrganisation.url });
    }
  };

  const handlePortfolioSelect = (event: any): void => {
    const portfolioId = event.currentTarget.getAttribute('value');
    onPortfolio({ url: selectedOrganisation.url, portfolioId });
  };

  const handleCompanySelect = (event: any): void => {
    const companyId = event.currentTarget.getAttribute('value');
    onCompany({ url: selectedOrganisation.url, companyId });
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void => {
    const findedOrganisation = organisations.find(
      (org) => org.id === event.target.value
    ) as Organization;

    if (findedOrganisation) {
      setOrganisation(findedOrganisation);
      onChangeSelectedOrganisation({
        url: findedOrganisation.url,
        id: findedOrganisation.id,
        companyOrganisation: findedOrganisation.id,
      });
      onChangeSidebarStatus(
        findedOrganisation.type !== ORGANISATION_TYPES.COMPANY
      );
      if (findedOrganisation.type === ORGANISATION_TYPES.COMPANY) {
        history.push(`${findedOrganisation.companies?.[0]?.url}`);
      } else {
        history.push(`${findedOrganisation.url}/updates`);
      }
    }
  };

  const handleMenu = (event: any): void => {
    setAnchorElLogin(event.currentTarget);
  };
  const handleMenuClose = (): void => {
    setAnchorElLogin(null);
  };

  // // Takes the organisations query result, and extracts all portfolios across all organisations
  // let Portfolios = [];
  // organisations
  //   .filter((el) => el.portfolios.items.length !== 0)
  //   .map((el) =>
  //     el.portfolios.items.map((eli) =>
  //       Portfolios.push({
  //         name: eli.name,
  //         value: extractUrn(eli.id).id,
  //       })
  //     )
  //   );

  const Settings = [];

  // TBD Should hide userAccess if no admin

  if (selectedOrganisation.role !== userRoles.VIEWER) {
    Settings.push(
      {
        name: <FormattedMessage {...translations.Header.usersAccess} />,
        value: 'usersAccess',
      },
      {
        name: <FormattedMessage {...translations.Header.addOrganisation} />,
        value: 'addOrganisation',
      },
      {
        name: <FormattedMessage {...translations.Header.organisationSettings} />,
        value: 'organizationSettings',
      }
    );
  } else {
    Settings.push({
      name: <FormattedMessage {...translations.Header.addOrganisation} />,
      value: 'addOrganisation',
    });
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Grid>
          <div className={classes.headerMain}>
            {organisation?.type !== ORGANISATION_TYPES.COMPANY && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={(): void => {
                  onChangeSidebarStatus(true);
                }}
                edge="start"
                className={classNames(classes.menuButton, {
                  [classes.hide]: sidebarStatus,
                })}
              >
                <MenuIcon className={classes.iconSize} />
              </IconButton>
            )}
            <div className={classes.infoBar}>
              <KeytrackLogoWhite />
              <FormControl className={classes.formControl}>
                {organisation?.id ? (
                  <Select
                    id="org-selector"
                    className={classes.selectOrganisation}
                    value={organisation.id}
                    onChange={handleChange}
                    inputProps={{ classes: { icon: classes.icon } }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {orgSelecter[0].length > 0 && (
                      <>
                        <div className={classes.dividerText}>
                          <Typography
                            style={{ fontWeight: 'bold' }}
                            variant="button"
                          >
                            {intl.formatMessage(
                              translations.Header.dividerFund
                            )}
                          </Typography>
                        </div>
                        <Divider />
                      </>
                    )}
                    {orgSelecter[0].length > 0 &&
                      orgSelecter[0].map((org) => (
                        <MenuItem
                          id={`org-${org.name}`}
                          key={org.id}
                          value={org.id}
                        >
                          {org.type === 'personOrg'
                            ? DEFAULT_MENU_ITEM
                            : org.name}
                        </MenuItem>
                      ))}
                    {orgSelecter[1].length > 0 && (
                      <>
                        <div className={classes.dividerText}>
                          <Typography
                            style={{ fontWeight: 'bold' }}
                            variant="button"
                          >
                            {intl.formatMessage(
                              translations.Header.dividerCompany
                            )}
                          </Typography>
                        </div>
                        <Divider />
                      </>
                    )}
                    {orgSelecter[1].length > 0 &&
                      orgSelecter[1].map((org) => (
                        <MenuItem
                          id={`org-${org.name}`}
                          key={org.id}
                          value={org.id}
                        >
                          {org.type === 'personOrg'
                            ? DEFAULT_MENU_ITEM
                            : org.name}
                        </MenuItem>
                      ))}
                  </Select>
                ) : (
                  <CircularProgress size={20} />
                )}
              </FormControl>

              {/* Settings Dropdown */}
              {/* {selectedOrganisation &&
                selectedOrganisation.role !== userRoles.VIEWER && ( */}
              <ButtonDropDownWithSearch
                id="simple-settings-menu"
                open={Boolean(anchorElSettings)}
                anchorEl={anchorElSettings}
                menuItems={Settings}
                searchRequired={false}
                dropdownTitle={
                  <FormattedMessage {...translations.Header.settings} />
                }
                onBtnPress={handleSettingsMenuClick}
                onItemPress={handleMenuItemSelect}
                onClose={handleSettingsMenuClose}
              />
              {/* )} */}

              {/* Companies Dropdown */}
              <ButtonDropDownWithSearch
                id="simple-companies-menu"
                open={Boolean(!isSelectedTypeCompany && anchorElCompanies)}
                anchorEl={anchorElCompanies}
                noArrow={isSelectedTypeCompany}
                subHeader2="Manage Companies"
                subHeader2Icon="BusinessOutlined"
                subHeader="Add Companies"
                subHeaderIcon="PostAddIcon"
                menuItems={companies}
                searchRequired
                dropdownTitle={
                  <FormattedMessage
                    id={
                      !isSelectedTypeCompany
                        ? translations.Header.companies.id
                        : translations.Header.companyProfile.id
                    }
                    defaultMessage={
                      !isSelectedTypeCompany
                        ? translations.Header.companies.defaultMessage
                        : translations.Header.companyProfile.defaultMessage
                    }
                  />
                }
                onBtnPress={
                  isSelectedTypeCompany
                    ? (): void => {
                        onCompanyProfile();
                      }
                    : handleCompaniesMenuClick
                }
                onItemPress={handleCompanySelect}
                onClose={handleCompaniesMenuClose}
                onSubHeader2Click={(e) =>
                  handleSubHeader2Click(e, 'simple-companies-menu2')
                }
                onSubHeaderClick={handleSubHeaderClick}
              />

              {/* Portfolios Dropdown */}
              {!isSelectedTypeCompany ? (
                <ButtonDropDownWithSearch
                  id="simple-portfolio-menu"
                  open={Boolean(anchorElPortfolios)}
                  anchorEl={anchorElPortfolios}
                  subHeader="Manage Portfolios"
                  subHeaderIcon="PieChartRounded"
                  menuItems={portfolios}
                  searchRequired
                  dropdownTitle={
                    <FormattedMessage {...translations.Header.portfolios} />
                  }
                  onBtnPress={handlePortfoliosMenuClick}
                  onItemPress={handlePortfolioSelect}
                  onClose={handlePortfoliosMenuClose}
                  onSubHeaderClick={handleSubHeaderClick}
                />
              ) : null}
            </div>
          </div>
        </Grid>

        <Grid>
          {/* Account Dropdown Starts */}
          <Button
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            className={classes.userButton}
            onClick={handleMenu}
            color="inherit"
            variant="text"
            endIcon={
              anchorElLogin ? (
                <ArrowDropUp className={classes.iconSize} />
              ) : (
                <ArrowDropDown className={classes.iconSize} />
              )
            }
          >
            <Typography component="span" variant="body2">
              {user?.email}
            </Typography>
          </Button>

          {isAuthenticated && (
            <Menu
              id="menu-appbar"
              open={Boolean(anchorElLogin)}
              keepMounted
              anchorEl={anchorElLogin}
              onClose={handleMenuClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
              <MenuItem
                className={classes.menuPopper}
                onClick={(): void => {
                  onUserSettings();
                  handleMenuClose();
                }}
              >
                <FormattedMessage {...translations.Header.userSettings} />
              </MenuItem>
              <MenuItem className={classes.logOutItem} onClick={() => logout()}>
                <Typography
                  style={{ fontWeight: 'bold' }}
                  variant="body1"
                  component="h6"
                >
                  <FormattedMessage {...translations.Header.logout} />
                </Typography>
                <ExitToApp fontSize="small" />
              </MenuItem>
            </Menu>
          )}
          {/* Account Dropdown Ends */}

          {!isAuthenticated && (
            <IconButton color="inherit" onClick={() => loginWithRedirect({})}>
              <VpnKey />
            </IconButton>
          )}
        </Grid>
      </Toolbar>
      {organisation?.type !== ORGANISATION_TYPES.COMPANY && (
      <div
        style={{
          display: trialStatus ? 'inherit' : 'none',
        }}
        className={classes.notificationBarContainer}
      >
        <div className={classes.notificationBar}>
          <Typography className={classes.notificationText} variant="body1">
            {trialStatus}
          </Typography>
          <span className={classes.notificationPadding} />
          <div
            className={classes.pointer}
            onClick={() => history.push(`${selectedOrganisation.url}/settings`)}
          >
            <Typography
              className={`${classes.notificationText} ${classes.notificationStrongText}`}
              variant="subtitle2"
            >
              <FormattedMessage {...translations.Header.upgradeNow} />
            </Typography>
          </div>
        </div>
        <div className={classes.notificationClose}>
          <CloseIcon
            onClick={handleCloseNotification}
            style={{ color: '#000' }}
          />
        </div>
      </div>
      )}
    </AppBar>
  );
};

export default Header;
