/* eslint-disable @typescript-eslint/camelcase */
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';
import config from 'config';

async function createAuth0ClientFunc(props: any): Promise<Auth0Client> {
  // eslint-disable-next-line new-cap
  const auth0Client: Auth0Client = await createAuth0Client({
    domain: config.auth0.domain,
    client_id: config.auth0.clientId,
    ...props,
  });
  global.auth0Client = auth0Client;
  return auth0Client;
}

export default createAuth0ClientFunc;
