import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { defineMessages, useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  gridTitle: {
    marginTop: theme.spacing(3),
  },
  sectionTitles: {
    // color: '#000',
    fontWeight: '500',
    letterSpacing: 0.2,
  },
}));

const GridTitle = ({ textId, text, classStyles }) => {
  const messages = defineMessages({
    gridTitle: {
      id: textId,
      defaultMessage: text,
    },
  });
  const classes = useStyles();
  const { formatMessage } = useIntl();
  return (
    <Grid item xs={12} className={`${classes.gridTitle} ${classStyles}`}>
      <Typography
        component="h6"
        variant="h6"
        color="textPrimary"
        gutterBottom
        className={classes.sectionTitles}
      >
        {formatMessage(messages.gridTitle)}
      </Typography>
    </Grid>
  );
};

GridTitle.propTypes = {
  textId: PropTypes.string,
  text: PropTypes.string,
  classStyles: PropTypes.string,
};

GridTitle.defaultProps = {
  textId: '',
  text: '',
  classStyles: '',
};

// eslint-disable-next-line import/prefer-default-export
export { GridTitle };
