import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddBox';
import { useToolbarStyles } from './styles';

interface ToolBarProps {
  onAddRow: () => void;
  enableEdit?: boolean;
}

export const EnhancedTableToolbar = ({
  onAddRow,
  enableEdit,
}: ToolBarProps) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar className={classes.root}>
      <Tooltip title="Add Row">
        {enableEdit ? (
          <IconButton
            aria-label="add-row"
            disabled={!enableEdit}
            onClick={onAddRow}
          >
            <AddIcon />
          </IconButton>
        ) : (
          <div />
        )}
      </Tooltip>
    </Toolbar>
  );
};
