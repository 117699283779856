/* eslint-disable no-console */
export const logActionError = (type, error = null, actionName) => {
  if (type === 'error' || type === 'log') {
    console[type](`Error: ${actionName} Action: `, error);
  }
};

export const checkActionBadDataReceived = (bool, actionName, action) => {
  if (bool) {
    logActionError('error', `Bad data received while ${action}`, actionName);
  }
  return bool;
};
