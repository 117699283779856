import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { setAlert } from 'containers/Config';

import { EmailVerification } from 'components/Common/EmailVerification';
import { RESEND_EMAIL_VERIFICATION } from '../../graphql';

const EmailVerificationContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const [resendEmailVerification] = useMutation(RESEND_EMAIL_VERIFICATION);
  const handleResendEmailVerification = async (
    userId: string
  ): Promise<void> => {
    try {
      await resendEmailVerification({
        variables: {
          userId,
        },
      });
      dispatch(
        setAlert({
          open: true,
          message: 'verification email send successfully!',
          severity: 'success',
        })
      );
    } catch (error) {
      const errorMessage =
        error.graphQLErrors &&
        error.graphQLErrors[0] &&
        error.graphQLErrors[0].code;
      dispatch(
        setAlert({
          open: true,
          message: errorMessage || error.message,
          severity: 'error',
        })
      );
    }
  };
  return (
    <>
      <EmailVerification
        resendEmailVerification={handleResendEmailVerification}
      />
    </>
  );
};

export { EmailVerificationContainer };
