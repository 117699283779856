/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import { useStyles } from './style';
import AddUser from './AddUser';
import AddOrganisation from './AddOrganisation';

const UserAccessModal = ({
  type,
  isOpened,
  onClose,
  onShareOrganisation,
  data,
  onCreateUser,
  onUpdateContact,
  showAddToContact,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={isOpened}
      aria-labelledby="onbording_modal"
      aria-describedby="onbording_modal"
      BackdropProps={{ className: classes.modalOverlay }}
      className={classes.modal}
      onClose={onClose}
    >
      <div className={classes.contentWrapper} onClick={onClose}>
        {type === 'user' ? (
          <AddUser
            classes={classes}
            data={data}
            showAddToContact={showAddToContact}
            onUpdateContact={onUpdateContact}
            onCreateUser={onCreateUser}
            closeModal={onClose}
          />
        ) : (
          <AddOrganisation
            classes={classes}
            onShareOrganisation={onShareOrganisation}
            closeModal={onClose}
          />
        )}
      </div>
    </Modal>
  );
};

UserAccessModal.propTypes = {
  type: PropTypes.string,
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  onCreateUser: PropTypes.func.isRequired,
  onShareOrganisation: PropTypes.func.isRequired,
  onUpdateContact: PropTypes.func,
  showAddToContact: PropTypes.bool,
};

UserAccessModal.defaultProps = {
  type: 'user',
  isOpened: false,
  data: [],
  onClose: () => {},
  onUpdateContact: () => {},
  showAddToContact: () => {},
};

// eslint-disable-next-line import/prefer-default-export
export { UserAccessModal };
