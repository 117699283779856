import React from 'react';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

const CompanyDefaultText = ({ text }) => <Alert severity="info">{text}</Alert>;

CompanyDefaultText.propTypes = {
  text: PropTypes.bool.isRequired,
};

// eslint-disable-next-line import/prefer-default-export
export { CompanyDefaultText };
