import makeStyles from '@material-ui/styles/makeStyles';

export const useStyle = makeStyles({
  companySelectorSelect: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  companySelectorOptionHeader: {
    position: 'relative',
    color: '#575151',
    fontWeight: 'bolder',
  },
});
