import { defineMessages } from 'react-intl';

export default defineMessages({
  userSttingsTitle: {
    id: 'UserSttings.title',
    defaultMessage: 'User Settings',
  },
  generalTitle: {
    id: 'UserSttings.General.title',
    defaultMessage: 'General',
  },
  emailLabel: {
    id: 'UserSttings.General.emailLabel',
    defaultMessage: 'Email',
  },
  nameLabel: {
    id: 'UserSttings.General.nameLabel',
    defaultMessage: 'Name',
  },
  phoneLabel: {
    id: 'UserSttings.General.phoneLabel',
    defaultMessage: 'Phone',
  },
  manageOrganization: {
    id: 'UserSettings.manageOrg',
    defaultMessage: 'Manage Organization',
  },
  idLabel: {
    id: 'UserSettings.manageOrg.id',
    defaultMessage: 'Id',
  },
  roleLabel: {
    id: 'UserSettings.manageOrg.role',
    defaultMessage: 'Role',
  },
  actionLabel: {
    id: 'UserSettings.manageOrg.action',
    defaultMessage: 'Action',
  },
  lastNameLabel: {
    id: 'UserSttings.General.lastNameLabel',
    defaultMessage: 'Last Name',
  },
  defaultOrganizationLabel: {
    id: 'UserSttings.General.defaultOrganizationLabel',
    defaultMessage: 'Default Organization',
  },
  updateBtnTitle: {
    id: 'UserSttings.Update.btnTitle',
    defaultMessage: 'Update',
  },
  deleteBtnTitle: {
    id: 'UserSttings.Delete.btnTitle',
    defaultMessage: 'Delete',
  },
  confirmDeleteBtnTitle: {
    id: 'UserSttings.Delete.Modal.btnTitle',
    defaultMessage: 'Confirm',
  },
  confirmDeleteText: {
    id: 'UserSttings.Delete.Modal.pre',
    defaultMessage:
      'You are about to delete your user account. This will also delete all \norganisations where you are the only admin user. \nTHIS STEP CANNOT BE UNDONE. Type "DELETE" in the form below to\nconfirm deletion.',
  },
  confirmDeleteTitle: {
    id: 'UserSttings.Delete.Modal.h2',
    defaultMessage: 'Are you sure?',
  },
  deleteMessage: {
    id: 'UserSttings.Delete.deleteMsg',
    defaultMessage: 'Permanently delete account',
  },
  deleteTitle: {
    id: 'UserSttings.Delete.title',
    defaultMessage: 'Delete Account',
  },
});
