import { gql } from 'apollo-boost';
import { INVESTMENT_PAYLOAD } from './payloads';

export const DELETE_INVESTMENTS_MUTATION = gql`
  mutation($investmentIds: [ID!]!) {
    deleteInvestments(investmentIds: $investmentIds)
  }
`;

export const UPDATE_INVESTMENT_MUTATION = gql`
  mutation($companyId: ID!, $investment: CreateInvestmentInput!) {
    updateInvestment(
      input: { companyId: $companyId, investment: $investment }
    ) {
      ${INVESTMENT_PAYLOAD}
      company {
        id
      }
    }
  }
`;
