import { handleActions, createAction } from 'redux-actions';
import { createSelector } from 'reselect';

import { ACTION_TYPES_ALERT } from '../actionTypes';

const initialState = {
  open: false,
  severity: '',
  message: '',
};

const alert = handleActions(
  {
    [ACTION_TYPES_ALERT.SET_ALERT]: (
      state,
      { payload = { ...initialState } }
    ) => ({
      ...state,
      ...payload,
    }),
  },
  initialState
);

const getAlert = (state) => state.alert;

const getOpen = createSelector(getAlert, (state) => state);

const setAlert = createAction(ACTION_TYPES_ALERT.SET_ALERT);

export { alert, getAlert, getOpen, setAlert };
