import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { SettingComponentProps, OnChangeEvent } from 'types';
import { Switch } from 'components/Common/Switch';
import { useStyles } from './styles';

export default ({
  field,
  status,
  handleChange,
  sortingNumber,
  changeSorting,
}: SettingComponentProps) => {
  const classes = useStyles();

  const handleSwitchChange = (event: OnChangeEvent) => {
    const {
      target: { checked },
    } = event;
    handleChange({
      field,
      status: checked,
      sorting: sortingNumber,
    });
  };

  return (
    <div className={classes.settingWrapper}>
      <IconButton
        onClick={() => changeSorting(-1)}
        className={classes.sortingIconWrapper}
      >
        <ArrowDropUp className={classes.sortingIcon} />
      </IconButton>
      <IconButton
        onClick={() => changeSorting(1)}
        className={classes.sortingIconWrapper}
      >
        <ArrowDropDown className={classes.sortingIcon} />
      </IconButton>
      <Typography className={classes.settingText} variant="h6">
        {field}
      </Typography>
      <Switch checked={status} onChange={handleSwitchChange} label="" />
    </div>
  );
};
