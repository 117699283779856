import React from 'react';
import {
  Modal,
  Fade,
  Container,
  Paper,
  Typography,
  Grid,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import { Button } from 'components/Common/Buttons';
import translations from 'translations';
import { GridTitle } from 'components/Common/Grid';
import { useStyles } from './styles';
import { CancelModalProps } from './type';

export const CancelSubscriptionModal = ({
  isOpened,
  onClose,
  selectedSubscription,
  cancelSubscription,
}: CancelModalProps): JSX.Element => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const handleOnCancel = async () => {
    if (!selectedSubscription || !selectedSubscription.id) {
      return;
    }
    onClose();
    await cancelSubscription({ subscriptionId: selectedSubscription.id });
  };

  return (
    <Modal
      open={isOpened}
      aria-labelledby="onbording_modal"
      aria-describedby="onbording_modal"
      className={classes.modal}
      onClose={onClose}
    >
      <Fade in={isOpened}>
        <Container maxWidth="sm" className={classes.cancelModalContainer}>
          <Paper className={classes.subscriptionModalPaper}>
            {/* BILLING GRID */}
            <GridTitle
              textId="OrganizationSettings.CancelSubscriptin.title"
              text="Cancel subscription"
            />
            <div className={classes.space} />
            <Typography variant="body1">
              {formatMessage(
                translations.OrganisationSettings.Subscription.alert
                  .cancelSubscriptionInfo
              )}
            </Typography>
            <div className={classes.space} />
            <div className={classes.space} />
            <Grid
              container
              item
              xs={8}
              sm={4}
              md={8}
              lg={8}
              justify="flex-start"
            >
              <Button
                typeColor="gray"
                shape="round"
                className={classes.actionBtn}
                onClick={onClose}
              >
                {formatMessage(
                  translations.OrganisationSettings.Subscription.alert
                    .abortCancelSubscription
                )}
              </Button>
              <Button
                typeColor="delete"
                shape="round"
                className={classes.actionBtn}
                onClick={handleOnCancel}
              >
                {formatMessage(translations.Common.common.cancel)}
              </Button>
            </Grid>
          </Paper>
        </Container>
      </Fade>
    </Modal>
  );
};
