/* eslint-disable operator-linebreak */
/* eslint-disable no-restricted-globals */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { ArrowDropUp } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from './messages';
import { getDownloads } from '../../common/getDownloads';
import { normalizeContacts } from '../../common/normalizeContactName';
import { COMPANY_CONTACT_TYPES } from '../../constants';
import { setDownloads } from '../../reducers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  report: {
    cursor: 'pointer',
    fontWeight: '500',
  },
}));

export function CompaniesTable({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const setDownloadRedux = (payload) => {
    dispatch(setDownloads(payload));
  };

  const columns = [
    {
      title: intl.formatMessage(messages.company),
      field: 'name',
      editable: 'never',
      defaultSort: 'asc',
      customSort: (a, b) => (a.name < b.name ? -1 : 1),
    },
    {
      title: intl.formatMessage(messages.investment),
      field: 'investment',
      editable: 'never',
      render: (rowData) => (
        <Typography>
          {isNaN(rowData.investment)
            ? '-'
            : intl.formatNumber(rowData.investment, {
                style: 'currency',
                currency: rowData.currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
        </Typography>
      ),
    },
    // {
    //   title: intl.formatMessage(messages.ownership),
    //   field: 'ownership',
    //   editable: 'never',
    //   render: (rowData) => (
    //     <Typography>
    //       {isNaN(rowData.ownership)
    //         ? '-'
    //         : intl.formatNumber(rowData.ownership, {
    //             style: 'percent',
    //             minimumFractionDigits: 2,
    //             maximumFractionDigits: 2,
    //           })}
    //     </Typography>
    //   ),
    // },
    {
      title: intl.formatMessage(messages.report),
      field: 'report',
      editable: 'never',
      render: (rowData) => (
        <Typography
          className={classes.report}
          onClick={async () => {
            if (rowData.report) {
              await getDownloads(
                rowData.report.url,
                rowData.report.name,
                rowData.id,
                setDownloadRedux
              );
            }
          }}
        >
          {rowData.report ? rowData.report.name : '-'}
        </Typography>
      ),
    },
    {
      title: intl.formatMessage(messages.investmentManager),
      field: 'investmentManager',
      editable: 'never',
      render: (rowData) => {
        const contacts = Array.isArray(rowData.contacts)
          ? rowData.contacts
          : [];
        const investmentManager = contacts.filter(
          (obj) => obj.contactType === COMPANY_CONTACT_TYPES.INVESTOR
        );
        // rowData.contacts ? rowData.contacts.firstInvestMgr : '-'
        return (
          <Typography>
            {normalizeContacts(investmentManager, 1) || '-'}
          </Typography>
        );
      },
    },
  ];

  const icons = {
    SortArrow: (props) => <ArrowDropUp {...props} />,
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <MaterialTable
          columns={columns}
          data={data}
          title=""
          icons={icons}
          options={{
            toolbar: false,
            sorting: true,
            // selection: false,
            search: false,
            emptyRowsWhenPaging: false,
            thirdSortClick: false,
            // addRowPosition: 'first',
            // actionsColumnIndex: -1,
            // grouping: false,
            // draggable: false,
            // debounceInterval: 0,
          }}
        />
      </Paper>
    </div>
  );
}

CompaniesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
