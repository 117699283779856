import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import messages from './messages';

const CompanyCount = ({ count }) => {
  const { formatMessage } = useIntl();
  return (
    <Box
      border={1}
      borderColor="grey.500"
      borderRadius={8}
      marginTop={0}
      color="text.primary"
      bgcolor="#1ABCFE40"
      width="fit-content"
      padding={1}
    >
      {`${formatMessage(messages.dataFrom)} ${count} ${
        count === 1 ? 'company' : 'companies'
      }`}
    </Box>
  );
};

CompanyCount.propTypes = {
  count: PropTypes.number.isRequired,
};

export default CompanyCount;
