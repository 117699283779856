import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { selectSubscriptionAccessLimit } from 'containers/Subscription';
import CompanyApiTabContainer from 'containers/Company/CompanyApiTabContainer';
import messages from './messages';

function tabProps(tabName) {
  return {
    id: `basic-tab-${tabName}`,
    'aria-controls': `basic-tabpanel-${tabName}`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  tabs: {
    ...theme.typography.fontSize,
  },
}));

const ManageCompanyTabs = ({
  selectedOrganisation,
  onCreateCompany,
  organisation,
  portfolios,
  investmentManagers,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { companies, hasTrial } = useSelector(selectSubscriptionAccessLimit);
  const canCreateCompany = companies || hasTrial;

  const TAB_CRUNCHBASE = intl.formatMessage(messages.addNewTab);
  const TAB_MANUAL = intl.formatMessage(messages.addFromSharedTab);

  const [value, setValue] = useState(
    canCreateCompany ? TAB_CRUNCHBASE : TAB_MANUAL
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        textColor="primary"
        indicatorColor="primary"
        onChange={handleChange}
        aria-label="manage-companies-tabs"
      >
        {canCreateCompany ? (
          <Tab
            className={classes.tabs}
            label={TAB_CRUNCHBASE}
            value={TAB_CRUNCHBASE}
            {...tabProps(TAB_CRUNCHBASE)}
          />
        ) : null}
        <Tab
          className={classes.tabs}
          label={TAB_MANUAL}
          value={TAB_MANUAL}
          {...tabProps(TAB_MANUAL)}
        />
      </Tabs>
      <Paper className={classes.paper} variant="outlined">
        <CompanyApiTabContainer
          showShared={!canCreateCompany ? TAB_MANUAL : value === TAB_MANUAL}
          organisation={organisation}
          onCreateCompany={onCreateCompany}
          selectedOrganisation={selectedOrganisation}
          portfolios={portfolios}
          investmentManagers={investmentManagers}
        />
      </Paper>
    </>
  );
};

export default ManageCompanyTabs;

ManageCompanyTabs.propTypes = {
  selectedOrganisation: PropTypes.object,
  onCreateCompany: PropTypes.func,
  organisation: PropTypes.object,
  portfolios: PropTypes.arrayOf(PropTypes.object).isRequired,
  investmentManagers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ManageCompanyTabs.defaultProps = {
  selectedOrganisation: {},
  onCreateCompany: null,
  organisation: {},
};
