import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '40px',
  },
  title: {
    marginBottom: 25,
  },
  paper: {
    padding: '16px 32px 24px',
  },
}));
