import { Colors } from 'constants/colors';
import { createMyTheme } from './configureTheme';

export const keyTrackTheme = createMyTheme({
  typography: {
    fontSize: 14,
    h6: {
      fontSize: '1.15rem',
    },
    h5: {
      fontSize: '1.25rem',
    },
    h3: {
      fontSize: '1.7rem',
    },
    h4: {
      fontSize: '1.45rem',
    },
    subtitle1: {
      fontSize: '14px',
    },
    body1: {
      fontSize: '14px',
    },
    body2: {
      fontSize: '14px',
    },
    overline: {
      fontSize: '14px',
    },
    subtitle2: {
      fontSize: '0.8rem',
    },
  },
  custom: {
    buttonLabel: {
      fontSize: '13px',
    },
    side_bar_text: {
      fontSize: '15px',
    },
    iconSize: {
      fontSize: '20px',
    },
  },
  overrides: {
    MuiRadio: {
      colorPrimary: {
        color: Colors.TEXT,
      },
      root: {
        '&$checked': {
          color: Colors.TEXT,
        },
        fontSize: '19px',
      },
    },
    MuiIcon: {
      fontSizeSmall: {
        fontSize: 14,
      },
      fontSizeLarge: {
        fontSize: 20,
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '22px',
      },
    },
    MuiButton: {
      // Name of the rule
      root: {
        // Some CSS
        fontSize: '13px',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '13px',
      },
    },
  },
});
