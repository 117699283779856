import React, { useState } from 'react';
import { Paper, Grid } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from 'components/Common/Auth';
import {
  AllowedOrganisationTypes,
  defaultCurrency,
} from 'common/commonContants';
import translations from 'translations';
import { GridTitle } from 'components/Common/Grid';
import {
  OrganizationThunk,
  selectOrganizationLoading,
} from 'containers/Organisation';
import { CompanyThunk } from 'containers/Company';
import { PortfolioThunk } from 'containers/Portfolio';
import {
  CreateOrgPayload,
  Organization,
  Company,
  CreateCompanyPayload,
} from 'types';
import { MAKE_ORGANISATION_DEFAULT } from '../../../graphql';
import { OnboardingStepZero } from './StepZero';
import { OnboardingStepOne } from './StepOne';
import { OnboardingStepTwo } from './StepTwo';
import { OnboardingStepThree } from './StepThree';
import { OnboardingStepFour } from './StepFour';
import { OnboardingStepFive } from './StepFive';
import { useStyles } from './styles';

interface OnboardingMainProps {
  onClose(obj: object): void;
  selectedOrganisation: Organization;
}

type onCreatePortfolioProps = {
  name: string;
  size: string | number;
  creationYear: string | number;
  targetNumberOfInvestments: string | number;
  sizeCurrency: string;
  organisationId: string;
};

const OnboardingMain = ({
  onClose,
  selectedOrganisation,
}: OnboardingMainProps): JSX.Element => {
  const classes = useStyles();
  const { logout } = useAuth0()!;
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const organizationLoading = useSelector(selectOrganizationLoading);
  const [text, setText] = useState(' ');
  const [componentsData, setComponentsData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);

  const [makeOrganisationDefault] = useMutation(MAKE_ORGANISATION_DEFAULT);

  const Steps = ({ step, ...props }: any): JSX.Element => {
    const { componentsData: { userType = '' } = {} } = props;
    switch (step) {
      case 0:
        setText("Let's get started");
        return <OnboardingStepZero {...props} />;
      case 1:
        setText('How are you investing?');
        return <OnboardingStepOne {...props} />;
      case 2:
        setText('Add a portfolio');
        return <OnboardingStepTwo {...props} />;
      case 3:
        setText(
          userType === AllowedOrganisationTypes.FUND
            ? 'Add a first investment'
            : 'Add your company profile'
        );
        return <OnboardingStepThree {...props} />;
      case 4:
        setText('One more thing');
        return <OnboardingStepFive {...props} />;
      case 5:
        setText('Done');
        return <OnboardingStepFour onClose={onClose} {...props} />;
      case 6:
        setText(
          userType === AllowedOrganisationTypes.FUND
            ? 'Add a first investment'
            : 'Add your company profile'
        );
        return <OnboardingStepThree {...props} />;
      default:
        return <OnboardingStepZero {...props} />;
    }
  };

  const onCreatePortfolio = async ({
    name,
    size,
    creationYear,
    targetNumberOfInvestments,
    sizeCurrency = defaultCurrency,
    organisationId,
  }: onCreatePortfolioProps): Promise<void> => {
    await new Promise((resolve, reject) => {
      dispatch(
        PortfolioThunk.createPortfolio(
          {
            organisationId: organisationId || selectedOrganisation.id,
            name,
            size: Number(size),
            sizeCurrency,
            targetNumberOfInvestments: Number(targetNumberOfInvestments),
            creationYear: Number(creationYear),
          },
          {
            resolve,
            reject,
          }
        )
      );
    });
    setCurrentStep(3);
  };

  const onCreateOrganisation = async (
    payload: CreateOrgPayload,
    markDefault: boolean
  ): Promise<Organization> => {
    const newOrg = await new Promise<Organization>((resolve, reject) => {
      dispatch(
        OrganizationThunk.createOrganization(payload, {
          resolve,
          reject,
          successMsg: (
            <FormattedMessage
              {...translations.Organization.Add.AddOrgSuccess}
            />
          ),
        })
      );
    });
    setComponentsData((state) => ({ ...state, ...newOrg }));
    if (markDefault) {
      await makeOrganisationDefault({
        variables: {
          organisationId: newOrg.id,
        },
      });
    }
    return newOrg;
  };

  const onCreateCompany = async (
    payload: CreateCompanyPayload
  ): Promise<Company> => {
    const newCompany: Company = await new Promise((resolve, reject) => {
      dispatch(
        CompanyThunk.createCompany(
          { company: payload },
          {
            resolve,
            reject,
            successMsg: (
              <FormattedMessage
                {...translations.Company.ManageCompanies.Add
                  .onSuccessCreateCompany}
              />
            ),
          }
        )
      );
    });
    setCurrentStep(4);
    return newCompany;
  };

  return (
    <Paper className={classes.paper} elevation={0} variant="outlined">
      <Grid container className={classes.grid}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flex: 1,
          }}
        >
          <GridTitle
            textId={translations.Onboarding.onBoardingMainTitle.id}
            text={text}
            classStyles={classes.title}
          />
          <Button
            style={{ padding: 0 }}
            variant="text"
            onClick={(): void => logout()}
          >
            <Typography className={classes.logout} variant="button">
              {`${formatMessage(translations.Auth.logout)}`}
            </Typography>
          </Button>
        </div>
        <Steps
          step={currentStep}
          componentsData={componentsData}
          onClose={onClose}
          setCurrentStep={setCurrentStep}
          setComponentsData={setComponentsData}
          selectedOrganisation={selectedOrganisation}
          onCreatePortfolio={onCreatePortfolio}
          createPortfolioLoading={organizationLoading}
          onCreateOrganisation={onCreateOrganisation}
          mutationLoading={false}
          onCreateCompany={onCreateCompany}
        />
      </Grid>
    </Paper>
  );
};

export { OnboardingMain };
