import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  Select,
  Divider,
  MenuItem,
  TextField,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useStyles, UpdateButton, DeleteButton } from './styles';
import messages from './messages';
import { emailRegex } from '../../utils';
import OrgTable from './OrgTable';

const UserSettings = ({
  organisations,
  onUpdateOrganisation,
  user,
  onDeleteAccount,
  onUpdateName,
  onLeaveOrganization,
  setLoading,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [values, setValue] = useState({
    name: user ? user.firstName : '',
    lastName: user ? user.lastName : '',
    phone: user ? user.phone : '',
    email: '',
    selectedDefaultOrganization: {
      name: '',
    },
    confirm: '',
  });
  const [open, setOpen] = useState(false);
  const [validValues, setValidValue] = useState({
    nameValid: false,
    nameHelperText: '',
    lastNameValid: false,
    lastNameHelperText: '',
    emailValid: false,
    emailHelperText: '',
    confirmValid: false,
    confirmHelperText: '',
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setValue({
      ...values,
      name: user ? user.firstName : '',
      lastName: user ? user.lastName : '',
      phone: user ? user.phone : '',
    });
  }, [user]);

  useEffect(() => {
    organisations.forEach((org) => {
      if (org.isDefault) {
        setValue({
          ...values,
          selectedDefaultOrganization: org,
        });
      }
    });
  }, [organisations]);

  const emailValidator = () => emailRegex.test(values.email);
  const nameValidator = () => (values.name ? values.name.length >= 3 : false);

  const handleChange = (fieldName) => (event) => {
    setValue({
      ...values,
      [fieldName]: event.target.value,
    });
    // Validaion
    switch (fieldName) {
      case 'name':
        if (!nameValidator()) {
          setValidValue({
            ...validValues,
            nameValid: true,
            nameHelperText: 'Minimun 3 characters required!',
          });
        } else {
          setValidValue({
            ...validValues,
            nameValid: false,
            nameHelperText: '',
          });
        }
        break;
      case 'lastName':
        if (!nameValidator()) {
          setValidValue({
            ...validValues,
            lastNameValid: true,
            lastNameHelperText: 'Minimun 3 characters required!',
          });
        } else {
          setValidValue({
            ...validValues,
            lastNameValid: false,
            lastNameHelperText: '',
          });
        }
        break;
      case 'confirm':
        if (event.target.value !== 'DELETE') {
          setValidValue({
            ...validValues,
            confirmValid: true,
            confirmHelperText: 'does not match',
          });
        } else {
          setValidValue({
            ...validValues,
            confirmValid: false,
            confirmHelperText: '',
          });
        }
        break;
      case 'email':
        if (!emailValidator()) {
          setValidValue({
            ...validValues,
            emailValid: true,
            emailHelperText: 'Invalid email address!',
          });
        } else {
          setValidValue({
            ...validValues,
            emailValid: false,
            emailHelperText: '',
          });
        }
        break;
      default:
        break;
    }
  };

  const makeOrganisationDefault = () => {
    onUpdateOrganisation({
      variables: {
        organisationId: values.selectedDefaultOrganization.id,
      },
    });
  };

  const handleOrganizationChange = (event) => {
    const findedCompany = organisations.find(
      (org) => org.name === event.target.value
    );
    setValue({
      ...values,
      selectedDefaultOrganization: findedCompany,
    });
  };

  const deleteUser = async () => {
    setLoading(true);
    await onDeleteAccount({
      variables: {
        id: user.id,
      },
    });
    return false;
  };

  const handleUpdateName = () => {
    onUpdateName({
      name: values.name,
      id: user.id,
      lastName: values.lastName,
      phone: values.phone,
    });
  };

  return (
    <>
      <Grid container className={classes.grid}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          disableAutoFocus
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.modalPaper}>
              <h2 id="transition-modal-title">
                {`${intl.formatMessage(messages.confirmDeleteTitle)}`}
              </h2>
              <pre id="transition-modal-description">
                {`${intl.formatMessage(messages.confirmDeleteText)}`}
              </pre>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{ justifyContent: 'space-between' }}
                  className={classes.inputsCol}
                >
                  <TextField
                    id="outlined-basic"
                    size="small"
                    placeholder=""
                    variant="outlined"
                    value={values.confirm}
                    error={validValues.confirmValid}
                    onChange={handleChange('confirm')}
                    className={classes.formTextField}
                    helperText={validValues.confirmHelperText}
                  />
                </div>
                <div style={{ width: '30%' }} className={classes.buttonCol}>
                  <DeleteButton
                    disabled={
                      !values.confirm.length || validValues.confirmValid
                    }
                    variant="contained"
                    color="primary"
                    onClick={deleteUser}
                  >
                    {`${intl.formatMessage(messages.confirmDeleteBtnTitle)}`}
                  </DeleteButton>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
        <Grid item xs={12}>
          <Typography
            component="h3"
            variant="h4"
            color="primary"
            gutterBottom
            className={classes.sectionTitles}
          >
            {intl.formatMessage(messages.userSttingsTitle)}
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.gridTitle}>
          <Typography
            component="h6"
            variant="h5"
            color="primary"
            gutterBottom
            className={classes.sectionTitles}
          >
            {intl.formatMessage(messages.generalTitle)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0} variant="outlined">
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              xs={11}
              sm={9}
              md={7}
              lg={7}
              className={classes.formRow}
            >
              <div style={{ display: 'none' }} className={classes.labelCol}>
                <InputLabel className={classes.label}>
                  {`${intl.formatMessage(messages.emailLabel)}`}
                </InputLabel>
              </div>
              <div style={{ display: 'none' }} className={classes.inputsCol}>
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  value={values.email}
                  placeholder="abc@email.com"
                  error={validValues.emailValid}
                  onChange={handleChange('email')}
                  className={classes.formTextField}
                  helperText={validValues.emailHelperText}
                />
              </div>
              <div className={classes.buttonCol} />
            </Grid>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              // justify="space-between"
              xs={11}
              sm={9}
              md={7}
              lg={7}
              className={classes.formRow}
            >
              <div className={classes.labelCol}>
                <InputLabel className={classes.label}>
                  {`${intl.formatMessage(messages.nameLabel)}`}
                </InputLabel>
              </div>
              <div className={classes.inputsCol}>
                <TextField
                  id="outlined-basic"
                  size="small"
                  placeholder=""
                  variant="outlined"
                  value={values.name}
                  error={validValues.nameValid}
                  onChange={handleChange('name')}
                  className={classes.formTextField}
                  helperText={validValues.nameHelperText}
                />
              </div>
            </Grid>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              xs={11}
              sm={9}
              md={7}
              lg={7}
              className={classes.formRow}
            >
              <div className={classes.labelCol}>
                <InputLabel className={classes.label}>
                  {`${intl.formatMessage(messages.lastNameLabel)}`}
                </InputLabel>
              </div>
              <div className={classes.inputsCol}>
                <TextField
                  id="outlined-basic"
                  size="small"
                  placeholder=""
                  variant="outlined"
                  value={values.lastName}
                  error={validValues.lastNameValid}
                  onChange={handleChange('lastName')}
                  className={classes.formTextField}
                  helperText={validValues.lastNameHelperText}
                />
              </div>
            </Grid>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              xs={11}
              sm={9}
              md={7}
              lg={7}
              className={classes.formRow}
            >
              <div className={classes.labelCol}>
                <InputLabel className={classes.label}>
                  {`${intl.formatMessage(messages.phoneLabel)}`}
                </InputLabel>
              </div>
              <div className={classes.inputsCol}>
                <TextField
                  id="outlined-basic"
                  size="small"
                  placeholder=""
                  variant="outlined"
                  value={values.phone}
                  error={validValues.phone}
                  onChange={handleChange('phone')}
                  className={classes.formTextField}
                  helperText={validValues.lastNameHelperText}
                />
              </div>
              <div className={classes.buttonCol}>
                <UpdateButton
                  onClick={handleUpdateName}
                  variant="contained"
                  color="primary"
                >
                  {`${intl.formatMessage(messages.updateBtnTitle)}`}
                </UpdateButton>
              </div>
            </Grid>

            <Divider className={classes.divider} />

            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              xs={11}
              sm={9}
              md={7}
              lg={7}
              className={classes.formRow}
            >
              <div className={classes.labelCol}>
                <InputLabel className={classes.label}>
                  {`${intl.formatMessage(messages.defaultOrganizationLabel)}`}
                </InputLabel>
              </div>
              <div className={classes.inputsCol}>
                <Select
                  id="select"
                  size="small"
                  variant="outlined"
                  className={classes.formTextField}
                  classes={{ icon: classes.icon }}
                  onChange={handleOrganizationChange}
                  value={values.selectedDefaultOrganization.name}
                >
                  {organisations.map((item) => (
                    <MenuItem key={JSON.stringify(item.id)} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.buttonCol}>
                <UpdateButton
                  onClick={makeOrganisationDefault}
                  variant="contained"
                  color="primary"
                >
                  {`${intl.formatMessage(messages.updateBtnTitle)}`}
                </UpdateButton>
              </div>
            </Grid>
          </Paper>
        </Grid>

        <Grid container>
          <Grid item xs={12} className={classes.gridTitle}>
            <Typography
              component="h6"
              variant="h5"
              color="primary"
              gutterBottom
              className={classes.sectionTitles}
            >
              {intl.formatMessage(messages.deleteTitle)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0} variant="outlined">
              <Grid
                item
                xs={7}
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <InputLabel className={classes.deleteMsg}>
                  {`${intl.formatMessage(messages.deleteMessage)}`}
                </InputLabel>
                <div className={classes.buttonCol}>
                  <DeleteButton
                    onClick={handleOpen}
                    variant="contained"
                    color="primary"
                  >
                    {`${intl.formatMessage(messages.deleteBtnTitle)}`}
                  </DeleteButton>
                </div>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <OrgTable
          organisations={organisations}
          onLeaveOrganization={onLeaveOrganization}
          user={user}
        />
      </Grid>
    </>
  );
};

UserSettings.propTypes = {
  onUpdateName: PropTypes.func,
  onDeleteAccount: PropTypes.func,
  onUpdateOrganisation: PropTypes.func,
  organisations: PropTypes.arrayOf(PropTypes.object),
  onLeaveOrganization: PropTypes.func,
  user: PropTypes.object,
  setLoading: PropTypes.func,
};

UserSettings.defaultProps = {
  onUpdateName: null,
  onDeleteAccount: null,
  onUpdateOrganisation: () => {},
  organisations: [],
  user: {},
  onLeaveOrganization: null,
  setLoading: null,
};

export default UserSettings;
