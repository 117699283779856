import React from 'react';
import { getLoading } from 'reducers';
import { useSelector } from 'react-redux';
import Spinner from 'components/Common/UI/Spinner';
import { selectLoading } from 'containers/Config';

const SpinnerContainer = (): JSX.Element => {
  const loading = useSelector(getLoading);
  const apiLoading = useSelector(selectLoading);
  return <Spinner size={40} loading={loading || apiLoading} />;
};

export default SpinnerContainer;
