import React, { useState, useEffect } from 'react';
import { Paper, Grid, Typography, TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CompanyPageSettingsContainer from 'containers/Company/companyPageSettingsContainer';

import translations from 'translations';
import { SelectInput } from 'components/Common/Select';
import { GridTitle } from 'components/Common/Grid';
import { DeleteButton, Button } from 'components/Common/Buttons';
import { CurrencyOptions } from 'common/commonContants';
import { SubscriptionComponent } from 'components/Subscriptions';
import { OnChangeEvent } from 'types';
import { useStyles } from './styles';
import { OrganizationSettingProps } from './type';

const OrganizationSettings = ({
  organisation,
  updateDefaultPortfolio,
  updateOrganisation,
  deleteOrganisation,
  subscriptions,
  updateSubscription,
  cancelSubscription,
  subscriptionAlert,
}: OrganizationSettingProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [Portfolio, setPortfolios] = useState<
    Array<{ name: string; value: string }>
  >([]);
  const [defaultPortfolio, setDefaultPortfolio] = useState('');
  const [validValues, setValidValue] = useState({
    nameValid: false,
    nameHelperText: '',
    defaultPortfolioValid: false,
    defaultPortfolioHelperText: '',
    emailValid: false,
    emailHelperText: '',
    confirmValid: false,
    confirmHelperText: '',
  });
  const [state, setState] = useState({
    name: '',
    selectedDefaultCurrency: CurrencyOptions[0].value,
    selectedDefaultPortfolio: '',
    confirm: '',
  });

  const populatePortfolio = () => {
    let defaultSet = false;
    const data = Object.keys(organisation).length
      ? organisation.portfolios.items.map((ele: any) => {
          if (ele.isDefault) {
            defaultSet = true;
            setDefaultPortfolio(ele.name);
            setState({
              ...state,
              selectedDefaultPortfolio: ele.name,
              selectedDefaultCurrency: organisation.currency || '',
            });
          }
          return {
            name: ele.name,
            value: ele.id,
          };
        })
      : [];
    if (!defaultSet) {
      setState({
        ...state,
        selectedDefaultCurrency: organisation.currency || '',
      });
    }
    return data;
  };

  useEffect(() => {
    setPortfolios(populatePortfolio());
  }, [JSON.stringify(organisation)]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const nameValidator = () => state.name.length > 3;

  const handleChange = (fieldName: string) => (event: OnChangeEvent) => {
    const { value } = event.target;
    setState({
      ...state,
      [fieldName]: value,
    });
    // Validaion
    switch (fieldName) {
      case 'name':
        if (!nameValidator()) {
          setValidValue({
            ...validValues,
            nameValid: true,
            nameHelperText: 'Minimun 3 characters required!',
          });
        } else {
          setValidValue({
            ...validValues,
            nameValid: false,
            nameHelperText: '',
          });
        }
        break;
      case 'confirm':
        if (event.target.value !== 'DELETE') {
          setValidValue({
            ...validValues,
            confirmValid: true,
            confirmHelperText: 'does not match',
          });
        } else {
          setValidValue({
            ...validValues,
            confirmValid: false,
            confirmHelperText: '',
          });
        }
        break;
      case 'selectedDefaultPortfolio':
        if (value === Portfolio[0].value) {
          setValidValue({
            ...validValues,
            defaultPortfolioValid: true,
            defaultPortfolioHelperText: 'Please select a portfolio!',
          });
        } else {
          setValidValue({
            ...validValues,
            defaultPortfolioValid: false,
            defaultPortfolioHelperText: '',
          });
        }
        break;
      default: {
        break;
      }
    }
  };

  const handleDeleteOrganisation = async () => {
    const { id } = organisation;
    await deleteOrganisation({ id });
    handleClose();
  };

  return (
    <>
      <Grid container className={classes.grid}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus
        >
          <Fade in={open}>
            <div className={classes.modalPaper}>
              <h2 id="transition-modal-title">
                {`${formatMessage(translations.Common.common.confirm)}`}
              </h2>
              <pre id="transition-modal-description">
                {`${formatMessage(
                  translations.OrganisationSettings.confirmDeleteText
                )}`}
              </pre>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{ justifyContent: 'space-between' }}
                  className={classes.inputsCol}
                >
                  <TextField
                    id="outlined-basic"
                    size="small"
                    placeholder=""
                    variant="outlined"
                    value={state.confirm}
                    error={validValues.confirmValid}
                    onChange={handleChange('confirm')}
                    className={classes.formTextField}
                    helperText={validValues.confirmHelperText}
                  />
                </div>
                <div style={{ width: '30%' }} className={classes.buttonCol}>
                  <DeleteButton
                    disabled={!state.confirm.length || validValues.confirmValid}
                    variant="contained"
                    color="primary"
                    onClick={handleDeleteOrganisation}
                  >
                    {`${formatMessage(translations.Common.common.confirm)}`}
                  </DeleteButton>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
        <Grid item xs={12}>
          <Typography
            component="h5"
            variant="h5"
            color="textPrimary"
            gutterBottom
            paragraph
          >
            {formatMessage(
              translations.OrganisationSettings.organizationSettingsTitle
            )}
          </Typography>
        </Grid>

        {/* GENERAL GRID */}
        <GridTitle textId="OrganizationSettings.General.title" text="General" />
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0} variant="outlined">
            <Grid
              container
              direction="column"
              alignItems="flex-start"
              item
              xs={6}
            >
              <SelectInput
                label={`${formatMessage(
                  translations.OrganisationSettings.defaultCurrencyLabel
                )}`}
                value={state.selectedDefaultCurrency}
                onChange={(e: OnChangeEvent) =>
                  handleChange('selectedDefaultCurrency')(e)
                }
                options={CurrencyOptions}
                wrapperClassName={classes.formTextField}
              />
              <br />
              <SelectInput
                label={`${formatMessage(
                  translations.OrganisationSettings.defaultPortfolioLabel
                )}`}
                options={Portfolio}
                error={validValues.defaultPortfolioValid}
                errorText={validValues.defaultPortfolioHelperText}
                onChange={(e: OnChangeEvent) =>
                  handleChange('selectedDefaultPortfolio')(e)
                }
                wrapperClassName={classes.formTextField}
                selectClass={classes.formTextField}
                value={
                  state.selectedDefaultPortfolio ||
                  formatMessage(translations.OrganisationSettings.noPortfolio)
                }
                isDisabled={!Portfolio.length}
              />
              <br />
              <Button
                style={{
                  alignSelf: 'flex-end',
                }}
                onClick={() => {
                  if (
                    state.selectedDefaultPortfolio &&
                    defaultPortfolio !== state.selectedDefaultPortfolio
                  ) {
                    updateDefaultPortfolio({
                      portfolioId:
                        Portfolio.find(
                          (ele) => ele?.name === state.selectedDefaultPortfolio
                        )?.value || '',
                    });
                  }
                  if (state.selectedDefaultCurrency !== organisation.currency)
                    updateOrganisation({
                      id: organisation.id,
                      currency: state.selectedDefaultCurrency,
                    });
                }}
              >
                {formatMessage(translations.Common.common.update)}
              </Button>
            </Grid>
          </Paper>
        </Grid>

        {/* Company Page Settings */}
        <GridTitle
          textId={translations.OrganisationSettings.companyPageSettings.id}
          text={formatMessage(
            translations.OrganisationSettings.companyPageSettings
          )}
        />
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0} variant="outlined">
            <CompanyPageSettingsContainer />
          </Paper>
        </Grid>

        {/* DELETE ORGANISATION GRID */}
        <GridTitle
          textId="OrganizationSettings.Delete.Title"
          text="Delete Organisation"
        />
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0} variant="outlined">
            <Grid container direction="row" alignItems="center">
              <Grid item xs={12} sm={8} lg={6}>
                <Typography
                  // component="subtitle2"
                  variant="subtitle2"
                  color="textPrimary"
                  gutterBottom
                >
                  {formatMessage(
                    translations.OrganisationSettings
                      .permanentDeleteOrganisation
                  )}
                </Typography>
              </Grid>
              <Grid item xs={8} sm={4} lg={6}>
                <DeleteButton
                  variant="contained"
                  color="primary"
                  className={classes.actionBtn}
                  onClick={handleOpen}
                >
                  {formatMessage(translations.Common.common.delete)}
                </DeleteButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* SUBSCRIPTION GRID */}
        <Grid item xs={12}>
          <GridTitle
            textId="OrganizationSettings.Subscriptions.Title"
            text="Subscriptions"
          />
          <SubscriptionComponent
            subscriptions={subscriptions}
            updateSubscription={updateSubscription}
            portfolios={Portfolio}
            cancelSubscription={cancelSubscription}
            selectedOrganization={organisation}
            subscriptionAlert={subscriptionAlert}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OrganizationSettings;
