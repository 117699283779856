// eslint-disable-next-line import/prefer-default-export
export const ACTION_TYPES_ORGANIZATION = {
  GET_ORGANIZATIONS: 'GET_ORGANIZATIONS',
  GET_ORGANIZATIONS_SUCCESS: 'GET_ORGANIZATIONS_SUCCESS',
  GET_ORGANIZATIONS_FAIL: 'GET_ORGANIZATIONS_FAIL',
  GET_SELECTED_ORGANIZATION: 'GET_SELECTED_ORGANIZATION',
  GET_SELECTED_ORGANIZATION_SUCCESS: 'GET_SELECTED_ORGANIZATION_SUCCESS',
  GET_SELECTED_ORGANIZATION_FAIL: 'GET_SELECTED_ORGANIZATION_FAIL',
  CREATE_ORGANIZATION: 'CREATE_ORGANIZATION',
  CREATE_ORGANIZATION_SUCCESS: 'CREATE_ORGANIZATION_SUCCESS',
  CREATE_ORGANIZATION_FAIL: 'CREATE_ORGANIZATION_FAIL',
  ADD_ORGANIZATIONS_SUCCESS: 'ADD_ORGANIZATIONS_SUCCESS',
  ADD_PORTFOLIO_TO_ORGANIZATION: 'ADD_PORTFOLIO_TO_ORGANIZATION',
};
