import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Modal, Button } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBoxOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import { useIntl } from 'react-intl';
import { useStyle } from '../styles';
import messages from '../messages';

const ImportConfirmModal = ({ list, handleModalClose, open }) => {
  const classes = useStyle();
  const { formatMessage } = useIntl();
  return (
    <Modal
      open={open}
      aria-labelledby="onbording_modal"
      aria-describedby="onbording_modal"
      className={classes.modal}
      onClose={handleModalClose}
      onBackdropClick={handleModalClose}
      style={{ className: classes.paper }}
      BackdropProps={{ className: classes.modalOverlay }}
    >
      <div style={{ width: '580px' }} className={classes.paper}>
        <Typography variant="h6">{formatMessage(messages.files)}</Typography>
        <br />
        {list.map((ele) => (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography style={{ wordBreak: 'break-all' }} variant="body1">
              {ele.name}
            </Typography>
            <div>
              {ele.status ? (
                <CheckBoxIcon style={{ color: '#75b657' }} />
              ) : (
                <CancelIcon style={{ color: '#c10707' }} />
              )}
            </div>
          </div>
        ))}
        <br />
        <Button
          className={classes.roundedButton}
          classes={{
            label: classes.lowerCapText,
          }}
          onClick={handleModalClose}
          variant="contained"
          color="primary"
          aria-controls="simple-menu"
          aria-haspopup="true"
        >
          {formatMessage(messages.ok)}
        </Button>
      </div>
    </Modal>
  );
};

ImportConfirmModal.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  handleModalClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

ImportConfirmModal.defaultProps = {
  list: [],
};

export default ImportConfirmModal;
