import { defineMessages } from 'react-intl';

export default defineMessages({
  statisticsScreenTitle: {
    id: 'StatisticsScreen.title',
    defaultMessage: 'Statistics',
  },
  statisticsScreenLocationsTitle: {
    id: 'StatisticsScreen.locations.title',
    defaultMessage: 'Locations',
  },
  statisticsScreenEmployeesClusterTitle: {
    id: 'StatisticsScreen.employeesCluster.title',
    defaultMessage: 'Employees Cluster',
  },
  statisticsScreenFoundingTitle: {
    id: 'StatisticsScreen.founding.title',
    defaultMessage: 'Founding Date',
  },
  statisticsScreenIndustriesTitle: {
    id: 'StatisticsScreen.industries.title',
    defaultMessage: 'Industry',
  },
});
