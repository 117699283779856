import { defineMessages } from 'react-intl';

export default defineMessages({
  onSuccessRemoveInvestment: {
    id: 'Portfolio.Screen.onSuccessRemoveInvestment',
    defaultMessage: 'Investment successfully removed!',
  },
  onSuccessUpdateInvestment: {
    id: 'Portfolio.Screen.onSuccessUpdateInvestment',
    defaultMessage: 'Investment successfully updated!',
  },
  onSuccessRemovePortfolio: {
    id: 'Portfolio.CreatePortfolio.onSuccessRemovePortfolio',
    defaultMessage: 'Portfolio successfully removed!',
  },
  onSuccessCreatePortfolio: {
    id: 'Portfolio.CreatePortfolio.onSuccessCreatePortfolio',
    defaultMessage: 'Portfolio successfully created!',
  },
  onShowAlertMessage: {
    id: 'Portfolio.CreatePortfolio.onShowAlertMessage',
    defaultMessage: 'Investment Already created for this company!',
  },
  onSuccessUpdatePortfolio: {
    id: 'Portfolio.CreatePortfolio.onSuccessUpdatePortfolio',
    defaultMessage: 'Portfolio successfully updated!',
  },
});
