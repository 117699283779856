import { gql } from 'apollo-boost';

export const UPDATE_COMPANY_KPI_MUTATION = gql`
  mutation($input: BulkUpdateKpiInput) {
    updateKpi(input: $input) {
      id
      companyId
      periodicity
      metricName
      metricCategory
      metricUnit
      metricValue
      sortingNumber
      hidden
      date
      action
      rowId
    }
  }
`;

export const GET_COMPANY_METRICS_QUERY = gql`
  query($companyId: ID!, $periodicity: String, $metricCategory: String) {
    getAllCompanyMetrics(
      companyId: $companyId
      periodicity: $periodicity
      metricCategory: $metricCategory
    ) {
      id
      companyId
      metricName
      periodicity
      metricCategory
      metricUnit
      metricValue
      sortingNumber
      hidden
      date
    }
  }
`;
