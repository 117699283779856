// eslint-disable-next-line import/prefer-default-export
export const defaultCurrency = 'USD';

export const defaultCurrencySign = '$';

export const CurrencyOptions = [
  { name: 'USD', value: 'USD' },
  { name: 'EUR', value: 'EUR' },
  { name: 'GBP', value: 'GBP' },
];
export const ExitedStatusOptions = [
  { name: 'Current', value: 'current' },
  { name: 'Exited', value: 'exited' },
];
export const PortfolioYears = [
  { name: '2020', value: '2020' },
  { name: '2019', value: '2019' },
  { name: '2018', value: '2018' },
  { name: '2017', value: '2017' },
  { name: '2016', value: '2016' },
  { name: '2015', value: '2015' },
  { name: '2014', value: '2014' },
  { name: '2013', value: '2013' },
  { name: '2012', value: '2012' },
  { name: '2011', value: '2011' },
  { name: '2010', value: '2010' },
  { name: '2009', value: '2009' },
  { name: '2008', value: '2008' },
  { name: '2007', value: '2007' },
  { name: '2006', value: '2006' },
  { name: '2005', value: '2005' },
];

export const LookupExitedStatusOptions = {
  current: 'Current',
  exited: 'Exited',
};
export const LookupCurrencyOptions = { USD: 'USD', EUR: 'EURO', GBP: 'GBP' };
export const LookupInvolvement = (intl, messages) => ({
  0: intl.formatMessage(messages.lowInvolvement),
  1: intl.formatMessage(messages.middleInvolvement),
  2: intl.formatMessage(messages.highInvolvement),
});

export const AllowedOrganisationTypes = {
  FUND: 'fund',
  PERSONAL_ORGANISATION: 'personOrg',
  COMPANY: 'company',
};
