import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSelectedOrganization } from 'containers/Organisation';
import { ORGANISATION_TYPES } from '../../../constants';

const HomeScreenContainer = (): JSX.Element | null => {
  const selectedOrganisation = useSelector(selectSelectedOrganization);
  if (selectedOrganisation && selectedOrganisation.id) {
    const { url, type } = selectedOrganisation;
    if (type !== ORGANISATION_TYPES.COMPANY)
      return <Redirect to={`${url}/updates`} />;
    const companyUrl = `${selectedOrganisation.companies?.[0]?.url}`;
    return <Redirect to={companyUrl} />;
  }
  return null;
};

export default HomeScreenContainer;
