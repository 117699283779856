import React from 'react';
import { Typography } from '@material-ui/core';
import { EmptyIcon } from 'components/Common/Assets';
import { useStyles } from './styles';

export const EmptyComponent = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <EmptyIcon
          viewBox="0 0 60 60"
          style={{
            width: 60,
            height: 60,
          }}
        />
        <Typography>No Data Available</Typography>
      </div>
    </div>
  );
};
