import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import HeaderContainer from 'containers/Common/HeaderContainer';
import SidebarContainer from 'containers/Common/SidebarContainer';
import { selectSelectedOrganization } from 'containers/Organisation';
import { useSubscriptionContainer } from 'containers/Subscription';
import { OnboadingModal } from 'components/Onboarding';
import { ORGANISATION_TYPES, SUBSCRIPTION_STATUS_CODES } from 'constants/index';
import Routes from '../Routes';
import { useStyles } from './styles';

const Layout = () => {
  const classes = useStyles();
  const selectedOrganisation = useSelector(selectSelectedOrganization);
  const { subscriptionAlert } = useSubscriptionContainer();
  const [sidebarStatus, setSidebarStatus] = useState(
    selectedOrganisation.type !== ORGANISATION_TYPES.COMPANY
  );
  const [notification, setNotification] = useState(true);
  const [trialStatus, setTrialStatus] = useState('');

  const onChangeSidebarStatus = (status) => {
    setSidebarStatus(status);
  };

  const handleCloseNotification = () => {
    setNotification(false);
  };

  const handleGetTrialStatus = () => {
    const blackList = [
      SUBSCRIPTION_STATUS_CODES.ALL_UPDATED,
      SUBSCRIPTION_STATUS_CODES.TYPE_COMPANY,
      SUBSCRIPTION_STATUS_CODES.NO_PAID,
    ];
    if (!subscriptionAlert || blackList.includes(subscriptionAlert.statusCode))
      return '';
    if (
      subscriptionAlert.statusCode === SUBSCRIPTION_STATUS_CODES.TRIAL_EXPIRING
    ) {
      const { days, hours } = subscriptionAlert;
      const trial = days > 0 ? 'day' : 'hour';
      const number = days > 0 ? days : hours > 0 ? hours : 0;
      return `You have ${number} ${trial}${
        number > 0 ? 's' : ''
      } in your trial`;
    }
    return subscriptionAlert.message;
  };

  useEffect(() => {
    const status = handleGetTrialStatus();
    setTrialStatus(status);
  }, [JSON.stringify(subscriptionAlert)]);

  return (
    <div className={classes.root}>
      <OnboadingModal />
      <HeaderContainer
        sidebarStatus={sidebarStatus}
        onChangeSidebarStatus={onChangeSidebarStatus}
        trialStatus={notification ? trialStatus : null}
        handleCloseNotification={handleCloseNotification}
      />
      <SidebarContainer
        sidebarStatus={sidebarStatus}
        onChangeSidebarStatus={onChangeSidebarStatus}
        // defaultOrganisation={defaultOrganisation} // not done default work
        trialStatus={notification ? trialStatus : null}
      />
      <main
        style={{ marginTop: notification && trialStatus ? '30px' : '0px' }}
        className={classes.content}
      >
        <div className={classes.toolbar} />
        <Routes />
      </main>
    </div>
  );
};

export default Layout;
