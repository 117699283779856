/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../constants';

const useStyles = makeStyles((theme) => ({
  customScroll: {
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#7b7a7a',
    },
  },
  root: {
    flexGrow: 1,
  },
  grid: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paper: {
    padding: theme.spacing(2.2),
    height: '250px',
    overflowY: 'scroll',
  },
  flexBox: {
    borderBottom: '1px solid #ccc',
    paddingBottom: theme.spacing(0.9),
    marginBottom: theme.spacing(0.9),
  },
  text: {
    fontSize: 15,
    margin: 0,
  },
  bolder: {
    fontWeight: 'bold',
    color: 'black',
    marginRight: theme.spacing(0.8),
  },
  actions: {
    textDecoration: 'underline',
  },
  actionBtn: {
    '&:hover': {
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  },
  count: {
    color: Colors.REPORTS_TOTAL,
  },
  statusOk: {
    color: Colors.STATUS_OK,
  },
  alert: {
    color: Colors.ALERT,
  },
  warning: {
    color: Colors.WARNING,
  },
  onboadingBtn: {
    width: '150px',
    ...theme.custom.buttonLabel,
  },
}));

export { useStyles };
