import { defineMessages } from 'react-intl';

export default defineMessages({
  sharedWithYouLabel: {
    id: 'Company.CompanyApiTab.sharedWithYouLabel',
    defaultMessage: 'Shared with you',
  },
  asInvestmentLabel: {
    id: 'Company.CompanyApiTab.asInvestmentLabel',
    defaultMessage: 'ADD DETAILS (YOU CAN EDIT THIS LATER)',
  },
  addContactMessage: {
    id: 'Company.CompanyApiTab.addContactMessage',
    defaultMessage:
      'Company contacts will be added as admin users to the company.',
  },
  contactList: {
    id: 'Company.CompanyApiTab.contactList',
    defaultMessage: 'Contacts to be added',
  },
  repeatedContactMessage: {
    id: 'Company.CompanyApiTab.repeatedContactMessage',
    defaultMessage: 'Contact already added.',
  },
  addInviteContactsLabel: {
    id: 'Company.CompanyApiTab.addInviteContactsLabel',
    defaultMessage: 'Company Contacts',
  },
  contactAtCompanyLabel: {
    id: 'Company.CompanyApiTab.contactAtCompanyLabel',
    defaultMessage: 'Company Contact',
  },
  contactAtCompanyLabelName: {
    id: 'Company.CompanyApiTab.contactAtCompanyLabelName',
    defaultMessage: 'Contact Name',
  },
  contactAtCompanyLabelLastName: {
    id: 'Company.CompanyApiTab.contactAtCompanyLabelLastName',
    defaultMessage: 'Contact Last Name',
  },
  investmentManagerLabel: {
    id: 'Company.CompanyApiTab.investmentManagerLabel',
    defaultMessage: 'Investment Manager',
  },
  secondContactAtCompanyLabel: {
    id: 'Company.CompanyApiTab.secondContactAtCompanyLabel',
    defaultMessage: '2nd Company Contact',
  },
  secondContactAtCompanyName: {
    id: 'Company.CompanyApiTab.secondContactAtCompanyName',
    defaultMessage: '2nd Contact Name',
  },
  secondContactAtCompanyLastName: {
    id: 'Company.CompanyApiTab.secondContactAtCompanyLastName',
    defaultMessage: '2nd Contact Last Name',
  },
  secondInvestmentManagerLabel: {
    id: 'Company.CompanyApiTab.secondInvestmentManagerLabel',
    defaultMessage: '2nd Investment Manager',
  },
  addBtnTitle: {
    id: 'Company.CompanyApiTab.addBtnTitle',
    defaultMessage: 'Create',
  },
  investmentDetails: {
    id: 'Company.CompanyApiTab.investmentDetails',
    defaultMessage: 'Investment Details',
  },
  contactFirstName: {
    id: 'Company.CompanyApiTab.investmentDetails',
    defaultMessage: 'First Contact first Name',
  },
  contactLastName: {
    id: 'Company.CompanyApiTab.investmentDetails',
    defaultMessage: 'First Contact Last Name',
  },
  secContactFirstName: {
    id: 'Company.CompanyApiTab.investmentDetails',
    defaultMessage: 'Sec Contact first Name',
  },
  secContactLastName: {
    id: 'Company.CompanyApiTab.investmentDetails',
    defaultMessage: 'Sec Contact Last Name',
  },
  investmentManagers: {
    id: 'Company.CompanyApiTab.investmentManagers',
    defaultMessage: 'Investment Managers',
  },
  investmentManager: {
    id: 'Company.CompanyApiTab.investmentManager',
    defaultMessage: 'Investment Manager',
  },
});
