import React from 'react';
import { CompanyPageSettingsProps } from 'types';

import CompanySettingComponent from './CompanySettingComponent';

export default ({
  organisation,
  handleChange,
}: CompanyPageSettingsProps): JSX.Element => {
  const { companySettings } = organisation;

  const handleAddition = (operand1: number, operand2: number) => {
    let result = (operand1 + operand2) % companySettings.length;
    if (result < 0) result = companySettings.length - 1;
    return result;
  };

  const handleChangeSorting = ({
    factor,
    index,
  }: {
    factor: number;
    index: number;
  }) => {
    const data = [];

    data[0] = {
      index,
      data: {
        ...companySettings[index],
        sorting: handleAddition(companySettings[index].sorting, factor),
      },
    };
    data[1] = {
      index: handleAddition(index, factor),
      data: {
        ...companySettings[handleAddition(index, factor)],
        sorting: handleAddition(
          companySettings[handleAddition(index, factor)].sorting,
          factor * -1
        ),
      },
    };
    handleChange(data);
  };

  return (
    <div>
      {companySettings && ( companySettings.map((settings, index) => (
        <CompanySettingComponent
          field={settings.field}
          sortingNumber={settings.sorting}
          status={settings.status}
          changeSorting={(factor) => handleChangeSorting({ factor, index })}
          handleChange={(data) =>
            handleChange([
              {
                index,
                data,
              },
            ])
          }
        />
      )))}
    </div>
  );
};
