import { setLoading, setAlert, setDownloads } from '../reducers';

// eslint-disable-next-line import/prefer-default-export
export class CommonAction {
  static setLoader(loading) {
    return (dispatch) => {
      dispatch(setLoading(loading));
    };
  }

  static setAlert(alertPayload = null) {
    return (dispatch) => {
      dispatch(setAlert(alertPayload));
    };
  }

  static setDownload(downloadPayload) {
    return (dispatch) => dispatch(setDownloads(downloadPayload));
  }
}
