import { defineMessages } from 'react-intl';

export default defineMessages({
  portfolioHeader: {
    id: 'Portfolio.PortfolioScreen.portfolioHeader',
    defaultMessage: 'Summary',
  },
  vintageYear: {
    id: 'Portfolio.PortfolioScreen.vintageYear',
    defaultMessage: 'Vintage Year',
  },
  targetAllocation: {
    id: 'Portfolio.PortfolioScreen.targetAllocation',
    defaultMessage: 'Target Allocation',
  },
  numberInvestments: {
    id: 'Portfolio.PortfolioScreen.numberInvestments',
    defaultMessage: 'Number of Investments',
  },
  totalInvested: {
    id: 'Portfolio.PortfolioScreen.totalInvested',
    defaultMessage: 'Total Invested',
  },
  totalReserves: {
    id: 'Portfolio.PortfolioScreen.totalReserves',
    defaultMessage: 'Total Reserves',
  },
  unallocated: {
    id: 'Portfolio.PortfolioScreen.unallocated',
    defaultMessage: 'Unallocated',
  },
  editBtn: {
    id: 'Portfolio.PortfolioScreen.editBtn',
    defaultMessage: 'Edit',
  },
  campanyTitle: {
    id: 'Company.CompanyApiTab.campanyTitle',
    defaultMessage: 'A Venture Seed I',
  },
  addInvestment: {
    id: 'Company.CompanyApiTab.addInvestment',
    defaultMessage: 'Add Investment',
  },
  selectCompanyLabel: {
    id: 'Company.CompanyApiTab.selectCompanyLabel',
    defaultMessage: 'Select Company',
  },
  investmentCurrencyLabel: {
    id: 'Company.CompanyApiTab.investmentCurrencyLabel',
    defaultMessage: 'Investment Currency',
  },
  investmentValueLabel: {
    id: 'Company.CompanyApiTab.investmentValueLabel',
    defaultMessage: 'Investment Value',
  },
  exitStatusLabel: {
    id: 'Company.CompanyApiTab.exitStatusLabel',
    defaultMessage: 'Exit Status',
  },
  ownershipLabel: {
    id: 'Company.CompanyApiTab.ownershipLabel',
    defaultMessage: 'Ownership',
  },
  investmentAmountLabel: {
    id: 'Company.CompanyApiTab.investmentAmountLabel',
    defaultMessage: 'Investment Amount',
  },
  reservesLabel: {
    id: 'Company.CompanyApiTab.reservesLabel',
    defaultMessage: 'Reserves',
  },
  involvementLabel: {
    id: 'Company.CompanyApiTab.involvementLabel',
    defaultMessage: 'Involvement',
  },
  editInvestments: {
    id: 'Company.CompanyApiTab.editInvestments',
    defaultMessage: 'Edit Investments',
  },
  addBtnTitle: {
    id: 'Company.CompanyApiTab.addBtnTitle',
    defaultMessage: 'Add',
  },
});
