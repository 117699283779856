import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  editBtn: {
    fontWeight: 'bold',
    color: '#3f50b5',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  titleBox: {
    width: '100%',
    marginLeft: '20px',
    marginRight: '20px',
  },
  paper: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    paddingBottom: theme.spacing(1.8),
    width: '100%',
    marginLeft: '20px',
    marginRight: '20px',
  },
  flexBox: {
    minHeight: '80px',
    width: '30%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  iconBox: {
    width: '15%',
    paddingTop: theme.spacing(1),
  },
  text: {
    fontSize: 13,
    color: '#000',
  },
  contentBox: {
    width: '85%',
  },
  contentTitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(0.8),
  },
  icon: {
    color: '#787676',
  },
  root: {
    marginBottom: '40px',
  },
  gridTitle: {
    marginTop: 0,
  },
  hidden: {
    display: 'none',
  },
}));

// eslint-disable-next-line import/prefer-default-export
export { useStyles };
