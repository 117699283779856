/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useAuth0 } from 'components/Common/Auth';
import {
  selectSelectedOrganization,
  selectOrganizations,
  OrganizationThunk,
} from 'containers/Organisation';
import Header from 'components/Common/Header';
import extractUrn from 'common/extractUrn';
import { CompanyShort, OrganizationShort } from 'types';

interface HeaderContainerProps {
  sidebarStatus: boolean;
  trialStatus: string;
  onChangeSidebarStatus(): void;
  handleCloseNotification(): void;
}

function HeaderContainer(props: HeaderContainerProps): JSX.Element {
  const {
    sidebarStatus,
    onChangeSidebarStatus,
    trialStatus,
    handleCloseNotification,
  } = props;

  const location = useLocation();
  const history = useHistory();
  const disptach = useDispatch();
  const selectedOrganization = useSelector(selectSelectedOrganization);
  const organisations = useSelector(selectOrganizations);
  const [portfolios, setPortfolios] = useState([]);
  const [companies, setCompanies] = useState([]);
  // const [selectedOrganizationUrl, setSelectedOrganizationUrl] = useState('');
  const [isSelectedTypeCompany, setIsSelectedTypeCompany] = useState(false);
  // const [companyProfileData, setCompanyProfileData] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState<OrganizationShort | null>(
    null
  );

  const locationArray = location.pathname.split('/');
  const { user } = useAuth0()!;

  const onChangeSelectedOrganisation = ({
    id,
    url,
    ...rest
  }: OrganizationShort): void => {
    if (!locationArray[3]) {
      history.push(`${url}/users`, { ...rest });
    } else {
      history.push(`${url}/${locationArray[3]}`, { ...rest });
    }
    disptach(OrganizationThunk.getSelectedOrganization(id));
  };

  const onManageUsersAccess = ({
    url,
    companyOrganisation,
  }: {
    companyOrganisation: string;
    url?: string;
  }): void => {
    history.push(`${url}/users`, { companyOrganisation });
  };

  const onSettings = ({ url }: OrganizationShort): void => {
    history.push(`${url}/settings`);
  };

  const onAddOrganisation = ({ url }: OrganizationShort): void => {
    history.push(`${url}/add`);
  };

  const onPortfolio = ({ url, portfolioId }: any): void => {
    history.push(`${url}/portfolios/${portfolioId}`);
  };

  const onCompany = ({ url, companyId }: any): void => {
    history.push(`${url}/companies/${companyId}`);
  };

  const onManagePortfolio = ({ url }: any): void => {
    history.push(`${url}/portfolios`);
  };

  const onManageCompanies = ({ url }: any) => {
    history.push(`${url}/companies/manage`);
  };

  const onAddCompanies = ({ url }: any): void => {
    history.push(`${url}/companies/add`);
  };

  const onUserSettings = (): void => {
    history.push(`/${locationArray[1]}/${locationArray[2]}/users/usersettings`);
  };

  const handleOnCompanyProfile = (): void => {
    history.push(`${selectedOrganization?.companies?.[0]?.url}`);
  };

  // const handleOrgChangeRedirect = () => {
  //   if (!selectedOrganization || !Object.keys(selectedOrganization).length) {
  //     return;
  //   }
  //   if (
  //     selectedOrganization.type === AllowedOrganisationTypes.COMPANY
  //   ) {
  //     setIsSelectedTypeCompany(true);
  //     const { companies: [companyProfile = {}] = [] } = selectedOrganization;
  //     setCompanyProfileData(companyProfile);
  //     if (!location.pathname.includes('/users')) {
  //       handleOnCompanyProfile(companyProfile);
  //     }
  //   } else {
  //     setIsSelectedTypeCompany(false);
  //     setCompanyProfileData(null);
  //     if (locationArray[2] && locationArray[locationArray.length - 1] !== 'updates') {
  //       history.push(`/${locationArray[1]}/${locationArray[2]}/updates`);
  //     }
  //   }
  // };

  // React.useEffect(() => {
  //   const orgId = locationArray[2];
  //   if (orgId && orgId !== selectedOrganizationUrl) {
  //     setSelectedOrganizationUrl(orgId);
  //     actionGetSelectedOrganization(orgId);
  //   }
  // }, [locationArray[2]]);

  React.useEffect(() => {
    if (!selectedOrganization) {
      return;
    }
    if (selectedOrganization.id !== selectedOrg?.id) {
      // handleOrgChangeRedirect();
      setSelectedOrg({ ...selectedOrganization });
    }
    if (
      selectedOrganization.portfolios &&
      Array.isArray(selectedOrganization.portfolios.items)
    ) {
      const portfoliosReduxStr = JSON.stringify(
        selectedOrganization.portfolios.items
      );
      if (portfoliosReduxStr !== JSON.stringify(portfolios)) {
        const newPortfolios = JSON.parse(portfoliosReduxStr).map(
          (portfolio: any) => ({
            ...portfolio,
            value: extractUrn(portfolio.id).id,
          })
        );
        setPortfolios(newPortfolios);
      }
    }

    if (Array.isArray(selectedOrganization.companies)) {
      const companiesReduxStr = JSON.stringify(selectedOrganization.companies);
      if (companiesReduxStr !== JSON.stringify(companies)) {
        const newCompanies = JSON.parse(companiesReduxStr).map(
          (company: CompanyShort) => ({
            ...company,
            value: company?.id,
          })
        );
        setCompanies(newCompanies);
      }
    }
  }, [selectedOrganization]);

  return (
    <Header
      organisations={organisations}
      user={user}
      onChangeSelectedOrganisation={onChangeSelectedOrganisation}
      selectedOrganisation={selectedOrganization || {}}
      sidebarStatus={sidebarStatus}
      onChangeSidebarStatus={onChangeSidebarStatus}
      onManageUsersAccess={onManageUsersAccess}
      onSettings={onSettings}
      onAddOrganisation={onAddOrganisation}
      onPortfolio={onPortfolio}
      onUserSettings={onUserSettings}
      onManagePortfolio={onManagePortfolio}
      onManageCompanies={onManageCompanies}
      onAddCompanies={onAddCompanies}
      onCompany={onCompany}
      trialStatus={trialStatus}
      handleCloseNotification={handleCloseNotification}
      portfolios={portfolios}
      companies={companies}
      isSelectedTypeCompany={isSelectedTypeCompany} // todo do it when other things done, it is used for redirect logic
      // companyProfileData={companyProfileData} // todo do it when other things done, it is used for redirect logic
      onCompanyProfile={handleOnCompanyProfile}
    />
  );
}

export default HeaderContainer;

HeaderContainer.propTypes = {
  sidebarStatus: PropTypes.bool,
  onChangeSidebarStatus: PropTypes.func,
  trialStatus: PropTypes.string,
  handleCloseNotification: PropTypes.func,
};

HeaderContainer.defaultProps = {
  sidebarStatus: false,
  onChangeSidebarStatus: null,
  trialStatus: '',
  handleCloseNotification: null,
};
