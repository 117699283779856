import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { Grid, Paper } from '@material-ui/core';

import translations from 'translations';
import CreatePortfolioContainer from 'containers/Portfolio/CreatePortfolioContainer';
import { ManagePortfolioProps } from 'types';
import PortfolioTable from '../PortfolioTable';
import { useStyles } from './styles';

const ManagePortfolio = ({
  selectedOrganisation,
  onDeletePortfolios,
  createPortfolio,
  updatePortfolio,
}: ManagePortfolioProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} className={classes.marginBottom}>
        <Typography
          component="h2"
          variant="h5"
          color="primary"
          gutterBottom
          className={classes.title}
        >
          {intl.formatMessage(translations.Sidebar.managePortfolioItem)}
        </Typography>
      </Grid>
      {selectedOrganisation && selectedOrganisation.type !== 'company' && (
        <CreatePortfolioContainer
          selectedOrganisation={selectedOrganisation}
          // refetchOrganisation={refetchOrganisation}
          createPortfolio={createPortfolio}
        />
      )}
      <Typography component="h4" variant="h6" color="primary" gutterBottom>
        {intl.formatMessage(
          translations.Portfolio.managePortfolio.editPortfolio
        )}
      </Typography>
      <Paper variant="outlined">
        <PortfolioTable
          portfolios={selectedOrganisation.portfolios}
          onDeletePortfolios={onDeletePortfolios}
          updatePortfolio={updatePortfolio}
          // refetchOrganisation={refetchOrganisation}
        />
      </Paper>
    </>
  );
};

export default ManagePortfolio;
