import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    alignItems: 'center',
  },
  text: {
    marginLeft: 14,
    marginRight: 10,
  },
}));
