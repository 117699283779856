/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import MaterialTable from 'material-table';
import { useParams } from 'react-router-dom';

import translations from 'translations';
import { CurrencyOptions } from 'common/commonContants';
import { Portfolio, PortfolioBasic, PortfolioPayload, Size } from 'types';

interface ItemProps {
  items: Array<Portfolio>;
}

interface PortfolioTableProps {
  portfolios: ItemProps;
  onDeletePortfolios(arr: Array<{ id: string }>): void;
  updatePortfolio(o: PortfolioPayload): void;
}

interface Param {
  id: string;
}

const PortfolioTable = ({
  portfolios,
  onDeletePortfolios,
  updatePortfolio,
}: PortfolioTableProps): JSX.Element => {
  const { items } = portfolios;
  const [usersRows, setUsersRows] = useState<PortfolioBasic[]>([]);
  const params: Param = useParams();
  const intl = useIntl();

  useEffect(() => {
    let portfoliosNormalized: Array<PortfolioBasic> = [];
    if (items && items.length > 0) {
      portfoliosNormalized = items.map(
        ({ name, size, creationYear, targetNumberOfInvestments, id }) => {
          const sizeObject = size as Size;
          return {
            id,
            name,
            size: sizeObject.amount,
            sizeCurrency: CurrencyOptions.findIndex(
              (ele) => ele.value === sizeObject.currency
            ),
            creationYear,
            targetNumberOfInvestments,
          };
        }
      );
    }
    setUsersRows(portfoliosNormalized);
  }, [items]);

  return (
    <MaterialTable
      columns={[
        {
          field: 'name',
          title: intl.formatMessage(translations.Common.common.name),
          editable: 'onUpdate',
          defaultSort: 'asc',
          customSort: (a: PortfolioBasic, b: PortfolioBasic): number =>
            a.name < b.name ? -1 : 1,
        },
        {
          field: 'size',
          title: intl.formatMessage(translations.Portfolio.portfolioTable.size),
          editable: 'onUpdate',
          render: (rowData: PortfolioBasic): JSX.Element => (
            <div>
              {intl.formatNumber(rowData.size as number, {
                currency: CurrencyOptions[rowData.sizeCurrency as number].value,
              })}
            </div>
          ),
        },
        {
          field: 'sizeCurrency',
          title: intl.formatMessage(
            translations.Portfolio.portfolioTable.sizeCurrency
          ),
          editable: 'onUpdate',
          lookup: CurrencyOptions.map((ele) => ele.value),
        },
        {
          field: 'creationYear',
          title: intl.formatMessage(
            translations.Portfolio.portfolioTable.creationYear
          ),
          editable: 'onUpdate',
        },
        {
          field: 'targetNumberOfInvestments',
          title: intl.formatMessage(
            translations.Portfolio.portfolioTable.targetNumberOfInvestments
          ),
          editable: 'onUpdate',
          render: (rowData: PortfolioBasic): JSX.Element => (
            <div>{intl.formatNumber(rowData.targetNumberOfInvestments)}</div>
          ),
        },
      ]}
      data={usersRows}
      title=""
      options={{
        sorting: true,
        search: false,
        selection: false,
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        grouping: false,
        draggable: false,
        debounceInterval: 0,
      }}
      actions={[
        {
          icon: 'delete',
          tooltip: 'Delete',
          onClick: (
            _event,
            rowData: PortfolioBasic | PortfolioBasic[]
          ): void => {
            if (!Array.isArray(rowData))
              onDeletePortfolios([{ id: rowData.id || '' }]);
          },
        },
      ]}
      editable={{
        onRowUpdate: (oldData: PortfolioBasic): Promise<PortfolioBasic> =>
          new Promise((resolve) => {
            setTimeout(async () => {
              {
                const sizeCurrency =
                  CurrencyOptions[Number(oldData.sizeCurrency)].value;
                updatePortfolio({
                  ...oldData,
                  size: +oldData.size,
                  sizeCurrency,
                  creationYear: +oldData.creationYear,
                  targetNumberOfInvestments: +oldData.targetNumberOfInvestments,
                  organisationId: params.id,
                });
                // await refetchOrganisation();
              }
              resolve();
            }, 1000);
          }),
      }}
    />
  );
};

export default PortfolioTable;
