import { defineMessages } from 'react-intl';

export default defineMessages({
  onSuccessRemoveContact: {
    id: 'Company.ManageCompanyTabsContainer.onSuccessRemoveContact',
    defaultMessage: 'Contact successfully removed!',
  },
  onEmptyKpis: {
    id: 'Company.ManageCompanyTabsContainer.onEmptyKpis',
    defaultMessage: 'Click edit to start setting up KPIs',
  },
  onSuccesDiscardCompanyShare: {
    id: 'Company.CompanyApiTabContainer.onDiscardShare',
    defaultMessage: 'Company shared request discard success',
  },
  onSuccesCreateInvite: {
    id: 'Company.CompanyContactContainer.onCreateInvite',
    defaultMessage: 'Invited successfully',
  },
  onErrorCreateInvite: {
    id: 'Company.CompanyContactContainer.onCreateInvite',
    defaultMessage: 'Something went wrong',
  },
});
