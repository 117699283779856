import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import GroupIcon from '@material-ui/icons/Group';
import StorageIcon from '@material-ui/icons/Storage';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useStyles } from './styles';

const CustomBox = ({ item, iconColor, spanClass }) => {
  const classes = useStyles();

  function getIcon() {
    switch (item.position) {
      case 0: {
        return <TrendingUpIcon color={iconColor} />;
      }
      case 1: {
        return <GroupIcon color={iconColor} />;
      }
      case 2: {
        return <StorageIcon color={iconColor} />;
      }
      default: {
        return <DateRangeIcon color={iconColor} />;
      }
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      className={classes.flexBox}
    >
      <p className={classes.text}>
        <span className={`${classes.bolder} ${spanClass}`}>{item.title}</span>
        {item.detail}
      </p>
      {getIcon()}
    </Box>
  );
};
// eslint-disable-next-line import/prefer-default-export
export { CustomBox };

CustomBox.propTypes = {
  spanClass: PropTypes.string,
  item: PropTypes.shape({
    title: PropTypes.string,
    detail: PropTypes.string,
    position: PropTypes.number,
  }).isRequired,
  iconColor: PropTypes.string,
};

CustomBox.defaultProps = {
  spanClass: '',
  iconColor: 'primary',
};
