import React from 'react';
import { FormControlLabel, Switch as MaterialSwitch } from '@material-ui/core';

import { useStyles } from './styles';
import { Props } from './type';

export const Switch = ({ label, checked, onChange }: Props) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      control={
        <MaterialSwitch
          classes={{
            switchBase: classes.switchBase,
            track: classes.track,
            checked: classes.checked,
          }}
        />
      }
      label={label}
      className={classes.formControlCol}
      checked={checked}
      onChange={onChange}
    />
  );
};
