/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, useContext } from 'react';
import { gql } from 'apollo-boost';
import { Auth0Client, RedirectLoginResult } from '@auth0/auth0-spa-js';
import createAuth0ClientFunc from './auth0ClientAsync';
import { Auth0ContextProps, AuthProps } from './type';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

const SESSION_QUERY = gql`
  query user {
    user {
      id
      email
      firstName
    }
  }
`;

export const Auth0Context = React.createContext<Auth0ContextProps | null>(null);
export const useAuth0 = (): Auth0ContextProps | null =>
  useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: AuthProps): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client?.loginWithPopup(params);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const sessionUser = await auth0Client?.getUser();
    const { data } = await global.auth0Client.query({
      query: SESSION_QUERY,
    });
    setUser(data && data.user);
    const emailVerified = sessionUser ? sessionUser.email_verified : false;
    setIsEmailVerified(emailVerified);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async (): Promise<RedirectLoginResult> => {
    setLoading(true);
    await auth0Client?.handleRedirectCallback();
    const sessionUser = await auth0Client?.getUser();
    const { data } = await global.apolloClient.query({
      query: SESSION_QUERY,
    });
    setLoading(false);
    setIsAuthenticated(Boolean(sessionUser));
    const emailVerified = sessionUser ? sessionUser.email_verified : false;
    setIsEmailVerified(emailVerified);
    setUser(data && data.user);
    return null as any;
  };

  const handleLoginWithRedirect = (params: any, client = auth0Client) => {
    const mode = window.location.search.includes('on_boarding=')
      ? 'signup'
      : 'login';
    return client!.loginWithRedirect({
      ...params,
      screen_hint: mode,
    });
  };

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0ClientFunc(initOptions);
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticatedFromHook = await auth0FromHook.isAuthenticated();
      if (isAuthenticatedFromHook) {
        const sessionUser = await auth0FromHook.getUser();
        const { data } = await global.apolloClient.query(
          {
            query: SESSION_QUERY,
          }
        );
        const emailVerified = sessionUser ? sessionUser.email_verified : false;
        setIsEmailVerified(emailVerified);
        setUser(data && data.user);
        setIsAuthenticated(true);
        setLoading(false);
        return;
      }
      handleLoginWithRedirect(
        {
          appState: { targetUrl: window.location.pathname },
        },
        auth0FromHook
      );
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        isEmailVerified,
        getIdTokenClaims: (...p) => auth0Client!?.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => handleLoginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client!?.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client!?.getTokenWithPopup(...p),
        logout: (...p) => auth0Client?.logout(...p),
        auth0Client,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
