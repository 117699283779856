/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { connect, useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useIntl } from 'react-intl';
import { Paper, Tabs, Tab, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import translations from 'translations';
import {
  selectOrganizations,
  selectSelectedOrganization,
} from 'containers/Organisation';
import { setAlert } from 'containers/Config';

import { CompanyAction, CommonAction } from '../../../actions';
import ExportTab from './ExportImportComponent/ExportTab';
import ImportTab from './ExportImportComponent/ImportTab';
import {
  BULK_EXPORT_GS,
  BULK_IMPORT_EXCEL,
  BULK_IMPORT_GS,
} from '../../../graphql';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  tabs: {
    ...theme.custom.font_size,
  },
}));

function tabProps(tabName) {
  return {
    id: `basic-tab-${tabName}`,
    'aria-controls': `basic-tabpanel-${tabName}`,
  };
}
const Container = ({ companies, setDownload }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const TAB_EXPORT = intl.formatMessage(translations.Company.Header.Export);
  const TAB_IMPORT = intl.formatMessage(translations.Company.Header.Import);

  const organisations = useSelector(selectOrganizations);
  const selectedOrganisation = useSelector(selectSelectedOrganization);

  const [value, setValue] = useState(TAB_EXPORT);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = useStyles();

  const [bulkExportGS] = useMutation(BULK_EXPORT_GS);
  const [bulkImportExcel] = useMutation(BULK_IMPORT_EXCEL);
  const [bulkImportGS] = useMutation(BULK_IMPORT_GS);

  const handleOpenAlert = (props) => {
    dispatch(setAlert(props));
  };

  const handleBulkImportGS = async (input) => {
    const { data: { bulkImportGS: data = {} } = {} } = await bulkImportGS({
      variables: {
        input,
      },
    });
    return data;
  };

  const handleBulkExportGS = async (input) => {
    try {
      const { data: { blukExportGS: data = {} } = {} } = await bulkExportGS({
        variables: {
          input,
        },
      });
      return data;
    } catch (err) {
      return {};
    }
  };

  const handleBulkImportExcel = async (input) => {
    const { data: { bulkImportExcel: data = {} } = {} } = await bulkImportExcel(
      {
        variables: {
          input,
        },
      }
    );
    return data;
  };

  return (
    <>
      <Grid item xs={3} sm={2} style={{ paddingBottom: 10 }}>
        <Typography variant="h3">
          {intl.formatMessage(translations.Sidebar.exportImport)}
        </Typography>
      </Grid>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        <Tab label={TAB_EXPORT} value={TAB_EXPORT} {...tabProps(TAB_EXPORT)} />
        <Tab label={TAB_IMPORT} value={TAB_IMPORT} {...tabProps(TAB_IMPORT)} />
      </Tabs>
      <Paper className={classes.paper} variant="outlined">
        {/* <ExportTab companies={companies} /> */}
        {value === TAB_IMPORT ? (
          <ImportTab
            companies={companies}
            handleBulkImportExcel={handleBulkImportExcel}
            handleBulkImportGS={handleBulkImportGS}
            setAlert={handleOpenAlert}
          />
        ) : (
          <ExportTab
            organisations={organisations}
            selectedOrganisation={selectedOrganisation}
            companies={companies}
            handleBulkExportGS={handleBulkExportGS}
            setDownload={setDownload}
          />
        )}
      </Paper>
    </>
  );
};

const mapState = createStructuredSelector({});

const mapDispatch = (dispatch) => ({
  getCompaniesByOrganisation: (id) =>
    dispatch(CompanyAction.getCompaniesByOrganisation(id)),
  setDownload: (downloadPayload) =>
    dispatch(CommonAction.setDownload(downloadPayload)),
});

Container.propTypes = {
  getCompaniesByOrganisation: PropTypes.func.isRequired,
  setDownload: PropTypes.func.isRequired,
};

const ExportImport = connect(mapState, mapDispatch)(Container);

export { ExportImport };
