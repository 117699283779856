import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';

import { VIEW_CONTENT_TYPE, companyScreenOptions } from 'constants/index';
import KpiSection from 'containers/Kpi';
import CompanyHeader from '../Reuseable/CompanyHeader';
import CompanyFeed from '../Reuseable/CompanyFeed';
// import CompanyMatricTable from '../Reuseable/CompanyMatricTable/Tabs';
import CompanyVisual from '../Reuseable/CompanyVisual';
// import CompanyKpi from '../Reuseable/CompanyKpi';
import { CompanyReports } from '../CompanyReports';
import { CompanyProfile } from '../CompanyProfile';
import CompanyComment from '../Reuseable/CompanyComments';
import messages from './messages';
import InvestmentTableCard from '../Reuseable/InvestmentTableCard';
import CompanySummary from '../Reuseable/CompanySummary';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const CompanyScreen = ({
  company,
  role,
  getCompany,
  onEditSave,
  kpis,
  profile,
  feeds,
  // onUpdateKpis,
  onProfileEdit,
  handleCreateReport,
  handleUpdateReport,
  handleDeleteReport,
  companyReports,
  onDeleteFeed,
  onUpdateFeed,
  matricOptions,
  getCompanyVisual,
  getCompanyMetricNames,
  visualData,
  loading,
  organisation,
  commentByCompany,
  handleOnUpdateComment,
  handleOnDeleteComment,
  summary,
  users,
  orgType,
  updateContact,
  deleteContact,
  investments,
  onInvestmentsSave,
  onInvestmentRemove,
  exportDataToGS,
  importFromExcelSheet,
  importFromGS,
  sendRequestDataEmail,
  periodicity,
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [view, setView] = useState([true, true, true]);
  const viewContent = VIEW_CONTENT_TYPE.filter(
    (_, index) => view[index]
  ).map((elem) => elem.name.toLowerCase());

  // eslint-disable-next-line
  const { __typename, ...filteredSummary } = summary;

  // eslint-disable-next-line consistent-return
  const getComponent = (value) => {
    switch (value) {
      case companyScreenOptions[0]:
        return (
          <CompanyHeader
            company={company}
            onEditSave={onEditSave}
            getCompany={getCompany}
            role={role}
            loading={loading}
            view={view}
            setView={setView}
            users={users}
            orgType={orgType}
            updateContact={updateContact}
            deleteContact={deleteContact}
            exportDataToGS={exportDataToGS}
            showData={[
              Boolean(feeds.length),
              Boolean(kpis.length),
              Boolean(matricOptions),
            ]}
            importFromExcelSheet={importFromExcelSheet}
            importFromGS={importFromGS}
            sendRequestDataEmail={sendRequestDataEmail}
          />
        );
      case companyScreenOptions[1]:
        return (
          <CompanySummary completeSummary={filteredSummary} loading={loading} />
        );
      case companyScreenOptions[2]:
        return (
          <KpiSection company={company} periodicity={periodicity} role={role} />
        );
      case companyScreenOptions[3]:
        return (
          <CompanyFeed
            feeds={feeds}
            onUpdateFeed={onUpdateFeed}
            onDeleteFeed={onDeleteFeed}
            role={role}
            viewContent={viewContent}
            organisationName={organisation ? organisation.name : ''}
            title={formatMessage(messages.feedTitle)}
            loading={loading}
          />
        );
      case companyScreenOptions[4]:
        return (
          <CompanyComment
            onUpdateComment={handleOnUpdateComment}
            onDeleteComment={handleOnDeleteComment}
            role={role}
            comment={commentByCompany}
            organisationName={organisation ? organisation.name : ''}
            viewContent={viewContent}
            loading={loading}
          />
        );
      case companyScreenOptions[5]:
        return (
          <InvestmentTableCard
            investments={investments}
            contacts={users}
            role={role}
            onSave={onInvestmentsSave}
            onRemove={onInvestmentRemove}
          />
        );
      case companyScreenOptions[6]:
        return (
          <CompanyReports
            handleCreateReport={handleCreateReport}
            handleUpdateReport={handleUpdateReport}
            handleDeleteReport={handleDeleteReport}
            companyReports={companyReports}
            role={role}
            viewContent={viewContent}
            loading={loading}
          />
        );
      case companyScreenOptions[7]:
        return (
          <CompanyVisual
            getCompanyMetricNames={getCompanyMetricNames}
            getCompanyVisual={getCompanyVisual}
            matricOptions={matricOptions}
            visualData={visualData}
          />
        );
      case companyScreenOptions[8]:
        return (
          <CompanyProfile
            profile={profile}
            onEdit={onProfileEdit}
            role={role}
          />
        );
    }
  };

  return (
    <div className={classes.root}>
      {organisation.companySettings.map((ele) => {
        const { field, status } = ele;
        if (!status) return null;
        return getComponent(field);
      })}
    </div>
  );
};

export default CompanyScreen;

CompanyScreen.propTypes = {
  company: PropTypes.object,
  getCompany: PropTypes.func,
  onEditSave: PropTypes.func,
  kpis: PropTypes.object,
  feeds: PropTypes.arrayOf(PropTypes.object),
  onUpdateKpis: PropTypes.func,
  onProfileEdit: PropTypes.func,
  profile: PropTypes.object,
  handleCreateReport: PropTypes.func,
  companyReports: PropTypes.arrayOf(PropTypes.object),
  onUpdateFeed: PropTypes.func,
  role: PropTypes.string,
  getCompanyVisual: PropTypes.func,
  matricOptions: PropTypes.arrayOf(PropTypes.string),
  getCompanyMetricNames: PropTypes.func,
  visualData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  handleUpdateReport: PropTypes.func,
  handleDeleteReport: PropTypes.func,
  commentByCompany: PropTypes.arrayOf(PropTypes.object),
  handleOnUpdateComment: PropTypes.func,
  handleOnDeleteComment: PropTypes.func,
  summary: PropTypes.object,
  users: PropTypes.arrayOf(PropTypes.object),
  orgType: PropTypes.string,
  updateContact: PropTypes.func,
  deleteContact: PropTypes.func,
  exportDataToGS: PropTypes.func,
  importFromExcelSheet: PropTypes.func,
  importFromGS: PropTypes.func,
  onDeleteFeed: PropTypes.func,
  organisation: PropTypes.object.isRequired,
  investments: PropTypes.arrayOf(PropTypes.object).isRequired,
  onInvestmentsSave: PropTypes.func.isRequired,
  onInvestmentRemove: PropTypes.func.isRequired,
  sendRequestDataEmail: PropTypes.func.isRequired,
  periodicity: PropTypes.string.isRequired,
};

CompanyScreen.defaultProps = {
  company: {},
  getCompany: () => {},
  handleCreateReport: () => {},
  onEditSave: null,
  kpis: {
    revenue: {
      value: '',
    },
    arr: {
      value: '',
    },
    employees: {
      value: '',
    },
  },
  profile: null,
  onProfileEdit: null,
  companyReports: [],
  feeds: [],
  onUpdateKpis: async () => {},
  onUpdateFeed: async () => {},
  role: '',
  getCompanyVisual: () => {},
  matricOptions: [],
  getCompanyMetricNames: () => {},
  visualData: [],
  loading: false,
  handleUpdateReport: null,
  handleDeleteReport: null,
  commentByCompany: [],
  handleOnUpdateComment: () => {},
  handleOnDeleteComment: () => {},
  summary: {},
  users: [],
  orgType: '',
  updateContact: async () => {},
  deleteContact: async () => {},
  exportDataToGS: async () => {},
  importFromExcelSheet: async () => {},
  importFromGS: async () => {},
  onDeleteFeed: async () => {},
};
