// eslint-disable-next-line import/prefer-default-export
export const SET_LOCALE = 'common/SET_LOCALE';
export const ACTION_TYPES_LOADING = {
  SET_LOADING: 'SET_LOADING',
  GET_LOADING: 'GET_LOADING',
};
export const ACTION_TYPES_ALERT = {
  GET_OPEN: 'GET_OPEN',
  SET_ALERT: 'SET_ALERT',
  GET_ALERT: 'GET_ALERT',
};

export const ACTION_TYPES_DOWNLOAD = {
  SET_DOWNLOAD: 'SET_DOWNLOAD',
  GET_DOWNLOAD: 'GET_DOWNLOAD',
};
