import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';

import { COMPANY_CONTACT_TYPES } from 'constants/index';
import messages from './messages';
import { useStyles } from './styles';
import { columns } from './columns';

const CompanyTable = ({
  companiesByOrganisation,
  onEditSave,
  onDeleteCompanies,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [companyContacts, setCompanyContacts] = useState([]);

  useEffect(() => {
    if (companiesByOrganisation.length) {
      setCompanyContacts(
        companiesByOrganisation.map(({ name, id, contacts = [] }) => {
          let obj = { name, companyId: id };
          if (contacts && contacts.isArray) {
            let CompanyContact = contacts.filter(
              (elem) => elem.contactType === COMPANY_CONTACT_TYPES.CONTACT
            );
            const restContactLength = CompanyContact.length - 2;
            CompanyContact = CompanyContact.slice(0, 2)
              .map(
                (elem) =>
                  `${elem.firstName ? `${elem.firstName} (` : ''}${elem.email}${
                    elem.firstName ? ')' : ''
                  }`
              )
              .join('\n');
            let InvestMgr = contacts.filter(
              (elem) => elem.contactType === COMPANY_CONTACT_TYPES.INVESTOR
            );
            const restInvestorLength = InvestMgr.length - 2;
            InvestMgr = InvestMgr.slice(0, 2)
              .map(
                (elem) =>
                  `${elem.firstName ? `${elem.firstName} (` : ''}${elem.email}${
                    elem.firstName ? ')' : ''
                  }`
              )
              .join('\n');
            obj = {
              ...obj,
              id,
              CompanyContact: `${CompanyContact}${
                restContactLength > 0 ? ` +${restContactLength}` : ''
              }`,
              InvestMgr: `${InvestMgr}${
                restInvestorLength > 0 ? ` +${restInvestorLength}` : ''
              }`,
            };
          }
          return obj;
        })
      );
    } else {
      setCompanyContacts([]);
    }
  }, [companiesByOrganisation]);

  return (
    <>
      <Typography
        component="h2"
        variant="h5"
        color="primary"
        gutterBottom
        className={classes.title}
      >
        {intl.formatMessage(messages.editCompaniesTitle)}
      </Typography>
      <MaterialTable
        columns={columns}
        data={companyContacts}
        title=""
        options={{
          sorting: true,
          selection: true,
          search: false,
          emptyRowsWhenPaging: false,
          actionsColumnIndex: -1,
          grouping: false,
          draggable: false,
          debounceInterval: 0,
        }}
        actions={[
          {
            icon: 'delete',
            tooltip: 'Delete Companies',
            onClick: (event, rowData) => {
              if (Array.isArray(rowData) && rowData.length) {
                onDeleteCompanies(
                  rowData.map((ele) => ({
                    id: ele.companyId,
                    name: ele.name,
                  }))
                );
              }
            },
          },
        ]}
        editable={{
          isDeletable: (rowData) => Boolean(rowData.companyId),
          onRowDelete: (rowData) =>
            new Promise((resolve) => {
              setTimeout(async () => {
                await onDeleteCompanies({
                  id: rowData.companyId,
                  name: rowData.name,
                });
                resolve();
              }, 1000);
            }),
          onRowUpdate: (oldData) =>
            new Promise((resolve) => {
              setTimeout(async () => {
                const { companyId, name } = JSON.parse(JSON.stringify(oldData));
                await onEditSave({
                  companyId,
                  name,
                });
                resolve();
              }, 1000);
            }),
        }}
      />
    </>
  );
};

export default CompanyTable;

CompanyTable.propTypes = {
  companiesByOrganisation: PropTypes.arrayOf(PropTypes.object),
  onEditSave: PropTypes.func,
  // onDeleteContacts: PropTypes.func,
};

CompanyTable.defaultProps = {
  companiesByOrganisation: [],
  onEditSave: () => {},
  // onDeleteContacts: () => {},
};
