import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'constants/colors';

const border = {
  borderColor: Colors.TEXT,
  borderStyle: 'solid',
  borderWidth: 1,
};

export const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'flex',
  },
  gridItem: {
    // marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  formLabel: {
    fontSize: theme.typography.fontSize,
    color: '#363636',
    marginRight: theme.spacing(3),
    display: 'flex',
  },
  formControlCol: {
    // width: '60%',
    '& label.Mui-focused': {
      color: Colors.TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        ...border,
        borderColor: Colors.BORDER_GREY,
      },
      '&.Mui-focused fieldset': {
        ...border,
      },
    },
    '&.hover .MuiOutlinedInput-root': {
      '& fieldset': border,
    },
  },
  actionBtn: {
    marginLeft: theme.spacing(1.2),
    width: '100px',
  },
}));
