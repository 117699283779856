import React from 'react';
import { FormattedMessage } from 'react-intl';
import translations from 'translations';
import { SUBSCRIPTION_TYPES } from 'constants/index';

export const Subscriptions = [
  {
    id:
      'translations.OrganisationSettings.Subscription.Billing.subscriptionMonthlyStarter',
    name: (
      <FormattedMessage
        {...translations.OrganisationSettings.Subscription.Billing
          .subscriptionMonthlyStarter}
      />
    ),
    value: SUBSCRIPTION_TYPES.MONTHLY,
  },
  {
    id:
      'translations.OrganisationSettings.Subscription.Billing.subscriptionMonthlyStarter',
    name: (
      <FormattedMessage
        {...translations.OrganisationSettings.Subscription.Billing
          .subscriptionMonthlyProfessional}
      />
    ),
    value: SUBSCRIPTION_TYPES.MONTHLY_PRO,
  },
  {
    id:
      'translations.OrganisationSettings.Subscription.Billing.subscriptionMonthlyStarter',
    name: (
      <FormattedMessage
        {...translations.OrganisationSettings.Subscription.Billing
          .subscriptionAnnualStarter}
      />
    ),
    value: SUBSCRIPTION_TYPES.ANNUAL,
  },
  {
    id:
      'translations.OrganisationSettings.Subscription.Billing.subscriptionMonthlyStarter',
    name: (
      <FormattedMessage
        {...translations.OrganisationSettings.Subscription.Billing
          .subscriptionAnnualProfessional}
      />
    ),
    value: SUBSCRIPTION_TYPES.ANNUAL_PRO,
  },
  {
    id:
      'translations.OrganisationSettings.Subscription.Billing.subscriptionAnnual',
    name: (
      <FormattedMessage
        {...translations.OrganisationSettings.Subscription.Billing
          .subscriptionEnterprice}
      />
    ),
    value: SUBSCRIPTION_TYPES.ENTERPRISE,
  },
];

export const paymentMethod = {
  id: 'translations.OrganisationSettings.Subscription.Billing.invoice',
  name: (
    <FormattedMessage
      {...translations.OrganisationSettings.Subscription.Billing.invoice}
    />
  ),
  value: 'invoice',
};
