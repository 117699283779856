import { gql } from 'apollo-boost';

export const SEND_REQUEST_DATA_EMAIL = gql`
  mutation SendRequestDataEmail(
    $companyId: ID
    $companyName: String
    $subject: String!
    $recipients: [BulkRequestContact]!
    $mainText: String!
    $cc: [BulkRequestContact]!
    $bcc: [String!]
    $bulkData: [BulkSendRequestCompanies!]
  ) {
    sendRequestDataEmail(
      input: {
        companyId: $companyId
        companyName: $companyName
        subject: $subject
        recipients: $recipients
        mainText: $mainText
        cc: $cc
        bcc: $bcc
        bulkData: $bulkData
      }
    )
  }
`;

export const GET_REQUEST_DATA = gql`
  query getRequestDataByCompany($companyId: [ID]!) {
    getRequestDataByCompany(companyId: $companyId) {
      name
      id
      data {
        feed {
          time
          type
          days
        }
        metrics {
          time
          type
          days
        }
        report {
          time
          type
          days
        }
        # currency
        show
      }
      contacts {
        firstName
        contactType
        lastName
        email
        id
      }
      # firstInvestMgr
      # firstCompanyContact
      # secCompanyContact
      # firstContactName
      # secContactName
      # userName
    }
  }
`;

export const RESEND_EMAIL_VERIFICATION = gql`
  mutation resendEmailVerification($userId: ID!) {
    resendEmailVerification(id: $userId)
  }
`;
