import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';

import { selectSelectedOrganization } from 'containers/Organisation';
import translations from 'translations';
import { setAlert } from 'containers/Config';
import getGraphqlErrorMessage from 'common/getGraphqlErrorMessage';
import RequestData from './RequestData';
import { SEND_REQUEST_DATA_EMAIL, GET_REQUEST_DATA } from '../../graphql';

const RequestDataContainer = () => {
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(selectSelectedOrganization);
  const [sendRequestDataEmail] = useMutation(SEND_REQUEST_DATA_EMAIL);
  const {
    data: { getRequestDataByCompany: requestData = [] } = {},
    loading,
    refetch: refetchRequestData,
  } = useQuery(GET_REQUEST_DATA, {
    variables: {
      companyId: selectedOrganization.companies.map((company) => company.id),
    },
    fetchPolicy: 'network-only',
  });

  const handleShowAlertError = ({ message }) => {
    dispatch(
      setAlert({
        open: true,
        message,
        severity: 'error',
      })
    );
  };

  const validateData = (data) => {
    const { recipients, mainText, subject } = data;
    if (!mainText) {
      handleShowAlertError({
        message: translations.RequestData.mainTextEmptyError,
      });
      return false;
    }
    if (!recipients) {
      handleShowAlertError({
        message: translations.RequestData.recipentEmptyError,
      });
      return false;
    }
    if (!subject) {
      handleShowAlertError({
        message: translations.RequestData.subjectEmptyError,
      });
      return false;
    }
    return true;
  };
  const handleSubmit = async (data) => {
    if (!validateData(data)) return false;
    try {
      const {
        data: { sendRequestDataEmail: response } = {},
      } = await sendRequestDataEmail({
        variables: {
          ...data,
        },
      });
      if (!response) throw new Error(response);
      dispatch(
        setAlert({
          open: true,
          message: translations.RequestData.mailSendSuccessfully,
          severity: 'success',
        })
      );
      return true;
    } catch (error) {
      const errorMessage = getGraphqlErrorMessage(error);
      handleShowAlertError({
        message: errorMessage || translations.Common.common.defaultErrorMessage,
      });
      return false;
    }
  };

  return (
    <RequestData
      companies={selectedOrganization ? selectedOrganization.companies : []}
      defaultOrganisation={selectedOrganization}
      handleSubmit={handleSubmit}
      getRequestDataByCompany={requestData}
      refetchRequestData={refetchRequestData}
      loading={loading}
    />
  );
};

// const mapState = createStructuredSelector({
//   selectedOrganization: getSelectedOrganization,
//   requestData: getRequestData,
//   requestDataLoading: getCompaniesLoading,
// });

// const mapDispatchToProps = (dispatch) => ({
//   getRequestDataByCompany: (id) =>
//     dispatch(CompanyAction.getRequestDataByCompany(id)),
// });

export default RequestDataContainer;
