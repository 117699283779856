/* eslint-disable consistent-return */
import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { connect, useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { useAuth0 } from 'components/Common/Auth';
import { setAlert } from 'containers/Config';
import {
  selectSelectedOrganization,
  OrganizationThunk,
} from 'containers/Organisation/ducks';
import {
  COMPANY_CONTACT_TYPE,
  EXPORT_FORMAT,
  initialValue,
} from 'constants/index';
import CompanyScreen from 'components/Company/CompanyScreen';
import buildUrn from 'common/buildUrn';
import reportError from 'common/reportError';
import { CommonAction } from 'actions';
import { userRoles } from 'user/userConstants';

import { getLoading } from 'reducers';
import { AllowedOrganisationTypes } from 'common/commonContants';
import { getDownloads, exportPdf } from 'common/getDownloads';
import { mapCompanyOptionsToKeys } from 'utils/index';
import translations from 'translations';
import { PortfolioThunk } from 'containers/Portfolio';
import { GET_KPI_DEFAULT_VALUES } from 'containers/Organisation/ducks/query';
import getGraphqlErrorMessage from 'common/getGraphqlErrorMessage';
import {
  GET_COMPANY_QUERY,
  GET_COMPANY_KPIS_QUERY,
  GET_COMPANY_FEEDS_QUERY,
  UPDATE_COMPANY_MUTATION,
  UPDATE_COMPANY_KPIS_MUTATION,
  CREATE_COMPANY_REPORT,
  GET_COMPANY_REPORTS,
  UPDATE_COMPANY_FEED_MUTATION,
  DELETE_COMPANY_FEED_MUTATION,
  GET_MATRIC_META,
  GET_COMPANY_VISUAL_DATA,
  UPDATE_COMPANY_REPORT,
  DELETE_REPORT_MUTATION,
  GET_COMPANY_COMMENT,
  UPDATE_COMPANY_COMMENT,
  DELETE_COMPANY_COMMENT,
  GET_COMPANY_SUMMARY,
  GET_USERS_BY_ORGANISATION_ID,
  DELETE_CONTACTS_MUTATION,
  UPDATE_CONTACTS_MUTATION,
  EXPORT_TO_GOOGLE_SHEET,
  IMPORT_FROM_EXCEL_SHEET,
  IMPORT_FROM_GOOGLE_SHEET,
  SEND_REQUEST_DATA_EMAIL,
} from '../../graphql';

const CompanyScreenContainer = ({
  loading,
  setDownload,
  onInvestmentsSave,
  onInvestmentRemove,
}) => {
  const dispatch = useDispatch();
  const organisation = useSelector(selectSelectedOrganization);
  const [companyProfile, setCompanyProfile] = useState({});
  const { user } = useAuth0();
  const [kpisData, setKpisData] = useState({
    revenue: {
      value: '',
    },
    arr: {
      value: '',
    },
    employees: {
      value: '',
    },
  });
  const [userData, setUserData] = useState([]);
  const params = useParams();
  const { companyId } = params;

  const [sendRequestDataEmail] = useMutation(SEND_REQUEST_DATA_EMAIL);

  const onSendRequestDataEmail = async (data) => {
    try {
      const {
        data: { sendRequestDataEmail: response } = {},
      } = await sendRequestDataEmail(data);
      if (!response) throw new Error(response);
      dispatch(
        setAlert({
          open: true,
          message: translations.RequestData.mailSendSuccessfully,
          severity: 'success',
        })
      );
      return true;
    } catch (error) {
      const errorMessage = getGraphqlErrorMessage(error);
      dispatch(
        setAlert({
          open: true,
          message:
            errorMessage || translations.Common.common.defaultErrorMessage,
          severity: 'success',
        })
      );
      return false;
    }
  };

  const {
    data: { getKpiDefaultValues: defaultKpiValues = initialValue } = {},
  } = useQuery(GET_KPI_DEFAULT_VALUES, {
    variables: {
      input: {
        orgId: organisation.id,
      },
    },
  });

  const {
    data: { getCompanyMetricNames: matricOptions = [] } = {},
    refetch: getCompanyMetricNames,
  } = useQuery(GET_MATRIC_META, {
    variables: {
      companyId,
    },
  });

  const {
    data: { getCompanyVisual: visualData = [] } = {},
    refetch: getCompanyVisual,
  } = useQuery(GET_COMPANY_VISUAL_DATA);

  const {
    data: { company = {} } = {},
    refetch: getCompany,
    loading: headerLoading,
  } = useQuery(GET_COMPANY_QUERY, {
    variables: { companyId: buildUrn('company', companyId) },
    fetchPolicy: 'no-cache',
  });

  const {
    data: { companyReports = [] } = {},
    loading: reportLoading,
  } = useQuery(GET_COMPANY_REPORTS, {
    variables: { companyId: buildUrn('company', companyId) },
  });

  const { data: { organisation: { users, companies } = {} } = {} } = useQuery(
    GET_USERS_BY_ORGANISATION_ID,
    {
      variables: { organisationId: organisation.id },
      fetchPolicy: 'no-cache',
    }
  );

  const {
    data: { kpiValuesByCompany = [] } = {},
    loading: kpiLoading,
  } = useQuery(GET_COMPANY_KPIS_QUERY, {
    variables: { companyId: buildUrn('company', companyId) },
  });

  const {
    data: { commentByCompany = [] } = {},
    loading: commentLoading,
  } = useQuery(GET_COMPANY_COMMENT, {
    variables: {
      input: {
        companyId: buildUrn('company', companyId),
      },
    },
  });

  const {
    data: { summaryByCompany = {} } = {},
    refetch: getCompanySummary,
  } = useQuery(GET_COMPANY_SUMMARY, {
    variables: {
      companyId: buildUrn('company', companyId),
      organisationId: organisation.id,
    },
  });

  const {
    data: { feedByCompany = [] } = {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    refetch: getCompanyFeeds,
    loading: feedLoading,
  } = useQuery(GET_COMPANY_FEEDS_QUERY, {
    variables: { companyId: buildUrn('company', companyId) },
  });

  const handlePopulateKpis = (kpis) => {
    if (Array.isArray(kpis)) {
      const newKpis = kpis.map((data) => ({
        ...data,
        title: data.kpi.name,
      }));
      setKpisData(newKpis);
    }
  };

  const onSuccessUpdateCompany = () => {
    dispatch(
      setAlert({
        open: true,
        message: (
          <FormattedMessage
            {...translations.Company.ManageCompanies.Update
              .onSuccessUpdateCompany}
          />
        ),
        severity: 'success',
      })
    );
  };

  const onSuccessUpdateContact = () => {
    dispatch(
      setAlert({
        open: true,
        message: (
          <FormattedMessage
            {...translations.Company.ManageCompanies.Update
              .onSuccessUpdateContact}
          />
        ),
        severity: 'success',
      })
    );
  };

  const onSuccessRemoveContact = () => {
    dispatch(
      setAlert({
        open: true,
        message: (
          <FormattedMessage
            {...translations.Company.ManageCompanies.Update
              .onSuccessRemoveContact}
          />
        ),
        severity: 'success',
      })
    );
  };

  const [updateCompany] = useMutation(UPDATE_COMPANY_MUTATION, {
    onCompleted: () => onSuccessUpdateCompany(),
  });

  const [createCompanyReport] = useMutation(CREATE_COMPANY_REPORT);

  const [updateCompanyKpi] = useMutation(UPDATE_COMPANY_KPIS_MUTATION, {
    onCompleted: () => onSuccessUpdateCompany(),
  });

  const [updateCompanyFeed] = useMutation(UPDATE_COMPANY_FEED_MUTATION, {
    onCompleted: () => onSuccessUpdateCompany(),
  });
  const [deleteCompanyFeed] = useMutation(DELETE_COMPANY_FEED_MUTATION, {
    onCompleted: () => onSuccessUpdateCompany(),
  });

  const [updateReport] = useMutation(UPDATE_COMPANY_REPORT);

  const [deleteReport] = useMutation(DELETE_REPORT_MUTATION);

  const [updateCompanyComment] = useMutation(UPDATE_COMPANY_COMMENT);

  const [deleteComment] = useMutation(DELETE_COMPANY_COMMENT);
  const [updateContacts] = useMutation(UPDATE_CONTACTS_MUTATION, {
    onCompleted: () => onSuccessUpdateContact,
  });

  const [deleteContact] = useMutation(DELETE_CONTACTS_MUTATION, {
    onCompleted: () => onSuccessRemoveContact,
  });

  const [exportToGoogleSheet] = useMutation(EXPORT_TO_GOOGLE_SHEET);

  const [importFromExcelFile] = useMutation(IMPORT_FROM_EXCEL_SHEET, {
    onError: () => {
      //
    },
  });

  const [importFromGoogleSheet] = useMutation(IMPORT_FROM_GOOGLE_SHEET);

  const importFromGS = async ({ link, optionValue, value }) => {
    let inputObj = {
      companyId,
      link,
    };
    if (value === 'All') {
      inputObj = {
        ...inputObj,
        company: true,
        profile: true,
        kpi: true,
        matric: true,
        feed: true,
      };
    } else if (value === 'Specific') {
      inputObj = {
        ...inputObj,
        ...mapCompanyOptionsToKeys(optionValue),
      };
    }
    try {
      const {
        data: { importFromGoogleSheet: response },
      } = await importFromGoogleSheet({
        variables: {
          input: inputObj,
        },
        refetchQueries: [
          {
            query: GET_MATRIC_META,
            variables: {
              companyId,
            },
          },
          {
            query: GET_COMPANY_QUERY,
            variables: { companyId: buildUrn('company', companyId) },
          },
          {
            query: GET_COMPANY_FEEDS_QUERY,
            variables: { companyId: buildUrn('company', companyId) },
          },
          {
            query: GET_COMPANY_KPIS_QUERY,
            variables: { companyId: buildUrn('company', companyId) },
          },
        ],
        awaitRefetchQueries: true,
      });
      dispatch(
        OrganizationThunk.getSelectedOrganization(organisation.id, false)
      );
      return response;
    } catch (err) {
      return false;
    }
  };

  const importFromExcelSheet = async ({ file, optionValue }) => {
    try {
      const {
        data: { importFromExcel: response },
      } = await importFromExcelFile({
        variables: {
          input: {
            companyId,
            file,
            ...mapCompanyOptionsToKeys(optionValue),
          },
        },
        refetchQueries: [
          {
            query: GET_MATRIC_META,
            variables: {
              companyId,
            },
          },
          {
            query: GET_COMPANY_QUERY,
            variables: { companyId: buildUrn('company', companyId) },
          },
          {
            query: GET_COMPANY_FEEDS_QUERY,
            variables: { companyId: buildUrn('company', companyId) },
          },
          {
            query: GET_COMPANY_KPIS_QUERY,
            variables: { companyId: buildUrn('company', companyId) },
          },
        ],
        awaitRefetchQueries: true,
      });
      dispatch(
        OrganizationThunk.getSelectedOrganization(organisation.id, false)
      );
      return response;
    } catch (err) {
      return false;
    }
  };

  const onEditSave = async ({
    companyUrnId,
    name,
    team,
    location,
    shortDescription,
    websiteUrl,
    industryId,
    legalName,
    employees,
    foundedDate,
    contactEmail,
    file,
  }) => {
    try {
      await updateCompany({
        variables: {
          input: {
            companyId: companyUrnId,
            name,
            shortDescription,
            team,
            location,
            websiteUrl,
            industryId,
            legalName,
            employees,
            foundedDate,
            contactEmail,
            file,
          },
        },
        refetchQueries: [
          {
            query: GET_COMPANY_QUERY,
            variables: { companyId: buildUrn('company', companyId) },
          },
        ],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      reportError(error);
    }
  };

  const handleGetCompanyProfile = (companyData) => {
    const {
      legalName,
      foundedDate,
      team,
      contactEmail,
      industryId,
      employees,
      websiteUrl,
    } = companyData;
    const profile = {
      legalName: {
        title: 'Legal Name',
        description: legalName,
      },
      foundedDate: {
        title: 'Founded',
        description: foundedDate,
      },
      team: {
        title: 'Team',
        description: team,
      },
      contactEmail: {
        title: 'Email',
        description: contactEmail,
      },
      industryId: {
        title: 'Industry',
        description: industryId,
      },
      employees: {
        title: 'Employees',
        description: employees,
      },
      websiteUrl: {
        title: 'Website',
        description: websiteUrl,
      },
    };
    setCompanyProfile(profile);
  };

  const handleOnProfileEdit = async (data) => {
    const {
      legalName,
      foundedDate,
      team,
      contactEmail,
      industryId,
      employees,
      websiteUrl,
    } = data;
    const profileData = {
      legalName: legalName.description,
      foundedDate: foundedDate.description,
      team: team.description,
      contactEmail: contactEmail.description,
      industryId: industryId.description,
      employees: employees.description,
      websiteUrl: websiteUrl.description,
    };
    await onEditSave({ ...company, ...profileData, companyUrnId: company.id });
  };

  const handleCreateReport = async ({ file, period, view }) => {
    try {
      await createCompanyReport({
        variables: {
          input: {
            companyId: company.id,
            organisation: organisation.id,
            file,
            period,
            view,
          },
        },
        refetchQueries: [
          {
            query: GET_COMPANY_REPORTS,
            variables: {
              companyId: buildUrn('company', companyId),
            },
          },
        ],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      reportError(error);
    }
  };

  const handleUpdateReport = async (data) => {
    try {
      await updateReport({
        variables: {
          input: data,
        },
        refetchQueries: [
          {
            query: GET_COMPANY_REPORTS,
            variables: {
              companyId: buildUrn('company', companyId),
            },
          },
        ],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      reportError(error);
    }
  };

  const handleDeleteReport = async ({ id }) => {
    try {
      await deleteReport({
        variables: {
          input: {
            id,
          },
        },
        refetchQueries: [
          {
            query: GET_COMPANY_REPORTS,
            variables: {
              companyId: buildUrn('company', companyId),
            },
          },
        ],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      reportError(error);
    }
  };

  const handleOnUpdateKpis = async (data) => {
    try {
      if (Array.isArray(data)) {
        const kpiValues = data.map((item) => ({
          id: item.id || null,
          value: item.value,
          kpiName: item.title,
        }));
        await updateCompanyKpi({
          variables: {
            input: {
              companyId: company.id,
              kpiValues,
            },
          },
          refetchQueries: [
            {
              query: GET_COMPANY_KPIS_QUERY,
              variables: { companyId: buildUrn('company', companyId) },
            },
          ],
          awaitRefetchQueries: true,
        });
      }
    } catch (error) {
      reportError(error);
    }
  };

  const handleOnUpdateComment = async (data) => {
    try {
      if (Array.isArray(data)) {
        const comments = data.map((item) => ({
          id: item.id || null,
          description: item.description,
          view: item.view,
          userId: user.id,
          organisationId: organisation.id,
        }));
        await updateCompanyComment({
          variables: {
            input: {
              companyId: company.id,
              comments,
            },
          },
          refetchQueries: [
            {
              query: GET_COMPANY_COMMENT,
              variables: {
                input: {
                  companyId: buildUrn('company', companyId),
                },
              },
            },
          ],
          awaitRefetchQueries: true,
        });
      }
    } catch (error) {
      reportError(error);
    }
  };

  const handleOnUpdateFeed = async (data) => {
    try {
      if (Array.isArray(data)) {
        const feeds = data.map((item) => ({
          id: item.id || null,
          title: item.title,
          description: item.description,
          date: item.date,
          view: item.view,
          userId: user.id,
          organisation: organisation.id,
        }));
        await updateCompanyFeed({
          variables: {
            input: {
              companyId: company.id,
              feeds,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_COMPANY_FEEDS_QUERY,
              variables: { companyId: buildUrn('company', companyId) },
            },
          ],
        });
      }
    } catch (error) {
      reportError(error);
    }
  };

  const handleOnDeleteComment = async (commentId) => {
    try {
      await deleteComment({
        variables: {
          input: {
            commentId,
          },
        },
        refetchQueries: [
          {
            query: GET_COMPANY_COMMENT,
            variables: {
              input: {
                companyId: buildUrn('company', companyId),
              },
            },
          },
        ],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      reportError(error);
    }
  };

  const handleOnDeleteFeed = async (feedId) => {
    try {
      if (feedId) {
        await deleteCompanyFeed({
          variables: {
            feedId,
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_COMPANY_FEEDS_QUERY,
              variables: { companyId: buildUrn('company', companyId) },
            },
          ],
        });
        onSuccessUpdateCompany();
      }
      // getCompanyFeeds({ companyId: buildUrn('company', companyId) });
    } catch (error) {
      reportError(error);
    }
  };

  const onUpdateContact = async (userId) => {
    await updateContacts({
      variables: {
        input: {
          orgId: organisation.id,
          userId,
          contactType: COMPANY_CONTACT_TYPE.INVESTOR,
          companyId,
        },
        refetchQueries: [
          {
            query: GET_USERS_BY_ORGANISATION_ID,
            variables: { organisationId: organisation.id },
          },
        ],
        awaitRefetchQueries: true,
      },
    });
  };

  const onDeleteContact = async (ids) => {
    await deleteContact({
      variables: {
        contactIds: ids,
      },
      refetchQueries: [
        {
          query: GET_USERS_BY_ORGANISATION_ID,
          variables: { organisationId: organisation.id },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  const exportDataToGS = async (obj) => {
    const { formatOption, optionValue, value } = obj;
    let inputObj = {
      companyId: buildUrn('company', companyId),
      company: true,
      profile: true,
    };
    if (value === 'All') {
      inputObj = {
        ...inputObj,
        feed: true,
        kpi: true,
        matric: true,
      };
    } else if (value === 'Specific') {
      inputObj = {
        ...inputObj,
        ...mapCompanyOptionsToKeys(optionValue),
      };
    } else {
      inputObj = {
        ...inputObj,
        feed: Boolean(feedByCompany.length),
        kpi: Boolean(kpisData.length),
        matric: true, // metric to be fixed
      };
    }
    if (
      formatOption === EXPORT_FORMAT[2].name ||
      formatOption === EXPORT_FORMAT[1].name
    ) {
      const {
        data: { exportToGoogleSheet: sheetUrl },
      } = await exportToGoogleSheet({
        variables: {
          input: {
            ...inputObj,
            share: formatOption === EXPORT_FORMAT[2].name,
          },
        },
      });
      if (formatOption === EXPORT_FORMAT[1].name) {
        await getDownloads(
          sheetUrl.split('/')[5],
          `${company.name}.xlsx`,
          companyId,
          'excel',
          setDownload
        );
        return '';
      }
      return sheetUrl;
    }

    if (formatOption === EXPORT_FORMAT[0].name) {
      await exportPdf(
        inputObj,
        `${company.name}_${new Date().getFullYear()}.pdf`,
        setDownload
      );
      return '';
    }
  };

  // const handleOnCreateMetric = async () => {};

  useEffect(() => {
    handlePopulateKpis(kpiValuesByCompany);
  }, [JSON.stringify(kpiValuesByCompany)]);

  useEffect(() => {
    if (company && Object.keys(company).length) {
      handleGetCompanyProfile(company);
    }
  }, [company]);

  useEffect(() => {
    if (Array.isArray(users)) {
      const usersData = users
        // .filter((userObj) => userObj.id !== user.id)
        .map((elem) => {
          let investmentManager = null;
          const selectedCompany = companies.find(
            (companyObj) => companyObj.id === companyId
          );
          if (selectedCompany && selectedCompany.contacts.length) {
            investmentManager = selectedCompany.contacts.find(
              (contact) =>
                contact.userId === elem.id &&
                contact.contactType === COMPANY_CONTACT_TYPE.INVESTOR
            );
          }
          const investmentManagerData = investmentManager || null;
          return {
            ...elem,
            investmentManager: investmentManagerData,
            checked: Boolean(investmentManager),
          };
        });
      setUserData(usersData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, companyId]);

  const [filteredInvestments, setFilteredInvestments] = useState([]);

  useEffect(() => {
    getCompanySummary({ companyId: buildUrn('company', companyId) });
    const portfolios = organisation.portfolios
      ? organisation.portfolios.items
      : [];
    const investmentsWithPortfolios = portfolios.map((p) =>
      p.investments.items.map((i) => ({
        ...i,
        portfolio: { id: p.id, name: p.name },
      }))
    );
    const investments = investmentsWithPortfolios.reduce(
      (total, cur) => [...total, ...cur],
      []
    );
    setFilteredInvestments(
      investments.filter((i) => i.company.id === companyId)
    );
  }, [JSON.stringify(organisation), companyId]);

  const handleInvestmentsUpdate = (investments) => {
    if (investments.length === 0) return;
    onInvestmentsSave(companyId, investments);
  };

  const role = useMemo(() => {
    const roles = Object.values(userRoles);
    const companyRole = company.id
      ? roles.findIndex(
          (ele) =>
            ele ===
            organisation.companies.find((elem) => elem.id === company.id)
              ?.organisation.role
        )
      : 2; // default viewer
    const orgRole = roles.findIndex((ele) => ele === organisation.role);
    if (companyRole > orgRole) {
      return roles[companyRole];
    }
    return roles[orgRole];
  }, [company.id]);

  return (
    <>
      <CompanyScreen
        company={company}
        role={role}
        getCompany={getCompany}
        onEditSave={onEditSave}
        profile={companyProfile}
        onProfileEdit={handleOnProfileEdit}
        kpis={kpisData}
        feeds={feedByCompany}
        onUpdateKpis={handleOnUpdateKpis}
        handleCreateReport={handleCreateReport}
        handleUpdateReport={handleUpdateReport}
        handleDeleteReport={handleDeleteReport}
        companyReports={companyReports}
        onUpdateFeed={handleOnUpdateFeed}
        onDeleteFeed={handleOnDeleteFeed}
        matricOptions={matricOptions}
        getCompanyVisual={getCompanyVisual}
        getCompanyMetricNames={getCompanyMetricNames}
        visualData={visualData}
        loading={
          loading ||
          headerLoading ||
          commentLoading ||
          feedLoading ||
          kpiLoading ||
          reportLoading
        }
        users={
          organisation.type === AllowedOrganisationTypes.FUND ? userData : []
        }
        organisation={organisation}
        commentByCompany={commentByCompany}
        handleOnUpdateComment={handleOnUpdateComment}
        handleOnDeleteComment={handleOnDeleteComment}
        summary={summaryByCompany}
        orgType={organisation.type}
        updateContact={onUpdateContact}
        deleteContact={onDeleteContact}
        investments={filteredInvestments}
        onInvestmentsSave={handleInvestmentsUpdate}
        onInvestmentRemove={onInvestmentRemove}
        exportDataToGS={exportDataToGS}
        importFromExcelSheet={importFromExcelSheet}
        importFromGS={importFromGS}
        sendRequestDataEmail={onSendRequestDataEmail}
        periodicity={defaultKpiValues.periodicity}
      />
    </>
  );
};

CompanyScreenContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  onInvestmentsSave: PropTypes.func.isRequired,
  onInvestmentRemove: PropTypes.func.isRequired,
  setDownload: PropTypes.func.isRequired,
};

const mapState = createStructuredSelector({
  loading: getLoading,
});

const mapDispatch = (dispatch) => ({
  onInvestmentsSave: (companyId, investments) => {
    dispatch(
      PortfolioThunk.updateInvestments(
        { companyId, investments },
        {
          id: 'Company.Screen.onSuccessUpdateInvestment',
          defaultMessage: 'Investment successfully updated!',
        }
      )
    );
  },
  onInvestmentRemove: (id, portfolioId) => {
    dispatch(
      PortfolioThunk.deleteInvestment([{ id, portfolioId }], {
        id: 'Company.Screen.onSuccessDeleteInvestment',
        defaultMessage: 'Investment successfully deleted!',
      })
    );
  },
  setDownload: (payload) => dispatch(CommonAction.setDownload(payload)),
});

export default connect(mapState, mapDispatch)(CompanyScreenContainer);
