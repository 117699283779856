import React from 'react';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';

import { GrayCheckbox, useStyles } from './styles';
import { Props } from './type';

export const Checkbox = ({
  checked,
  name,
  onChange,
  label,
  wrapperStyle = {},
  labelStyle = {},
  style = {},
}: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} style={wrapperStyle}>
      {label && (
        <InputLabel className={classes.formLabel} style={labelStyle}>
          {label}
        </InputLabel>
      )}
      <GrayCheckbox
        color="default"
        name={name}
        checked={checked}
        onChange={onChange}
        style={style}
      />
    </Box>
  );
};
