/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, ChangeEvent } from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Input } from 'components/Common/Input';
import { LabelWithRadioGroup } from 'components/Common/Radio';
import { Button } from 'components/Common/Buttons';
import { AllowedOrganisationTypes } from 'common/commonContants';
import translations from 'translations';
import { AddOrgProps } from './type';
import useStyles from './styles';

const organisationTypes = [
  {
    id: new Date().getTime(),
    name: 'Investor',
    value: AllowedOrganisationTypes.FUND,
  },
  {
    id: new Date().getTime(),
    name: 'Company',
    value: AllowedOrganisationTypes.COMPANY,
  },
];

function AddOrganisationScreen({
  onCreateOrganisation,
}: AddOrgProps): JSX.Element {
  const classes = useStyles();
  const [organisationType, setOrganisationType] = useState(
    AllowedOrganisationTypes.FUND
  );
  const [organisationName, setOrganisationName] = useState('');
  const [errorEmptyName, setErrorEmptyName] = useState(false);

  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOrganisationType(event.target.value);
  };

  const onFormSubmitted = () => {
    if (!organisationName || !organisationName.trim().length) {
      setErrorEmptyName(true);
      return;
    }
    onCreateOrganisation({
      name: organisationName,
      type: organisationType,
      // setDefault: organisationType !== organisationTypes[1].value,
    });
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setErrorEmptyName(true);
    } else {
      setErrorEmptyName(false);
    }
    setOrganisationName(event.target.value);
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Typography
          component="h6"
          variant="h6"
          color="textPrimary"
          gutterBottom
          paragraph
        >
          <FormattedMessage {...translations.Organization.Add.AddOrg} />
        </Typography>
      </Grid>
      <Grid container className={classes.formWrapper} direction="column">
        <Grid container item xs={12} sm={8} lg={4} md={6}>
          <Input
            variant="outlined"
            wrapperClassName={classes.inputWidth}
            fullWidth
            label={<FormattedMessage {...translations.Common.common.name} />}
            id="organisation-name-input"
            value={organisationName}
            error={errorEmptyName}
            errorText={
              errorEmptyName ? 'Please enter name for organisation.' : ''
            }
            onChange={handleNameChange}
          />
          <LabelWithRadioGroup
            wrapperClassName={classes.inputWidth}
            label={<FormattedMessage {...translations.Common.common.type} />}
            name="organisationType.radio"
            radios={organisationTypes}
            value={organisationType}
            onChange={handleTypeChange}
          />
          <div className={classes.btnContainer}>
            <Button
              shape="round"
              typeColor="common"
              size="medium"
              color="primary"
              className={classes.addButton}
              disabled={errorEmptyName}
              onClick={() => onFormSubmitted()}
              style={{ marginLeft: '8px' }}
            >
              ADD
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

AddOrganisationScreen.propTypes = {
  onCreateOrganisation: PropTypes.func,
};

AddOrganisationScreen.defaultProps = {
  onCreateOrganisation: null,
};

export { AddOrganisationScreen };
