import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    minHeight: '5rem',
    paddingLeft: '40px',
    marginBottom: '38px',
  },
  typographySubtiltesStyle: {
    paddingTop: '20px',
  },
  sectionTitles: {
    color: '#000',
    fontWeight: '300',
    letterSpacing: 0.2,
  },
  strongText: {
    fontWeight: '500',
    textTransform: 'capitalize',
  },
}));
