import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { AlertState } from 'types';
import { ConfigReducer, ConfigState } from './reducer';

export const rootSelector = (state: RootState): ConfigState => state.config;

export const getLocale = (state: ConfigReducer): string => state.locale;

export const selectLocale = createSelector(
  rootSelector,
  getLocale,
  (res1, res2) => res2
);

export const getAlert = (state: ConfigReducer): AlertState => state.alert;

export const selectAlert = createSelector(rootSelector, getAlert);

export const getLoading = (state: ConfigReducer): boolean => state.loading;

export const selectLoading = createSelector(rootSelector, getLoading);
