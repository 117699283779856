export const VIEW_CONTENT_TYPE = [
  {
    name: 'Anyone',
    detail: 'Shared with any user\nwith access to this company\npage',
  },
  {
    name: 'Organisation',
    detail: 'Include content\nonly shared within your\norganisation',
  },
  {
    name: 'Personal',
    detail: 'Include your personal\ncontent',
  },
];
/* eslint-disable import/prefer-default-export */

export const COMPANY_CONTACT_TYPE = {
  CONTACT: 'contact',
  INVESTOR: 'investor',
};

export const CONTACT_PLACEHOLDERS = {
  '[[company.name]]': 'Name of receving company',
  '[[contact.first_name]]': `Company contact's first name`,
  '[[contact.last_name]]': `Comapny contact's last name`,
  '[[user.first_name]]': 'First name of logged in user',
  '[[user.last_name]]': 'last name of logged in user',
  '[[user.telephone]]': 'Phone number of logged in user',
  '[[investment_manager.first_name]]': `investment manager's first name`,
  '[[investment_manager.last_name]]': `investment manager's last name`,
  '[[keytrack.email]]': `use keytrack's email`,
  '[[keytrack.phone-EU]]': `use keytrack's EU-Phone Number`,
  '[[keytrack.phone-US]]': `use keytrack's US-Phone Number`,
};

export const EXCEL_FILE_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const PDF_FILE_TYPE = 'application/pdf';

export const companyScreenOptions = [
  'Header',
  'Summary',
  'KPIs',
  'Recent Updates',
  'Comment',
  'Investment',
  'Reports',
  'Visualize',
  'Profile',
];
