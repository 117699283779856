import React from 'react';
import Modal from '@material-ui/core/Modal';
import { useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';
import { Alert, AlertTitle } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import { getModalStyle } from './helpers';
import messages from './messages';

export default function AlertPopup({ open, handleToggleModal }) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const intl = useIntl();

  return (
    <div>
      <Modal
        onClose={handleToggleModal}
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box style={modalStyle} className={classes.paperModal}>
          <Alert severity="info">
            <AlertTitle>{intl.formatMessage(messages.info)}</AlertTitle>
            <strong>{intl.formatMessage(messages.atLeastOneCompany)}</strong>
          </Alert>
        </Box>
      </Modal>
    </div>
  );
}

AlertPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleToggleModal: PropTypes.func.isRequired,
};
