import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton } from '@material-ui/core';
import { Backup } from '@material-ui/icons';
import { useStyle } from './styles';

const FileInputField = ({ fileName, fileChangeHandler }) => {
  const uploadRef = useRef();
  const classes = useStyle();
  return (
    <div className={classes.fileInputWrapper}>
      <input
        type="file"
        multiple="multiple"
        style={{ display: 'none' }}
        ref={uploadRef}
        onChange={fileChangeHandler}
      />
      <Typography>{fileName}</Typography>
      <IconButton
        classes={{
          root: classes.uploadButtonRoot,
        }}
        aria-label="upload"
        onClick={() => uploadRef.current && uploadRef.current.click()}
      >
        <Backup />
      </IconButton>
    </div>
  );
};

FileInputField.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileChangeHandler: PropTypes.func.isRequired,
};

export default FileInputField;
