/* eslint-disable import/prefer-default-export */
/* eslint-disable operator-linebreak */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import Icon from '@mdi/react';
import { mdiPercent, mdiCurrencyGbp } from '@mdi/js';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import AttachMoney from '@material-ui/icons/AttachMoney';
import EuroIcon from '@material-ui/icons/Euro';

import NumberFormatCustom from '../../../common/NumberFormatCustom';
import messages from './messages';
import { deNormalizeInvolvement } from '../../../common/normalizeInvolvement';
import {
  LookupCurrencyOptions,
  LookupExitedStatusOptions,
  LookupInvolvement,
} from '../../../common/commonContants';
import { useStyles } from './styles';

const currencyFormat = (currency) => ({
  style: 'currency',
  currency,
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const InvestmentTable = ({
  investments: { items = [] },
  onDeleteInvestments,
  onUpdateInvestment,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [investmentData, setInvestmentData] = React.useState([]);
  const dataRef = React.useRef(investmentData);

  const textFieldIcon = {
    USD: <AttachMoney className={classes.icon} />,
    EUR: <EuroIcon className={classes.icon} />,
    GBP: <Icon path={mdiCurrencyGbp} size={0.7} />,
  };

  const deleteInvestmentData = (investments, id) => {
    const newData = investments.filter((data) => data.id !== id);
    setInvestmentData(JSON.parse(JSON.stringify(newData)));
  };

  const handleOnDeleteInvestments = async (id) => {
    const allItems = JSON.parse(JSON.stringify(dataRef.current));
    try {
      deleteInvestmentData(allItems, id);
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          onDeleteInvestments([id]).then(resolve).catch(reject);
        }, 200);
      });
    } catch (error) {
      setInvestmentData(JSON.parse(JSON.stringify(allItems)));
    }
  };

  const columns = [
    {
      title: messages.name.defaultMessage,
      field: 'name',
      editable: 'never',
      defaultSort: 'asc',
      customSort: (a, b) => (a.name < b.name ? -1 : 1),
    },
    {
      title: 'Currency',
      field: 'investmentCurrency',
      lookup: LookupCurrencyOptions,
      editable: 'onUpdate',
    },
    {
      title: 'Investment Amount',
      field: 'investedAmount',
      editable: 'onUpdate',
      render: (rowData) => (
        <div>
          {intl.formatNumber(rowData.investedAmount, {
            ...currencyFormat(rowData.investmentCurrency),
          })}
        </div>
      ),
      // eslint-disable-next-line react/prop-types
      editComponent: ({ rowData: { investmentCurrency }, value, onChange }) => (
        <TextField
          id="outlined-basic"
          size="small"
          variant="standard"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          InputProps={{
            inputComponent: NumberFormatCustom,
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  edge="start"
                  disabled
                  classes={{ root: classes.iconButton }}
                >
                  {textFieldIcon[investmentCurrency]}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    {
      title: 'Reserves',
      field: 'reserved',
      type: 'currency',
      render: (rowData) => (
        <div>
          {intl.formatNumber(rowData.reserved, {
            ...currencyFormat(rowData.investmentCurrency),
          })}
        </div>
      ),
      // eslint-disable-next-line react/prop-types
      editComponent: ({ rowData: { investmentCurrency }, value, onChange }) => (
        <TextField
          id="outlined-basic"
          size="small"
          variant="standard"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          InputProps={{
            inputComponent: NumberFormatCustom,
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  edge="start"
                  disabled
                  classes={{ root: classes.iconButton }}
                >
                  {textFieldIcon[investmentCurrency]}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    {
      title: 'Investment Value',
      field: 'investedValue',
      editable: 'onUpdate',
      render: (rowData) => (
        <div>
          {intl.formatNumber(rowData.investedValue, {
            ...currencyFormat(rowData.investmentCurrency),
          })}
        </div>
      ),
      // eslint-disable-next-line react/prop-types
      editComponent: ({ rowData: { investmentCurrency }, value, onChange }) => (
        <TextField
          id="outlined-basic"
          size="small"
          variant="standard"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          InputProps={{
            inputComponent: NumberFormatCustom,
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  edge="start"
                  disabled
                  classes={{ root: classes.iconButton }}
                >
                  {textFieldIcon[investmentCurrency]}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    {
      title: 'Ownership',
      field: 'ownership',
      editable: 'onUpdate',
      render: (rowData) => (
        <div>
          {
            // eslint-disable-next-line no-restricted-globals
            isNaN(rowData.ownership)
              ? rowData.ownership
              : intl.formatNumber(rowData.ownership, {
                  style: 'percent',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
          }
        </div>
      ),
      // eslint-disable-next-line react/prop-types
      editComponent: ({ value, onChange }) => (
        <TextField
          id="outlined-basic"
          size="small"
          variant="standard"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          InputProps={{
            inputComponent: NumberFormatCustom,
            inputProps: {
              thousandSeparator: false,
            },
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  edge="start"
                  disabled
                  classes={{ root: classes.iconButton }}
                >
                  <Icon path={mdiPercent} size={0.7} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    {
      title: 'Involvement',
      field: 'involvement',
      editable: 'onUpdate',
      lookup: LookupInvolvement(intl, messages),
    },
    {
      title: 'Exit Status',
      field: 'exitStatus',
      editable: 'onUpdate',
      lookup: LookupExitedStatusOptions,
    },
  ];

  React.useEffect(() => {
    const investmentItems = items.map((item) => ({
      id: item.id,
      companyId: item.company.id,
      investedAmount: item.totalInvestments || 0,
      investedValue: item.investedAmount.amount || 0,
      investmentCurrency: item.investedAmount.currency,
      involvement: item.involvement,
      name: item.name,
      reserved: item.reserved,
      ownership: item.ownership || 0,
      exitStatus: item.exitStatus,
    }));
    setInvestmentData(investmentItems);
    dataRef.current = investmentItems;
  }, [JSON.stringify(items)]);

  return (
    <Paper elevation={0} variant="outlined">
      <MaterialTable
        columns={columns}
        data={investmentData}
        title=""
        options={{
          sorting: true,
          selection: true,
          search: false,
          emptyRowsWhenPaging: false,
          cellStyle: {
            textAlign: 'left',
          },
          headerStyle: {
            textAlign: 'left',
          },
          // addRowPosition: 'first',
          actionsColumnIndex: -1,
          grouping: false,
          draggable: false,
          debounceInterval: 0,
        }}
        actions={[
          {
            icon: 'delete',
            tooltip: 'Delete Contacts',
            onClick: (event, rowData) => {
              if (Array.isArray(rowData) && rowData.length) {
                onDeleteInvestments(
                  rowData.filter((item) => item.id).map((item) => item.id)
                );
              }
            },
          },
        ]}
        editable={{
          isDeletable: (rowData) => Boolean(rowData.id),
          onRowDelete: (oldData) => handleOnDeleteInvestments(oldData.id),
          // new Promise((resolve, reject) => {
          //   setTimeout(async () => {
          //     await ;
          //     resolve();
          //   }, 1000);
          // }),
          onRowUpdate: (oldData) =>
            new Promise((resolve) => {
              setTimeout(async () => {
                // eslint-disable-next-line no-lone-blocks
                {
                  // eslint-disable-next-line no-param-reassign
                  oldData.involvement = deNormalizeInvolvement(
                    oldData.involvement,
                    intl,
                    messages
                  );
                  await onUpdateInvestment(oldData);
                }
                resolve();
              }, 1000);
            }),
        }}
      />
    </Paper>
  );
};

export { InvestmentTable };

InvestmentTable.propTypes = {
  investments: PropTypes.object,
  onDeleteInvestments: PropTypes.func,
  onUpdateInvestment: PropTypes.func,
};

InvestmentTable.defaultProps = {
  investments: {},
  onDeleteInvestments: async () => {},
  onUpdateInvestment: async () => {},
};
