import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Sidebar from 'components/Common/Sidebar';
import { AllowedOrganisationTypes } from 'common/commonContants';
import { selectSelectedOrganization } from 'containers/Organisation';
import { Organization } from 'types';

const SidebarContainer = ({
  sidebarStatus,
  onChangeSidebarStatus,
  // defaultOrganisation,
  trialStatus,
}: any): JSX.Element => {
  const selectedOrg = useSelector(selectSelectedOrganization);
  const [
    selectedOrganization,
    setSelectedOrganization,
  ] = useState<Organization | null>(null);

  useEffect(() => {
    if (selectedOrg) {
      setSelectedOrganization(JSON.parse(JSON.stringify(selectedOrg)));
    }
  }, [selectedOrg]);

  return (
    <Sidebar
      sidebarStatus={
        selectedOrganization?.type !== AllowedOrganisationTypes.COMPANY &&
        sidebarStatus
      }
      onChangeSidebarStatus={onChangeSidebarStatus}
      selectedOrganisation={selectedOrganization || {}}
      companies={selectedOrganization ? selectedOrganization.companies : []}
      trialStatus={trialStatus}
    />
  );
};

SidebarContainer.propTypes = {
  sidebarStatus: PropTypes.bool,
  onChangeSidebarStatus: PropTypes.func,
  defaultOrganisation: PropTypes.object,
  trialStatus: PropTypes.string,
};

SidebarContainer.defaultProps = {
  sidebarStatus: null,
  onChangeSidebarStatus: null,
  defaultOrganisation: {},
  trialStatus: '',
};

export default SidebarContainer;
