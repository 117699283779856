import React from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useIntl } from 'react-intl';
import translations from 'translations';
import PropTypes from 'prop-types';
import { ContactSelector } from 'components/Common/Selector';
import FormHelperText from '@material-ui/core/FormHelperText';
import { normalizeContacts } from 'common/normalizeContactName';
import messages from './messages';
import { useStyles } from './styles';

export default function MailContactRow({
  data,
  onChangeName,
  useContact,
  contactsData,
  isBulkRequest,
}) {
  const classes = useStyles();
  const intl = useIntl();
  const handleOnChange = (contacts) => {
    onChangeName(contacts);
  };

  const renderField = (contacts) => {
    if (isBulkRequest) {
      return Array.isArray(contacts) && contacts.length
        ? `All${
            contacts.includes(0)
              ? `${contacts.length > 1 ? ' ' : ' company '}contacts`
              : ''
          }${contacts.length > 1 ? ' and' : ''} ${
            contacts.includes(1)
              ? ` investment${contacts.length > 1 ? '' : ' manager'}`
              : ''
          }`
        : '';
    }
    if (!Array.isArray(contacts) || !contacts.length) {
      return '';
    }
    return normalizeContacts(contacts, 1, true);
  };

  return (
    <Box
      style={{
        alignItems: 'flex-start',
      }}
      className={classes.flexRow}
    >
      <Box flexBasis="50%" paddingRight=" 15px">
        <Typography
          variant="h5"
          color="primary"
          gutterBottom
          className={classes.sectionSubtitles}
        >
          {useContact !== 'second'
            ? intl.formatMessage(messages.to)
            : intl.formatMessage(messages.cc)}
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.subjectText} ${classes.inputHeight}`}
        >
          {renderField(data.contact)}
        </Typography>
        {Array.isArray(data.contact) &&
          !data.contact.length &&
          useContact !== 'second' && (
            <FormHelperText
              style={{
                marginLeft: 14,
              }}
              error
            >
              Recipent cannot be empty
            </FormHelperText>
          )}
      </Box>
      {/* {!isBulkRequest && ( */}
      <Box flexBasis="50%" paddingLeft=" 15px">
        <Typography
          variant="h5"
          color="primary"
          gutterBottom
          className={classes.sectionSubtitles}
        >
          {intl.formatMessage(messages.recipients)}
        </Typography>
        <ContactSelector
          handleChange={handleOnChange}
          contacts={contactsData}
          isContact={useContact !== 'second'}
          placeholder={intl.formatMessage(
            translations.Common.common.noOptionSelected
          )}
          bulkSelect={isBulkRequest}
          fullWidth
        />
      </Box>
      {/* )} */}
    </Box>
  );
}

MailContactRow.propTypes = {
  data: PropTypes.object.isRequired,
  onChangeName: PropTypes.func.isRequired,
  contactsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  useContact: PropTypes.string.isRequired,
  isBulkRequest: PropTypes.bool.isRequired,
};
