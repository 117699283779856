import { defineMessages } from 'react-intl';

export default defineMessages({
  userAndAccess: {
    id: 'TabPanel.userAndAccess',
    defaultMessage: 'User & Access',
  },
  organisationName: {
    id: 'TabPanel.organisationName',
    defaultMessage: 'Organisation Name:',
  },
  organisationType: {
    id: 'TabPanel.organisationType',
    defaultMessage: 'Organisation Type:',
  },
});
