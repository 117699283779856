/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import {
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import translations from 'translations';
import { Button } from 'components/Common/Buttons';
import { AllowedOrganisationTypes } from 'common/commonContants';
import { useStyles } from './styles';

const userTypes = [
  { value: AllowedOrganisationTypes.FUND, text: 'Investor' },
  { value: AllowedOrganisationTypes.COMPANY, text: 'Company' },
];
interface OnboardingStepZeroProps {
  setCurrentStep(num: number): void;
  setComponentsData(obj: object): void;
}

const OnboardingStepZero = ({
  setCurrentStep,
  setComponentsData,
}: OnboardingStepZeroProps): JSX.Element => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [userType, setUserType] = useState('');

  const gotoStep = (): void => {
    setComponentsData({ userType });
    if (userType === 'company') {
      setComponentsData((state: object) => ({
        ...state,
        type: userType,
      }));
      setCurrentStep(3);
    } else {
      setCurrentStep(1);
    }
  };

  return (
    <Grid container className={classes.stepWrapper}>
      <Grid item xs={12} className={classes.gridWrapper}>
        <Grid item xs={12} className={classes.gridWrapper}>
          <Typography
            // className={classes.subtitle}
            variant="subtitle2"
            color="textPrimary"
            gutterBottom
          >
            {`${formatMessage(
              translations.Onboarding.onBoardingDialog0Instruction
            )}`}
          </Typography>
        </Grid>
        <RadioGroup
          name="subscription"
          value={userType}
          onChange={(e, value): void => {
            setUserType(value);
          }}
          className={classes.formControlCol}
        >
          {userTypes.map((item, index) => (
            <div key={`userType${index}`}>
              <FormControlLabel
                value={item.value}
                control={
                  <Radio
                    classes={{ root: classes.radio, checked: classes.checked }}
                  />
                }
                label={<Typography variant="subtitle2">{item.text}</Typography>}
              />
            </div>
          ))}
        </RadioGroup>
      </Grid>
      <Grid item xs={12} className={classes.gridWrapper}>
        <Button
          variant="contained"
          color="primary"
          typeColor="common"
          onClick={gotoStep}
          id="step-zero-next-button"
        >
          {`${formatMessage(translations.Onboarding.onBoardingNext)}`}
        </Button>
      </Grid>
    </Grid>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { OnboardingStepZero };
