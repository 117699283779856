import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

import translations from 'translations';
import {
  CALLING_FROM,
  CreatePortfolio,
} from 'components/Portfolio/CreatePortfolio';
import { Organization } from 'types';
import { useStyles } from './styles';
import { ComponentsData } from './type';

interface OnboardingStepTwoProps {
  componentsData: ComponentsData;
  setCurrentStep(number: number): void;
  onCreatePortfolio(obj: object): Promise<void>;
  selectedOrganisation: Organization;
}

const OnboardingStepTwo = ({
  setCurrentStep,
  onCreatePortfolio,
  selectedOrganisation,
}: OnboardingStepTwoProps): JSX.Element => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Grid item xs={12} className={classes.stepWrapper}>
      <Grid item xs={12} className={classes.gridWrapper}>
        <Typography
          // className={classes.subtitle}
          variant="subtitle2"
          color="textPrimary"
          gutterBottom
        >
          {`${formatMessage(
            translations.Onboarding.onBoardingDialog2Instruction
          )}`}
        </Typography>
      </Grid>

      <CreatePortfolio
        selectedOrganisation={selectedOrganisation}
        setCurrentStep={setCurrentStep}
        onCreatePortfolio={onCreatePortfolio}
        from={CALLING_FROM.PORTFOLIO_ONBOARDING}
      />
    </Grid>
  );
};

export { OnboardingStepTwo };
