/* eslint-disable consistent-return */
import React, { memo, useState, useEffect, useRef } from 'react';
import { Paper } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { CompanyTop } from 'components/Company/Reuseable/CompanyTop';
import translations from 'translations';
import { KPISectionProps } from 'types';
import { EnhancedTable } from 'components/Table';
import { KPI_ACTIONS } from 'constants/index';
import { useStyles } from './styles';
import { KpiSelect } from './KpiSelect';

interface KpiAction {
  CREATED_UPDATED: string;
  DELETED: string;
  SORTED: string;
}

export const Kpi = memo(
  ({
    data,
    isLoading,
    getPeriodicity,
    periodicity,
    role,
    onBulkOperation,
  }: KPISectionProps): JSX.Element => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const [enableEdit, setEnableEdit] = useState(false);
    const [kpiData, setKpiData] = useState<any[]>([]);
    const kpiCloneRef = useRef<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [didChanged, setDidChanged] = useState(false);

    const handleCleanUpState = () => {
      kpiCloneRef.current = JSON.parse(JSON.stringify(data));
      setDidChanged(false);
    };

    const handleDiscard = () => {
      if (!enableEdit) return;
      setEnableEdit(false);
      kpiCloneRef.current = JSON.parse(JSON.stringify(data));
      setKpiData(JSON.parse(JSON.stringify(data)));
      handleCleanUpState();
    };

    const handleSetAction = (updates: any, action: keyof KpiAction) => {
      const rowIndex = kpiCloneRef.current.findIndex(
        (val) => val?.meta?.id === updates?.meta?.id
      );
      if (rowIndex < 0) {
        kpiCloneRef.current.push({
          ...updates,
          action,
        });
        setDidChanged(true);
        return;
      }
      const operativeData = kpiCloneRef.current[rowIndex];
      const newClone = [...kpiCloneRef.current];
      if (action === 'DELETED') {
        const updated = {
          ...operativeData,
          ...updates,
          action,
          tableData: { id: rowIndex },
        };
        newClone[rowIndex] = updated;
      } else {
        const findedData = kpiData[rowIndex];
        const changed = JSON.stringify(findedData) !== JSON.stringify(updates);
        const updated = {
          ...operativeData,
          ...updates,
          action,
          tableData: { id: rowIndex },
        };
        if (!changed) {
          delete updated.action;
        }
        newClone[rowIndex] = updated;
      }
      kpiCloneRef.current = newClone;
      setDidChanged(newClone.some((val) => val?.action in KPI_ACTIONS));
      return newClone;
    };

    const handleUpdate = async (updates: any) => {
      handleSetAction(updates, 'CREATED_UPDATED');
    };

    const handleDelete = (id: string) => {
      const item = kpiCloneRef.current.find((val) => val?.meta?.id === id);
      if (!item) return;
      handleSetAction(item, 'DELETED');
    };

    const handleSave = async () => {
      setLoading(true);
      const apiData = kpiCloneRef.current.filter(
        (val) => val.action in KPI_ACTIONS && val.meta?.metricName
      );
      const hasUpdated = await onBulkOperation(apiData);
      if (hasUpdated) {
        setDidChanged(false);
        setEnableEdit(false);
      }
      setLoading(false);
    };

    const handleActionClick = () => {
      if (!enableEdit) {
        setEnableEdit(true);
        return;
      }
      setEnableEdit(false);
      if (didChanged) handleSave();
    };

    useEffect(() => {
      const propsData = JSON.stringify(data);
      const localData = JSON.stringify(kpiData);
      if (propsData === localData) return;
      if (enableEdit) {
        setEnableEdit(false);
      }
      setKpiData(JSON.parse(propsData));
      handleCleanUpState();
    }, [JSON.stringify(data)]);

    useEffect(() => {
      if (isLoading !== loading) setLoading(isLoading);
    }, [isLoading]);

    return (
      <div className={classes.root}>
        <CompanyTop
          didChange={didChanged}
          handleDiscard={handleDiscard}
          setFeedResponse={(res) => setEnableEdit(res)}
          enableEdit={enableEdit}
          title={formatMessage(translations.Common.kpis.title)}
          handleActionClick={handleActionClick}
          role={role}
        />
        <Paper className={classes.paper} elevation={4}>
          <KpiSelect
            onChangePeriodicity={getPeriodicity}
            isLoading={loading || enableEdit}
            periodicity={periodicity}
          />
          <EnhancedTable
            data={kpiData}
            onUpdate={handleUpdate}
            periodicity={periodicity}
            enableEdit={!loading && enableEdit}
            onDelete={handleDelete}
          />
        </Paper>
      </div>
    );
  }
);
