import * as React from 'react';

const KeytrackLogoWhite = (): JSX.Element => {
  return (
    <svg
      width="120px"
      height="30px"
      viewBox="0 0 15000 3600"
      preserveAspectRatio="xMidYMid"
      fillRule="evenodd"
      strokeWidth={28.222}
      strokeLinejoin="round"
    >
      <defs className="prefix__ClipPathGroup">
        <clipPath id="prefix__a" clipPathUnits="userSpaceOnUse">
          <path d="M0 0h15000v3600H0z" />
        </clipPath>
      </defs>
      <g className="prefix__SlideGroup">
        <g className="prefix__Slide" clipPath="url(#prefix__a)">
          <g className="prefix__Page">
            <g className="prefix__Graphic">
              <path
                className="prefix__BoundingBox"
                fill="none"
                d="M37 32h3078v3129H37z"
              />
              <defs>
                <clipPath id="prefix__b" clipPathUnits="userSpaceOnUse">
                  <path d="M37 32h3077v3128H37V32z" />
                </clipPath>
              </defs>
              <defs>
                <clipPath id="prefix__c" clipPathUnits="userSpaceOnUse">
                  <path d="M37 32h3077v3128H37V32z" />
                </clipPath>
              </defs>
              <g clipPath="url(#prefix__c)">
                <path
                  fill="#FFF"
                  d="M760 1287H201c-69 0-124 72-124 161v1414c0 89 55 161 124 161h559c68 0 123-72 123-161V1448c0-89-55-161-123-161zM1855 117h-559c-68 0-124 72-124 161v2584c0 89 56 161 124 161h559c68 0 124-72 124-161V278c0-89-56-161-124-161zM2950 775h-559c-68 0-123 72-123 161v1926c0 89 55 161 123 161h559c69 0 124-72 124-161V936c0-89-55-161-124-161z"
                />
                <text className="prefix__TextShape">
                  <tspan
                    className="prefix__TextParagraph"
                    fontFamily="'Helvetica Neue'"
                    fontSize={240}
                    fontWeight={700}
                  >
                    <tspan className="prefix__TextPosition" x={37} y={3829}>
                      <tspan fill="#730000">Created by Arafat Uddin</tspan>
                    </tspan>
                  </tspan>
                </text>
                <text className="prefix__TextShape">
                  <tspan
                    className="prefix__TextParagraph"
                    fontFamily="'Helvetica Neue'"
                    fontSize={240}
                    fontWeight={700}
                  >
                    <tspan className="prefix__TextPosition" x={37} y={4070}>
                      <tspan fill="#730000">from the Noun Project</tspan>
                    </tspan>
                  </tspan>
                </text>
              </g>
            </g>
            <g className="prefix__com_sun_star_drawing_TextShape">
              <path
                className="prefix__BoundingBox"
                fill="none"
                d="M3357 261h14644v3840H3357z"
              />
              <text className="prefix__TextShape">
                <tspan
                  className="prefix__TextParagraph"
                  fontFamily="Helvetica Neue"
                  fontSize={2540}
                  fontWeight={700}
                >
                  <tspan className="prefix__TextPosition" x={3607} y={2863}>
                    <tspan fill="#FAFAFA">Keytrack</tspan>
                  </tspan>
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default KeytrackLogoWhite;
