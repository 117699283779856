/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';

import {
  Paper,
  Grid,
  Typography,
  CssBaseline,
  Box,
  FormControl,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { CompanySelector } from 'components/Common/Selector';

import { GridTitle } from '../Common/Grid';
import { useStyles } from './styles';
import messages from './messages';
import LocationChart from './LocationChart';
import EmployeeChart from './EmployeeChart';
import FoundingChart from './FoundingChart';
import IndustryChart from './IndustryChart';
import CompanyCount from '../Company/Reuseable/CompanyCount';

const StatisticsScreen = ({
  setGroup,
  locationData,
  locationDataLength,
  employeeData,
  employeeDataLength,
  FoundingData,
  FoundingDataLength,
  industryData,
  industryDataLength,
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const handleChangeCampanies = (selectedCompanies) => {
    setGroup(selectedCompanies.map((company) => company.id));
  };

  return (
    <>
      <CssBaseline />
      <div className={`${classes.root}`}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                component="h3"
                variant="h4"
                color="primary"
                gutterBottom
              >
                {`${formatMessage(messages.statisticsScreenTitle)}`}
              </Typography>
            </Box>
            <Box display="flex">
              <Box width={200} marginRight={4}>
                <FormControl fullWidth className={classes.formControl}>
                  <CompanySelector onChangeHandler={handleChangeCampanies} />
                </FormControl>
              </Box>
            </Box>
          </Grid>

          {/* ROW ONE */}
          <Grid item xs={12} className={classes.grid}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <GridTitle
                textId={messages.statisticsScreenLocationsTitle.id}
                text={messages.statisticsScreenLocationsTitle.defaultMessage}
              />
              <Paper className={`${classes.paper} ${classes.customScroll}`}>
                <LocationChart data={locationData} />
              </Paper>
              <CompanyCount count={locationDataLength} />
            </Grid>

            <Grid item xs={12} sm={12} md={5} lg={5}>
              <GridTitle
                textId={messages.statisticsScreenEmployeesClusterTitle.id}
                text={
                  messages.statisticsScreenEmployeesClusterTitle.defaultMessage
                }
              />
              <Paper className={`${classes.paper} ${classes.customScroll}`}>
                <EmployeeChart data={employeeData} />
              </Paper>
              <CompanyCount count={employeeDataLength} />
            </Grid>
          </Grid>
          {/* END OF ROW ONE */}

          {/* ROW TWO */}
          <Grid item xs={12} className={classes.grid}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <GridTitle
                textId={messages.statisticsScreenFoundingTitle.id}
                text={messages.statisticsScreenFoundingTitle.defaultMessage}
              />
              <Paper className={`${classes.paper} ${classes.customScroll}`}>
                <FoundingChart data={FoundingData} />
              </Paper>
              <CompanyCount count={FoundingDataLength} />
            </Grid>

            <Grid item xs={12} sm={12} md={5} lg={5}>
              <GridTitle
                textId={messages.statisticsScreenIndustriesTitle.id}
                text={messages.statisticsScreenIndustriesTitle.defaultMessage}
              />
              <Paper className={`${classes.paper} ${classes.customScroll}`}>
                <IndustryChart data={industryData} />
              </Paper>
              <CompanyCount count={industryDataLength} />
            </Grid>
          </Grid>
          {/* END OF ROW TWO */}
        </Grid>
      </div>
    </>
  );
};

export default StatisticsScreen;
