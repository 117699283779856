import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import { InputLabel, FormControlLabel } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { userRoles } from '../../../user/userConstants';
import messages from './messages';
import { useStyles } from './style';

const RoleRadio = ({ role, handleOnChange }) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={classes.radioWrapper}>
      <InputLabel className={classes.formLabel}>
        {intl.formatMessage(messages.role)}
      </InputLabel>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="role"
          name="role"
          value={role}
          onChange={(e) => handleOnChange('role', e.target.value)}
        >
          <FormControlLabel
            value={userRoles.ADMIN}
            control={
              <Radio
                classes={{
                  colorSecondary: classes.radioColor,
                }}
              />
            }
            label={intl.formatMessage(messages.roleAdmin)}
          />
          <FormControlLabel
            value={userRoles.EDITOR}
            control={
              <Radio
                classes={{
                  colorSecondary: classes.radioColor,
                }}
              />
            }
            label={intl.formatMessage(messages.roleEditor)}
          />
          <FormControlLabel
            value={userRoles.VIEWER}
            control={
              <Radio
                classes={{
                  colorSecondary: classes.radioColor,
                }}
              />
            }
            label={intl.formatMessage(messages.roleViewer)}
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

RoleRadio.propTypes = {
  role: PropTypes.string,
  handleOnChange: PropTypes.func,
};

RoleRadio.defaultProps = {
  role: '',
  handleOnChange: () => {},
};

export default RoleRadio;
