import tableMessages from '../CompanyApiTab/messages';
import messages from './messages';

export const columns = [
  {
    title: messages.name.defaultMessage,
    field: 'name',
    editable: 'onUpdate',
    defaultSort: 'asc',
    customSort: (a, b) => (a.name < b.name ? -1 : 1),
  },
  {
    title: tableMessages.contactAtCompanyLabel.defaultMessage,
    field: 'CompanyContact',
    editable: 'never',
    sorting: false,
    cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'break-spaces' },
  },
  {
    title: tableMessages.investmentManagerLabel.defaultMessage,
    field: 'InvestMgr',
    editable: 'never',
    sorting: false,
  },
];
