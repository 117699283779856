import { gql } from 'apollo-boost';

import {
  ORGANISATION_PAYLOAD,
  PORTFOLIO_PAYLOAD,
  RUNWAY_PAYLOAD,
  COMPANY_PAYLOAD_SHORT,
  COMPANY_PAYLOAD_LONG,
} from './payloads';

export const COMPANY_CONTACT_PAYLOAD = `
  contacts {
    email
  }
`;

export const COMPANY_REPORT_PAYLOAD = `
  report{
    name
    url
  }
`;

export const COMPANY_INVESTMENT_PAYLOAD = `
  involvement
  ownership
  investedAmount{
    amount
    currency
  }
`;

export const COMPANIES_BY_USER_PAYLOAD = `
  ${COMPANY_CONTACT_PAYLOAD}
  ${COMPANY_REPORT_PAYLOAD}
  runwayData {
    ${RUNWAY_PAYLOAD}
  }
  id
  name
  contactEmail
  investments {
    ${COMPANY_INVESTMENT_PAYLOAD}
  } 
`;

export const GET_API_COMPANIES_QUERY = gql`
  query($searchTerm: String!) {
    crunchbaseCompanies(searchTerm: $searchTerm) {
      uuid
      value
      logoUrl
      shortDescription
      logoUrl
      permalink
    }
  }
`;

export const GET_COMPANIES_FROM_SHARED = gql`
  query($organisationId: ID!) {
    getCompanyFromShared(organisationId: $organisationId) {
      id
      name
      orgKey
    }
  }
`;

export const DISCARD_SHARED_REQUEST = gql`
  mutation($organisationIdOrigin: ID!, $organisationIdTarget: ID!) {
    discardSharedRequest(
      organisationIdOrigin: $organisationIdOrigin
      organisationIdTarget: $organisationIdTarget
    )
  }
`;

export const GET_COMPANY_OTHER_DATA = gql`
  query($apiCompanyId: ID!, $logoUrl: String) {
    crunchbaseCompaniesById(apiCompanyId: $apiCompanyId, logoUrl: $logoUrl) {
      location
      website
      image
    }
  }
`;

export const CREATE_COMPANY_MUTATION = gql`
  mutation(
    $organisationId: ID!
    $name: String!
    $file: Upload
    $shortDescription: String
    $investments: [CreateInvestmentInput]
    $apiCompanyId: ID
  ) {
    createCompany(
      input: {
        organisationId: $organisationId
        name: $name
        file: $file
        shortDescription: $shortDescription
        investments: $investments
        apiCompanyId: $apiCompanyId
      }
    ) {
      id
      name
      shortDescription
      logoUrl
      ${COMPANIES_BY_USER_PAYLOAD}
      ${COMPANY_PAYLOAD_SHORT}
      organisation {
        ${ORGANISATION_PAYLOAD}
        portfolios {
          totalCount
          items {
            ${PORTFOLIO_PAYLOAD}
          }
        }
        companies {
          ${COMPANY_PAYLOAD_SHORT}
        }
      }
    }
  }
`;

export const GET_COMPANIES_BY_USER_QUERY = gql`
  query companiesByUser {
    companiesByUser {
      ${COMPANIES_BY_USER_PAYLOAD}
    }
  }
`;

export const GET_DETAILED_COMPANIES_BY_USER_QUERY = gql`
  query companiesByUser {
    companiesByUser {
      ${COMPANY_PAYLOAD_LONG}
    }
  }
`;

// todo, it is now in company container
// remove it when not neeed
export const UPDATE_CONTACTS_MUTATION = gql`
  mutation($input: UpdateContactInput!) {
    updateContact(input: $input)
  }
`;

export const EXPORT_TO_GOOGLE_SHEET = gql`
  mutation($input: ExportToGoogleSheetPayload!) {
    exportToGoogleSheet(input: $input)
  }
`;

export const IMPORT_FROM_EXCEL_SHEET = gql`
  mutation($input: ImportFromExcel!) {
    importFromExcel(input: $input) {
      status
      filename
    }
  }
`;

export const IMPORT_FROM_GOOGLE_SHEET = gql`
  mutation($input: ImportFromGoogleSheet!) {
    importFromGoogleSheet(input: $input) {
      status
      filename
    }
  }
`;

export const BULK_EXPORT_GS = gql`
  mutation($input: blukExportGSPayload!) {
    blukExportGS(input: $input)
  }
`;

export const BULK_IMPORT_EXCEL = gql`
  mutation($input: BulkImportFromExcel!) {
    bulkImportExcel(input: $input) {
      filename
      status
    }
  }
`;

export const BULK_IMPORT_GS = gql`
  mutation($input: BulkImportFromGS!) {
    bulkImportGS(input: $input) {
      status
      filename
    }
  }
`;

// todo, remove it when not needed
export const DELETE_CONTACTS_MUTATION = gql`
  mutation($contactIds: [ID!]!) {
    deleteContacts(contactIds: $contactIds)
  }
`;

export const GET_COMPANY_QUERY = gql`
  query($companyId: ID!) {
    company(companyId: $companyId) {
      id
      name
      shortDescription
      logoUrl
      longDescription
      team
      location
      websiteUrl
      industryId
      canEdit
      legalName
      foundedDate
      employees
      contactEmail
      companyOrganisation
      organisation {
        id
        name
      }
      contacts {
        id
        email
        firstName
        lastName
        contactType
      }
    }
  }
`;

export const GET_COMPANIES_QUERY = gql`
  query($companiesId: [ID!]!) {
    companies(companiesId: $companiesId) {
    ${COMPANY_PAYLOAD_LONG}
    }
  }
`;

export const GET_COMPANY_KPIS_QUERY = gql`
  query($companyId: ID!) {
    kpiValuesByCompany(companyId: $companyId) {
      id
      value
      kpi {
        name
      }
    }
  }
`;

export const GET_COMPANY_FEEDS_QUERY = gql`
  query($companyId: ID!) {
    feedByCompany(companyId: $companyId) {
      id
      title
      description
      date
      view
    }
  }
`;
// todo, remove it when not needed
export const UPDATE_COMPANY_MUTATION = gql`
  mutation($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      id
      name
      shortDescription
      team
      location
      websiteUrl
      industryId
      legalName
      employees
      foundedDate
      contactEmail
      url
    }
  }
`;

export const UPDATE_COMPANY_KPIS_MUTATION = gql`
  mutation($input: UpdateKpiValueBulkInput!) {
    updateKpiValues(input: $input)
  }
`;

export const CREATE_COMPANY_REPORT = gql`
  mutation($input: CreateReportInput!) {
    createReport(input: $input) {
      name
      url
    }
  }
`;

export const UPDATE_COMPANY_REPORT = gql`
  mutation($input: [UpdateReportInput]!) {
    updateReport(input: $input)
  }
`;

export const DELETE_REPORT_MUTATION = gql`
  mutation($input: DeleteReportInput!) {
    deleteReport(input: $input)
  }
`;

export const GET_COMPANY_COMMENT = gql`
  query($input: CommentInput!) {
    commentByCompany(input: $input) {
      id
      companyId
      description
      view
      date
      userName
      orgName
    }
  }
`;

export const UPDATE_COMPANY_COMMENT = gql`
  mutation($input: UpdateCommentInputPayload!) {
    updateComment(input: $input)
  }
`;

export const DELETE_COMPANY_COMMENT = gql`
  mutation($input: DeleteCommentInput!) {
    deleteComment(input: $input)
  }
`;

export const GET_COMPANY_SUMMARY = gql`
  query($companyId: ID!, $organisationId: ID!) {
    summaryByCompany(companyId: $companyId, organisationId: $organisationId) {
      status
      totalInvestments
    }
  }
`;

export const GET_COMPANY_REPORTS = gql`
  query($companyId: ID!) {
    companyReports(companyId: $companyId) {
      name
      url
      period
      view
      id
      userId
    }
  }
`;

export const UPDATE_COMPANY_FEED_MUTATION = gql`
  mutation($input: UpdateFeedBulkInput!) {
    updateFeed(input: $input)
  }
`;

export const DELETE_COMPANY_FEED_MUTATION = gql`
  mutation($feedId: ID!) {
    deleteFeed(feedId: $feedId)
  }
`;

export const GET_COMPANY_VISUAL_DATA = gql`
  query($input: CompanyVisualInput!) {
    getCompanyVisual(input: $input) {
      x
      y
    }
  }
`;

export const GET_MATRIC_META = gql`
  query($companyId: ID!, $type: String) {
    getCompanyMetricNames(companyId: $companyId, type: $type)
  }
`;

export const DELETE_KPI_MUTATION = gql`
  mutation($kpiValueId: ID!) {
    deleteKpiValue(kpiValueId: $kpiValueId)
  }
`;

export const GET_METRIC_NAMES_GROUPED_BY_CATEGORY = gql`
  query($companyIds: [String]!, $periodicity: String!, $date: String!) {
    getMetricNamesGroupedByCategory(
      companyIds: $companyIds
      periodicity: $periodicity
      date: $date
    ) {
      name
      metrics
    }
  }
`;

export const UPDATE_COMPANY_METRIC_MUTATION = gql`
  mutation updateValue(
    $companyMetricId: ID!
    $metricValue: String
    $metricUnit: String!
    $sortingNumber: Int!
    $metricName: String!
  ) {
    updateMetricValue(
      companyMetricId: $companyMetricId
      metricValue: $metricValue
      metricUnit: $metricUnit
      sortingNumber: $sortingNumber
      metricName: $metricName
    ) {
      id
      companyId
      periodicity
      metricName
      metricCategory
      metricUnit
      metricValue
      sortingNumber
      hidden
      date
    }
  }
`;

export const GET_SINGLE_METRICS = gql`
  query($companyIds: [String]!, $columns: [SingleMetricColumn]!) {
    getSingleMetrics(companyIds: $companyIds, columns: $columns) {
      name
      metrics {
        date
        name
        metricValue
        metricName
        metricUnit
      }
    }
  }
`;

export const GET_COMPANY_NAME_WITHOUT_METRICS = gql`
  query($companyIds: [String]!, $columns: [SingleMetricColumn]!) {
    getSingleMetrics(companyIds: $companyIds, columns: $columns) {
      name
    }
  }
`;

// todo, remove if not needed.
export const GET_COMPANY_METRICS_QUERY = gql`
  query($companyId: ID!, $periodicity: String, $metricCategory: String) {
    getAllCompanyMetrics(
      companyId: $companyId
      periodicity: $periodicity
      metricCategory: $metricCategory
    ) {
      id
      companyId
      metricName
      periodicity
      metricCategory
      metricUnit
      metricValue
      sortingNumber
      hidden
      date
    }
  }
`;

export const CREATE_COMPANY_METRICS_MUTATION = gql`
  mutation addCompanyMetrics($input: CreateCompanyMetricsInput) {
    createCompanyMetrics(input: $input) {
      id
      companyId
      periodicity
      metricName
      metricCategory
      metricUnit
      metricValue
      sortingNumber
      hidden
      date
    }
  }
`;

export const DELETE_COMPANY_METRICS_MUTATION = gql`
  mutation deleteCompanyMetrics($metricName: String!) {
    deleteMetric(metricName: $metricName)
  }
`;
