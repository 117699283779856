import organization from 'containers/Organisation/ducks/reducer';
import config from 'containers/Config/ducks/reducer';
import { company, portfolio, alert, loading, utils } from 'reducers';

// only organization is configured as per the new redux patter after refactor
// while rewamp module do rewamp the respective redux module according to organization redux patter
const rootReducer = {
  organization,
  company,
  portfolio,
  alert,
  loading,
  config,
  utils,
};

export default rootReducer;
