import { AppDispatch, AppThunk } from 'store';
import {
  GraphqlService,
  logActionError,
  handleGraphqlServerError,
} from 'utils/api';
import {
  GraphqlResponse,
  OnSuccessPayload,
  CreateUserPayload,
  User,
  DeleteContact,
} from 'types';
import {
  createUser as createUserAction,
  createUserSuccess,
  createUserFail,
  updateUser as updateUserAction,
  updateUserSuccess,
  updateUserFail,
  deleteUser as deleteUserAction,
  deleteUserSuccess,
  deleteUserFail,
  createContact as createContactAction,
  createContactSuccess,
  createContactFail,
  deleteContact as deleteContactAction,
  deleteContactSuccess,
  deleteContactFail,
} from 'containers/Organisation';
import { setAlert } from 'containers/Config';
import { UPDATE_CONTACTS_MUTATION } from 'containers/Company/ducks/query';
import { COMPANY_CONTACT_TYPES } from 'constants/index';
import {
  CREATE_USER,
  UPDATE_USER,
  DELETE_USERS,
  UPDATE_USER_ROLE,
  DELETE_CONTACTS_MUTATION,
} from './query';

const createUser = (
  payload: CreateUserPayload,
  onSuccess?: OnSuccessPayload
): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { resolve, reject, successMsg } = onSuccess || {};
    try {
      dispatch(createUserAction());
      const {
        organisationId,
        email,
        role,
        firstName,
        lastName,
        updateNameContionally = false,
      } = payload;
      const service = new GraphqlService();
      const {
        data: { createUser: createdUser },
      } = await service.mutation<GraphqlResponse<{ createUser: User }>>(
        CREATE_USER,
        {
          input: {
            organisationId,
            email,
            role,
            firstName,
            lastName,
            updateNameContionally,
          },
        }
      );
      dispatch(
        setAlert({
          message: successMsg || 'User create success',
          open: true,
          severity: 'success',
        })
      );
      const newUser = {
        ...createdUser,
        role: payload.role,
        organisationId,
      };
      dispatch(createUserSuccess(newUser));
      if (resolve) resolve(JSON.parse(JSON.stringify(newUser)));
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          severity: 'error',
          message: handleGraphqlServerError(error),
        })
      );
      dispatch(createUserFail());
      logActionError('log', error, 'createUser');
      if (reject) reject();
    }
  };
};

const updateUser = (
  payload: CreateUserPayload,
  onSuccess?: OnSuccessPayload
): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { resolve, reject, successMsg } = onSuccess || {};
    try {
      dispatch(updateUserAction());
      const {
        id = '',
        lastName = '',
        firstName = '',
        organisationId = '',
        updateNameContionally = false,
      } = payload;
      const service = new GraphqlService();
      const {
        data: { updateUser: updatedUser },
      } = await service.mutation<GraphqlResponse<{ updateUser: User }>>(
        UPDATE_USER,
        {
          input: {
            name: firstName,
            lastName,
            id,
            updateNameContionally,
          },
        }
      );
      dispatch(
        setAlert({
          message: successMsg || 'update user success',
          open: true,
          severity: 'success',
        })
      );
      const newUser = {
        id,
        firstName,
        lastName,
        organisationId,
        ...updatedUser,
      };
      dispatch(updateUserSuccess(newUser));
      if (resolve) resolve(JSON.parse(JSON.stringify(newUser)));
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          severity: 'error',
          message: handleGraphqlServerError(error),
        })
      );
      dispatch(updateUserFail());
      logActionError('log', error, 'updateUser');
      if (reject) reject();
    }
  };
};

const updateUserRole = (
  payload: CreateUserPayload,
  onSuccess?: OnSuccessPayload
): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { resolve, reject, successMsg } = onSuccess || {};
    try {
      dispatch(updateUserAction());
      const { id = '', organisationId = '', role } = payload;
      const service = new GraphqlService();
      await service.mutation(UPDATE_USER_ROLE, {
        input: {
          organisationId,
          userId: id,
          role,
        },
      });
      dispatch(
        setAlert({
          message: successMsg || 'update user role success',
          open: true,
          severity: 'success',
        })
      );
      const newUser = {
        id,
        organisationId,
        role,
      };
      dispatch(updateUserSuccess(newUser));
      if (resolve) resolve(JSON.parse(JSON.stringify(newUser)));
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          severity: 'error',
          message: handleGraphqlServerError(error),
        })
      );
      dispatch(updateUserFail());
      logActionError('log', error, 'updateUser');
      if (reject) reject();
    }
  };
};

const deleteUser = (
  payload: { userIds: Array<string>; organisationId: string },
  onSuccess?: OnSuccessPayload
): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { resolve, reject, successMsg } = onSuccess || {};
    try {
      dispatch(deleteUserAction());
      const { userIds, organisationId } = payload;
      const service = new GraphqlService();
      await service.mutation<boolean>(DELETE_USERS, {
        userIds,
        organisationId,
      });
      dispatch(deleteUserSuccess({ ids: userIds, organisationId }));
      dispatch(
        setAlert({
          message: successMsg || 'Delete create success',
          open: true,
          severity: 'success',
        })
      );
      if (resolve) resolve();
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          severity: 'error',
          message: handleGraphqlServerError(error),
        })
      );
      dispatch(deleteUserFail());
      logActionError('log', error, 'deleteUser');
      if (reject) reject();
    }
  };
};

const updateContact = (
  payload: {
    email?: string;
    firstName?: string;
    id?: string;
    lastName?: string;
    userId: string;
    contactType?: string;
    orgId: string;
  },
  onSuccess: OnSuccessPayload
) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { reject, resolve, successMsg } = onSuccess;
    const {
      id = null,
      orgId,
      userId = '',
      contactType = COMPANY_CONTACT_TYPES.CONTACT,
      email = '',
      firstName = '',
      lastName = '',
    } = payload;
    try {
      const service = new GraphqlService();
      dispatch(createContactAction());
      const {
        data: { updateContact: newId },
      } = await service.mutation<GraphqlResponse<{ updateContact: string }>>(
        UPDATE_CONTACTS_MUTATION,
        {
          input: {
            id,
            orgId,
            userId,
            contactType,
            companyId: '',
          },
        }
      );
      dispatch(
        createContactSuccess({
          contactType,
          id: newId,
          userId,
          organisationId: orgId,
          email,
          firstName,
          lastName,
        })
      );
      dispatch(
        setAlert({
          message: successMsg || 'Update contact success',
          open: true,
          severity: 'success',
        })
      );
      if (resolve) resolve();
    } catch (error) {
      logActionError('log', error, 'updateContact');
      dispatch(createContactFail());
      dispatch(
        setAlert({
          open: true,
          severity: 'error',
          message: handleGraphqlServerError(error),
        })
      );
      if (reject) reject();
    }
  };
};

const deleteContact = (payload: DeleteContact, onSuccess: OnSuccessPayload) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { reject, resolve, successMsg } = onSuccess;
    try {
      const { ids, organisationId } = payload;
      const service = new GraphqlService();
      dispatch(deleteContactAction());
      await service.mutation(DELETE_CONTACTS_MUTATION, {
        contactIds: ids,
      });
      dispatch(
        deleteContactSuccess({
          ids,
          organisationId,
        })
      );
      dispatch(
        setAlert({
          message: successMsg || 'Delete contact success',
          open: true,
          severity: 'success',
        })
      );
      if (resolve) resolve();
    } catch (error) {
      logActionError('log', error, 'deleteContact');
      dispatch(deleteContactFail());
      dispatch(
        setAlert({
          open: true,
          severity: 'error',
          message: handleGraphqlServerError(error),
        })
      );
      if (reject) reject();
    }
  };
};

const UserThunk = {
  createUser,
  updateUser,
  deleteUser,
  updateUserRole,
  updateContact,
  deleteContact,
};

export { UserThunk };
