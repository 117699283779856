import React, { useRef } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useIntl } from 'react-intl';

import translations from 'translations';
import { Button } from 'components/Common/Buttons';
import { CompanySearched } from 'components/Common/Company';
import { AllowedOrganisationTypes } from 'common/commonContants';
import { CreateCompanyPayload, Company } from 'types';
import { useStyles } from './styles';
import { CompanyData, ComponentsData } from './type';

interface OnboardingStepThreeProps {
  componentsData: ComponentsData;
  setCurrentStep(number: number): void;
  onCreateOrganisation(obj: object, markDefault: boolean): Promise<void>;
  onCreateCompany: (payload: CreateCompanyPayload) => Promise<Company>;
}

const OnboardingStepThree = ({
  componentsData,
  setCurrentStep,
  onCreateOrganisation,
  onCreateCompany,
}: OnboardingStepThreeProps): JSX.Element => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const getCompanySearchedSate = useRef<any>(null);

  // const [createCompany] = useMutation(CREATE_COMPANY_MUTATION, {
  //   onCompleted: () => setCurrentStep(4),
  // });

  // const onCreateCompany = async ({
  //   organisationId,
  //   name,
  //   shortDescription = null,
  //   websiteUrl = '',
  //   location = '',
  //   apiCompanyId,
  // }: any): Promise<void> => {
  //   await createCompany({
  //     variables: {
  //       organisationId,
  //       name,
  //       shortDescription,
  //       apiCompanyId,
  //       websiteUrl,
  //       location,
  //     },
  //   });
  // };

  const onAddCompany = async (
    companySearchedSate: CompanyData,
    organisationId: string
  ): Promise<void> => {
    const {
      id,
      companyName,
      shortDescription,
      websiteUrl,
      location,
    } = companySearchedSate;

    await onCreateCompany({
      name: companyName,
      organisationId,
      apiCompanyId: id,
      shortDescription,
      websiteUrl,
      location,
    });
  };

  const createOrganisation = async (
    companySearchedSate: CompanyData,
    markDefault: boolean
  ): Promise<void> => {
    const obj = {
      name: companySearchedSate.companyName,
      type: AllowedOrganisationTypes.COMPANY,
    };
    await onCreateOrganisation(obj, markDefault).then(() => {
      setCurrentStep(4);
    });
  };

  const handleAddCompany = (): void => {
    // // to get state from crunchbase component
    const companySearchedSate = getCompanySearchedSate.current();
    if (!companySearchedSate) {
      return;
    }
    if (componentsData.id) {
      // if type fund then organisation already created on step 1
      onAddCompany(companySearchedSate, componentsData.id);
    } else {
      createOrganisation(companySearchedSate, true);
    }
  };

  return (
    <>
      <Grid item xs={12} className={classes.gridWrapper}>
        {/* <Typography variant="subtitle2" color="textPrimary" gutterBottom>
          {componentsData.userType === 'fund'
            ? ''
            : `${formatMessage(translations.Onboarding.onBoardingDialog3Instruction1)}`}
        </Typography> */}
      </Grid>
      <Grid item xs={12} className={classes.gridWrapper}>
        <Paper elevation={0} variant="outlined">
          <Grid container className={classes.grid}>
            <CompanySearched
              showShared={false}
              getCompanySearchedSate={(callback) => {
                getCompanySearchedSate.current = callback;
              }}
              getSignal={() => {}}
              // from={INVALID_TEXT.INVESTMENT_COMPANY_TAB}
            />
            <Grid
              container
              item
              xs={6}
              className={`${classes.gridWrapper} ${classes.actionWrapper}`}
              justify="flex-start"
            >
              <Button
                typeColor="common"
                variant="contained"
                color="primary"
                onClick={handleAddCompany}
                style={{ marginRight: 20 }}
              >
                {`${formatMessage(translations.Onboarding.onBoardingAddLabel)}`}
              </Button>
              {componentsData.userType === AllowedOrganisationTypes.FUND && (
                <Button
                  variant="contained"
                  color="primary"
                  className={`${classes.actionBtn} ${classes.grayBtn}`}
                  onClick={(): void => {
                    setCurrentStep(4);
                  }}
                >
                  {`${formatMessage(translations.Onboarding.onBoardingNext)}`}
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { OnboardingStepThree };
