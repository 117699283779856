import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Snackbar, Paper, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Close, CheckCircle } from '@material-ui/icons';
import { useStyle } from './styles';
import Progress from './Progress';
import { Colors } from '../../../../constants';
import { getDownloadStatus, setDownloads } from '../../../../reducers';

export const DownloadProgressModal = ({
  elevation,
  closeButtonProps,
  iconSize,
  progressVariant,
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { progressOpen: open, progress, message } = useSelector(
    getDownloadStatus
  );

  const setClose = () =>
    dispatch(
      setDownloads({
        progressOpen: false,
      })
    );
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
    >
      <Paper className={classes.root} elevation={elevation}>
        {progress < 100 ? (
          <Progress progress={progress} progressVariant={progressVariant} />
        ) : (
          <CheckCircle
            style={{ color: Colors.STATUS_OK }}
            fontSize={iconSize}
          />
        )}
        <Typography variant="body1" className={classes.text}>
          {message}
        </Typography>
        <IconButton {...closeButtonProps} onClick={setClose}>
          <Close fontSize={iconSize} />
        </IconButton>
      </Paper>
    </Snackbar>
  );
};

DownloadProgressModal.propTypes = {
  elevation: PropTypes.number,
  closeButtonProps: PropTypes.shape({
    size: PropTypes.string,
    color: PropTypes.string,
  }),
  iconSize: PropTypes.string,
  progressVariant: PropTypes.string,
  progress: PropTypes.number,
};

DownloadProgressModal.defaultProps = {
  elevation: 3,
  closeButtonProps: {
    size: 'small',
    color: 'inherit',
  },
  iconSize: 'small',
  progressVariant: 'static',
  progress: 0,
};
