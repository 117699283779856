/* eslint-disable import/prefer-default-export */

export const METRIC_CURRENCY_DICT = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  Number: '',
};

export const Periodicity = {
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  ANNUAL: 'Annual',
  HALF_YEAR: 'Half-Year',
  YTD: 'Quarterly YTD',
};

export const KPI_DATA = [
  {
    id: 1,
    metricName: 'Company',
    periodicity: 'Company',
  },
  {
    id: 2,
    metricName: 'Metric',
    periodicity: Periodicity.MONTHLY,
    metricCategory: '',
    date: 'Latest',
  },
  {
    id: 3,
    metricName: 'Metric',
    periodicity: Periodicity.MONTHLY,
    metricCategory: '',
    date: 'Latest',
  },
  {
    id: 4,
    metricName: 'Metric',
    periodicity: Periodicity.MONTHLY,
    metricCategory: '',
    date: 'Latest',
  },
  {
    id: 5,
    metricName: 'Metric',
    periodicity: Periodicity.MONTHLY,
    metricCategory: '',
    date: 'Latest',
  },
  {
    id: 6,
    metricName: 'Metric',
    periodicity: Periodicity.MONTHLY,
    metricCategory: '',
    date: 'Latest',
  },
  {
    id: 7,
    metricName: 'Metric',
    periodicity: Periodicity.MONTHLY,
    metricCategory: '',
    date: 'Latest',
  },
];

export const KPI_VALUES = [
  {
    id: 0,
    value: null,
  },
  {
    id: 1,
    value: null,
  },
  {
    id: 2,
    value: null,
  },
  {
    id: 3,
    value: null,
  },
  {
    id: 4,
    value: null,
  },
  {
    id: 5,
    value: null,
  },
  {
    id: 6,
    value: null,
  },
];

export const METRIC_NUMBER_ADORNMENT = { ...METRIC_CURRENCY_DICT, '%': '%' };

export const METRIC_NUMBER_UNTI = {
  USD: 'USD',
  EUR: 'EUR',
  GBP: 'GBP',
  '%': '%',
  Number: 'Number',
};

export const METRIC_UNIT = {
  ...METRIC_NUMBER_UNTI,
  Date: 'Date',
  Text: 'Text',
};

export const METRIC_CATEGORY = {
  KPI: 'KPI',
};

export const KPI_ACTIONS = {
  CREATED_UPDATED: 'CREATED_UPDATED',
  DELETED: 'DELETED',
  SORTED: 'SORTED',
};
export const initialValue = {
  periodicity: '',
  data: [],
};
