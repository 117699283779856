import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  editBtn: {
    fontWeight: 'bold',
  },
  paper: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  titleBox: {
    width: '100%',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  scrollable: {
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#7b7a7a',
    },
    maxWidth: '75%',
    width: '90%',
    height: '190px',
    overflowY: 'scroll',
    overfloxX: 'hidden',
  },
  text: {
    fontSize: 15,
    margin: 0,
    fontWeight: 'bold',
  },
  flexBox: {
    borderBottom: '1px solid #ccc',
    paddingBottom: theme.spacing(0.9),
    marginBottom: theme.spacing(0.9),
  },
  iconBtn: {
    padding: theme.spacing(1.3),
  },
  deleteBtn: {
    fontWeight: 'bold',
    color: '#ff0020',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    marginRight: theme.spacing(1.2),
  },
  root: {
    marginBottom: '40px',
    marginLeft: '20px',
    marginRight: '20px',
  },
  gridTitle: {
    marginTop: 0,
  },
  modalOverlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.48) !important',
  },
  modal: {
    overflowY: 'scroll',
  },
  title: {
    fontWeight: '400',
    letterSpacing: 0.2,
  },
  paperModal: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
  },
  modalContentWrapper: {
    display: 'flex',
    flexGrow: 1,
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    width: 150,
  },
  addButton: {
    borderRadius: 20,
    width: '100%',
  },
  labelStyle: {
    minWidth: '30%',
  },
  modelContainer: {
    width: 580,
    outline: 'none',
  },
}));

// eslint-disable-next-line import/prefer-default-export
export { useStyles };
