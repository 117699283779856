import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Snackbar, Typography } from '@material-ui/core';
import Alert from 'components/Common/UI/Alert';
import { setAlert } from 'containers/Config';
import { selectAlert } from 'containers/Config/ducks/selector';
import { Message } from 'types';

export default (): JSX.Element => {
  const dispatch = useDispatch();
  const alert = useSelector(selectAlert);
  const { formatMessage } = useIntl();
  const handleAlertClose = (event: any, reason: string): void => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(
      setAlert({
        open: false,
        message: '',
        severity: 'success',
      })
    );
  };
  return (
    <Snackbar
      open={alert.open}
      autoHideDuration={4000}
      onClose={handleAlertClose}
    >
      <Alert onClose={handleAlertClose} severity={alert.severity}>
        <Typography variant="body1">
          {typeof alert.message === 'string'
            ? alert.message
            : React.isValidElement(alert.message)
            ? alert.message
            : formatMessage(alert.message as Message)}
        </Typography>
      </Alert>
    </Snackbar>
  );
};
