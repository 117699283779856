import { createSelector } from '@reduxjs/toolkit';
import {
  Organization,
  Subscriptions,
  SubscriptionLimitResponse,
  OrganizationSliceState,
} from 'types';
import {
  selectSelectedOrganization,
  selectorOrganizationRoot,
} from 'containers/Organisation/ducks';

const selectSubscriptions = (state: Organization | null): Subscriptions => {
  if (!state) return [];
  return state.subscriptions;
};

const getAccessLimit = (
  state: OrganizationSliceState
): SubscriptionLimitResponse => {
  return state.accessLimit;
};

export const selectSubscriptionsByOrg = createSelector(
  selectSelectedOrganization,
  selectSubscriptions
);

export const selectSubscriptionAccessLimit = createSelector(
  selectorOrganizationRoot,
  getAccessLimit
);
