/* eslint-disable global-require */
import * as Sentry from '@sentry/browser';
import config from './config';

if (config.sentry.dsn) {
  Sentry.init({ dsn: config.sentry.dsn });
}

//  Intl
// the these docs if it won't be enough:
// https://github.com/formatjs/react-intl/blob/280a541faa85f620f915fc60c1eb72a69c2ecf18/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
if (!global.Intl) {
  require('intl');
  require('intl/locale-data/jsonp/en.js');
}
