import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import messages from './messages';
import { useStyles } from './styles';
// import PortfolioSettingsContainer from '../../../containers/Portfolio/PortfolioSettingsContainer';
import PortfolioSummary from '../Reusable/PortfolioSummary';
import PortfolioInvestments from '../Reusable/PortfolioInvestments';

const PortfolioScreen = ({ portfolio, companies }) => {
  const {
    name,
    size = { amount: 0, currency: 'USD' },
    investments = { totalCount: 0, totalInvested: 0 },
    creationYear,
  } = portfolio;
  const intl = useIntl();
  const classes = useStyles();

  const uniqueCompanies = (investments.items || []).reduce((uniques, curr) => {
    if (!uniques.some((u) => u.company.id === curr.company.id)) {
      uniques.push(curr);
    }
    return uniques;
  }, []);
  const totalInvested = (investments.items || []).reduce(
    (acc, cur) => acc + cur.totalInvestments * +(cur.exitStatus === 'current'),
    0
  );
  return (
    <div className={classes.root}>
      <Grid item xs={12} className={classes.marginBottom}>
        <Typography
          component="h3"
          variant="h4"
          color="textSecondary"
          gutterBottom
          className={classes.title}
        >
          {name || intl.formatMessage(messages.campanyTitle)}
        </Typography>
      </Grid>

      <PortfolioSummary
        vintageYear={creationYear}
        targetAllocation={size.amount}
        numberOfInvestments={uniqueCompanies.length}
        totalInvested={totalInvested || investments.totalInvested}
        currency={size.currency}
      />

      <PortfolioInvestments companies={companies} />

      {/* <PortfolioSettingsContainer /> */}
    </div>
  );
};

PortfolioScreen.propTypes = {
  portfolio: PropTypes.object,
  companies: PropTypes.arrayOf(PropTypes.object),
  selectedOrganisation: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
};

PortfolioScreen.defaultProps = {
  portfolio: {},
  companies: [],
};

export default PortfolioScreen;
