import { defineMessages } from 'react-intl';

export default defineMessages({
  userSttingsTitle: {
    id: 'UserSttings.title',
    defaultMessage: 'User Sttings',
  },
  generalTitle: {
    id: 'UserSttings.General.title',
    defaultMessage: 'General',
  },
  deleteTitle: {
    id: 'UserSttings.Delete.title',
    defaultMessage: 'Delete Account',
  },
  onSuccessLeaveOrganization: {
    id: 'UserSttings.onSuccessLeaveOrganization',
    defaultMessage: 'Left organization successfully',
  },
  onError: {
    id: 'UserSttings.onErrorLeaveOrganization',
    defaultMessage: 'Something went wrong',
  },
});
