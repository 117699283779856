import React from 'react';
import PropTypes from 'prop-types';
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import ClearIcon from '@material-ui/icons/Clear';
import { v4 as uuid } from 'uuid';
import { ArrowDropUp } from '@material-ui/icons';

const themeSelector = createMuiTheme({
  palette: {
    primary: {
      main: '#3F51B5',
    },
    secondary: {
      main: 'rgba(0,0,0,0.56)',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  trow: {
    '& td': {
      borderBottom: 'none',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  label: {
    '& svg': {
      width: '22px',
      height: '22px',
    },
  },
  labelDisabled: {
    pointerEvents: 'none',
  },
  metricButtonDisabled: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
  draftText: {
    color: themeSelector.palette.primary.main,
    cursor: 'pointer',
  },
  reducedPadding: {
    paddingLeft: '7px',
    paddingRight: '7px',
  },
  runway: {
    borderRadius: '16px',
    color: '#fff',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  stickyActionsColumn: {
    '& table:first-child': {
      '& tr': {
        '& td:first-child, th:first-child': {
          backgroundColor: '#fff',
          position: 'sticky',
          left: 0,
          zIndex: 1,
        },
        '& th:first-child': {
          zIndex: 2,
        },
      },
    },
  },
}));

function CompaniesTableHead(props) {
  const {
    classes,
    columns,
    handleOpenModal,
    handleDeleteColumn,
    open,
    rowCount,
  } = props;

  const headCells = columns;

  return (
    <TableHead>
      <TableRow>
        {headCells.map(({ periodicity, date, metricName, id }) => {
          const columnStyles =
            metricName === 'Company' ? classes.labelDisabled : classes.label;
          const annualDate =
            periodicity === 'Annual' &&
            `${date ? ` (${date.slice(3)})` : date}`;
          const periodicityType =
            periodicity === 'Period'
              ? periodicity
              : `${periodicity}${annualDate || ` (${date})`}`;
          return (
            <TableCell
              style={metricName === 'Company' && open ? { zIndex: 0 } : {}}
              key={id}
              align="left"
            >
              {metricName === 'Company' ? (
                ''
              ) : (
                <>
                  <TableSortLabel
                    className={classes.label}
                    IconComponent={ArrowDropUp}
                    onClick={() => handleOpenModal('Periodicity', id)}
                  >
                    {periodicityType}
                  </TableSortLabel>
                  <IconButton
                    size="small"
                    disabled={columns.length === 2}
                    onClick={() => handleDeleteColumn(id)}
                  >
                    <ClearIcon style={{ fontSize: 'small' }} />
                  </IconButton>
                </>
              )}
              <br />
              <TableSortLabel
                className={
                  !rowCount ? classes.metricButtonDisabled : columnStyles
                }
                IconComponent={ArrowDropUp}
                disabled={!rowCount}
                onClick={() => handleOpenModal('Metrics', id, periodicity)}
              >
                {metricName}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

CompaniesTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleOpenModal: PropTypes.func,
  handleDeleteColumn: PropTypes.func,
};

CompaniesTableHead.defaultProps = {
  handleOpenModal: () => {},
  handleDeleteColumn: () => {},
};

export default function CompaniesTable({
  data,
  columns,
  handleOpenModal,
  deleteKpiColumn,
  open,
}) {
  const classes = useStyles();
  const handleDeleteColumn = (id) => {
    deleteKpiColumn(id);
  };

  return (
    <div className={`${classes.stickyActionsColumn} ${classes.root}`}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <CompaniesTableHead
              classes={classes}
              rowCount={data.length}
              columns={columns}
              handleOpenModal={handleOpenModal}
              handleDeleteColumn={handleDeleteColumn}
              open={open}
            />
            <TableBody>
              {data.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    className={classes.trow}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.name}
                  >
                    {Object.entries(row).map((elem, i) => {
                      const ele = elem[1];

                      if (i === 0) {
                        return (
                          <TableCell key={labelId} scope="row">
                            <ThemeProvider theme={themeSelector}>
                              {ele}
                            </ThemeProvider>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={uuid()} align="left">
                          {ele}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

CompaniesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleOpenModal: PropTypes.func,
  deleteKpiColumn: PropTypes.func,
};

CompaniesTable.defaultProps = {
  handleOpenModal: () => {},
  deleteKpiColumn: () => {},
};
