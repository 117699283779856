/* eslint-disable import/prefer-default-export */
export const Colors = {
  REPORTS_TOTAL: '#3f51b5',
  ALERT: '#ff0020',
  STATUS_OK: '#00be62',
  WARNING: '#f78a1d',
  TEXT: 'rgba(0,0,0,0.6)',
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  BLUE_DODGER: '#0088FE',
  GREEN_CARIBBEAN: '#00C49F',
  SUPERNOVA: '#FFBB28',
  CORAL: '#FF8042',
  HOVER: '#039289',
  BACKGROUND_DISALBED: '#0000001f',
  DISALBED: '#00000042',
  DARK_GREY: '#363629ad',
  DELETE: '#ff0c3e',
  DELETE_DARK: '#c3032a',
  BORDER_GREY: '#DCDCDC',
  PRIMARY: '#2CA4AB',
  DEFAULT: '#3f51b5',
  DEFAULT_HOVER: 'rgba(25, 118, 210, 0.04)',
  TEA_GREEN: '#BEEEB2',
  DUTCH_WHITE: '#EEE1B2',
  TEXT_DEFAULT: '#363636',
};
