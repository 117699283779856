import { gql } from 'apollo-boost';

import {
  COMPANY_PAYLOAD_SHORT,
  INVESTMENT_COUNT_PAYLOAD,
  ORGANISATION_PAYLOAD,
  PORTFOLIO_PAYLOAD,
  INVESTMENT_PAYLOAD,
  CONTACT_PAYLOAD,
  RUNWAY_PAYLOAD,
  COMPANIED_PAYLOAD,
  COMPANY_PAYLOAD_LONG,
  USER_PAYLOAD,
  ORGANISATION_PAYLOAD_SHORT,
} from './payloads';

export const GET_COMPANIES_BY_ORGANISATION_QUERY = gql`
  query getCompaniesByOrganisation($organisationId: ID!) {
    companiesByOrganisation(organisationId: $organisationId) {
      id
      name
      report {
        name
      }
      runwayData {
        ${RUNWAY_PAYLOAD}
      }
      investments {
        ${INVESTMENT_PAYLOAD}
      }
      contacts {
        ${CONTACT_PAYLOAD}
      }
    }
  }
`;

export const GET_DETAILED_COMPANIES_BY_ORGANISATION_QUERY = gql`
query($organisationId: ID!) {
  organisation(organisationId: $organisationId) {
    id
    name
    portfolios {
      totalCount
      items {
        ${PORTFOLIO_PAYLOAD}
      }
    }
    companies {
      ${COMPANY_PAYLOAD_LONG}
      portfolio {
        id
        name
      }
    }
  }
}
`;

export const GET_SELECTED_ORGANISATION_QUERY = gql`
  query getSelectedOrganisation($organisationId: ID!) {
    organisation(organisationId: $organisationId) {
      ${COMPANIED_PAYLOAD}
    }
  }
`;

export const GET_SELECTED_ORGANISATION_WITHOUT_PORTFOLIO_QUERY = gql`
  query getSelectedOrganisation($organisationId: ID!) {
    organisation(organisationId: $organisationId) {
      ${ORGANISATION_PAYLOAD}
      companies {
        ${COMPANY_PAYLOAD_SHORT}
      }
    }
  }
`;

export const GET_ORGANISATION_QUERY = gql`
  query($organisationId: ID!) {
    organisation(organisationId: $organisationId) {
      ${ORGANISATION_PAYLOAD}
      portfolios {
        totalCount
        url
        items {
          ${PORTFOLIO_PAYLOAD}
        }
      }
    }
  }
`;
export const MAKE_ORGANISATION_DEFAULT = gql`
  mutation($organisationId: ID!) {
    makeOrganisationDefault(organisationId: $organisationId) {
      id
    }
  }
`;

export const GET_SELECTED_ORGANISATION_WITH_INVESTMENT_QUERY = gql`
  query getSelectedOrganisation($organisationId: ID!) {
    organisation(organisationId: $organisationId) {
      ${ORGANISATION_PAYLOAD}
      companies {
        runwayData {
          ${RUNWAY_PAYLOAD}
        }
        ${COMPANY_PAYLOAD_SHORT}
        report{
          name
          url
        }
        contacts{
          ${CONTACT_PAYLOAD}
        }
        investments {
          ${INVESTMENT_PAYLOAD}
          portfolio{
            ${PORTFOLIO_PAYLOAD}
          }
        }
      }
      portfolios {
        totalCount
        url
        items {
          ${PORTFOLIO_PAYLOAD}
          investments {
            ${INVESTMENT_COUNT_PAYLOAD}
            items {
              ${INVESTMENT_PAYLOAD}
              company {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_ORGANISATION = gql`
  mutation($id: ID!, $isActive: Boolean, $currency: String, $name: String) {
    updateOrganisation(
      id: $id
      isActive: $isActive
      currency: $currency
      name: $name
    )
  }
`;

export const LEAVE_ORGANIZATION = gql`
  mutation($input: LeaveOrganisationInput!) {
    leaveOrganisation(input: $input)
  }
`;

export const USER_SHARED_ORGANIZATION = gql`
  query {
    getUserSharedOrganization {
      id
      name
      role
      isDefault
    }
  }
`;

export const SHARE_ORGANISATION = gql`
  mutation(
    $role: ORGANISATION_ROLE!
    $originOrganisationKey: String!
    $targetOrganisationId: ID!
  ) {
    shareOrganisation(
      input: {
        role: $role
        originOrganisationKey: $originOrganisationKey
        targetOrganisationId: $targetOrganisationId
      }
    )
  }
`;

export const UNSHARE_ORGANISATIONS = gql`
  mutation($originOrganisationIds: [ID!]!, $targetOrganisationId: ID!) {
    unshareOrganisation(
      originOrganisationIds: $originOrganisationIds
      targetOrganisationId: $targetOrganisationId
    )
  }
`;

// todo, it is done in selected org query
// remove it when not needed by any component
export const GET_USERS_BY_ORGANISATION_ID = gql`
query($organisationId: ID!) {
  organisation(organisationId: $organisationId) {
    companies {
      id
      contacts{
            ${CONTACT_PAYLOAD}
          }
        }
      users {
        ${USER_PAYLOAD}
        role
      }
  }
}
`;

export const GET_SHARED_ORGANISATIONS = gql`
query($organisationId: ID!) {
  organisation(organisationId: $organisationId) {
    sharedOrganisations {
      ${ORGANISATION_PAYLOAD_SHORT}
    }
  }
}
`;

export const UPDATE_ORGANISATION_ROLE = gql`
  mutation($input: UpdateOrganisationRoleInput!) {
    updateOrganisationRole(input: $input)
  }
`;
