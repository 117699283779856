import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { InputProps } from '@material-ui/core';
import NumberFormatCustom from 'common/NumberFormatCustom';

import { METRIC_NUMBER_ADORNMENT } from 'constants/index';
import { Order, MetricUnitType } from 'types';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function getInputProps(unit?: keyof MetricUnitType): InputProps | undefined {
  if (!unit || unit === 'Text') return undefined;
  const obj: InputProps = {};
  obj.inputComponent = NumberFormatCustom as any;
  if (unit === 'Number') return obj;
  if (unit === 'Date') {
    obj.inputProps = {
      thousandSeparator: false,
      format: '##/##/####',
      placeholder: 'DD/MM/YYYY',
    };
    return obj;
  }
  obj.startAdornment = (
    <InputAdornment position="start">
      {METRIC_NUMBER_ADORNMENT[unit]}
    </InputAdornment>
  );
  if (unit === '%') {
    obj.inputProps = {
      thousandSeparator: false,
      placeholder: '25%',
    };
    return obj;
  }
  return obj;
}

export const TableUtils = {
  descendingComparator,
  stableSort,
  getComparator,
  getInputProps,
};
