import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

const BlockTitle = ({ title }) => {
  const classes = useStyles();
  return (
    <div className={classes.headers}>
      <Typography variant="h6" className={classes.header} gutterBottom>
        {title}
      </Typography>
    </div>
  );
};

BlockTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default BlockTitle;
