import React from 'react';
import { Button as CustomButton, useStyles } from './styles';
import { ButtonProps } from './type';

export const Button = ({
  children,
  shape = 'round',
  typeColor = 'common',
  style = {},
  disabled,
  onClick,
  id = 'button',
  className,
  variant = 'contained',
}: ButtonProps): JSX.Element => {
  const styles = useStyles();
  const classes = {
    shape: {
      square: styles.square,
      round: styles.round,
    },
    type: {
      default: styles.typeDefault,
      common: styles.typeCommon,
      delete: styles.typeDelete,
      primary: styles.typePrimary,
      gray: styles.typeGray,
    },
  };
  return (
    <CustomButton
      className={`${styles.root} ${classes.shape[shape]} ${classes.type[typeColor]} ${className}`}
      style={style}
      disabled={disabled}
      onClick={onClick}
      id={id}
      variant={variant}
    >
      {children}
    </CustomButton>
  );
};
