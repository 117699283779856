/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-fragments */
import React, { Fragment, useEffect, useState, memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import Profile from 'components/User/Profile';
import { withRouteProtection } from 'components/Common/Auth';
import HomeScreenContainer from 'containers/Unknown/HomeScreenContainer';
import ManageUsersContainer from 'containers/User/ManageUsersContainer';
import ManagePortfolioContainer from 'containers/Portfolio/ManagePortfolioContainer';
import PotfolioScreenContainer from 'containers/Portfolio/PotfolioScreenContainer';
import ManageCompanyContainer from 'containers/Company/ManageCompany';
import CompanyScreenContainer from 'containers/Company/CompanyScreenContainer';
import SettingsScreenContainer from 'containers/Common/SettingsScreenContainer';
import UserSettingsContainer from 'containers/UserSettings/UserSettingsContainer';
import OrganisationCreateFormContainer from 'containers/Organisation/AddOrganizationContainer';
import UpdatesScreenContainer from 'containers/Updates/UpdatesScreenContainer';
import StatisticsScreenContainer from 'containers/Statistics/StatisticsScreenContainer';
import RequestDataContainer from 'containers/RequestData/RequestDataContainer';
import { AllCompaniesScreenContainer } from 'containers/AllCompanies';
import { CashRunwayScreenContainer } from 'containers/CashRunway/CashRunway';
import { KpiPageScreenContainer } from 'containers/KpiPage/KpiPageScreenContainer';
import { ExportImport } from 'components/Company/Reuseable/ExportImport';
import ScrollToTop from './ScrollToTop';

// `component`s should have same values to reuse them in `Switch`
// details are here: https://github.com/ReactTraining/react-router/issues/4578#issuecomment-282081567

interface RoutesProps {
  mounted: boolean;
}

export default (): JSX.Element => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return <Routes mounted={mounted} />;
};

const Routes = memo(
  (props: RoutesProps): JSX.Element => {
    return (
      <Fragment>
        <ScrollToTop />
        <Switch>
          <Route
            path="/"
            component={withRouteProtection(HomeScreenContainer)}
            exact
          />
          <Route
            path="/profile"
            component={withRouteProtection(Profile)}
            exact
          />
          <Route
            path="/organisations/:id"
            component={withRouteProtection(ManageUsersContainer)}
            exact
          />
          <Route
            path="/organisations/:id/updates"
            component={withRouteProtection(UpdatesScreenContainer)}
            exact
          />
          <Route
            path="/organisations/:id/statistics"
            component={withRouteProtection(StatisticsScreenContainer)}
            exact
          />
          <Route
            path="/organisations/:id/users"
            component={withRouteProtection(ManageUsersContainer)}
            exact
          />
          <Route
            path="/organisations/:id/portfolios"
            component={withRouteProtection(ManagePortfolioContainer)}
            exact
          />
          <Route
            path="/organisations/:id/users/usersettings"
            component={withRouteProtection(UserSettingsContainer)}
            exact
          />
          <Route
            path="/organisations/:id/portfolios/:portfolioId"
            component={withRouteProtection(PotfolioScreenContainer)}
            exact
          />
          <Route
            path="/organisations/:id/companies"
            component={withRouteProtection(AllCompaniesScreenContainer)}
            exact
          />
          <Route
            path="/organisations/:id/companies/add"
            component={withRouteProtection(() => (
              <ManageCompanyContainer type="add" />
            ))}
            exact
          />
          <Route
            path="/organisations/:id/companies/manage"
            component={withRouteProtection(() => (
              <ManageCompanyContainer type="manage" />
            ))}
            exact
          />
          <Route
            path="/organisations/:id/companies/:companyId"
            component={withRouteProtection(CompanyScreenContainer)}
            exact
          />
          <Route
            path="/organisations/:id/request-data"
            component={withRouteProtection(RequestDataContainer)}
            exact
          />
          <Route
            path="/organisations/:id/settings"
            component={withRouteProtection(SettingsScreenContainer)}
            exact
          />
          <Route
            path="/organisations/:id/add"
            component={withRouteProtection(OrganisationCreateFormContainer)}
            exact
          />
          <Route
            path="/organisations/:id/cash-runway"
            component={withRouteProtection(CashRunwayScreenContainer)}
            exact
          />
          <Route
            path="/organisations/:id/kpi-page"
            component={withRouteProtection(KpiPageScreenContainer)}
            exact
          />
          <Route
            path="/organisations/:id/export-import"
            component={withRouteProtection(ExportImport)}
            exact
          />
        </Switch>
      </Fragment>
    );
  },
  (prev, next: RoutesProps) => next.mounted
);
