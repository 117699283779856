import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'constants/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  inlineRoot: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '220px',
  },
  topRoot: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  inlineLabel: {
    flexBasis: '35%',
  },
  inlineSelect: {
    flexBasis: '60%',
  },
  topLabel: {
    marginBottom: '20px',
  },
  selectOutLined: {
    padding: '10.5px 14px',
  },
  icon: {
    ...(theme.overrides && theme.overrides.MuiIcon
      ? theme.overrides.MuiIcon.fontSizeLarge
      : {}),
  },
  formLabel: {
    color: Colors.BLACK,
    marginRight: theme.spacing(3),
    display: 'flex',
  },
}));
