/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

import { setAlert } from 'containers/Config';
import { Kpi } from 'components/Kpi';
import {
  METRIC_CATEGORY,
  Periodicity,
  KPI_ACTIONS,
  METRIC_UNIT,
} from 'constants/index';
import { ParamsType, GraphqlResponse, KPI } from 'types';
import {
  periodicityResponse,
  getPeriodictyDate,
  getPeriodictyDateByKey,
} from 'utils/index';
import { GraphqlService, handleGraphqlServerError } from 'utils/api';
import translations from 'translations';
import {
  UPDATE_COMPANY_KPI_MUTATION,
  GET_COMPANY_METRICS_QUERY,
} from './ducks';

const KpiPage = ({
  company,
  periodicity: propsPeriodicity,
  role,
}: {
  company: any;
  role: string;
  periodicity: string;
}): JSX.Element => {
  const service = new GraphqlService();
  const params = useParams<ParamsType>();
  const dispatch = useDispatch();
  const { companyId } = params;
  const [data, setData] = useState<Array<unknown>>([]);
  const [period, setPeriodicity] = useState(Periodicity.MONTHLY);
  const periodicityRef = useRef(period);
  const [isLoading, setIsLoading] = useState(false);
  // const isLoadingRef = useRef(false);
  const companyRef = useRef('');

  const handleFormatData = (
    getAllCompanyMetrics: Array<any>,
    newPeriodicity: string
  ): any => {
    const newData: Array<unknown> = [];
    getAllCompanyMetrics.forEach(
      ({ metricName, metricUnit, metricValue, id, date, sortingNumber }) => {
        const tempDate = getPeriodictyDate(new Date(date), newPeriodicity);
        let obj: any = {
          meta: {
            id: uuid(),
            metricName,
            metricUnit,
            companyId,
            periodicity: newPeriodicity,
            actualDate: date,
            sortingNumber,
          },
        };
        const index = newData.findIndex(
          (val: any) => val.meta.metricName === metricName
        );
        if (index >= 0) {
          obj = newData[index];
        }
        obj[tempDate] = {
          metricValue,
          id,
          metricUnit,
          actualDate: date,
        };
        if (index >= 0) {
          newData[index] = obj;
          return;
        }
        newData.push(obj);
      }
    );
    return newData;
  };

  const formateObj = ({
    metricValue,
    metricName,
    id,
    metricUnit,
    date,
    periodicity,
    companyId: creatorCompany,
    sortingNumber,
    rowId,
  }: KPI): any => {
    const tempDate = getPeriodictyDate(new Date(date), periodicity);
    const obj: any = {
      datePeriodicity: tempDate,
      meta: {
        id: rowId || null,
        metricName,
        metricUnit,
        companyId: creatorCompany,
        periodicity,
        actualDate: date,
        sortingNumber,
      },
    };
    obj[tempDate] = {
      metricValue,
      id,
      metricUnit,
      actualDate: date,
    };
    return obj;
  };

  const handleGetData = async (compId: string = companyId): Promise<void> => {
    const {
      data: { getAllCompanyMetrics = [] },
    } = (await service.query(GET_COMPANY_METRICS_QUERY, {
      companyId: compId,
      periodicity: periodicityRef.current,
      metricCategory: METRIC_CATEGORY.KPI,
    })) as GraphqlResponse<any>;
    const formatedData = handleFormatData(
      getAllCompanyMetrics,
      periodicityRef.current
    );
    setData(formatedData);
  };

  const [updateKpis] = useMutation(UPDATE_COMPANY_KPI_MUTATION);

  const handleUpdateData = (updatedArr: any[]) => {
    const dataToUpdate = [...data] as any[];
    updatedArr.forEach((val) => {
      const { action, ...actualData } = val;
      const index = dataToUpdate.findIndex(
        (obj) => obj?.meta.id === actualData.rowId
      );
      if (action === KPI_ACTIONS.DELETED && index >= 0) {
        dataToUpdate.splice(index, 1);
        return;
      }
      const formatedData = formateObj(actualData);
      const { datePeriodicity, meta, ...updatedData } = formatedData;
      const newObj = formatedData[datePeriodicity];
      const prevObj = dataToUpdate[index];
      if (index < 0 || !prevObj) {
        dataToUpdate.push({
          ...updatedData,
          meta,
        });
        return;
      }
      dataToUpdate[index] = {
        ...prevObj,
        meta,
      };
      dataToUpdate[index][datePeriodicity] = newObj;
    });
    return dataToUpdate;
  };

  const handleDenormalizeData = (apiData: any[]) => {
    const updatedKpis: any[] = [];
    apiData.forEach((newData) => {
      const {
        meta: {
          metricName,
          metricUnit = METRIC_UNIT.Number,
          sortingNumber = -1,
          id: rowId = null,
        } = {
          metricName: '',
          metricUnit: '',
          sortingNumber: -1,
          id: '',
        },
        action,
        tableData = { id: null },
        ...createObj
      } = newData;
      if (action === KPI_ACTIONS.DELETED) {
        updatedKpis.push({
          companyId,
          metricValue: null,
          companyMetricId: null,
          metricUnit: metricUnit || METRIC_UNIT.Number,
          metricName,
          metricCategory: METRIC_CATEGORY.KPI,
          periodicity: periodicityRef.current,
          date: null,
          hidden: false,
          sortingNumber,
          action,
          rowId,
        });
      }
      const { id: editIndex } = tableData;
      const prevData: any = data[editIndex];
      const keys = Object.keys(createObj).filter(
        (key) => createObj[key].isValid !== false
      );
      if (!keys.length) {
        const { month } = periodicityResponse[periodicityRef.current];
        const currentDate = moment();
        currentDate.set('month', month);
        updatedKpis.push({
          companyId,
          metricValue: null,
          companyMetricId: null,
          metricUnit: metricUnit || METRIC_UNIT.Number,
          metricName,
          metricCategory: METRIC_CATEGORY.KPI,
          periodicity: periodicityRef.current,
          date: currentDate.endOf('month').startOf('day').toDate(),
          hidden: false,
          sortingNumber,
          action,
          rowId,
        });
      }
      keys.forEach((key) => {
        const date = getPeriodictyDateByKey(key, periodicityRef.current);
        const updatedData = newData[key];
        const prevObj = prevData?.[key];
        if (!prevObj) {
          updatedKpis.push({
            companyId,
            metricValue: updatedData.metricValue,
            companyMetricId: null,
            metricUnit,
            metricName,
            metricCategory: METRIC_CATEGORY.KPI,
            periodicity: periodicityRef.current,
            date: date.toDate(),
            hidden: false,
            sortingNumber,
            action,
            rowId,
          });
          return;
        }
        const pointChanged =
          JSON.stringify(prevObj) !== JSON.stringify(updatedData);
        const metaChanged =
          JSON.stringify(newData.meta) !== JSON.stringify(prevData.meta);
        if (pointChanged || metaChanged) {
          updatedKpis.push({
            companyId,
            companyMetricId: updatedData.id,
            metricUnit,
            metricName,
            metricValue: updatedData.metricValue,
            metricCategory: METRIC_CATEGORY.KPI,
            periodicity: periodicityRef.current,
            date: date.toDate(),
            hidden: updatedData.hidden,
            sortingNumber,
            action,
            rowId,
          });
        }
      });
    });
    return updatedKpis;
  };

  const handleBulkOperation = async (apiData: any[]) => {
    try {
      setIsLoading(true);
      const kpis = handleDenormalizeData(apiData);
      const {
        data: { updateKpi: updatedKpis = [] } = { updateKpi: [] },
      } = await updateKpis({
        variables: {
          input: {
            kpis,
          },
        },
      });
      setData(handleUpdateData(updatedKpis));
      setIsLoading(false);
      dispatch(
        setAlert({
          open: true,
          message: translations.Common.kpis.onSuccessKpiUpdate,
          severity: 'success',
        })
      );
      return true;
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          message: `Uh oh, unble to update kpis ${handleGraphqlServerError(
            error
          )}`,
          severity: 'error',
        })
      );
      return false;
    }
  };

  const handleGetPeriodicity = (newPeriodicity: string): void => {
    setPeriodicity(newPeriodicity);
    periodicityRef.current = newPeriodicity;
    setIsLoading(true);
    handleGetData().then(() => setIsLoading(false));
  };

  useEffect(() => {
    if (propsPeriodicity) handleGetPeriodicity(propsPeriodicity);
  }, [propsPeriodicity]);

  useEffect(() => {
    if (!company || !company.id) return;
    if (isLoading) return;
    if (companyRef.current !== company.id) {
      companyRef.current = company.id;
      setIsLoading(true);
      handleGetData(company.id).then(() => setIsLoading(false));
    }
  }, [company, isLoading]);

  return (
    <Kpi
      data={data}
      isLoading={isLoading}
      periodicity={periodicityRef.current}
      getPeriodicity={handleGetPeriodicity}
      onBulkOperation={handleBulkOperation}
      role={role}
    />
  );
};

export default React.memo(
  KpiPage,
  (prev, current) => prev.company.id === current.company.id
);
