import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { createStructuredSelector } from 'reselect';
import { useIntl } from 'react-intl';
import { useAuth0 } from 'components/Common/Auth';
import {
  OrganizationThunk,
  selectOrganizations,
} from 'containers/Organisation';
import { setAlert } from 'containers/Config';

import UserSettings from '../../components/UserSettings';
import { CommonAction } from '../../actions';
import {
  MAKE_ORGANISATION_DEFAULT,
  GET_USER,
  UPDATE_USER,
  DELETE_USER,
  LEAVE_ORGANIZATION,
  // USER_SHARED_ORGANIZATION,
} from '../../graphql';
import messages from './messages';

const UserSettingsContainer = ({ setLoading }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { data: { user = {} } = {} } = useQuery(GET_USER);
  const organizations = useSelector(selectOrganizations);
  // const {
  //   data: { getUserSharedOrganization: organizations = [] } = {},
  //   refetch: refetchUserSharedOrganization,
  // } = useQuery(USER_SHARED_ORGANIZATION);

  const [makeOrganisationDefault] = useMutation(MAKE_ORGANISATION_DEFAULT);
  const [updateUser] = useMutation(UPDATE_USER);
  const { logout } = useAuth0();
  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: () => {
      logout();
    },
    onError: () => {
      setLoading(false);
      dispatch(
        setAlert({
          open: true,
          message: intl.formatMessage(messages.onError),
          severity: 'error',
        })
      );
    },
  });
  const [leaveOrganization] = useMutation(LEAVE_ORGANIZATION, {
    onCompleted: async () => {
      try {
        // await refetchUserSharedOrganization();
        await new Promise((resolve, reject) => {
          dispatch(
            OrganizationThunk.getOrganizations({
              resolve,
              reject,
            })
          );
        });
        dispatch(
          setAlert({
            open: true,
            message: intl.formatMessage(messages.onSuccessLeaveOrganization),
            severity: 'success',
          })
        );
      } catch (err) {
        //
      }
    },
    onError: () =>
      dispatch(
        setAlert({
          open: true,
          message: intl.formatMessage(messages.onError),
          severity: 'error',
        })
      ),
  });

  const onLeaveOrganization = async (props) => {
    await setLoading(true);
    await leaveOrganization({
      variables: {
        input: props,
      },
    });
    await setLoading(false);
  };

  const onUpdateName = async (props) => {
    await setLoading(true);
    await updateUser({
      variables: {
        input: props,
      },
      refetchQueries: [
        {
          query: GET_USER,
        },
      ],
    });
    return setLoading(false);
  };
  const onDeleteAccount = (props) => deleteUser(props);
  const onUpdateOrganisation = async (props) => {
    await makeOrganisationDefault(props);
  };

  return (
    <>
      <UserSettings
        onUpdateName={onUpdateName}
        onDeleteAccount={onDeleteAccount}
        onUpdateOrganisation={onUpdateOrganisation}
        onLeaveOrganization={onLeaveOrganization}
        organisations={organizations}
        user={user}
        setLoading={setLoading}
      />
    </>
  );
};

UserSettingsContainer.propTypes = {
  setLoading: PropTypes.func.isRequired,
};

const mapState = createStructuredSelector({});

const mapDispatch = (dispatch) => ({
  setLoading: (loading) => dispatch(CommonAction.setLoader(loading)),
});

export default connect(mapState, mapDispatch)(UserSettingsContainer);
