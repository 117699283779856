import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    height: 30,
    cursor: 'pointer',
  },
  iconSize: {
    ...theme.custom.iconSize,
  },
  selectOrganisation: {
    color: '#fff',
    marginLeft: 40,
    marginRight: -40,
    marginTop: 3,
    width: 130,
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '&.MuiInput-underline:focus:not(.Mui-disabled):before': {
      border: 'none',
    },
    '&.MuiInput-underline:before': {
      border: 'none',
    },
  },
  formControl: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(8),
  },
  button: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  toolBar: {
    justifyContent: 'space-between',
    minHeight: 58,
    backgroundColor: theme.palette.primary.main,
  },
  infoBar: {
    display: 'flex',
    alignItems: 'center',
  },
  headerMain: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'transparent',
  },
  hide: {
    display: 'none',
  },
  userButton: {
    textTransform: 'none',
  },
  menuButton: {
    marginRight: 36,
  },
  dropdownButton: {
    color: '#fff',
    backgroundColor: '#131836',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    ...theme.custom.buttonLabel,
  },
  icon: {
    color: '#fff',
    ...theme.custom.iconSize,
  },
  menuPopper: {
    width: 250,
  },
  SubHeaderIconElement: {
    marginRight: `${theme.spacing(2)}px`,
  },
  SubHeader: {
    fontWeight: 800,
  },
  logOutItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchTextInput: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  notificationBarContainer: {
    backgroundColor: '#FCBA63',
    display: 'flex',
    flexDirection: 'row',
  },
  notificationBar: {
    height: '30px',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  notificationText: {
    color: 'black',
  },
  notificationPadding: {
    width: '10px',
  },
  notificationStrongText: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  notificationClose: {
    width: '30px',
    height: '30px%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },
  pointer: {
    cursor: 'pointer',
  },
  profileLink: {
    color: 'white',
  },
  dividerText: {
    margin: '4px 0px 0px 16px',
  },
}));

export default useStyles;
