const isTextValid = (text) => Boolean(text && `${text}`.trim().length);
const isAmountValid = (text) => isTextValid && Number(text) > 0;

export const INVALID_TEXT = {
  LOCATION: 'location Cannot be empty',
  WEBSITE: 'illegal input',
  INVESTMENTVALUE: 'should be a valid amount',
  PORTFOLIO: 'Select a portfolio',
  COMPANY: 'Select a company',
  OWNERSHIP: 'should be a valid percentage value',
  FIRST_COMPANY_CONTACT: 'Please enter a valid Company Contact',
  SEC_COMPANY_CONTACT: 'Please enter a valid 2nd Company Contact',
  FIRST_INVEST_MGR: 'Please enter a valid Investment Manager',
  SEC_INVEST_MGR: 'Please enter a valid 2nd Investment Manager',
  COMPANY_NAME: 'company cannot be empty',
  CRUNCHBASE_COMPANY: 'company not found in database',
  AMOUNT: 'Should be a valid amount',
  INVESTMENT_COMPANY_TAB: 'company-tab',
  INVESTMENT_PORTFOLIO: 'portfolio',
  NAME: 'Invalid input',
};

export const validateInput = (key, value, from) => {
  switch (key) {
    case 'reserved':
      if (!isAmountValid(value)) {
        return {
          reservedValid: true,
          reservedHelperText: INVALID_TEXT.AMOUNT,
        };
      }
      return {
        reservedValid: false,
        reservedHelperText: '',
      };

    case 'investmentValue':
      if (!isAmountValid(value)) {
        return {
          investmentValueValid: true,
          investmentValueHelperText: INVALID_TEXT.AMOUNT,
        };
      }
      return {
        investmentValueValid: false,
        investmentValueHelperText: '',
      };

    case 'investmentAmount':
      if (!isAmountValid(value)) {
        return {
          investmentAmountValid: true,
          investmentAmountHelperText: INVALID_TEXT.AMOUNT,
        };
      }
      return {
        investmentAmountValid: false,
        investmentAmountHelperText: '',
      };

    case 'selectedItem':
      if (!value) {
        return {
          selectedItemValid: true,
          selectedItemHelperText:
            from === INVALID_TEXT.INVESTMENT_COMPANY_TAB
              ? INVALID_TEXT.COMPANY
              : INVALID_TEXT.PORTFOLIO,
        };
      }
      return {
        selectedItemValid: false,
        selectedItemHelperText: '',
      };

    case 'ownership':
      if (!isAmountValid(value) && Number(value) <= 100) {
        return {
          ownershipValid: true,
          ownershipHelperText: INVALID_TEXT.OWNERSHIP,
        };
      }
      return {
        ownershipValid: false,
        ownershipHelperText: '',
      };

    default:
      return {};
  }
};
