import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '40px',
  },
  paper: {
    padding: '16px 0px 24px',
  },
  title: {
    marginBottom: 25,
  },
  label: {
    fontSize: 15,
    flexBasis: '-1%',
    marginRight: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  input: {
    marginRight: 10,
  },
  searchBtn: {
    width: 120,
    marginLeft: 25,
    height: 35,
  },
  kpiActionWrapper: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
}));
