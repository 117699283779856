/* eslint-disable import/prefer-default-export */

import { initialValue } from 'constants/index';
import { GET_KPI_DEFAULT_VALUES } from 'containers/Organisation/ducks/query';
import {
  GET_SELECTED_ORGANISATION_WITH_INVESTMENT_QUERY,
  DELETE_CONTACTS_MUTATION,
  // GET_REQUEST_DATA,
  UPDATE_COMPANY_MUTATION,
  CREATE_COMPANY_MUTATION,
  CREATE_USERS,
} from '../graphql';
import {
  ACTION_TYPES_COMPANY,
  ACTION_TYPES_LOADING,
  ACTION_TYPES_ORGANIZATION,
} from '../actionTypes';
import { actionCreator } from '../common/actionCreator';
import { CommonAction } from './common';

// eslint-disable-next-line import/no-cycle
import { OrganizationAction } from './organization';
import { logActionError } from '../common/checkActionBadData';

export class CompanyAction {
  static getKpiPageData(orgId) {
    return async (dispatch) => {
      const client = global.apolloClient;
      const {
        data: { getKpiDefaultValues: defaultKpiValues = initialValue } = {},
      } = await client.query({
        query: GET_KPI_DEFAULT_VALUES,
        variables: {
          input: {
            orgId,
          },
        },
      });
      dispatch(
        actionCreator(ACTION_TYPES_COMPANY.GET_KPI_DATA, defaultKpiValues)
      );
    };
  }

  static getUpdateKpiData(actionData) {
    return (dispatch) => {
      dispatch(actionCreator(ACTION_TYPES_COMPANY.UPDATE_KPI_DATA, actionData));
    };
  }

  static addKpiDataColumn() {
    return (dispatch) => {
      dispatch(actionCreator(ACTION_TYPES_COMPANY.ADD_KPI_DATA_COLUMN));
    };
  }

  static getUpdateKpiPeriodicity(actionData) {
    return (dispatch) => {
      dispatch(
        actionCreator(ACTION_TYPES_COMPANY.UPDATE_KPI_PERIODICITY, actionData)
      );
    };
  }

  static getUpdateKpiPeriodicityDate(actionData) {
    return (dispatch) => {
      dispatch(
        actionCreator(
          ACTION_TYPES_COMPANY.UPDATE_KPI_PERIODICITY_DATE,
          actionData
        )
      );
    };
  }

  static getKpiColumnValue() {
    return (dispatch) => {
      dispatch(actionCreator(ACTION_TYPES_COMPANY.GET_KPI_COLUMN_VALUES));
    };
  }

  static getUpdateKpiColumnValue(actionData) {
    return (dispatch) => {
      dispatch(
        actionCreator(ACTION_TYPES_COMPANY.UPDATE_KPI_VALUES, actionData)
      );
    };
  }

  static deleteKpiColumn(id) {
    return (dispatch) => {
      dispatch(actionCreator(ACTION_TYPES_COMPANY.DELETE_KPI_COLUMN, id));
    };
  }

  static getCompaniesByOrganisation(id, companies) {
    return async (dispatch) => {
      try {
        const client = global.apolloClient;
        dispatch(actionCreator(ACTION_TYPES_COMPANY.GET_COMPANIES));
        if (Array.isArray(companies)) {
          dispatch(
            actionCreator(
              ACTION_TYPES_COMPANY.GET_COMPANIES_SUCCESS,
              JSON.parse(JSON.stringify(companies))
            )
          );
          return;
        }
        dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, true));
        const {
          data: { organisation: { companies: fetchedCompanies = [] } } = {},
        } = await client.query({
          query: GET_SELECTED_ORGANISATION_WITH_INVESTMENT_QUERY,
          variables: {
            organisationId: id,
          },
          fetchPolicy: 'network-only',
        });
        dispatch(
          actionCreator(
            ACTION_TYPES_COMPANY.GET_COMPANIES_SUCCESS,
            JSON.parse(JSON.stringify(fetchedCompanies))
          )
        );
        dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, false));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error: getCompaniesByOrganisation Action: ', error);
        dispatch(actionCreator(ACTION_TYPES_COMPANY.GET_COMPANIES_FAIL));
        dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, false));
      }
    };
  }

  static getAllOrganizationsCompanies(organizations) {
    return (dispatch, getState) => {
      dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, true));
      const { organization } = getState();
      const { organizations: reduxOrganizations } = organization;
      let allOrganizations = organizations;
      dispatch(actionCreator(ACTION_TYPES_COMPANY.GET_ALL_COMPANIES));
      if (!Array.isArray(organizations)) {
        allOrganizations = reduxOrganizations;
      }
      const orgComapnies = allOrganizations
        .filter((org) => org.companies && org.companies.length)
        .map((org) => org.companies)
        .flat(1);
      dispatch(
        actionCreator(
          ACTION_TYPES_COMPANY.GET_ALL_COMPANIES_SUCCESS,
          JSON.parse(JSON.stringify(orgComapnies))
        )
      );
      dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, false));
    };
  }

  static addNewAllCompanies(newCompany, organizationId) {
    return (dispatch) => {
      try {
        if (!newCompany || !Object.keys(newCompany).length) {
          throw new Error('Bad data received');
        }
        // dispatch(actionCreator(ACTION_TYPES_COMPANY.ADD_ALL_COMPANIES));
        // dispatch(
        //   actionCreator(
        //     ACTION_TYPES_COMPANY.ADD_ALL_COMPANIES_SUCCESS,
        //     JSON.parse(JSON.stringify(newCompany)),
        //   ),
        // );
        if (organizationId) {
          dispatch(
            OrganizationAction.addCompanyToOrganization(
              newCompany,
              organizationId
            )
          );
          // It is for all companies table
          if (
            newCompany.organisation &&
            organizationId === newCompany.organisation.id
          ) {
            dispatch(
              actionCreator(
                ACTION_TYPES_COMPANY.ADD_COMPANIES,
                JSON.parse(JSON.stringify(newCompany))
              )
            );
          }
        }
      } catch (error) {
        logActionError('error', error, 'addNewAllCompanies');
        dispatch(actionCreator(ACTION_TYPES_COMPANY.ADD_ALL_COMPANIES_FAIL));
      }
    };
  }

  static updateCompany(companyData) {
    return async (dispatch) => {
      dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, true));
      try {
        const client = global.apolloClient;
        const {
          data: { updateCompany: updatedCompany = {} } = {},
        } = await client.mutate({
          mutation: UPDATE_COMPANY_MUTATION,
          variables: {
            ...companyData,
          },
        });
        const organizationId = updatedCompany.url.split('/')[2];
        dispatch(
          OrganizationAction.addCompanyToOrganization(
            updatedCompany,
            organizationId,
            true
          )
        );
        dispatch(
          actionCreator(ACTION_TYPES_COMPANY.UPDATE_COMPANY, updatedCompany)
        );
      } catch (error) {
        logActionError('error', error, 'createCompany');
      } finally {
        dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, false));
      }
    };
  }

  static createCompany(companyData, onSuccessMsg, contactDetails) {
    const onContactSuccess = (dispatch, createdCompany, state) => {
      const {
        organization: { organization: selectedOrganisation = {} } = {},
      } = state;
      const { organisation: createdOrg, ...actualCompany } = createdCompany;
      dispatch(
        CompanyAction.addNewAllCompanies(
          {
            ...actualCompany,
          },
          selectedOrganisation.id
        )
      );
      dispatch(
        actionCreator(
          ACTION_TYPES_COMPANY.CREATE_COMPANY_SUCCESS,
          JSON.parse(JSON.stringify(actualCompany))
        )
      );
      dispatch(
        OrganizationAction.getSelectedOrganization(selectedOrganisation.id)
      );
      const newSelectedOrganization = {
        ...createdOrg,
        direct: true,
        companies: [
          {
            ...actualCompany,
          },
        ],
      };
      dispatch(
        actionCreator(
          ACTION_TYPES_ORGANIZATION.ADD_ORGANIZATIONS_SUCCESS,
          newSelectedOrganization
        )
      );
      // dispatch(
      //   actionCreator(
      //     ACTION_TYPES_ORGANIZATION.GET_SELECTED_ORGANIZATION_SUCCESS,
      //     newSelectedOrganization,
      //   ),
      // );
      dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, false));
      dispatch(
        CommonAction.setAlert({
          open: true,
          message: onSuccessMsg,
          severity: 'success',
        })
      );
    };
    return async (dispatch, getState) => {
      try {
        const client = global.apolloClient;
        const state = getState();
        dispatch(actionCreator(ACTION_TYPES_COMPANY.CREATE_COMPANY));
        dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, true));
        const {
          data: { createCompany: createdCompany = {} } = {},
        } = await client.mutate({
          mutation: CREATE_COMPANY_MUTATION,
          variables: {
            ...companyData,
          },
        });
        const { organisation: createdOrg } = createdCompany;
        if (contactDetails.length) {
          const { data: { createUsers } = {} } = await client.mutate({
            mutation: CREATE_USERS,
            variables: {
              input: contactDetails.map((elem) => ({
                ...elem,
                organisationId: createdOrg.id,
                contactType: elem.contactType || 'contact',
              })),
            },
          });
          dispatch(
            CompanyAction.updateContact(
              createUsers.map((elem) => ({
                orgId: createdOrg.id,
                userId: elem.id,
                email: elem.email,
                firstName: elem.firstName,
                lastName: elem.lastName,
                contactType: 'contact',
                companyId: '',
              }))
            )
          );
        }
        onContactSuccess(dispatch, createdCompany, state);
      } catch (error) {
        logActionError('error', error, 'createCompany');
        dispatch(actionCreator(ACTION_TYPES_COMPANY.CREATE_COMPANY_FAIL));
        dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, false));
      }
    };
  }

  // static updateContact(contactData, onSuccessCallback, onSuccessMsg) {
  //   return async (dispatch, getState) => {
  //     try {
  //       const client = global.apolloClient;
  //       dispatch(actionCreator(ACTION_TYPES_COMPANY.UPDATE_CONTACT));
  //       if (!onSuccessCallback) {
  //         dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, true));
  //       }
  //       if (!Array.isArray(contactData)) {
  //         await client.mutate({
  //           mutation: UPDATE_CONTACTS_MUTATION,
  //           variables: {
  //             input: {
  //               ...contactData,
  //             },
  //           },
  //         });
  //       }
  //       if (onSuccessCallback) {
  //         onSuccessCallback();
  //         return;
  //       }
  //       const { company: { companies = [] } = {} } = getState();
  //       const { companyId } = contactData;
  //       const filteredCompanyIndex = companies.findIndex(
  //         (company) => company.id === companyId
  //       );
  //       const filteredCompany = companies[filteredCompanyIndex];
  //       if (!filteredCompany || !Object.keys(filteredCompany).length) {
  //         throw new Error('No company found');
  //       }
  //       const updatedCompany = {
  //         ...filteredCompany,
  //         contacts: {
  //           ...contactData,
  //         },
  //       };
  //       companies[filteredCompanyIndex] = updatedCompany;
  //       dispatch(actionCreator(ACTION_TYPES_COMPANY.UPDATE_CONTACT_SUCCESS));
  //       dispatch(
  //         actionCreator(
  //           ACTION_TYPES_COMPANY.GET_COMPANIES_SUCCESS,
  //           JSON.parse(JSON.stringify(companies))
  //         )
  //       );
  //       dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, false));
  //       dispatch(
  //         CommonAction.setAlert({
  //           open: true,
  //           message: onSuccessMsg,
  //           severity: 'success',
  //         })
  //       );
  //     } catch (error) {
  //       logActionError('error', error, 'updateContact');
  //       dispatch(actionCreator(ACTION_TYPES_COMPANY.UPDATE_CONTACT_FAIL));
  //       dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, false));
  //     }
  //   };
  // }

  static deleteContacts(contactIds, companyId, onSuccessMsg) {
    return async (dispatch, getState) => {
      try {
        const client = global.apolloClient;
        const { company: { companies } = {} } = getState();
        dispatch(actionCreator(ACTION_TYPES_COMPANY.DELETE_CONTACTS));
        dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, true));
        await client.mutate({
          mutation: DELETE_CONTACTS_MUTATION,
          variables: {
            contactIds,
          },
        });
        const filteredCompanyIndex = companies.findIndex(
          (company) => company.id === companyId
        );
        const filteredCompany = companies[filteredCompanyIndex];
        if (!filteredCompany || !Object.keys(filteredCompany).length) {
          throw new Error('No company found');
        }
        const updatedCompany = {
          ...filteredCompany,
          contacts: null,
        };
        companies[filteredCompanyIndex] = updatedCompany;
        dispatch(
          actionCreator(
            ACTION_TYPES_COMPANY.DELETE_CONTACTS_SUCCESS,
            JSON.parse(JSON.stringify(companies))
          )
        );
        dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, false));
        dispatch(
          CommonAction.setAlert({
            open: true,
            message: onSuccessMsg,
            severity: 'success',
          })
        );
      } catch (error) {
        logActionError('error', error, 'deleteContacts');
        dispatch(actionCreator(ACTION_TYPES_COMPANY.DELETE_CONTACTS_FAIL));
        dispatch(actionCreator(ACTION_TYPES_LOADING.SET_LOADING, false));
      }
    };
  }

  // static getRequestDataByCompany(organisationId) {
  //   return async (dispatch) => {
  //     try {
  //       const client = global.apolloClient;
  //       dispatch(
  //         actionCreator(ACTION_TYPES_COMPANY.GET_REQUEST_DATA_BY_COMPANIES)
  //       );
  //       const {
  //         data: { getRequestDataByCompany = [] } = {},
  //       } = await client.query({
  //         query: GET_REQUEST_DATA,
  //         variables: {
  //           organisationId,
  //         },
  //         fetchPolicy: 'network-only',
  //       });
  //       dispatch(
  //         actionCreator(
  //           ACTION_TYPES_COMPANY.GET_REQUEST_DATA_BY_COMPANIES_SUCCESS,
  //           JSON.parse(JSON.stringify(getRequestDataByCompany))
  //         )
  //       );
  //     } catch (error) {
  //       logActionError('error', error, 'getRequestDataByCompany');
  //       dispatch(
  //         actionCreator(ACTION_TYPES_COMPANY.GET_REQUEST_DATA_BY_COMPANIES_FAIL)
  //       );
  //     }
  //   };
  // }
}
