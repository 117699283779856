import { defineMessages } from 'react-intl';

export default defineMessages({
  // teamMembers: {
  //   id: 'Company.CompanyScreen.teamMembers',
  //   defaultMessage: 'Team',
  // },
  // location: {
  //   id: 'Company.CompanyScreen.location',
  //   defaultMessage: 'Location',
  // },
  // industry: {
  //   id: 'Company.CompanyScreen.industry',
  //   defaultMessage: 'Industry',
  // },
  // companyName: {
  //   id: 'Company.CompanyScreen.companyName',
  //   defaultMessage: 'Company name',
  // },
  // website: {
  //   id: 'Company.CompanyScreen.website',
  //   defaultMessage: 'Website',
  // },
  // shortDescription: {
  //   id: 'Company.CompanyScreen.shortDescription',
  //   defaultMessage: 'Short description',
  // },
  // helperIndustry: {
  //   id: 'Company.CompanyScreen.helperIndustry',
  //   defaultMessage: 'e.g Internet',
  // },
  // helperTeam: {
  //   id: 'Company.CompanyScreen.helperTeam',
  //   defaultMessage: 'Employees, e.g 10-20',
  // },
  // helperLocation: {
  //   id: 'Company.CompanyScreen.helperLocation',
  //   defaultMessage: 'e.g San Francisco, USA',
  // },
  // helperWebsite: {
  //   id: 'Company.CompanyScreen.helperWebsite',
  //   defaultMessage: 'e.g http://example.com',
  // },
  // helperName: {
  //   id: 'Company.CompanyScreen.helperName',
  //   defaultMessage: 'Should not be empty',
  // },
  // saveBtn: {
  //   id: 'Company.CompanyScreen.saveBtn',
  //   defaultMessage: 'Save',
  // },
  feedTitle: {
    id: 'Company.CompanyScreen.feed.title',
    defaultMessage: 'Recent Updates',
  },
  kpisTitle: {
    id: 'Company.CompanyScreen.kpis.title',
    defaultMessage: 'Current KPIs',
  },
});
