import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '40px',
  },
  paperWrapper: {
    padding: '25px',
  },
  header: {
    letterSpacing: '0.5px',
  },
  headers: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  summaryContainer: {
    height: 'fit-content',
    paddingBottom: '16px',
  },
  summaryPaper: {
    paddingBottom: '8px',
  },
  summaryLabel: {
    fontWeight: 500,
    fontSize: '14px',
    textTransform: 'capitalize',
    marginBottom: '5px',
  },
  summaryContent: {
    fontSize: '14px',
  },
  summarySkeleton: {
    marginBottom: '20px',
    height: '50px',
  },
});

export default useStyles;
