import React, { useState, useEffect, useRef } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';

import { SelectInput } from 'components/Common/Select';
import { OnChangeEvent, ValidationType } from 'types';
import { METRIC_UNIT } from 'constants/index';
import { useMetricField } from 'utils/index';
import { DraggableRowComponent } from './DragableRow';
import { EnhancedTableRowProps } from './type';
import { EditableField } from './EditableField';
import { useStyles } from './styles';

const unitOptions = Object.values(METRIC_UNIT).map((val) => ({
  id: val,
  value: val,
}));

export function EnhancedTableRow({
  // isSelected,
  onRowSelect,
  columns,
  data,
  enableEdit,
  onUpdate,
  rowIndex,
  onDelete,
  // onAddColumns,
  onAddRow,
  tableData,
  reset,
}: EnhancedTableRowProps) {
  const { renderFormatedMetric } = useMetricField();
  const [, , ...dynamicColumns] = columns;
  const [rowData, setRowData] = useState<any>({});
  // const [validation, setValidation] = useState<ValidationType>({
  //   errorText: '',
  //   isValid: true,
  //   duplicate: false,
  // });
  const valid = useRef<ValidationType[]>([]);

  const {
    meta: { metricName = '', metricUnit = '' } = {
      metricName: '',
      metricUnit: '',
    },
    ...renderData
  } = rowData;
  const classes = useStyles();

  const addValidInResponse = (newData: any) => {
    valid.current.forEach((val, index) => {
      if (index === 0) return;
      const {
        // eslint-disable-next-line no-empty-pattern
        meta: {},
        ...validData
      } = newData;
      const keys = Object.keys(validData);
      const obj = validData[keys[index - 1]];
      if (obj) {
        obj.isValid = !val;
        // eslint-disable-next-line no-param-reassign
        newData[keys[index - 1]] = obj;
      }
    });
    return newData;
  };

  const handleOnChange = (fieldName: string, property: string) => (
    event: OnChangeEvent | { target: { value: string } }
  ) => {
    const { value } = event.target;
    const newData = { ...rowData };
    if (!newData[fieldName]) {
      newData[fieldName] = {};
    }
    newData[fieldName][property] = value;
    // let isValid = true;
    if (property === 'metricUnit') {
      setRowData(newData);
      setTimeout(() => {
        const element = document.getElementById(`${rowIndex}_${0}`);
        if (element) element.focus();
      }, 100);
    }
    // const [kpiNameValid] = valid.current;
    // if (kpiNameValid && !kpiNameValid.isValid) {
    //   isValid = false;
    // }
    // const newValid = valid.current.filter((val) => Boolean(val));
    // valid.current = newValid;
    // if (valid.current.length) {
    //   isValid = false;
    // }
    onUpdate(rowIndex, addValidInResponse(newData));
  };

  const validateDate = (date: string): boolean => {
    if (date.split('/').length < 3) return false;
    const momentDate = moment(date, 'DD/MM/YYYY');
    return momentDate.isValid();
  };

  const validatePercentage = (per: string): boolean => {
    // eslint-disable-next-line no-restricted-globals
    return !isNaN(Number(per));
    // const number = Number(Number(per).toFixed(4));
    // return number <= 100;
  };

  const validateDuplicateKpiName = (kpiName: string): boolean => {
    const isDuplicate = tableData.some(
      (val) =>
        val?.meta?.metricName === kpiName && data?.meta?.id !== val?.meta?.id
    );
    return isDuplicate;
  };

  const handleValidate = (key: string, colIndex: number) => async (
    value: string
  ): Promise<ValidationType> => {
    const newValidation: ValidationType[] = [...valid.current];
    const validObj: ValidationType = {
      errorText: '',
      isValid: true,
      validValue: value,
    };
    if (key === 'metricName') {
      if (!value.trim().length) {
        validObj.isValid = false;
        validObj.errorText = 'Kpi name is required, else it will be not saved';
      } else if (validateDuplicateKpiName(value)) {
        validObj.isValid = false;
        validObj.errorText = 'Duplicate kpi names are not saved';
        validObj.duplicate = true;
      }
    } else if (key === 'metricValue') {
      if (!value) {
        validObj.isValid = true;
      } else if (metricUnit === METRIC_UNIT.Date && !validateDate(value)) {
        validObj.isValid = false;
        validObj.errorText = 'Invalid date, it will be not saved';
      } else if (
        metricUnit === METRIC_UNIT['%'] &&
        !validatePercentage(value)
      ) {
        validObj.isValid = false;
        validObj.errorText = 'Should be valid value';
      }
    } else {
      validObj.isValid = true;
      validObj.errorText = '';
    }
    if (!validObj.isValid) {
      newValidation[colIndex] = { ...validObj };
    } else {
      newValidation.splice(colIndex, 1);
    }
    valid.current = newValidation;
    return validObj;
  };

  const onKeyDownMericValue = (colIndex: number) => (
    e: React.KeyboardEvent
  ) => {
    if (e.keyCode === 9 && colIndex >= dynamicColumns.length - 2) {
      const nextCol = `${rowIndex}_${colIndex + 1}`;
      const element = document.getElementById(nextCol);
      if (element) element.focus();
      // onAddColumns(nextCol);
    } else if (e.keyCode === 13) {
      const element = document.getElementById(
        `${rowIndex + 1}_${e.shiftKey ? 0 : colIndex}`
      );
      if (element) element.focus();
      else {
        const currElement = document.getElementById(`_${rowIndex}_meta`);
        if (currElement) {
          currElement.focus();
          onAddRow.current();
        }
        setTimeout(() => {
          const newElement = document.getElementById(`_${rowIndex + 1}_meta`);
          if (newElement) newElement.focus();
        }, 100);
      }
    }
  };

  // const isItemSelected = isSelected(metricName);
  const labelId = `enhanced-table-checkbox-${metricName}`;

  useEffect(() => {
    setRowData(JSON.parse(JSON.stringify(data)));
  }, [JSON.stringify(data)]);

  const RenderTableCell = () => (
    <>
      <TableCell id={labelId} scope="row" className={classes.name}>
        {!enableEdit ? (
          metricName
        ) : (
          <EditableField
            onChange={handleOnChange('meta', 'metricName')}
            placeholder="Metric name"
            value={metricName}
            id={`_${rowIndex}_meta`}
            onKeydown={(e: React.KeyboardEvent) => {
              if (e.keyCode === 9) {
                setTimeout(() => {
                  const element = document.getElementById(`${rowIndex}_select`);
                  if (element) element.focus();
                }, 100);
              }
            }}
            onValidate={handleValidate('metricName', 0)}
          />
        )}
      </TableCell>
      <TableCell className={classes.unit}>
        {!enableEdit ? (
          metricUnit
        ) : (
          <SelectInput
            variant="standard"
            onChange={handleOnChange('meta', 'metricUnit')}
            options={unitOptions}
            value={metricUnit}
            style={{ minWidth: 80 }}
            id={`${rowIndex}_select`}
            useId
          />
        )}
      </TableCell>
      {dynamicColumns.map((col, colIndex) => (
        <TableCell
          className={classes.metricValue}
          align="left"
          key={renderData?.[col.value]?.id || `${metricName}_${colIndex}`}
        >
          {!enableEdit ? (
            renderFormatedMetric({
              value: renderData?.[col.value]?.metricValue || null,
              unit: metricUnit,
            })
          ) : (
            <EditableField
              onChange={handleOnChange(col.value, 'metricValue')}
              unit={metricUnit}
              value={renderData?.[col.value]?.metricValue || ''}
              id={`${rowIndex}_${colIndex}`}
              onKeydown={onKeyDownMericValue(colIndex)}
              onValidate={handleValidate('metricValue', colIndex + 1)}
            />
          )}
        </TableCell>
      ))}
      {enableEdit ? (
        <TableCell align="center" className={classes.noPadding}>
          <IconButton onClick={() => onDelete(rowIndex)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      ) : (
        <TableCell align="center" className={classes.emptySpaceForIcon} />
      )}
    </>
  );

  return enableEdit ? (
    <DraggableRowComponent
      index={rowIndex}
      columns={columns}
      enableEdit={enableEdit}
      id={rowIndex.toString()}
      hover={!enableEdit}
      metricName={metricName}
      metricUnit={metricUnit}
      reset={reset}
      onClick={
        !enableEdit ? (event: any) => onRowSelect(event, metricName) : undefined
      }
      // role="checkbox"
      // aria-checked={isItemSelected}
      tabIndex={-1}
      key={metricName}
      // selected={isItemSelected}
    >
      {RenderTableCell()}
    </DraggableRowComponent>
  ) : (
    <TableRow>{RenderTableCell()}</TableRow>
  );
}
