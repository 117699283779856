import { defineMessages } from 'react-intl';

export default defineMessages({
  companyReportsTitle: {
    id: 'CompanyReports.title',
    defaultMessage: 'Company Reports',
  },
  add: {
    id: 'CompanyReports.AddReportModal.add',
    defaultMessage: 'Add',
  },
  file: {
    id: 'CompanyReports.AddReportModal.file',
    defaultMessage: 'File',
  },
  period: {
    id: 'CompanyReports.AddReportModal.period',
    defaultMessage: 'Period',
  },
  sharedWith: {
    id: 'CompanyReports.AddReportModal.sharedWith',
    defaultMessage: 'Shared with',
  },
});
