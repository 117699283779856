import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, TextField } from '@material-ui/core';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Grid from '@material-ui/core/Grid';
import { useIntl } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment-timezone';
import translations from 'translations';
import { CompanyDefaultText } from './CompanyDefault';
import { useStyle } from './styles';
import { CompanyTop } from './CompanyTop';
import { VIEW_CONTENT_TYPE } from '../../../constants';
import getSharedWith from '../../../common/getSharedColor';
import messages from './messages';

const newComment = {
  date: new Date(),
  title: '',
  description: '',
  view: VIEW_CONTENT_TYPE[0].name.toLowerCase(),
};

const CompanyComment = ({
  onDeleteComment,
  onUpdateComment,
  role,
  comment: propsComment,
  organisationName,
  viewContent,
  loading,
}) => {
  const classes = useStyle();
  const intl = useIntl();
  const [comments, setComments] = useState([]);
  const [enableEdit, setEnableEdit] = useState(false);
  const [didChange, setDidChange] = useState(false);
  const [addDisable, setAddDisable] = useState(false);

  const filterFeed = (data) =>
    data.filter((elem) => viewContent.includes(elem.view));

  const onCommentChange = (key, value, index) => {
    setDidChange(true);

    const data = JSON.parse(JSON.stringify(comments));
    data[index][key] = value;
    if (addDisable && index === 0 && Boolean(data[index][key])) {
      setAddDisable(false);
    }
    setComments(data);
  };

  const onCommentsDelete = (comment, index) => {
    const data = JSON.parse(JSON.stringify(comments));
    data.splice(index, 1);
    onDeleteComment(comment.id);
    setComments(data);
    if (!data.length) setEnableEdit(false);
  };

  const handleDiscard = () => {
    setEnableEdit(false);
    setDidChange(false);
    const viewableComment = filterFeed(propsComment);
    setComments(viewableComment);
  };

  const handleAddActionClick = () => {
    setComments((commentsData) => [newComment, ...commentsData]);
    setAddDisable(true);
  };

  const handleActionClick = () => {
    if (enableEdit) {
      // Remove if any feed does not have title
      setEnableEdit(false);
      onUpdateComment(comments);
      setDidChange(false);
    } else {
      if (!comments.length) handleAddActionClick();
      setEnableEdit(true);
    }
  };

  useEffect(() => {
    if (JSON.stringify(propsComment) !== JSON.stringify(comments)) {
      const viewableComment = filterFeed(propsComment);
      setComments(viewableComment);
    }
  }, [propsComment]);

  useEffect(() => {
    if (enableEdit) {
      setEnableEdit(false);
    }
    const viewableComment = filterFeed(propsComment);
    setComments(viewableComment);
  }, [viewContent]);

  const commentResponse = (response) => {
    if (
      !response &&
      comments.length &&
      !comments[comments.length - 1].description
    ) {
      setComments(comments.slice(0, comments.length - 1));
    }
    setEnableEdit(response);
  };

  const Comment = (comment, index, onChange, onDelete) => (
    <Paper
      elevation={4}
      className={classes.paperColored}
      key={`${comment.id || ''}_${index}`}
    >
      <Grid container>
        <Grid container item>
          <Grid item xs={9} direction="row">
            <div className={classes.sharedWithContainer}>
              {comment.userName && (
                <Typography
                  id={`comment-username-${index}`}
                  className={classes.commentHeader}
                  variant="body1"
                >
                  {`${
                    comment.userName
                  } / ${comment.orgName.toUpperCase()} ( ${moment(comment.date)
                    .tz('CET')
                    .format('D MMMM YYYY, HH:mm z')} )`}
                </Typography>
              )}
              {enableEdit && (
                <>
                  <Typography
                    className={classes.sharedWithText}
                    variant="body1"
                  >
                    {intl.formatMessage(
                      translations.Company.CompanyScreen.sharedWith
                    )}
                  </Typography>
                  <Select
                    value={comment.view}
                    id={`comment-view-type-${index}`}
                    onChange={(event) => {
                      onChange('view', event.target.value, index);
                    }}
                  >
                    {VIEW_CONTENT_TYPE.map((elem) => {
                      const value = elem.name.toLowerCase();
                      return (
                        <MenuItem
                          id={`view-type-${value}-${index}`}
                          value={value}
                        >
                          {elem.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.iconContainer}>
              <div
                style={{
                  color: getSharedWith(comment.view, organisationName)[1],
                }}
                className={classes.sharedTextContainer}
              >
                {!enableEdit && (
                  <Typography
                    id={`comment-view-type-${index}`}
                    className={classes.sharedText}
                    variant="subtitle1"
                  >
                    {`${intl.formatMessage(messages.shared)}: ${
                      getSharedWith(comment.view, organisationName)[0]
                    }`}
                  </Typography>
                )}
              </div>
              {enableEdit
                ? comment.id && (
                    <DeleteForever
                      onClick={() => {
                        onDelete(comment, index);
                      }}
                      className={classes.icon}
                    />
                  )
                : null}
            </div>
          </Grid>
        </Grid>
        <Grid style={{ height: 10 }} item xs={12} />
        <Grid item xs={12}>
          {enableEdit ? (
            <TextField
              variant="outlined"
              value={comment.description}
              id={`comment-description-${index}`}
              onChange={(event) =>
                onChange('description', event.target.value, index)
              }
              fullWidth
              multiline
              rowsMax={3}
              InputProps={{
                classes: {
                  root: classes.description,
                },
              }}
            />
          ) : (
            <Typography
              id={`comment-description-${index}`}
              className={classes.body}
              variant="body1"
              gutterBottom
            >
              {comment.description}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
  return (
    <div className={classes.root}>
      <CompanyTop
        didChange={didChange}
        handleDiscard={handleDiscard}
        setFeedResponse={commentResponse}
        enableEdit={enableEdit}
        showAdd={enableEdit && !addDisable}
        title="Comment"
        handleActionClick={handleActionClick}
        handleAddActionClick={handleAddActionClick}
        role={role}
      />
      <Paper className={classes.paperWrapper} elevation={2}>
        {loading ? (
          <>
            <Skeleton
              variant="rect"
              style={{ height: 100, width: '100%', marginBottom: 25 }}
            />
            <Skeleton
              variant="rect"
              style={{ height: 100, width: '100%', marginBottom: 25 }}
            />
          </>
        ) : comments.length || enableEdit ? (
          comments.map((elem, index) =>
            Comment(elem, index, onCommentChange, onCommentsDelete)
          )
        ) : (
          <CompanyDefaultText text="No Updates added yet" />
        )}
      </Paper>
    </div>
  );
};

CompanyComment.propTypes = {
  onDeleteComment: PropTypes.func.isRequired,
  onUpdateComment: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  comment: PropTypes.arrayOf(PropTypes.object).isRequired,
  organisationName: PropTypes.string.isRequired,
  viewContent: PropTypes.arrayOf(PropTypes.string).isRequired,
};

CompanyComment.defaultProps = {};

export default CompanyComment;
