import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'EmailVerification.title',
    defaultMessage: 'Verify your email',
  },
  direction: {
    id: 'EmailVerification.direction',
    defaultMessage:
      'Please verify your email by clicking the link in the confirmation email.',
  },
  resendEmailVerification: {
    id: 'EmailVerification.resendEmailVerification',
    defaultMessage: 'Resend Email Verification',
  },
});
