import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Grid, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { Input } from 'components/Common/Input';
import { Button } from 'components/Common/Buttons';
import translations from 'translations';
import { UPDATE_USER, GET_USER } from 'graphql/user';

import { useStyles } from './styles';

interface OnboardingStepFiveProps {
  setCurrentStep(num: number): void;
}

interface ValuProps {
  firstName: string;
  lastName: string;
  id: string;
  phone: string;
}

const OnboardingStepFive = ({
  setCurrentStep,
}: OnboardingStepFiveProps): JSX.Element => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [values, setValue] = useState<ValuProps>({
    firstName: '',
    id: '',
    lastName: '',
    phone: '',
  });
  const [updateUser] = useMutation(UPDATE_USER);
  const { data: { user = {} } = {} } = useQuery(GET_USER) as any; // use here ts use type instead of any

  const handleChange = (fieldName: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setValue({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  const onUpdateName = async (): Promise<void> => {
    await updateUser({
      variables: {
        input: {
          name: values.firstName,
          id: user.id,
          lastName: values.lastName,
          phone: values.phone,
        },
      },
      refetchQueries: [
        {
          query: GET_USER,
        },
      ],
    });
    setCurrentStep(5);
  };

  return (
    <Grid item xs={12} className={classes.step4Grid}>
      <Grid item xs={12} className={classes.gridWrapper}>
        <Typography variant="subtitle2" color="textPrimary" gutterBottom>
          {`${formatMessage(
            translations.Onboarding.onBoardingDialogOneMoreThing
          )}`}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.gridWrapper}>
        <Grid item xs={6}>
          <Input
            variant="outlined"
            label={`${formatMessage(
              translations.Onboarding.onBoardingFirstName
            )}`}
            value={values.firstName}
            placeholder="Enter your First Name"
            // formControlStyle={classes.inputField}
            onChange={handleChange('firstName')}
            id="user-firstName"
          />
          <div className={classes.spacing} />
          <Input
            variant="outlined"
            label={`${formatMessage(
              translations.Onboarding.onBoardingLastName
            )}`}
            value={values.lastName}
            placeholder="Enter your Last Name"
            // formControlStyle={classes.inputField}
            onChange={handleChange('lastName')}
            id="user-lastName"
          />
          <div className={classes.spacing} />
          <Input
            variant="outlined"
            label={`${formatMessage(translations.Onboarding.onBoardingPhone)}`}
            value={values.phone}
            placeholder="Enter your phone"
            // formControlStyle={classes.inputField}
            onChange={handleChange('phone')}
            id="user-phone"
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={6}
        className={`${classes.gridWrapper} ${classes.actionWrapper}`}
        justify="flex-start"
      >
        <Button
          typeColor="gray"
          color="primary"
          className={classes.grayBtn}
          onClick={(): void => {
            setCurrentStep(5);
          }}
          style={{ marginRight: 20 }}
        >
          {`${formatMessage(translations.Onboarding.onBoardingNext)}`}
        </Button>
        <Button
          typeColor="common"
          variant="contained"
          color="primary"
          className={classes.actionBtn}
          onClick={onUpdateName}
          id="step-5-button"
        >
          {`${formatMessage(translations.Onboarding.onBoardingSaveLabel)}`}
        </Button>
      </Grid>
    </Grid>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { OnboardingStepFive };

