/* eslint-disable no-restricted-globals */
import moment, { Moment } from 'moment';
import { useIntl } from 'react-intl';
import { Periodicity } from 'constants/index';
import { MetricUnitType } from 'types';

export const getQuarterly = (date?: Date): number => {
  const today = date || new Date();
  const month = today.getMonth() + 1;
  if (month < 4) return 2;
  if (month < 7) return 5;
  if (month < 10) return 8;
  return 11;
};

const month = new Date().getMonth();

export const periodicityResponse = {
  [Periodicity.MONTHLY]: {
    difference: 1,
    columns: 6,
    month: new Date().getMonth(),
  },
  [Periodicity.QUARTERLY]: {
    difference: 3,
    columns: 4,
    month: getQuarterly(),
  },
  [Periodicity.YTD]: {
    difference: 1,
    columns: (parseInt((month / 3).toString(), 3) + 1) * 3,
    month: (parseInt((month / 3).toString(), 3) + 1) * 3 - 1,
  },
  [Periodicity.ANNUAL]: {
    difference: 12,
    columns: 3,
    month: 0,
  },
  [Periodicity.HALF_YEAR]: {
    difference: 6,
    columns: 2,
    month: month < 5 ? 5 : 11,
  },
};

export const getPeriodictyDateByKey = (
  key: string,
  periodicity: string
): Moment => {
  if (periodicity === Periodicity.ANNUAL) {
    return moment(key, 'YYYY').endOf('year').endOf('month').startOf('day');
  }
  if (periodicity === Periodicity.QUARTERLY) {
    const [quarter, year] = key.split('/');
    return moment(year, 'YYYY')
      .quarter(+quarter[1])
      .endOf('quarter')
      .startOf('day');
  }
  return moment(key, 'MM/YYYY').endOf('month').startOf('day');
};

export const getPeriodictyDate = (date: Date, periodicity: string): string => {
  let tempDate = moment(date).format('MM/YYYY');
  if (![Periodicity.ANNUAL, Periodicity.QUARTERLY].includes(periodicity)) {
    return tempDate;
  }
  tempDate = moment(date).format('YYYY');
  if (periodicity === Periodicity.QUARTERLY) {
    tempDate = `Q${moment(date).quarter()}/${tempDate}`;
  }
  return tempDate;
};

export const useMetricField = () => {
  const { formatNumber } = useIntl();

  const renderFormatedMetric = ({
    unit,
    value,
  }: {
    value: string | number | null;
    unit: keyof MetricUnitType;
  }) => {
    if (value === null) return '';
    if (unit === 'Text') return value;
    if (unit === 'Date') {
      const date = moment(value);
      if (date.isValid()) {
        return date.format('DD/MM/YYYY');
      }
    }
    if (isNaN(Number(value))) return value;
    if (unit === '%')
      return formatNumber(Number(value), {
        style: 'percent',
      });
    if (unit === 'Number')
      return formatNumber(Number(value), {
        style: 'decimal',
        maximumFractionDigits: 2,
      });
    return formatNumber(Number(value), {
      style: 'currency',
      currency: unit,
    });
  };
  return { renderFormatedMetric };
};
