/* eslint-disable no-console */
export const logActionError = (
  type: 'error' | 'log',
  error: Error | null = null,
  actionName: string
): void => {
  if (process.env.NODE_ENV === 'development')
    console[type](`Error: ${actionName} Action: `, error);
};

export const isGraphqlServerError = (error: any): any =>
  error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].code;

export const handleGraphqlServerError = (error: any = null): string => {
  if (!error) return error;
  const graphqlError = isGraphqlServerError(error);
  return graphqlError || 'Whoops!! Something went wrong';
};
