/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/prefer-default-export */
import FileSaver from 'file-saver';
import axios from 'axios';
import config from '../config';
import { EXPORT_FORMAT } from '../constants';

const downloadFile = async (
  url,
  saveFileName,
  setDownload,
  method = 'GET',
  body
) => {
  setDownload({
    progressOpen: true,
    progress: 0,
    message: saveFileName,
  });
  const { auth0Client } = global;
  const { __raw: token } = (await auth0Client.getIdTokenClaims()) || {};
  const data = await axios.request({
    method,
    url,
    data: JSON.stringify(body),
    responseType: 'blob',
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      'Content-Type': 'application/json',
    },
    onDownloadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentCompleted = Math.round((loaded * 100) / total);
      setDownload({
        progress: percentCompleted,
      });
    },
  });
  if (data.status >= 200 && data.status <= 300) {
    const blob = await data.data;
    const fileLocalUrl = URL.createObjectURL(blob);
    FileSaver.saveAs(fileLocalUrl, saveFileName);
  }
};

export const getDownloads = async (
  filename,
  saveFileName,
  companyId,
  type = 'file',
  setDownload
) => {
  const url = `${config.downloads.url}downloads?file=${filename}&id=${companyId}&type=${type}`;
  await downloadFile(url, saveFileName, setDownload);
};

export const exportPdf = async (inputData, saveFileName, setDownload) => {
  const { companyId, company, feed, kpi, profile, matric } = inputData;
  const url = `${config.downloads.url}pdf_export?companyId=${companyId}&company=${company}&feed=${feed}&kpi=${kpi}&profile=${profile}&matric=${matric}`;
  await downloadFile(url, saveFileName, setDownload);
};

export const bulkExport = async (inputData, type, setDownload) => {
  const url = `${config.downloads.url}${
    type === EXPORT_FORMAT[0].name ? 'bulk_export_pdf' : 'bulk_export_excel'
  }`;
  await downloadFile(url, 'files.zip', setDownload, 'POST', inputData);
};

export const getImagesUrl = (image) => {
  const imageId = image ? image.split('/').pop() : '';
  const url = `${config.downloads.url}downloads?id=${imageId}`;
  return url;
};
