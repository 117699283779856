import React, { useState } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { SelectInput } from 'components/Common/Select';
import { Colors } from '../../../constants/colors';
import CompanySpecificSelector from './ExportImportComponent/CompanySpecificDropDown';
import { EXPORT_FORMAT, SECTIONS } from '../../../constants';

const Export = ({ exportDataToGS }) => {
  const [value, setValue] = useState('All');
  const [optionValue, setOptionsValue] = useState(SECTIONS);
  const [formatOption, setFormatOptions] = useState(EXPORT_FORMAT[0].name);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleOptionsChange = (event) => {
    setOptionsValue(event.target.value);
  };
  const handleFormatChange = (event) => {
    setFormatOptions(event.target.value);
  };
  const useStyle = makeStyles({
    root: {
      flexGrow: 1,
    },
    modelWidth: {
      maxWidth: '580px !important',
    },

    lowerCapText: {
      textTransform: 'initial',
    },
    labelStyle: {
      minWidth: '0%',
      marginRight: '10px',
      color: '#363636',
    },
    selectInput: {
      alignSelf: 'center',
      width: '40.5%',
      '& fieldset': {
        borderColor: Colors.TEXT,
      },
    },
    select: {
      padding: '5px',
      paddingLeft: '8px',
    },
  });
  const classes = useStyle();

  return (
    <div style={{ width: 580 }}>
      <Grid
        container
        // style={useAsModal ? modalStyle : {}}
        className={`${classes.modelWidth} `}
        direction="column"
        spacing={1}
      >
        <Grid container xs={12} style={{ marginBottom: 15 }}>
          <Typography variant="h5">Export</Typography>
        </Grid>

        <Grid container style={{ marginBottom: 10 }}>
          <Grid item xs={3}>
            <Typography>Format</Typography>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.selectInput}>
              <SelectInput
                value={formatOption}
                fullWidth
                onChange={handleFormatChange}
                options={EXPORT_FORMAT}
                customClasses={{
                  select: classes.select,
                }}
              />
            </div>
          </Grid>
        </Grid>
        <CompanySpecificSelector
          value={value}
          handleChange={handleChange}
          optionValue={optionValue}
          handleOptionsChange={handleOptionsChange}
          showAllWithDataOption
        />
        <Grid item>
          <Button
            style={{
              borderRadius: 20,
              width: 160,
              marginTop: 20,
            }}
            classes={{
              label: classes.lowerCapText,
            }}
            variant="contained"
            color="primary"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={() =>
              exportDataToGS({
                formatOption,
                value,
                optionValue,
              })
            }
          >
            Export
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

Export.propTypes = {
  exportDataToGS: PropTypes.func,
};

Export.defaultProps = {
  exportDataToGS: () => {},
};

export default Export;
