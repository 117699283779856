/* eslint-disable quotes */
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

export const useStyles = makeStyles((theme) => ({
  modelRoot: {
    minWidth: '640px',
  },
  thumb: {
    backgroundColor: '#00696B',
  },
  track: {
    backgroundColor: '#00696B',
  },
  selectOption: {
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '30px 40px 30px 15px',
    // '& >div': {
    //   flexBasis: '50%',
    // },
    marginBottom: theme.spacing(3),
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flexRowStart: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexRowFullwidth: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  searchTextInput: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  lastUpdateLabel: {
    marginRight: '20px',
  },
  draftRequestButton: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    borderRadius: '40px',
    textTransform: 'none',
  },
  title: {
    margin: '5px 0',
    color: '#363636',
    letterSpacing: 1,
    fontWeight: '400',
  },
  paper: {
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    flexDirection: 'row',
    margin: `${theme.spacing(2)}px auto`,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  grid: {
    minHeight: '225px',
    padding: theme.spacing(2),
  },
  sectionTitles: {
    color: '#212121',
    fontWeight: '400',
    letterSpacing: 0.2,
  },
  sectionSubtitles: {
    fontSize: '1rem',
    color: '#212121',
    fontWeight: '400',
    marginTop: '10px',
    marginBottom: '5px',
  },
  label: {
    margin: '5px 0',
    color: '#363636',
    letterSpacing: 1,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: `${theme.spacing(2)}px 0`,
  },
  subjectText: {
    border: '1px solid #cecece',
    borderRadius: 5,
    paddingRight: 3,
    paddingLeft: 10,
    height: '25px',
    overflow: 'hidden',
  },
  subjectContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subjectWrapper: {
    display: 'flex',
    flex: 1,
  },
  subjectInput: {
    [`& fieldset`]: {
      // border: '1px solid black',
      borderRadius: '5px',
    },
    [`& input`]: {
      paddingTop: '5.5px',
      paddingBottom: '5.5px',
    },
  },
  subjectField: {
    '& .MuiOutlinedInput-root': {
      height: theme.spacing(4.4),
      lineHeight: `${theme.spacing(3.75)}px`,
    },
  },
  checkPointFirst: {
    marginRight: '20px',
  },
  subjectRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '30px',
  },
  mainTextField: {
    marginTop: '30px',
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    minWidth: '690px',
    maxWidth: '100%',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
  },
  modal: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  fullWidth: {
    width: '100%',
  },
  emptySpace: {
    height: theme.spacing(3.2),
  },
  lowerCapText: {
    textTransform: 'initial',
  },
  inputHeight: {
    height: theme.spacing(4.4),
    lineHeight: `${theme.spacing(3.75)}px`,
  },
  selectInput: {
    height: theme.spacing(4.4),
  },
  placeholderContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  RequestDataWrapper: {
    minWidth: 830,
  },
}));

export const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    minWidth: theme.spacing(15),
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: '0.8rem',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);
