import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  },
  title: {
    margin: '5px 0',
    color: '#363636',
    letterSpacing: 1,
    fontWeight: '400',
    textAlign: 'center',
    marginBottom: theme.spacing(2.5),
  },
  paper: {
    width: '350px',
  },
  contentWrapper: {
    padding: `${theme.spacing(2.5)}px ${theme.spacing(1.3)}px`,
  },
  logoWrapper: {
    width: '100%',
    height: 60,
    backgroundColor: '#1976d2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 145,
    height: 35,
  },
  direction: {
    marginTop: theme.spacing(2.6),
  },
  actionBtn: {
    width: '85%',
    marginBottom: theme.spacing(2.5),
    backgroundColor: '#1976d2',
    '&:hover': {
      background: '#025bb3',
    },
  },
}));
