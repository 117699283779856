/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';

// import { useAuth0 } from 'components/Common/Auth';
import getGraphqlErrorMessage from 'common/getGraphqlErrorMessage';
import { ORGANISATION_TYPES, COMPANY_CONTACT_TYPES } from 'constants/index';
import { userRoles } from 'user/userConstants';
import OrganisationKeyDisplay from '../OrganisationKeyDisplay';
import { ManageUser } from '../ManageUser';
import { ManageOrganisation } from '../ManageOrganisation';
import { UserAccessModal } from '../UserAccessModal';
import messages from './messages';
import { useStyles } from './styles';
import {
  SHARE_ORGANISATION,
  UNSHARE_ORGANISATIONS,
  GET_SHARED_ORGANISATIONS,
  UPDATE_ORGANISATION,
  UPDATE_ORGANISATION_ROLE,
} from '../../../graphql';

export default function TabPanel(props) {
  const {
    selectedOrganisation,
    onErrorShareOrganisation,
    onSuccessShareOrganisation,
    onErrorUnshareOrganisation,
    onSuccessUnshareOrganisation,
    onSuccessUpdateOrganisation,
    onCreateUser,
    onUpdateUser,
    onDeleteUser,
    onUpdateUserRole,
    onUpdateContact,
    onDeleteContact,
    setLoading,
  } = props;
  const classes = useStyles();
  const [update, setUpdate] = useState(0);
  const [userData, setUserData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  // const { user } = useAuth0();
  const intl = useIntl();

  const [shareOrganisation] = useMutation(SHARE_ORGANISATION, {
    onCompleted: () => onSuccessShareOrganisation(),
  });
  const [unshareOrganisations] = useMutation(UNSHARE_ORGANISATIONS, {
    onCompleted: () => onSuccessUnshareOrganisation(),
  });
  const [updateOrganisation] = useMutation(UPDATE_ORGANISATION, {
    onCompleted: () => onSuccessUpdateOrganisation,
  });
  const [updateOrganisationRole] = useMutation(UPDATE_ORGANISATION_ROLE, {
    onCompleted: () => onSuccessUpdateOrganisation,
  });

  const {
    data: { organisation: { sharedOrganisations = [] } = {} } = {},
    refetch: getSharedOrganisations,
    loading,
  } = useQuery(GET_SHARED_ORGANISATIONS, {
    variables: { organisationId: selectedOrganisation.id },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const onShareOrganisation = async (organisationKey, role) => {
    try {
      await shareOrganisation({
        variables: {
          role,
          originOrganisationKey: organisationKey,
          targetOrganisationId: selectedOrganisation.id,
        },
      });
      await getSharedOrganisations();
      setUpdate(update + 1);
    } catch (error) {
      const errorMessage = getGraphqlErrorMessage(error);
      if (onErrorShareOrganisation) onErrorShareOrganisation(errorMessage);
    }
  };

  const onUpdateOrganisationRole = async (originId, role) => {
    await updateOrganisationRole({
      variables: {
        input: {
          role: Object.values(userRoles)[role],
          originOrganisationId: originId,
          targetOrganisationId: selectedOrganisation.id,
        },
      },
    });
    await getSharedOrganisations();
    setUpdate(update + 1);
  };

  const onUpdateOrganisation = async (name, id) => {
    updateOrganisation({
      variables: {
        name,
        id,
      },
    });
    await getSharedOrganisations();
    setUpdate(update + 1);
  };

  const onDeleteSharedOrganisation = (organisationsRemove) => {
    const organisationsIds = [organisationsRemove];

    const unshareOrganisationRequest = async () => {
      try {
        await unshareOrganisations({
          variables: {
            originOrganisationIds: organisationsIds,
            targetOrganisationId: selectedOrganisation.id,
          },
        });
      } catch (error) {
        const errorMessage =
          error.graphQLErrors &&
          error.graphQLErrors[0] &&
          error.graphQLErrors[0].code;

        if (onErrorUnshareOrganisation)
          onErrorUnshareOrganisation(errorMessage);
      }
      await getSharedOrganisations();
      setUpdate(update + 1);
    };
    unshareOrganisationRequest();
    return false;
  };

  const deleteRecord = async (record) => {
    switch (record.type) {
      case 'user':
        await onDeleteUser(record.id);
        setUpdate(update + 1);
        break;

      case 'organization':
        await onDeleteSharedOrganisation(record.id);
        setUpdate(update + 1);
        break;
      default:
        break;
    }
  };

  const handleCreateUser = async (paylod) => {
    const newUserId = await onCreateUser(paylod);
    setUpdate(update + 1);
    return newUserId;
  };

  const handleUpdateUser = async (paylod) => {
    await onUpdateUser(paylod);
    setUpdate(update + 1);
  };

  const handleUpdateContact = async (paylod) => {
    await onUpdateContact(paylod);
    setUpdate(update + 1);
  };

  useEffect(() => {
    if (Array.isArray(sharedOrganisations)) {
      setOrgData(
        sharedOrganisations.map((elem) => ({
          ...elem,
          role: Object.values(userRoles).findIndex((ele) => ele === elem.role),
        }))
      );
    }
    const { users, companies } = selectedOrganisation;
    if (Array.isArray(users)) {
      const usersData = [];
      users.forEach((userObj) => {
        // Show existing user also
        // https://trello.com/c/2jt0Y30V/325-does-not-show-creating-user-in-list-of-users
        // if (userObj.id === user.id) {
        //   return;
        // }
        let isContact = false;
        if (
          Array.isArray(companies) &&
          companies.length &&
          Array.isArray(companies[0].contacts)
        ) {
          const userContact = companies[0].contacts.find(
            (ele) => ele.userId === userObj.id
          );
          isContact =
            userContact &&
            userContact.contactType === COMPANY_CONTACT_TYPES.CONTACT
              ? userContact
              : null;
        }

        usersData.push({
          ...userObj,
          role: Object.values(userRoles).findIndex(
            (ele) => ele === userObj.role
          ),
          isContact,
        });
      });
      setUserData(usersData);
    }
  }, [
    JSON.stringify(selectedOrganisation.users),
    JSON.stringify(sharedOrganisations),
    JSON.stringify(selectedOrganisation.companies),
  ]);

  const handleModalAction = (open) => {
    setShowModal(open);
  };

  return (
    <div>
      <UserAccessModal
        showAddToContact={
          selectedOrganisation.type === ORGANISATION_TYPES.COMPANY
        }
        onCreateUser={handleCreateUser}
        onUpdateContact={handleUpdateContact}
        onShareOrganisation={onShareOrganisation}
        type={modalType}
        data={userData}
        isOpened={showModal}
        onClose={() => handleModalAction(false)}
      />
      <Typography
        component="h3"
        variant="h4"
        color="primary"
        gutterBottom
        className={classes.sectionTitles}
      >
        {intl.formatMessage(messages.userAndAccess)}
      </Typography>
      <Typography variant="body1" className={classes.strongText}>
        {`${intl.formatMessage(messages.organisationName)} ${
          selectedOrganisation.name
        }`}
      </Typography>
      <Typography variant="body1" className={classes.strongText}>
        {`${intl.formatMessage(messages.organisationType)} ${
          selectedOrganisation.type
        }`}
      </Typography>
      {selectedOrganisation.type === ORGANISATION_TYPES.FUND && (
        <OrganisationKeyDisplay
          organisation={selectedOrganisation.name}
          orgkey={selectedOrganisation.orgKey}
        />
      )}
      <ManageUser
        onDeleteContact={onDeleteContact}
        onUpdateContact={handleUpdateContact}
        showContacts={selectedOrganisation.type === ORGANISATION_TYPES.COMPANY}
        data={userData}
        onUpdateRole={onUpdateUserRole}
        deleteRecord={deleteRecord}
        onUpdateUser={handleUpdateUser}
        openModal={() => {
          setModalType('user');
          handleModalAction(true);
        }}
      />
      <div style={{ marginBottom: 20 }} />
      {selectedOrganisation.type === ORGANISATION_TYPES.COMPANY && (
        <ManageOrganisation
          onUpdateOrganisation={onUpdateOrganisation}
          onUpdateOrganisationRole={onUpdateOrganisationRole}
          deleteRecord={deleteRecord}
          data={orgData}
          openModal={() => {
            setModalType('organisation');
            handleModalAction(true);
          }}
        />
      )}
    </div>
  );
}

TabPanel.propTypes = {
  selectedOrganisation: PropTypes.object,
  onErrorShareOrganisation: PropTypes.func,
  onSuccessShareOrganisation: PropTypes.func,
  onErrorUnshareOrganisation: PropTypes.func,
  onSuccessUnshareOrganisation: PropTypes.func,
  onSuccessUpdateOrganisation: PropTypes.func,
};

TabPanel.defaultProps = {
  selectedOrganisation: {},
  onErrorShareOrganisation: null,
  onSuccessShareOrganisation: null,
  onErrorUnshareOrganisation: null,
  onSuccessUnshareOrganisation: null,
  onSuccessUpdateOrganisation: null,
};
