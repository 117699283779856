/* eslint-disable import/prefer-default-export */
export const ACTION_TYPES_PORTFOLIO = {
  GET_PORTFOLIOS: 'GET_PORTFOLIOS',
  GET_PORTFOLIOS_SUCCESS: 'GET_PORTFOLIOS_SUCCESS',
  GET_PORTFOLIOS_FAIL: 'GET_PORTFOLIOS_FAIL',
  CREATE_PORTFOLIO: 'CREATE_PORTFOLIO',
  CREATE_PORTFOLIO_SUCCESS: 'CREATE_PORTFOLIO_SUCCESS',
  CREATE_PORTFOLIO_FAIL: 'CREATE_PORTFOLIO_FAIL',
  GET_SELECTED_PORTFOLIO: 'GET_SELECTED_PORTFOLIO',
  UPDATE_INVESTMENT: 'UPDATE_INVESTMENT',
  UPDATE_INVESTMENT_SUCCESS: 'UPDATE_INVESTMENT_SUCCESS',
  UPDATE_INVESTMENT_FAIL: 'UPDATE_INVESTMENT_FAIL',
  DELETE_INVESTMENT: 'DELETE_INVESTMENT',
  DELETE_INVESTMENT_SUCCESS: 'DELETE_INVESTMENT_SUCCESS',
  DELETE_INVESTMENT_FAIL: 'DELETE_INVESTMENT_FAIL',
  UPDATE_PORTFOLIO: 'UPDATE_PORTFOLIO',
  UPDATE_PORTFOLIO_SUCCESS: 'UPDATE_PORTFOLIO_SUCCESS',
  UPDATE_PORTFOLIO_FAIL: 'UPDATE_PORTFOLIO_FAIL',
  DELETE_PORTFOLIO: 'DELETE_PORTFOLIO',
  DELETE_PORTFOLIO_SUCCESS: 'DELETE_PORTFOLIO_SUCCESS',
  DELETE_PORTFOLIO_FAIL: 'DELETE_PORTFOLIO_FAIL',
};
