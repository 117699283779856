import React, { useState } from 'react';
import PropsTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { Modal, Grid, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useStyles } from './styles';
import messages from './messages';
import { VIEW_CONTENT_TYPE } from '../../../constants';

const AddReportModal = ({ onClose, isOpened, file, handleCreateReport }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [state, setState] = useState({
    filename: '',
    period: 'cs',
    sharedWith: VIEW_CONTENT_TYPE[0].name.toLowerCase(),
  });

  const handleChange = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  return (
    <Modal
      open={isOpened}
      aria-labelledby="onbording_modal"
      aria-describedby="onbording_modal"
      BackdropProps={{ className: classes.modalOverlay }}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className={classes.modal}
      onClose={onClose}
    >
      <div className={classes.modelContainer}>
        <Grid
          container
          className={classes.paperModal}
          direction="column"
          spacing={1}
        >
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.title}>
              {intl.formatMessage(messages.add)}
            </Typography>
          </Grid>
          <br />
          <Grid item xs={12} direction="row">
            <div style={{ display: 'flex' }}>
              <Typography
                className={classes.label}
                variant="subtitle1"
                gutterBottom
              >
                {intl.formatMessage(messages.file)}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {file ? file.name : ''}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: 'flex' }}>
              <Typography
                className={classes.label}
                variant="subtitle1"
                gutterBottom
              >
                {intl.formatMessage(messages.period)}
              </Typography>
              <Select
                className={classes.label}
                value={state.period}
                onChange={(event) => handleChange('period', event.target.value)}
              >
                <MenuItem value="cs">Coming Soon</MenuItem>
              </Select>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div style={{ display: 'flex' }}>
              <Typography
                className={classes.label}
                variant="subtitle1"
                gutterBottom
              >
                {intl.formatMessage(messages.sharedWith)}
              </Typography>
              <Select
                className={classes.label}
                value={state.sharedWith}
                onChange={(event) =>
                  handleChange('sharedWith', event.target.value)
                }
              >
                {VIEW_CONTENT_TYPE.map((elem) => (
                  <MenuItem value={elem.name.toLowerCase()}>
                    {elem.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
          <br />
          <Grid item xs={4}>
            <Button
              className={classes.addButton}
              id="add-report-modal"
              classes={{
                label: classes.lowerCapText,
              }}
              onClick={() => {
                handleCreateReport({
                  file,
                  period: state.period,
                  view: state.sharedWith,
                });
                onClose();
              }}
              variant="contained"
              color="primary"
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

AddReportModal.propTypes = {
  onClose: PropsTypes.func,
  isOpened: PropsTypes.bool,
  file: PropsTypes.object,
  handleCreateReport: PropsTypes.func,
};

AddReportModal.defaultProps = {
  onClose: () => {},
  isOpened: false,
  file: null,
  handleCreateReport: () => {},
};

export default AddReportModal;
