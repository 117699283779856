import { gql } from 'apollo-boost';
import { USER_PAYLOAD } from './payloads';

export const GET_USER = gql`
  query {
    user {
      ${USER_PAYLOAD}
    }
  }
`;

// todo, remove it when not needed
export const UPDATE_USER = gql`
  mutation($input: UpdateUserInput) {
    updateUser(input: $input) {
        ${USER_PAYLOAD}
    }
  }
`;

export const DELETE_USER = gql`
  mutation($id: ID!) {
    deleteUser(id: $id)
  }
`;

export const CREATE_USERS = gql`
  mutation($input: [CreateUserInput]!) {
    createUsers(input: $input) {
      id
    }
  }
`;
