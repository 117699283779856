import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

export default function NumberFormatCustom(props) {
  const { inputRef, onChange, onBlur, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            formattedValue: values.formattedValue,
            floatValue: values.floatValue,
          },
        });
      }}
      onBlur={(values) => {
        if (onBlur) {
          onBlur({
            target: {
              value: values.target.value,
            },
          });
        }
      }}
      thousandSeparator
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.element }),
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  thousandSeparator: PropTypes.bool,
};

NumberFormatCustom.defaultProps = {
  inputRef: null,
  onChange: null,
  onBlur: null,
  thousandSeparator: false,
};
