/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, CircularProgress } from '@material-ui/core';
import { userRoles } from 'user/userConstants';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from 'containers/Config';
import {
  getIsEditingInvestments,
  setIsEditingInvestments,
  getIsLoadingInvestments,
} from 'reducers';
import { CompanyTop } from './CompanyTop';
import { useStyle } from './styles';
import InvestmentTable from './InvestmentTable';

const InvestmentTableCard = ({
  investments = [],
  role,
  disableHeader,
  onSave,
  onRemove,
}) => {
  const classes = useStyle();
  const isEditing = useSelector(getIsEditingInvestments);
  const isLoading = useSelector(getIsLoadingInvestments);
  const [didChange, setDidChange] = useState(false);
  const [discard, setDiscard] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsEditingInvestments(false));
  }, [investments]);

  const handleInvesmentChange = (bool) => {
    setDidChange(bool);
  };

  const handleActionClick = () => {
    setDiscard(false);
    dispatch(setIsEditingInvestments(!isEditing));
  };

  const handleDiscard = () => {
    setDiscard(true);
    dispatch(setIsEditingInvestments(!isEditing));
  };

  const handleOnSave = (invests) => {
    if (!discard)
      if (invests) {
        onSave(invests);
      } else {
        dispatch(
          setAlert({
            open: true,
            message: {
              id: 'Reuseable.InvestmentsTableCard.validation',
              defaultMessage: 'Please fill up all highlighted fields',
            },
            severity: 'warning',
          })
        );
        dispatch(setIsEditingInvestments(true));
      }
  };

  return (
    <div className={classes.root}>
      <CompanyTop
        didChange={didChange}
        handleDiscard={handleDiscard}
        setFeedResponse={handleActionClick}
        enableEdit={isEditing}
        title="Investments"
        handleActionClick={handleActionClick}
        role={role}
      />
      <Paper className={classes.paperWrapper} style={{ position: 'relative' }}>
        <InvestmentTable
          investments={investments}
          edit={isEditing}
          handleInvesmentChange={handleInvesmentChange}
          disableHeader={disableHeader}
          onSave={handleOnSave}
          onRemove={onRemove}
        />
        {isLoading && (
          <div
            style={{
              backgroundColor: 'rgb(255, 255, 255, 0.8)',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress disableShrink size={35} />
          </div>
        )}
      </Paper>
    </div>
  );
};

InvestmentTable.propTypes = {
  investments: PropTypes.arrayOf(PropTypes.object),
  role: PropTypes.string,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
};

InvestmentTable.defaultProps = {
  investments: [],
  role: userRoles.VIEWER,
  onSave: () => {},
  onRemove: () => {},
};

export default InvestmentTableCard;
