import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formWrapper: {
    backgroundColor: 'white',
    padding: theme.spacing(4),
    boxShadow: '0px 0px 5px 0.1rem rgba(0, 0, 0, 0.11)',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  actionBtnWrapper: {
    marginTop: '20px',
  },
  addButton: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    width: '130px',
    marginLeft: 150,
  },
  inputWidth: {
    minWidth: '230px',
    marginTop: '10px',
    width: '100%',
    '& label': {
      marginRight: '0px',
      width: '40%',
    },
  },
  radioContainer: {
    display: 'flex',
    flex: 1,
  },
  btnContainer: {
    alignSelf: 'flex-end',
    width: 95,
    '& button': {
      width: '100%',
    },
  },
}));
