import React, { memo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import { Draggable } from 'react-beautiful-dnd';

export const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
});

export const DraggableRowComponent = memo(
  (props: any) => {
    return (
      <Draggable draggableId={props.id} index={props.index}>
        {(provided, snapshot) => (
          <TableRow
            ref={provided.innerRef}
            {...(props.enableEdit
              ? { ...provided.draggableProps, ...provided.dragHandleProps }
              : null)}
            style={{
              ...getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              ),
            }}
            {...props}
          >
            {props.children}
          </TableRow>
        )}
      </Draggable>
    );
  },
  (prev, next) => {
    return (
      JSON.stringify(prev.columns) === JSON.stringify(next.columns) &&
      prev.enableEdit === next.enableEdit &&
      prev.metricUnit === next.metricUnit &&
      !next.reset
    );
  }
);
