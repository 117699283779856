/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import { selectSelectedOrganization } from 'containers/Organisation';
import { GET_SINGLE_METRICS } from '../../graphql/company';

import Alert from '../../components/Common/UI/Alert';
import { getKpiData } from '../../reducers';
import { CompanyAction } from '../../actions';

import KpiPage from '../../components/KpiPage/KpiPage';

const Container = ({
  // companies,
  kpiData,
  getKpiPageData,
  getUpdateKpiData,
  getUpdateKpiPeriodicity,
  getUpdateKpiPeriodicityDate,
  deleteKpiColumn,
  addKpiDataColumn,
  getCompaniesByOrganisation,
}) => {
  const organisation = useSelector(selectSelectedOrganization);
  const { companies } = organisation;
  const getYear = new Date().getFullYear();
  const getMonth = new Date().getMonth() + 1;
  const currentDate = `0${getMonth}/${getYear}`;
  const columnVariables = kpiData
    .map(({ metricName, periodicity, metricCategory, date }) => ({
      metricName,
      periodicity: periodicity === 'Period' ? 'Monthly' : periodicity,
      metricCategory,
      date: date || currentDate,
    }))
    .slice(1);
  const params = useParams();
  const { loading, data: { getSingleMetrics = [] } = {} } = useQuery(
    GET_SINGLE_METRICS,
    {
      variables: {
        companyIds: companies.map((item) => item.id),
        columns: columnVariables,
      },
      fetchPolicy: 'no-cache',
    }
  );

  React.useEffect(() => {
    getKpiPageData(organisation.id, companies);
    if (Array.isArray(companies) && !companies.length) {
      getCompaniesByOrganisation(params.id);
    }
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={60} />
        </div>
      ) : (
        <KpiPage
          companiesByOrganisation={companies}
          columns={kpiData}
          metricsData={getSingleMetrics}
          getUpdateKpiData={getUpdateKpiData}
          getUpdateKpiPeriodicity={getUpdateKpiPeriodicity}
          getUpdateKpiPeriodicityDate={getUpdateKpiPeriodicityDate}
          deleteKpiColumn={deleteKpiColumn}
          addKpiDataColumn={addKpiDataColumn}
        />
      )}
      <Snackbar open={false} autoHideDuration={4000} onClose={() => {}}>
        <Alert onClose={() => {}} severity="success" />
      </Snackbar>
    </>
  );
};

const mapState = createStructuredSelector({
  kpiData: getKpiData,
});

const mapDispatch = (dispatch) => ({
  getCompaniesByOrganisation: (id) =>
    dispatch(CompanyAction.getCompaniesByOrganisation(id)),
  getKpiPageData: (orgId, companies) =>
    dispatch(CompanyAction.getKpiPageData(orgId, companies)),
  getUpdateKpiData: (actionData) =>
    dispatch(CompanyAction.getUpdateKpiData(actionData)),
  getUpdateKpiPeriodicity: (actionData) =>
    dispatch(CompanyAction.getUpdateKpiPeriodicity(actionData)),
  getUpdateKpiPeriodicityDate: (actionData) =>
    dispatch(CompanyAction.getUpdateKpiPeriodicityDate(actionData)),
  deleteKpiColumn: (id) => dispatch(CompanyAction.deleteKpiColumn(id)),
  addKpiDataColumn: () => dispatch(CompanyAction.addKpiDataColumn()),
});

Container.propTypes = {
  getCompaniesByOrganisation: PropTypes.func.isRequired,
  kpiData: PropTypes.arrayOf(PropTypes.object).isRequired,
  getKpiPageData: PropTypes.func.isRequired,
  getUpdateKpiData: PropTypes.func.isRequired,
  getUpdateKpiPeriodicity: PropTypes.func.isRequired,
  getUpdateKpiPeriodicityDate: PropTypes.func.isRequired,
  deleteKpiColumn: PropTypes.func.isRequired,
  addKpiDataColumn: PropTypes.func.isRequired,
};

const KpiPageScreenContainer = connect(mapState, mapDispatch)(Container);

export { KpiPageScreenContainer };
