import React, { useState, useEffect } from 'react';
import { Box, TextField } from '@material-ui/core';
import PropType from 'prop-types';
import HistoryIcon from '@material-ui/icons/History';
import BusinessIcon from '@material-ui/icons/Business';
import LanguageIcon from '@material-ui/icons/Language';
import GroupIcon from '@material-ui/icons/Group';
import EmailIcon from '@material-ui/icons/Email';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import DescriptionIcon from '@material-ui/icons/Description';

import { useStyles } from './styles';

const CompanyProfileItem = ({
  item,
  editable,
  placeholder,
  saveChanges,
  validator,
  helperText,
}) => {
  const classes = useStyles();
  const [currentItem, setCurrentItem] = useState(item);
  const [validInput, setValidInput] = useState({
    notValid: false,
    helperText: '',
  });

  // eslint-disable-next-line consistent-return
  const getIcon = (icon) => {
    // eslint-disable-next-line default-case
    switch (icon) {
      case 'Founded':
        return <HistoryIcon className={classes.icon} />;
      case 'Industry':
        return <BusinessIcon className={classes.icon} />;
      case 'Website':
        return <LanguageIcon className={classes.icon} />;
      case 'Team':
        return <GroupIcon className={classes.icon} />;
      case 'Email':
        return <EmailIcon className={classes.icon} />;
      case 'Employees':
        return <PermContactCalendarIcon className={classes.icon} />;
      case 'Legal Name':
        return <DescriptionIcon className={classes.icon} />;
    }
  };

  useEffect(() => {
    if (JSON.stringify(item) !== JSON.stringify(currentItem)) {
      setCurrentItem(JSON.parse(JSON.stringify(item)));
    }
  }, [item]);

  const handleValidate = (value) => {
    if (!validator) {
      return true;
    }
    const isValid = validator(value);
    setValidInput({
      notValid: !isValid,
      helperText: !isValid ? helperText : '',
    });
    return isValid;
  };

  const handleOnChange = ({ target: { value } }) => {
    const isValid = handleValidate(value);
    setCurrentItem({ ...item, description: value, isValid });
  };

  const handleSaveChanges = () => {
    saveChanges({ ...currentItem });
  };
  return (
    <Box className={classes.flexBox}>
      <Box className={classes.iconBox}>{getIcon(item.title)}</Box>
      <Box className={classes.contentBox}>
        <div className={`${classes.text} ${classes.contentTitle}`}>
          {currentItem.title}
        </div>
        {editable ? (
          <TextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            placeholder={placeholder}
            value={currentItem.description || ''}
            onChange={handleOnChange}
            onBlur={handleSaveChanges}
            error={validInput.notValid}
            helperText={validInput.helperText}
            multiline
            rows={3}
          />
        ) : (
          <div>{currentItem.description}</div>
        )}
      </Box>
    </Box>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { CompanyProfileItem };

CompanyProfileItem.propTypes = {
  item: PropType.object,
  editable: PropType.bool,
  saveChanges: PropType.func,
  placeholder: PropType.string,
  validator: PropType.func,
  helperText: PropType.string,
};

CompanyProfileItem.defaultProps = {
  item: {},
  editable: false,
  saveChanges: () => {},
  validator: null,
  placeholder: '',
  helperText: '',
};
