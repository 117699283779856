/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, FormControlLabel, Button } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { Checkbox } from 'components/Common/Checkbox';
import { Input } from 'components/Common/Input';
// import { SelectInput } from 'components/Common/Select';
import { COMPANY_CONTACT_TYPES } from 'constants/contacts';
import { emailRegex } from 'utils';
import { userRoles } from 'user/userConstants';
import { useStyles } from './style';
import RoleRadio from './RoleRadio';
import messages from './messages';


interface Props {
  showRoles: boolean,
  showAddToContact: boolean,
  useAsModal: boolean,
  showHeader: boolean,
  isCompanyAdd: boolean,
  data: Array<Object>,
  onCreateUser(obj: object): string,
  closeModal(): void,
  onUpdateContact(obj: object): void,
}

const AddUser = ({
  onCreateUser,
  closeModal,
  onUpdateContact,
  showRoles,
  isCompanyAdd,
  data,
  showAddToContact,
  useAsModal,
  showHeader,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  // const [contactTypes, setContactTypes] = useState([]);
  const [state, setState] = useState({
    email: '',
    firstName: '',
    lastName: '',
    role: userRoles.ADMIN,
    contactType: COMPANY_CONTACT_TYPES.CONTACT,
  });
  const [addToContact, setAddToContact] = useState(false);
  const [valid, setValid] = useState({
    emailValid: true,
    emailHelperText: '',
  });
  const validateInput = (key: string, value: string): void => {
    switch (key) {
      case 'email':
        if(data.some((user: any) => user.email === value)){
          return setValid({
            ...valid,
            emailValid: false,
            emailHelperText: 'The user already exists',
          });
        }
        if (!emailRegex.test(value)) {
          return setValid({
            ...valid,
            emailValid: false,
            emailHelperText: 'invalid format',
          });
        }
        setValid({
          ...valid,
          emailValid: true,
          emailHelperText: '',
        });
        break;
    }
  };

  useEffect(() => {
    setState({
      email: '',
      firstName: '',
      lastName: '',
      role: userRoles.ADMIN,
      contactType: '',
    });
  }, [isCompanyAdd]);

  const handleOnChange = (key: string, value: string): void => {
    setState({
      ...state,
      [key]: value,
    });
  };
  const handleAdd = async () => {
    const { email, firstName, lastName, role, contactType } = state;
    const userId = await onCreateUser({
      email,
      role,
      firstName,
      lastName,
      contactType,
    });
    if (addToContact) {
      await onUpdateContact({
        userId,
        email,
        role,
        firstName,
        lastName,
        contactType,
      });
    }
    setState({
      email: '',
      firstName: '',
      lastName: '',
      role: userRoles.ADMIN,
      contactType: '',
    });
    return closeModal();
  };

  // React.useEffect(() => {
  //   setContactTypes(
  //     Object.keys(COMPANY_CONTACT_TYPES).map((key) => {
  //       const name = COMPANY_CONTACT_TYPES[key];
  //       name[1].toUpperCase();
  //       return {
  //         id: key,
  //         value: name,
  //         name,
  //       };
  //     })
  //   );
  // }, []);

  return (
    <div style={{ width: 580 }} onClick={(e) => e.stopPropagation()}>
      <Grid
        container
        className={`${classes.modelWidth} ${
          useAsModal ? classes.paperModal : ''
        }`}
        direction="column"
        spacing={1}
      >
        <Grid style={{ display: showHeader ? 'initial' : 'none' }} item>
          <Typography variant="h5" className={classes.title}>
            {formatMessage(messages.addUser)}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Input
            variant="outlined"
            label="Email*"
            value={state.email}
            onBlur={(e) => validateInput('email', e.target.value)}
            onChange={(e) => handleOnChange('email', e.target.value)}
            // gridStyle={{ xs: 12 }}
            error={!valid.emailValid}
            errorText={valid.emailHelperText}
            fullWidth
            lableStyle={classes.labelStyle}
          />
        </Grid>
        <Grid item xs={9}>
          <Input
            variant="outlined"
            label={formatMessage(messages.firstName)}
            value={state.firstName}
            onBlur={(e) => validateInput('firstName', e.target.value)}
            onChange={(e) => handleOnChange('firstName', e.target.value)}
            // gridStyle={{ xs: 12 }}
            fullWidth
            lableStyle={classes.labelStyle}
            // error={!valid.firstNameValid}
            // errorText={valid.firstNameHelperText}
          />
        </Grid>
        <Grid item xs={9}>
          <Input
            variant="outlined"
            label={formatMessage(messages.lastName)}
            value={state.lastName}
            onBlur={(e) => validateInput('lastName', e.target.value)}
            onChange={(e) => handleOnChange('lastName', e.target.value)}
            // gridStyle={{ xs: 12 }}
            fullWidth
            lableStyle={classes.labelStyle}
            // error={!valid.lastNameValid}
            // errorText={valid.lastNameHelperText}
          />
        </Grid>
        {/* {
            from === 'addCompanies'
              && (
                <Grid item xs={9}>
                  <SelectInput
                    label={`${intl.formatMessage(messages.contactType)}`}
                    lableStyle={classes.labelStyle}
                    value={state.contactType}
                    fullWidth
                    onBlur={(e) => validateInput('lastName', e.target.value)}
                    onChange={(e) => handleOnChange('contactType', e.target.value)}
                    options={contactTypes}
                    formControlStyle={classes.selectInput}
                  />
                </Grid>
              )
          } */}
        <Grid style={{ display: showRoles ? 'initial' : 'none' }} item xs={12}>
          <RoleRadio role={state.role} handleOnChange={handleOnChange} />
        </Grid>
        <Grid item>
          { showAddToContact && <FormControlLabel
            className={classes.checkPointFirst}
            control={
              <Checkbox
                checked={addToContact}
                onChange={() => setAddToContact(!addToContact)}
                name="addToContact"
              />
            }
            label={formatMessage(messages.companyContact)}
          />}
        </Grid>
        <Grid xs={3}>
          <Button
            disabled={!valid.emailValid || !state.email}
            className={classes.addButton}
            classes={{
              label: classes.lowerCapText,
            }}
            onClick={handleAdd}
            variant="contained"
            color="primary"
          >
            {formatMessage(messages.add)}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

AddUser.propTypes = {
  onCreateUser: PropTypes.func,
  closeModal: PropTypes.func,
  onUpdateContact: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  showRoles: PropTypes.bool,
  isCompanyAdd: PropTypes.bool,
  showAddToContact: PropTypes.bool,
  useAsModal: PropTypes.bool,
  showHeader: PropTypes.bool,
  // from: PropTypes.string,
};

AddUser.defaultProps = {
  onCreateUser: () => {},
  closeModal: () => {},
  onUpdateContact: () => {},
  data: [],
  showRoles: true,
  isCompanyAdd: false,
  showAddToContact: true,
  useAsModal: true,
  showHeader: true,
  // from: '',
};

export default AddUser;

