import { Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  tableRoot: {
    paddingTop: '30px',
    flexGrow: 1,
  },
  grid: {
    padding: theme.spacing(3),
  },
  gridTitle: {
    marginTop: theme.spacing(3),
  },
  formRow: {
    marginBottom: theme.spacing(2),
  },
  labelCol: {
    width: '20%',
  },
  inputsCol: {
    width: '60%',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonCol: {
    width: '20%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formTextField: {
    width: '90%',
  },
  addPortfolioBtn: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  paper: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
  },
  divider: {
    margin: `${theme.spacing(4)}px 0px`,
  },
  deleteMsg: {
    color: '#000',
    fontWeight: 'bold',
  },
  sectionTitles: {
    color: '#000',
    fontWeight: '300',
    letterSpacing: 0.2,
  },
  label: {
    ...theme.typography.fontSize,
    color: '#000',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  icon: {
    fontSize: '20px',
  },
}));

export const UpdateButton = withStyles((theme) => ({
  root: {
    width: '70%',
    background: '#02beb2',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    '&:hover': {
      background: '#039289',
    },
    ...theme.custom.buttonLabel,
  },
  label: {
    color: '#fff',
    textTransform: 'capitalize',
  },
}))(Button);

export const DeleteButton = withStyles((theme) => ({
  root: {
    width: '70%',
    background: '#ff0c3e',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    '&:hover': {
      background: '#c3032a',
    },
    ...theme.custom.buttonLabel,
  },
  label: {
    color: '#fff',
    textTransform: 'capitalize',
  },
}))(Button);
