// does not include `process.env` to allow webpack env loader replacing them
function getBrowserEnv(key) {
  return global.env && global.env[key];
}

export default {
  api: {
    url: getBrowserEnv('API_URL') || process.env.REACT_APP_API_URL,
  },
  common: {
    defaultLocale: 'en',
    supportedLocales: ['en'],
  },
  sentry: {
    dsn: getBrowserEnv('SENTRY_DSN') || process.env.REACT_APP_SENTRY_DSN,
  },
  auth0: {
    domain: getBrowserEnv('AUTH0_DOMAIN') || process.env.REACT_APP_AUTH0_DOMAIN,
    clientId:
      getBrowserEnv('AUTH0_CLIENT_ID') || process.env.REACT_APP_AUTH0_CLIENT_ID,
  },
  downloads: {
    url: process.env.REACT_APP_DOWNLOADS_URL,
  },
  stripe: {
    key: process.env.REACT_APP_STRIPE_KEY,
  },
};
