import { defineMessages } from 'react-intl';

export default defineMessages({
  organisationId: {
    id: 'User.ManageUser.ManageUserTable.organisationId ',
    defaultMessage: 'Organisation ID',
  },
  name: {
    id: 'User.ManageUser.ManageUserTable.name',
    defaultMessage: 'Name',
  },
  role: {
    id: 'User.ManageUser.ManageUserTable.role',
    defaultMessage: 'Role',
  },
  manageOrganisation: {
    id: 'User.ManageOrganisation.manageOrganisation',
    defaultMessage: 'Manage Organisation',
  },
  addOrganisation: {
    id: 'User.ManageOrganisation.Add Organisation',
    defaultMessage: 'Add Organisation',
  },
});
