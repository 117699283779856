import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { Organization, OrganizationSliceState } from 'types';

export const selectorOrganizationRoot = (
  state: RootState
): OrganizationSliceState => state.organization;

export const selectorOrganization = (
  state: OrganizationSliceState
): Organization => state.organizations[state.organization] || {};

export const selectorOrganizationById = (
  state: OrganizationSliceState,
  organizationId: string
): number => state.organizations.findIndex((org) => org.id === organizationId);

export const selectSelectedOrganizationById = createSelector(
  selectorOrganizationById,
  (res) => res
);

export const selectSelectedOrganization = createSelector(
  selectorOrganizationRoot,
  selectorOrganization
);

export const selectOrganizationLoading = createSelector(
  selectorOrganizationRoot,
  (state) => state.isLoading
);

export const selectOrganizations = createSelector(
  selectorOrganizationRoot,
  (state) => state.organizations
);
