/* eslint-disable import/named */
import React, { useState } from 'react';
import { Typography, Grid, Button, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { SelectInput } from 'components/Common/Select';
import { CompanySelector } from 'components/Common/Selector';
import messages from '../messages';
import CompanyCount from '../CompanyCount';
import { bulkExport } from '../../../../common/getDownloads';
import { EXPORT_FORMAT, SECTIONS } from '../../../../constants';
import DownloadModal from '../DownloadModal';
import { useStyle } from '../styles';
import CompanySpecificSelector from './CompanySpecificDropDown';

const sections = SECTIONS;
const format = EXPORT_FORMAT;

const Export = ({ handleBulkExportGS, setDownload }) => {
  const classes = useStyle();
  const { formatMessage } = useIntl();
  const [value, setValue] = React.useState('All');
  const [optionValue, setOptionsValue] = useState(sections);
  const [formatOption, setFormatOptions] = useState(format[0].name);
  const [sheetUrl, setSheetUrl] = useState([]);
  const [disableExport, setDisableExport] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleOptionsChange = (event) => {
    setOptionsValue(event.target.value);
  };
  const handleFormatChange = (event) => {
    setFormatOptions(event.target.value);
  };

  const handleCompanyChange = (allCompanies) => {
    setSelectedCompanies(allCompanies);
  };

  const handleExport = async () => {
    setDisableExport(true);
    let fields = [];
    if (value === 'Specific') {
      fields = optionValue.map((ele) => ele.value);
    } else {
      fields = value;
    }
    const inputParams = selectedCompanies.map((ele) => ele.id);
    switch (formatOption) {
      case format[1].name:
      case format[0].name: {
        await bulkExport(
          {
            companyIds: inputParams,
            fields,
          },
          formatOption,
          setDownload
        );
        break;
      }
      case format[2].name: {
        const data = await handleBulkExportGS({
          companyIds: inputParams,
          formatOption,
          fields,
        });
        setSheetUrl(data);
        break;
      }
    }
    setDisableExport(false);
  };

  const handleModalClose = () => {
    setSheetUrl([]);
  };

  return (
    <div style={{ width: 580 }}>
      <Modal
        open={sheetUrl.length}
        aria-labelledby="onbording_modal"
        aria-describedby="onbording_modal"
        className={classes.modal}
        onClose={handleModalClose}
        onBackdropClick={handleModalClose}
        style={{ className: classes.paper }}
        BackdropProps={{ className: classes.modalOverlay }}
      >
        <div className={classes.paper}>
          <DownloadModal sheetUrl={sheetUrl} closeModal={handleModalClose} />
        </div>
      </Modal>
      <Grid
        container
        // style={useAsModal ? modalStyle : {}}
        className={`${classes.modelWidth} `}
        direction="column"
        spacing={1}
      >
        <Grid container xs={12} style={{ marginBottom: 15 }}>
          <Typography variant="h5">{formatMessage(messages.export)}</Typography>
        </Grid>
        <Grid container style={{ marginBottom: 10 }}>
          <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={3}>
            <Typography>{formatMessage(messages.selectCompanies)}</Typography>
          </Grid>
          <Grid direction="row" item xs={8}>
            <div className={classes.selectorWrapper}>
              <div style={{ width: 200 }}>
                <CompanySelector
                  onChangeHandler={handleCompanyChange}
                  fullWidth
                />
              </div>
              <CompanyCount count={selectedCompanies.length} />
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 10 }}>
          <Grid item xs={3}>
            <Typography>{formatMessage(messages.format)}</Typography>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.selectInput}>
              <SelectInput
                value={formatOption}
                useId={false}
                fullWidth
                onChange={handleFormatChange}
                options={format}
              />
            </div>
          </Grid>
        </Grid>
        <CompanySpecificSelector
          value={value}
          handleChange={handleChange}
          optionValue={optionValue}
          handleOptionsChange={handleOptionsChange}
        />
        <Grid item>
          <Button
            style={{
              borderRadius: 20,
              width: 160,
              marginTop: 20,
            }}
            classes={{
              label: classes.lowerCapText,
            }}
            onClick={handleExport}
            variant="contained"
            color="primary"
            aria-controls="simple-menu"
            aria-haspopup="true"
            disabled={disableExport}
          >
            Export
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

Export.propTypes = {
  handleBulkExportGS: PropTypes.func.isRequired,
  setDownload: PropTypes.func.isRequired,
};

export default Export;
