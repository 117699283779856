import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'constants/index';

const border = {
  borderColor: Colors.TEXT,
  borderStyle: 'solid',
  borderWidth: 1,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
  },
  labelCol: {
    width: '20%',
  },
  label: {
    color: '#000',
    marginRight: theme.spacing(2),
    fontSize: 15,
  },
  buttonCol: {
    width: '20%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputsCol: {
    width: '60%',
    display: 'flex',
    justifyContent: 'center',
  },
  formTextField: {
    width: '100%',
  },
  dropDownSelect: {
    padding: `${theme.spacing(3)}px`,
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
  },
  radioLabel: {
    marginTop: theme.spacing(1.2),
    color: Colors.TEXT_DEFAULT,
    display: 'flex',
    flex: 1,
  },
  actionBtn: {
    marginRight: theme.spacing(5),
    width: '100px',
    alignSelf: 'flex-end',
  },
  gridCol1: {
    marginRight: theme.spacing(3),
    width: '40%',
  },
  gridCol2: {
    width: '55%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiContainer-root': {
      outline: 'none',
      height: '99%',
    },
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  space: {
    height: 10,
    width: '100%',
  },
  statusBar: {
    padding: 10,
    paddingLeft: 20,
    borderRadius: 3,
    background: '#BEEEB2',
    border: '1px solid #191919',
  },
  addSubscriptionBtn: {
    marginLeft: theme.spacing(5),
    width: '200px',
  },
  box: {
    marginTop: 15,
    marginBottom: 20,
  },
  subscriptionModalPaper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    overflowY: 'auto',
    height: '100%',
  },
  formControl: {
    width: '55%',
    flexDirection: 'row',
    display: 'flex',
    '& > div': {
      width: '65% !important',
    },
    '& > label': {
      marginRight: 0,
      width: '30% !important',
      color: '#000',
      fontSize: 15,
    },
  },
  cancelModalContainer: {
    height: 'unset !important',
  },
  formControlCol: {
    // width: '60%',
    '& label.Mui-focused': {
      color: Colors.TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        ...border,
        borderColor: Colors.BORDER_GREY,
      },
      '&.Mui-focused fieldset': {
        ...border,
      },
    },
    '&.hover .MuiOutlinedInput-root': {
      '& fieldset': border,
    },
  },
  paymentMethod: {
    alignItems: 'center',
    '& > label': { marginTop: 0 },
  },
}));

export { useStyles };
