import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { selectSubscriptionAccessLimit } from 'containers/Subscription';
import { setAlert } from 'containers/Config';
import translations from 'translations';
import { Button } from '../../Common/Buttons';
import ManageUserTable from './manageUserTable';
import messages from './messages';

const ManageUser = ({
  openModal,
  data,
  deleteRecord,
  onUpdateUser,
  onUpdateRole,
  showContacts,
  onUpdateContact,
  onDeleteContact,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { users, hasTrial } = useSelector(selectSubscriptionAccessLimit);
  const canCreateUser = users || hasTrial;

  const handleOnAddUser = (e) => {
    if (!canCreateUser) {
      dispatch(
        setAlert({
          open: true,
          message: formatMessage(
            translations.OrganisationSettings.Subscription.alert
              .userLimitExceeded
          ),
          severity: 'error',
        })
      );
      return;
    }
    openModal(e);
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {formatMessage(messages.manageUser)}
      </Typography>
      <div style={{ width: 162, marginBottom: 20 }}>
        <Button onClick={handleOnAddUser}>
          {formatMessage(messages.addUser)}
        </Button>
      </div>
      <ManageUserTable
        onDeleteContact={onDeleteContact}
        onUpdateContact={onUpdateContact}
        showContacts={showContacts}
        data={data}
        deleteRecord={deleteRecord}
        onUpdateUser={onUpdateUser}
        onUpdateRole={onUpdateRole}
      />
    </>
  );
};

ManageUser.propTypes = {
  openModal: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  deleteRecord: PropTypes.func,
  onUpdateUser: PropTypes.func,
  onUpdateRole: PropTypes.func,
  showContacts: PropTypes.bool,
  onUpdateContact: PropTypes.bool,
  onDeleteContact: PropTypes.func,
};

ManageUser.defaultProps = {
  openModal: () => {},
  data: [],
  deleteRecord: () => {},
  onUpdateUser: () => {},
  onUpdateRole: () => {},
  showContacts: false,
  onUpdateContact: () => {},
  onDeleteContact: () => {},
};

// eslint-disable-next-line import/prefer-default-export
export { ManageUser };
