/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import keys from 'lodash/keys';
import { periodicityColumnTitle, periodicityValues } from '../../constants';
import { useStyles } from './styles';

const ModalBody = ({
  modalTypes,
  metricsData,
  handleColumnFilter,
  handleChangePereodicity,
  loading,
  handleChangePereodicityDate,
  actionPeriodicity,
  handleSubmitData,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(actionPeriodicity.value);

  const getQuarterly = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    if (month < 4) return 2;
    if (month < 7) return 5;
    if (month < 10) return 8;
    return 11;
  };
  const getHalfYear = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    if (month < 7) return 5;
    return 11;
  };
  const currentDate =
    value === 'Monthly'
      ? new Date()
      : value === 'Quarterly'
      ? new Date(new Date().setMonth(getQuarterly()))
      : value === 'Annual'
      ? new Date(new Date().setMonth(0))
      : value === 'Half-Year'
      ? new Date(new Date().setMonth(getHalfYear()))
      : new Date(new Date().setMonth(getQuarterly()));
  const monthArray = [];
  const difference =
    value === 'Monthly'
      ? 1
      : value === 'Quarterly'
      ? 3
      : value === 'Annual'
      ? 12
      : value === 'Half-Year'
      ? 6
      : 3;
  const colunmsNumber =
    value === 'Monthly'
      ? 7
      : value === 'Quarterly'
      ? 5
      : value === 'Annual'
      ? 4
      : value === 'Half-Year'
      ? 3
      : 5;

  for (let i = 1; i < colunmsNumber; i++) {
    const tempMonth = moment(new Date(currentDate)).format('MM/YYYY');
    const annualValue = colunmsNumber === 4 ? tempMonth.slice(3) : null;

    monthArray.push({
      title: annualValue || tempMonth,
      name: annualValue || tempMonth,
      type: 'Number',
      actual_date: new Date(currentDate),
    });
    currentDate.setMonth(currentDate.getMonth() - difference);
  }
  monthArray.unshift({
    title: 'Latest',
    name: 'Latest',
    type: 'String',
    actual_date: new Date(currentDate),
  });
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (modalTypes === 'Periodicity')
      handleChangePereodicityDate('Latest', 'date');
  }, []);

  if (modalTypes === 'Metrics') {
    return (
      <>
        {loading ? (
          <div className={classes.loadingCircle}>
            <CircularProgress size={60} />
          </div>
        ) : (
          <div className={classes.modalContainer}>
            {metricsData.map(({ metrics, name }) => {
              const metricsValue = JSON.parse(metrics);
              return (
                <div key={name} className={classes.modalColumn}>
                  <h3>{`${
                    name.length > 3
                      ? name.replace(/([A-Z])/g, ' $1').trim()
                      : name
                  }`}</h3>
                  {metricsValue.map((elem) => (
                    <div key={uuid()} className={classes.metricNamesContainer}>
                      <span
                        className={classes.metricNames}
                        onClick={() =>
                          handleColumnFilter(
                            keys(elem).join(),
                            'metricName',
                            name,
                            'metricCategory'
                          )
                        }
                        onKeyPress={() => {}}
                        role="textbox"
                        tabIndex={-1}
                      >
                        {keys(elem)}
                      </span>
                      <span>{` (${Object.values(elem)})`}</span>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  }
  if (modalTypes === 'Periodicity') {
    return (
      <>
        <div className={classes.periodicityContainer}>
          {periodicityColumnTitle.map((item) => (
            <div key={item.id} style={{ padding: '10px' }}>
              <h3>{item.title}</h3>
              {item.title === 'Date' ? (
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="date"
                    name="date"
                    defaultValue="Latest"
                  >
                    {monthArray.map((items) => {
                      return (
                        <FormControlLabel
                          key={items.title}
                          value={items.title}
                          control={<Radio />}
                          label={items.title}
                          onChange={() =>
                            handleChangePereodicityDate(items.title, 'date')
                          }
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              ) : (
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="periodicity"
                    name="periodicity"
                    value={value}
                    onChange={handleChange}
                  >
                    {periodicityValues.map((elem) => (
                      <FormControlLabel
                        key={elem.id}
                        value={elem.periodName}
                        control={<Radio />}
                        label={elem.periodName}
                        onChange={() =>
                          handleChangePereodicity(
                            elem.periodName,
                            'periodicity'
                          )
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            </div>
          ))}
        </div>
        <div className={classes.saveButton}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleSubmitData()}
          >
            Save
          </Button>
        </div>
      </>
    );
  }
};

export default ModalBody;
