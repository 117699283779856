import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(3),
  },
  paper: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
  },
  buttonCol: {
    width: '20%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputsCol: {
    width: '60%',
    display: 'flex',
    justifyContent: 'center',
  },
  formTextField: {
    width: '100%',
  },
  actionBtn: {
    marginRight: theme.spacing(5),
    width: '100px',
    alignSelf: 'flex-end',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiContainer-root': {
      outline: 'none',
      height: '99%',
    },
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  settingWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  sortingIcon: {
    fontSize: 34,
  },
  sortingIconWrapper: {
    padding: 0,
  },
  settingText: {
    fontSize: 15,
    fontWeight: 'bolder',
    marginLeft: 12,
    width: 166,
  },
}));

export { useStyles };
