import { defineMessages } from 'react-intl';

export default defineMessages({
  updatesScreenTitle: {
    id: 'UpdatesScreen.title',
    defaultMessage: 'Status',
  },
  updatesScreenReportsTitle: {
    id: 'UpdatesScreen.reports.title',
    defaultMessage: 'Reports',
  },
  updatesScreenRecentUpdatesTitle: {
    id: 'UpdatesScreen.recentUpdates.title',
    defaultMessage: 'Recent Updates',
  },
  updatesScreenFollowUpSuggestionTitle: {
    id: 'UpdatesScreen.followUpSuggestion.title',
    defaultMessage: 'Follow Up Suggestions',
  },
  updatesScreenNotificationTitle: {
    id: 'UpdatesScreen.notification.title',
    defaultMessage: 'Notifications',
  },
  updatedScreenOnBoardingTitle: {
    id: 'UpdatesScreen.onboarding.title',
    defaultMessage: 'Onboarding',
  },
  comingSoon: {
    id: 'UpdatesScreen.notification.status',
    defaultMessage: 'coming soon',
  },
});
