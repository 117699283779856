import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'Investment.InvestmentTable.companyName',
    defaultMessage: 'Company Name',
  },
  action: {
    id: 'Investment.InvestmentTable.action',
    defaultMessage: 'Action',
  },
  lowInvolvement: {
    id: 'Investment.InvestmentTable.lowInvolvement',
    defaultMessage: 'low',
  },
  middleInvolvement: {
    id: 'Investment.InvestmentTable.middleInvolvement',
    defaultMessage: 'middle',
  },
  highInvolvement: {
    id: 'Investment.InvestmentTable.highInvolvement',
    defaultMessage: 'high',
  },

  // =================================================

  selectCompanyLabel: {
    id: 'Company.CompanyApiTab.selectCompanyLabel',
    defaultMessage: 'Select Company ',
  },
  addNewCompanyLabel: {
    id: 'Company.CompanyApiTab.addNewCompanyLabel',
    defaultMessage: 'add new company',
  },
  addNewPortfolioLabel: {
    id: 'Company.CompanyApiTab.addNewPortfolioLabel',
    defaultMessage: 'Add new Portfolio.',
  },
  selectPortfolioLabel: {
    id: 'Company.Investment.selectPortfolioLabel',
    defaultMessage: 'Select Portfolio',
  },
  noPortfolio: {
    id: 'Company.Investment.noPortfolio',
    defaultMessage: 'Set up a portfolio to start adding investments',
  },
  investmentCurrencyLabel: {
    id: 'Company.InvestmentTable.investmentCurrencyLabel',
    defaultMessage: 'Investment Currency',
  },
  investmentValueLabel: {
    id: 'Company.InvestmentTable.investmentValueLabel',
    defaultMessage: 'Investment Value',
  },
  exitStatusLabel: {
    id: 'Company.InvestmentTable.exitStatusLabel',
    defaultMessage: 'Exit Status',
  },
  ownershipLabel: {
    id: 'Company.InvestmentTable.ownershipLabel',
    defaultMessage: 'Ownership',
  },
  investmentAmountLabel: {
    id: 'Company.InvestmentTable.investmentAmountLabel',
    defaultMessage: 'Investment Amount',
  },
  reservesLabel: {
    id: 'Company.InvestmentTable.reservesLabel',
    defaultMessage: 'Reserves',
  },
  involvementLabel: {
    id: 'Company.InvestmentTable.involvementLabel',
    defaultMessage: 'Involvement',
  },
});
