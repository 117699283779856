import { defineMessages } from 'react-intl';

export default defineMessages({
  role: {
    id: 'UserAccessModal.RoleRadio',
    defaultMessage: 'Role',
  },
  roleAdmin: {
    id: 'UserAccessModal.RoleRadio.admin',
    defaultMessage: 'Admin (view/edit data & manage users)',
  },
  roleEditor: {
    id: 'UserAccessModal.RoleRadio.editor',
    defaultMessage: 'Editor (view/edit data)',
  },
  roleViewer: {
    id: 'UserAccessModal.RoleRadio.viewer',
    defaultMessage: 'View (view/edit data)',
  },
  contactType: {
    id: 'UserAccessModal.contactType',
    defaultMessage: 'Contact Type',
  },
  addUser: {
    id: 'UserAccessModal.addUser',
    defaultMessage: 'Add User',
  },
  email: {
    id: 'UserAccessModal.email',
    defaultMessage: 'Email*',
  },
  firstName: {
    id: 'UserAccessModal.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'UserAccessModal.lastName',
    defaultMessage: 'Last Name',
  },
  companyContact: {
    id: 'UserAccessModal.companyContact',
    defaultMessage: 'Company Contact',
  },
  add: {
    id: 'UserAccessModal.add',
    defaultMessage: 'Add',
  },
});
