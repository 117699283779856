import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

import { GET_STATUS_DATA } from '../../graphql';
import { UpdatesScreen } from '../../components/Updates';

const UpdatesScreenContainer = () => {
  const { id } = useParams();

  const { data: { getStatus: statusData = {} } = {} } = useQuery(
    GET_STATUS_DATA,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          organisationId: id,
        },
      },
    }
  );

  return (
    <>
      <UpdatesScreen statusData={statusData} />
    </>
  );
};

export default UpdatesScreenContainer;
