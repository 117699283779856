/* eslint-disable comma-dangle */
/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../constants';

export const useStyles = makeStyles((theme) => ({
  modal: {
    overflowY: 'scroll',
  },
  modalContent: {
    outline: 'none',
    margin: `${theme.spacing(12)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px ${theme.spacing(33)}px`,
  },
  contentWrapper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  modalOverlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.48) !important',
  },
  paperModal: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
  },
  modelWidth: {
    maxWidth: '580px !important',
  },
  title: {
    fontWeight: 400,
    letterSpacing: 0.2,
  },
  formLabel: {
    // ...theme.typography.fontSize,
    color: '#363636',
    marginRight: theme.spacing(3),
    width: '111px',
    paddingTop: '14px',
  },
  radioWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  radioColor: {
    color: '#757575 !important',
  },
  lowerCapText: {
    textTransform: 'initial',
  },
  addButton: {
    borderRadius: 20,
    width: '100%',
  },
  labelStyle: {
    minWidth: '30%',
    marginRight: '10px',
    color: '#363636',
  },
  checkPointFirst: {
    marginRight: '20px',
    justifyContent: 'center',
  },
  selectInput: {
    width: '100%',
    '& fieldset': {
      borderColor: Colors.TEXT,
    },
  },
}));
