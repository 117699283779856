/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { userRoles } from '../../../user/userConstants';

const useStyles = makeStyles({
  headers: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  discardButton: {
    display: 'none',
  },
  actionButtonWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  hidden: {
    display: 'none',
  },
});

const CompanyTop = ({
  didChange,
  setFeedResponse,
  handleDiscard,
  enableEdit,
  showAdd,
  title,
  handleActionClick,
  handleAddActionClick,
  role,
  children,
}) => {
  const classes = useStyles();

  const Toggle = () => {
    setFeedResponse(false);
  };

  const saveOrNot = () => {
    if (enableEdit === true && didChange === false) return Toggle();
    return handleActionClick();
  };
  return (
    <div className={classes.headers}>
      {children || (
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
      )}
      {role !== userRoles.VIEWER ? (
        <div
          className={`${classes.actionButtonWrapper} ${
            !role || role === userRoles.VIEWER ? classes.hidden : ''
          }`}
        >
          <Button
            onClick={handleDiscard}
            className={classes.discardButton}
            style={{
              display: enableEdit ? (didChange ? 'block' : 'none') : 'none',
            }}
          >
            Discard
          </Button>
          <Button
            id={`edit-company-${title.toLowerCase().trim().replace(' ', '-')}`}
            onClick={saveOrNot}
            color="primary"
          >
            {enableEdit ? (didChange ? 'Save' : 'Stop Edit') : 'Edit'}
          </Button>
        </div>
      ) : (
        <div />
      )}

      {enableEdit && showAdd && (
        <Button
          id={`add-company-${title.toLowerCase().trim().replace(' ', '-')}`}
          onClick={handleAddActionClick}
          color="primary"
        >
          Add
        </Button>
      )}
    </div>
  );
};

CompanyTop.propTypes = {
  enableEdit: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  showAdd: PropTypes.bool,
  handleActionClick: PropTypes.func.isRequired,
  handleAddActionClick: PropTypes.func,
  role: PropTypes.string,
  children: PropTypes.element,

  didChange: PropTypes.bool.isRequired,
  setFeedResponse: PropTypes.func.isRequired,
  handleDiscard: PropTypes.func.isRequired,
};

CompanyTop.defaultProps = {
  showAdd: false,
  handleAddActionClick: () => {},
  role: userRoles.VIEWER,
  children: null,
};

// eslint-disable-next-line import/prefer-default-export
export { CompanyTop };
