// eslint-disable-next-line import/prefer-default-export
export const ACTION_TYPES_COMPANY = {
  GET_COMPANIES: 'GET_COMPANIES',
  GET_KPI_DATA: 'GET_KPI_DATA',
  UPDATE_KPI_DATA: 'UPDATE_KPI_DATA',
  DELETE_KPI_COLUMN: 'DELETE_KPI_COLUMN',
  UPDATE_KPI_VALUES: 'UPDATE_KPI_VALUES',
  ADD_KPI_DATA_COLUMN: 'ADD_KPI_DATA_COLUMN',
  GET_KPI_COLUMN_VALUES: 'GET_KPI_COLUMN_VALUES',
  UPDATE_KPI_PERIODICITY: 'UPDATE_KPI_PERIODICITY',
  UPDATE_KPI_PERIODICITY_DATE: 'UPDATE_KPI_PERIODICITY_DATE',
  GET_COMPANIES_SUCCESS: 'GET_COMPANIES_SUCCESS',
  GET_COMPANIES_FAIL: 'GET_COMPANIES_FAIL',
  ADD_COMPANIES: 'ADD_COMPANIES',
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  GET_ALL_COMPANIES: 'GET_ALL_COMPANIES',
  GET_ALL_COMPANIES_SUCCESS: 'GET_ALL_COMPANIES_SUCCESS',
  GET_ALL_COMPANIES_FAIL: 'GET_ALL_COMPANIES_FAIL',
  ADD_ALL_COMPANIES: 'ADD_ALL_COMPANIES',
  ADD_ALL_COMPANIES_SUCCESS: 'ADD_ALL_COMPANIES_SUCCESS',
  ADD_ALL_COMPANIES_FAIL: 'ADD_ALL_COMPANIES_FAIL',
  CREATE_COMPANY: 'CREATE_COMPANY',
  CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
  CREATE_COMPANY_FAIL: 'CREATE_COMPANY_FAIL',
  UPDATE_CONTACT: 'UPDATE_CONTACT',
  UPDATE_CONTACT_SUCCESS: 'UPDATE_CONTACT_SUCCESS',
  UPDATE_CONTACT_FAIL: 'UPDATE_CONTACT_FAIL',
  DELETE_CONTACTS: 'DELETE_CONTACTS',
  DELETE_CONTACTS_SUCCESS: 'DELETE_CONTACTS_SUCCESS',
  DELETE_CONTACTS_FAIL: 'DELETE_CONTACTS_FAIL',
  GET_REQUEST_DATA_BY_COMPANIES: 'GET_REQUEST_DATA_BY_COMPANIES',
  GET_REQUEST_DATA_BY_COMPANIES_SUCCESS:
    'GET_REQUEST_DATA_BY_COMPANIES_SUCCESS',
  GET_REQUEST_DATA_BY_COMPANIES_FAIL: 'GET_REQUEST_DATA_BY_COMPANIES_FAIL',
};
