/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import { Checkbox } from 'components/Common/Checkbox';
import { Input } from '../Input';

export const MultiSelectMenu = ({
  anchorEl,
  onClose,
  searchInput,
  searchInputProps: { searchText, onSearchChange } = { searchText: '' },
  options,
  onMenuItemClick,
  renderText,
  popupProps,
}) => {
  const handleMenuItemClick = (item) => {
    onMenuItemClick(item);
  };

  return (
    <Menu
      id="investment-managers"
      anchorEl={anchorEl}
      keepMounted
      onKeyDown={(e) => e.stopPropagation()}
      open={Boolean(anchorEl)}
      onClose={onClose}
      PaperProps={popupProps}
    >
      <MenuItem
        onKeyDown={(e) => e.stopPropagation()}
        key="investment-managers.textinput"
        value="investment-managers.textinput"
      >
        {searchInput && (
          <Input
            placeholder="Search"
            value={searchText}
            onChange={onSearchChange}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      </MenuItem>
      {options.map((obj) => (
        <MenuItem
          onClick={() => handleMenuItemClick(obj)}
          key={obj.id}
          value={obj.id}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Checkbox
            color="primary"
            name={`invesmetnManager_${obj.id}_checked`}
            checked={Boolean(obj.checked)}
            onChange={() => {}}
          />
          <Typography variant="inherit">{renderText(obj)}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};

MultiSelectMenu.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  searchInput: PropTypes.bool,
  searchInputProps: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  onMenuItemClick: PropTypes.func,
  renderText: PropTypes.func,
  popupProps: PropTypes.object,
};

MultiSelectMenu.defaultProps = {
  anchorEl: null,
  onClose: () => {},
  searchInput: false,
  searchInputProps: {},
  options: [],
  onMenuItemClick: () => {},
  renderText: () => {},
  popupProps: {},
};
