import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import React from 'react';
import PropType from 'prop-types';

const EmployeeChart = ({ data }) => (
  <ResponsiveContainer>
    <BarChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis width={30} allowDecimals={false} />
      <Tooltip />
      {/* <Legend /> */}
      <Bar dataKey="Count" fill="#82ca9d" />
    </BarChart>
  </ResponsiveContainer>
);

export default EmployeeChart;

EmployeeChart.propTypes = {
  data: PropType.arrayOf(PropType.object).isRequired,
};
