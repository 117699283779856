import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const Progress = ({ progress, progressVariant }) => (
  <Box position="relative" display="inline-flex">
    <CircularProgress
      variant={progress ? progressVariant : 'indeterminate'}
      size={35}
      value={progress}
    />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        style={{ fontSize: 12 }}
        variant="caption"
        component="div"
        color="textSecondary"
      >
        {`${Math.round(progress)}%`}
      </Typography>
    </Box>
  </Box>
);

Progress.propTypes = {
  progress: PropTypes.number.isRequired,
  progressVariant: PropTypes.string.isRequired,
};

export default Progress;
