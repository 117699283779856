/* eslint-disable no-param-reassign */
import React from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Box from '@material-ui/core/Box';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

import { Order, HeadCell } from 'types';
import { periodicityResponse, getPeriodictyDate } from 'utils/index';
import { METRIC_UNIT, Periodicity } from 'constants/index';
import { EnhancedTableProps } from './type';
import { useStyles } from './styles';
import { EnhancedTableHead } from './TableHead';
import { EnhancedTableBody } from './TableBody';
import { EnhancedTableToolbar } from './EnhancedToolbar';

const initialColumns: HeadCell[] = [
  {
    id: 'metric',
    numeric: false,
    value: 'KPI',
  },
  {
    id: 'metricUnit',
    numeric: false,
    value: 'Unit',
  },
];

export function EnhancedTable({
  data,
  periodicity,
  enableEdit,
  onUpdate,
  onDelete,
}: EnhancedTableProps) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const addRowRef = React.useRef<() => void>(() => {});
  const [columns, setColumns] = React.useState<HeadCell[]>([]);
  const [tableData, setTableData] = React.useState<any[]>([]);
  const tableRef = React.useRef(tableData);
  const [reset, setReset] = React.useState(false);

  const addRow = () => {
    const prevElement = tableRef.current[tableRef.current.length - 1];
    const newTableData = tableRef.current.concat({
      meta: {
        metricUnit: METRIC_UNIT.Number,
        sortingNumber: prevElement ? prevElement.meta.sortingNumber + 1 : 0,
        id: uuid(),
      },
    });
    setTableData(newTableData);
    tableRef.current = newTableData;
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSetSelected = (payload: string[]) => {
    setSelected(payload);
  };

  const bindMonths = (): HeadCell[] => {
    const newColumns: HeadCell[] = [];
    const {
      difference = 1,
      columns: columnsNumber = 7,
      month,
    } = periodicityResponse[periodicity];
    // const dataKeys = data.reduce((acc, ele) => {
    //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //   const { meta, actions, ...rest } = ele;
    //   const keys = Object.keys(rest);
    //   if (keys.length > acc.length) acc = keys;
    //   return acc;
    // }, []);
    // if (dataKeys.length) {
    //   newColumns = dataKeys
    //     .sort(
    //       (a: string, b: string) =>
    //         moment(b, 'MM/YYYY').valueOf() - moment(a, 'MM/YYYY').valueOf()
    //     )
    //     .map((elem: string) => ({
    //       id: `${elem}.metricValue`,
    //       value: elem,
    //       numeric: true,
    //       disablePadding: false,
    //     }));
    const currentDate = moment().startOf('day');
    currentDate.set('month', month);
    for (let i = 0; i < 6; i += 1) {
      const date = getPeriodictyDate(currentDate.toDate(), periodicity);
      newColumns.push({
        id: `${date}.metricValue`,
        value: date,
        numeric: true,
        disablePadding: false,
      });
      currentDate.set('month', currentDate.get('month') - difference);
    }
    newColumns.reverse();
    setColumns([...initialColumns].concat(newColumns));
    return [...initialColumns].concat(newColumns);
  };

  React.useEffect(() => {
    bindMonths();
  }, [data, enableEdit, periodicity]);

  const handleAddColumn = (operator: '+' | '-'): string => {
    const lastRow = columns[operator === '-' ? 2 : columns.length - 1];
    const inputFormate =
      periodicity === Periodicity.QUARTERLY ||
      periodicity === Periodicity.ANNUAL
        ? '/YYYY'
        : 'MM/YYYY';
    const { difference = 1 } = periodicityResponse[periodicity];
    let currentDate = moment(lastRow.value, inputFormate);
    let offset = 0;
    if (periodicity === Periodicity.QUARTERLY) {
      currentDate = currentDate.quarter(+lastRow.value[1]).startOf('quarter');
    } else if (periodicity === Periodicity.YTD) {
      offset = 3 - difference;
    }
    const month = currentDate.get('month');
    const monthDiff =
      operator === '+'
        ? month + (difference + offset)
        : month - (difference + offset);
    currentDate.set('month', monthDiff);
    // const offset =
    //   periodicity === Periodicity.QUARTERLY
    //     ? +lastRow.value[1] * 3 - difference
    //     : periodicity === Periodicity.YTD
    //     ? 3 - difference
    //     : 0;
    // const date = moment(lastRow.value, inputFormate);
    // const month = date.get('month');
    // const monthDiff =
    //   operator === '+'
    //     ? month - (difference + offset)
    //     : month + (difference + offset);
    // date.set('month', monthDiff);
    const formatedDate = getPeriodictyDate(currentDate.toDate(), periodicity);
    const newColumn = {
      id: `${formatedDate}.metricValue`,
      value: formatedDate,
      numeric: true,
      disablePadding: false,
    };
    setColumns((prevColumns: HeadCell[]) => {
      const [a, b, ...newColumns] = prevColumns;
      if (operator === '+') {
        newColumns.splice(0, 1);
        newColumns.push(newColumn);
      } else {
        newColumns.splice(newColumns.length - 1, 1);
        newColumns.unshift(newColumn);
      }
      return [a, b, ...newColumns];
    });
    setTimeout(() => {
      const element = document.getElementById('kpi-table-body');
      if (element) element.scrollTo(element.scrollWidth, 0);
    }, 100);
    return formatedDate;
  };

  const handleUpdate = (rowIndex: number, updatedData: any) => {
    const newTableData = [...tableRef.current];
    newTableData[rowIndex] = { ...tableRef.current[rowIndex], ...updatedData };
    setTableData(newTableData);
    tableRef.current = newTableData;
    onUpdate(updatedData);
  };

  const handleDelete = (rowIndex: number) => {
    const newTableData = JSON.parse(JSON.stringify(tableRef.current));
    newTableData.splice(rowIndex, 1);
    setTableData(newTableData);
    const { meta: { id = '' } = { id: '' } } = tableRef.current[rowIndex] || {};
    tableRef.current = newTableData;
    if (rowIndex > data.length - 1) return;
    onDelete(id);
  };

  const deleteEmptyRows = (nData?: any[]) => {
    const newData = (nData || tableRef.current).filter((val) => {
      const { meta: { metricName = '' } = { metricName: '' } } = val || {};
      return Boolean(metricName);
    });
    // if (newData.length !== tableRef.current.length) {
    setTableData(JSON.parse(JSON.stringify(newData)));
    tableRef.current = JSON.parse(JSON.stringify(newData));
    // }
  };

  React.useEffect(() => {
    deleteEmptyRows(data);
    setTimeout(() => {
      setReset(true);
    }, 100);
    addRowRef.current = addRow;
  }, [data]);

  return (
    <div className={classes.root}>
      <EnhancedTableToolbar
        onAddRow={addRowRef.current}
        enableEdit={enableEdit}
      />
      <Box display="flex" alignItems="flex-start">
        <Tooltip title="Decrease periods" onClick={() => handleAddColumn('-')}>
          <IconButton
            aria-label="decrease-column"
            className={classes.columnAction}
          >
            <ArrowLeftIcon />
          </IconButton>
        </Tooltip>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              columns={columns}
              enableEdit={enableEdit}
            />
            <EnhancedTableBody
              // data={data}
              order={order}
              setSelected={handleSetSelected}
              // onAddColumns={handleAddColumn}
              orderBy={orderBy}
              selected={selected}
              rowsPerPage={rowsPerPage}
              columns={columns}
              enableEdit={enableEdit}
              onUpdate={onUpdate}
              onDelete={onDelete}
              onAddRow={addRowRef}
              page={page}
              tableData={tableRef.current}
              setTableData={(newTable) => {
                tableRef.current = newTable;
                setTableData(newTable);
              }}
              reset={reset}
              setReset={() => {
                setReset(false);
              }}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
              deleteEmptyRows={deleteEmptyRows}
            />
          </Table>
        </TableContainer>
        <Tooltip title="Increase periods" onClick={() => handleAddColumn('+')}>
          <IconButton
            aria-label="increase-column"
            className={classes.columnAction}
          >
            <ArrowRightIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
