import { AppDispatch, RootState, AppThunk } from 'store';
import {
  GraphqlService,
  logActionError,
  handleGraphqlServerError,
} from 'utils/api';
import {
  GraphqlResponse,
  OrganizationResponse,
  Organization,
  OrganizationsResponse,
  CreateOrgPayload,
  CreateOrgResponse,
  OnSuccessPayload,
  CompaniesShort,
  UpdateOrganisationPayload,
  OrganizationShort,
  Company,
  CompanySettings,
} from 'types';
import { setAlert } from 'containers/Config';
import {
  getSelectedOrganization as getSelectedOrgAction,
  getSelectedOrganizationFail,
  getSelectedOrganizationSuccess,
  getOrganizations as getOrganizationsAction,
  getOrganizationsSuccess,
  getOrganizationsFail,
  createOrganization as createOrganizationAction,
  createOrganizationFail,
  createOrganizationSuccess,
  updateOrgCompanies as updateOrgCompaniesAction,
  updateSelectedOrganization as updateSelectedOrganizationAction,
  addOrganization as addOrganizationAction,
  deleteOrganisation as deleteOrganisationAction,
  deleteOrganisationSuccess,
  deleteOrganisationFail,
  updateOrganisation as updateOrganisationAction,
  updateOrganisationSuccess,
  updateOrganisationFail,
  updateCompanySettings as updateCompanySettingsActions,
  updateCompanySettingSuccess,
  updateCompanySettingsFail,
} from './reducer';
import {
  selectSelectedOrganizationById,
  selectOrganizations,
  selectSelectedOrganization,
  selectorOrganizationRoot,
} from './selectors';
import {
  GET_SELECTED_ORGANIZATION_QUERY,
  GET_ORGANISATIONS_QUERY,
  CREATE_ORGANISATION,
  UPDATE_ORGANISATION,
  UPDATE_COMPANY_SETTINGS,
} from './query';

const getSelectedOrganization = (
  organizationId: string,
  changeSelectedOrg = true
): AppThunk => {
  return async (
    dispatch: AppDispatch,
    getState: () => RootState
  ): Promise<void> => {
    try {
      const service = new GraphqlService(global.apolloClient);
      dispatch(getSelectedOrgAction());
      const state = getState();
      const selectedOrgIndex = selectSelectedOrganizationById(
        state.organization,
        organizationId
      );
      const organizations = selectOrganizations(state);
      const {
        data: { organisation },
      } = (await service.query(GET_SELECTED_ORGANIZATION_QUERY, {
        organisationId: organizationId,
      })) as GraphqlResponse<OrganizationResponse>;
      if (organisation) {
        const organisationFinal: Organization = JSON.parse(
          JSON.stringify(organisation)
        );
        const newOrganizations = JSON.parse(JSON.stringify(organizations));
        newOrganizations[selectedOrgIndex] = organisationFinal;
        dispatch(
          getOrganizationsSuccess(JSON.parse(JSON.stringify(newOrganizations)))
        );
      }
      if (changeSelectedOrg)
        dispatch(getSelectedOrganizationSuccess(selectedOrgIndex));
    } catch (error) {
      logActionError('log', error, 'getSelectedOrganization');
      dispatch(getSelectedOrganizationFail());
    }
  };
};

const getOrganizations = (onSuccess?: OnSuccessPayload): AppThunk => {
  return async (
    dispatch: AppDispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { resolve, reject } = onSuccess || {};
    try {
      const state = getState();
      const service = new GraphqlService(global.apolloClient);
      dispatch(getOrganizationsAction());
      const {
        data: { organisations },
      } = (await service.query(GET_ORGANISATIONS_QUERY)) as GraphqlResponse<
        OrganizationsResponse
      >;
      dispatch(
        getOrganizationsSuccess(JSON.parse(JSON.stringify(organisations)))
      );
      let defaultOrg = 0;
      if (!state.organization.organizations.length) {
        organisations.forEach((org, index) => {
          if (org.isDefault) {
            defaultOrg = index;
          }
        });
        dispatch(getSelectedOrganizationSuccess(defaultOrg));
        if (organisations.length) {
          dispatch(
            getSelectedOrganization(organisations[defaultOrg].id) as any
          );
        }
      }
      if (resolve) {
        resolve(organisations[defaultOrg] as Organization);
      }
    } catch (error) {
      logActionError('log', error, 'getOrganizations');
      dispatch(getOrganizationsFail());
      if (reject) {
        reject(error);
      }
    }
  };
};

const createOrganization = (
  payload: CreateOrgPayload,
  onSuccess?: OnSuccessPayload
): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { resolve, reject, successMsg } = onSuccess || {};
    try {
      dispatch(createOrganizationAction());
      const service = new GraphqlService(global.apolloClient);
      const { name, type } = payload;
      const {
        data: { createOrganisation },
      } = await service.mutation<GraphqlResponse<CreateOrgResponse>>(
        CREATE_ORGANISATION,
        {
          name,
          type,
        }
      );
      dispatch(
        createOrganizationSuccess(
          JSON.parse(JSON.stringify(createOrganisation))
        )
      );
      dispatch(
        setAlert({
          message: successMsg || 'Organization create success',
          open: true,
          severity: 'success',
        })
      );
      if (resolve) {
        resolve(JSON.parse(JSON.stringify(createOrganisation)));
      }
    } catch (error) {
      logActionError('log', error, 'createOrganization');
      dispatch(createOrganizationFail());
      dispatch(
        setAlert({
          open: true,
          severity: 'error',
          message: handleGraphqlServerError(error),
        })
      );
      if (reject) {
        reject(error);
      }
    }
  };
};

const updateSelectedOrganization = (updatedOrg: Organization): any => {
  return (dispatch: AppDispatch): void => {
    dispatch(updateSelectedOrganizationAction(updatedOrg));
  };
};

const updateOrgCompanies = (companies: CompaniesShort): any => {
  return (dispatch: AppDispatch, getState: () => RootState): void => {
    try {
      const state = getState();
      const organisations = JSON.parse(
        JSON.stringify(state.organization.organizations)
      ) as Array<Organization>;
      const selectedOrg = JSON.parse(
        JSON.stringify(organisations[state.organization.organization])
      ) as Organization;
      selectedOrg.companies = JSON.parse(JSON.stringify(companies));
      organisations[state.organization.organization] = selectedOrg;
      dispatch(updateOrgCompaniesAction(organisations));
    } catch (error) {
      logActionError('log', error, 'updateOrgCompanies');
    }
  };
};

const addOrganization = (newOrg: Organization, addOrg = true): any => {
  return (dispatch: AppDispatch, getState: () => RootState): void => {
    const state = getState();
    const { companies } = newOrg;
    const selectedOrg = JSON.parse(
      JSON.stringify(selectSelectedOrganization(state))
    ) as Organization;
    dispatch(
      addOrganizationAction(
        JSON.parse(JSON.stringify({ ...newOrg, direct: addOrg }))
      )
    );
    dispatch(updateOrgCompanies(selectedOrg.companies.concat(companies)));
  };
};

const updateOrganisation = (
  payload: UpdateOrganisationPayload,
  onSuccess?: OnSuccessPayload
): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { resolve, reject } = onSuccess || {};
    try {
      dispatch(updateOrganisationAction());
      const service = new GraphqlService(global.apolloClient);
      const {
        data: { updateOrganisation: updatedOrgData },
      } = await service.mutation<
        GraphqlResponse<{
          updateOrganisation: OrganizationShort;
        }>
      >(UPDATE_ORGANISATION, {
        ...payload,
      });
      dispatch(updateOrganisationSuccess(updatedOrgData));
      if (resolve) {
        resolve(updatedOrgData);
      }
    } catch (error) {
      logActionError('log', error, 'update organisation');
      dispatch(updateOrganisationFail());
      if (reject) {
        reject();
      }
    }
  };
};

const updateCompanySettings = (
  payload: {
    orgId: string;
    data: Array<CompanySettings>;
  },
  onSuccess?: OnSuccessPayload
): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { resolve, reject } = onSuccess || {};
    try {
      console.log({ payload });
      const { data } = payload;
      dispatch(updateCompanySettingsActions);
      const service = new GraphqlService(global.apolloClient);
      await service.mutation<GraphqlResponse<boolean>>(
        UPDATE_COMPANY_SETTINGS,
        {
          input: payload,
        }
      );
      dispatch(updateCompanySettingSuccess(data));
      if (resolve) {
        resolve();
      }
    } catch (error) {
      logActionError('log', error, 'update company setting');
      dispatch(updateCompanySettingsFail());
      if (reject) {
        reject();
      }
    }
  };
};

const deleteOrganisation = (
  id: string,
  onSuccess?: OnSuccessPayload
): AppThunk => {
  return async (
    dispatch: AppDispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { resolve, reject } = onSuccess || {};
    try {
      dispatch(deleteOrganisationAction());
      const service = new GraphqlService(global.apolloClient);
      await service.mutation<GraphqlResponse<OrganizationShort>>(
        UPDATE_ORGANISATION,
        {
          id,
          isActive: false,
        }
      );
      dispatch(deleteOrganisationSuccess({ id }));
      const state = getState();
      const newOrganisation = selectorOrganizationRoot(state);
      dispatch(
        getSelectedOrganization(
          newOrganisation.organizations[newOrganisation.organization].id
        ) as any
      );
      if (resolve) {
        resolve(id);
      }
    } catch (error) {
      logActionError('log', error, 'delete organisation');
      dispatch(deleteOrganisationFail());
      if (reject) {
        reject();
      }
    }
  };
};

const OrganizationThunk = {
  getSelectedOrganization,
  getOrganizations,
  createOrganization,
  updateOrgCompanies,
  updateSelectedOrganization,
  addOrganization,
  deleteOrganisation,
  updateOrganisation,
  updateCompanySettings,
};

export { OrganizationThunk };
