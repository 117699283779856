import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  loadingStatus: {
    display: 'block',
    margin: '0 auto',
  },
  modal: {
    backgroundColor: '#fff',
  },
  modalContent: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: '#fff',
  },
}));

const Spinner = ({ size, loading }) => {
  const classes = useStyles();

  return (
    <Modal
      open={loading || false}
      aria-labelledby="loader_modal"
      aria-describedby="loader_modal"
      id="loader_modal"
      // BackdropProps={{ className: classes.modalOverlay }}
      className={classes.modal}
      // onClose={onClose}
    >
      <div className={classes.modalContent}>
        <CircularProgress size={size} className={classes.loadingStatus} />
      </div>
    </Modal>
  );
};

export default Spinner;

Spinner.propTypes = {
  size: PropTypes.number,
  loading: PropTypes.bool.isRequired,
};

Spinner.defaultProps = {
  size: 10,
};
