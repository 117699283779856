import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AddOrganisationScreen } from 'components/Organisation/AddOrganization';
import translations from 'translations';
import { CreateOrgPayload } from 'types';
import { OrganizationThunk } from './ducks';

const AddOrganisationContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onCreateOrganisation = async (
    payload: CreateOrgPayload
  ): Promise<void> => {
    await new Promise((resolve, reject) => {
      dispatch(
        OrganizationThunk.createOrganization(payload, {
          successMsg: (
            <FormattedMessage
              {...translations.Organization.Add.AddOrgSuccess}
            />
          ),
          resolve,
          reject,
        })
      );
    });
    history.push('/');
  };
  return <AddOrganisationScreen onCreateOrganisation={onCreateOrganisation} />;
};

export default AddOrganisationContainer;
