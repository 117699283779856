import { colors, withStyles, Checkbox, makeStyles } from '@material-ui/core';
import { Colors } from 'constants/colors';

const { grey } = colors;

export const GrayCheckbox = withStyles({
  root: {
    color: grey[400],
    '&$checked': {
      color: grey[600],
    },
  },
  checked: {},
})(Checkbox);

export const useStyles = makeStyles((theme) => ({
  formLabel: {
    color: Colors.BLACK,
    marginRight: theme.spacing(3),
    display: 'flex',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
