import React from 'react';
import PropTypes from 'prop-types';
import { CreatePortfolioContainerProps, PortfolioPayloadBasic } from 'types';
import {
  CreatePortfolio,
  CALLING_FROM,
} from 'components/Portfolio/CreatePortfolio';
import { defaultCurrency } from 'common/commonContants';

const CreatePortfolioContainer = ({
  selectedOrganisation,
  createPortfolio,
}: CreatePortfolioContainerProps): JSX.Element => {
  const onCreatePortfolio = async ({
    name,
    size,
    creationYear,
    targetNumberOfInvestments,
    sizeCurrency = defaultCurrency,
  }: PortfolioPayloadBasic): Promise<void> => {
    await createPortfolio({
      organisationId: selectedOrganisation.id,
      name,
      size: Number(size),
      sizeCurrency,
      targetNumberOfInvestments: Number(targetNumberOfInvestments),
      creationYear: Number(creationYear),
    });
  };

  return (
    <>
      <CreatePortfolio
        selectedOrganisation={selectedOrganisation}
        onCreatePortfolio={onCreatePortfolio}
        from={CALLING_FROM.PORTFOLIO_PORTFOLIO}
      />
    </>
  );
};

export default CreatePortfolioContainer;

CreatePortfolioContainer.propTypes = {
  selectedOrganisation: PropTypes.object,
  createPortfolio: PropTypes.func.isRequired,
};

CreatePortfolioContainer.defaultProps = {
  selectedOrganisation: {},
};
