/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    createCompany(state): void {
      state.isLoading = true;
    },
    createCompanySuccess(state): void {
      state.isLoading = false;
    },
    createCompanyFail(state): void {
      state.isLoading = false;
    },
  },
});

const { actions } = slice;

export const {
  createCompany,
  createCompanySuccess,
  createCompanyFail,
} = actions;
