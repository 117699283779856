import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles((theme: any) => ({
  paper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    margin: `${theme.spacing(2)}px auto`,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  grid: {
    minHeight: '225px',
    padding: theme.spacing(2),
  },
  sectionTitles: {
    color: '#212121',
    fontWeight: 400,
    letterSpacing: 0.2,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: `${theme.spacing(2)}px 0`,
  },
  actionBtn: {
    width: '100px !important',
    ...theme.custom.buttonLabel,
    marginTop: `${theme.spacing(4)}px`,
    marginRight: `${theme.spacing(3)}px`,
  },
  spacing: {
    height: `${theme.spacing(2)}px`,
  },
  icon: {
    color: '#363636',
  },
  inputField: {
    width: '60%',
  },
  grayBtn: {
    background: 'rgba(0,0,0,0.6) !important',
  },
  validationErrorTextMain: {
    color: 'red',
  },
  margin: {
    marginTop: 12,
  },
}));
