import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles((theme) => ({
  label: {
    ...theme.typography.fontSize,
    margin: '5px 0',
    color: '#363636',
    letterSpacing: 1,
  },

  previewLabel: {
    color: '#363636',
    margin: '15px 0',
    fontWeight: 'bold',
  },
  detailsDiv: {
    width: '13%',
    display: 'flex',
    marginRight: 10,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  descriptionDiv: {
    width: '65%',
    display: 'flex',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    margin: `${theme.spacing(2)}px 0`,
  },
  autoCompleteTextField: {
    width: '100%',
  },
  optionStyle: {
    display: 'flex',
    flex: 1,
  },
  companyNameRow: {
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  formControlCol: {
    width: '44%',
  },
  switchBase: {
    color: '#0C696A',
    '&$checked': {
      color: '#0C696A',
    },
    '&$checked + $track': {
      backgroundColor: '#0C696A',
    },
  },
  track: {},
  checked: {
    color: 'rgba(0,0,0,0.6)',
  },
}));
