// eslint-disable-next-line import/prefer-default-export
export const periodicityValues = [
  {
    id: 1,
    periodName: 'Monthly',
  },
  {
    id: 2,
    periodName: 'Quarterly',
  },
  {
    id: 3,
    periodName: 'Annual',
  },
  {
    id: 4,
    periodName: 'Quarterly YTD',
  },
  {
    id: 5,
    periodName: 'Half-Year',
  },
];

export const periodicityColumnTitle = [
  {
    id: 'Periodicity',
    title: 'Periodicity',
  },
  {
    id: 'Date',
    title: 'Date',
  },
];

export const periodicityDate = [
  {
    id: 1,
    periodName: 'Latest',
  },
  {
    id: 2,
    periodName: '2020',
  },
  {
    id: 3,
    periodName: '2019',
  },
  {
    id: 4,
    periodName: '2018',
  },
  {
    id: 5,
    periodName: '2017',
  },
];
