import React from 'react';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';
import { useStyle } from './styles';

const NumberFormatCustom = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    // key={`${investment.id}${accessor}_input_nf`}
    getInputRef={inputRef}
    onValueChange={(values) => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    thousandSeparator
    prefix="$"
  />
);

const FormattedTextField = ({
  investment,
  handleChange,
  column: { accessor, isRequired },
  isCreationgField,
}) => {
  const classes = useStyle();
  return (
    <TextField
      onChange={(event) =>
        handleChange(investment.id, {
          [accessor]: event.target.value,
        })
      }
      required={isRequired}
      error={!investment[accessor] && isRequired && isCreationgField}
      variant="outlined"
      value={investment[accessor]}
      InputProps={{
        className: classes.inputStyles,
        inputComponent: NumberFormatCustom,
      }}
    />
  );
};

export default FormattedTextField;
