import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from './react-auth0-spa';

export const withRouteProtection = (Component: any) => {
  return function PrivateRoute({ ...rest }): JSX.Element | null {
    const location = useLocation();
    const path = location.pathname;

    const { loading, isAuthenticated, loginWithRedirect } = useAuth0()!;

    useEffect(() => {
      if (loading || isAuthenticated) {
        return;
      }
      const fn = async () => {
        await loginWithRedirect({
          appState: { targetUrl: path },
        });
      };
      fn();
    }, [loading, isAuthenticated, loginWithRedirect, path]);

    const render = (props: any) =>
      isAuthenticated === true ? <Component {...props} /> : null;

    return render(rest);
  };
};
