import { gql } from 'apollo-boost';
import { USER_PAYLOAD } from '../../../graphql/payloads';

export const CREATE_USER = gql`
  mutation($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      firstName
      lastName
      email
      role
    }
  }
`;

export const UPDATE_USER = gql`
  mutation($input: UpdateUserInput) {
    updateUser(input: $input) {
        ${USER_PAYLOAD}
    }
  }
`;

export const DELETE_USERS = gql`
  mutation($userIds: [ID!]!, $organisationId: ID!) {
    removeUsersFromOrganisation(
      userIds: $userIds
      organisationId: $organisationId
    )
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input)
  }
`;

export const DELETE_CONTACTS_MUTATION = gql`
  mutation($contactIds: [ID!]!) {
    deleteContacts(contactIds: $contactIds)
  }
`;
