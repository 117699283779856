import React from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider } from '@material-ui/core/styles';
import { Auth0Provider, useAuth0 } from 'components/Common/Auth';
import { History } from 'history';

import config from 'config';
import { IntlProviderContainer } from 'containers/Config';
import apolloClient from 'components/Common/Auth/apolloClient';
import Router from 'components/Common/Router';
import { keyTrackTheme } from 'common/Theme';
import App from 'components/Common/App';
import { EntryPointProps, CallbackProp } from './type';

function Entrypoint({ history, store }: EntryPointProps): JSX.Element {
  const onRedirectCallback = (appState: CallbackProp): void => {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };
  return (
    <Auth0Provider
      domain={config.auth0.domain}
      client_id={config.auth0.clientId}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <Provider store={store}>
        <ThemeProvider theme={keyTrackTheme}>
          <ApolloWrapper history={history} />
        </ThemeProvider>
      </Provider>
    </Auth0Provider>
  );
}

const ApolloWrapper = ({ history }: { history: History }): JSX.Element => {
  const { logout } = useAuth0()!;
  const client = apolloClient(() => logout());
  global.apolloClient = client;
  return (
    <ApolloProvider client={client}>
      <IntlProviderContainer>
        <Router history={history}>
          <App />
        </Router>
      </IntlProviderContainer>
    </ApolloProvider>
  );
};

export default Entrypoint;
