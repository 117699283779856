/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { useIntl } from 'react-intl';
import { Button } from '@material-ui/core';
import messages from './messages';
import { userRoles } from '../../../user/userConstants';

const ManageOrganisationTable = ({
  data,
  deleteRecord,
  onUpdateOrganisation,
  onUpdateOrganisationRole,
}) => {
  const intl = useIntl();
  const [tableData, setTableData] = useState(data);
  useEffect(() => {
    setTableData(data);
  }, [data]);
  const columns = [
    {
      title: intl.formatMessage(messages.organisationId),
      field: 'orgKey',
      defaultSort: 'asc',
      editable: 'never',
    },
    {
      title: intl.formatMessage(messages.name),
      field: 'name',
      editable: 'never',
    },
    {
      title: intl.formatMessage(messages.role),
      field: 'role',
      lookup: Object.values(userRoles),
      cellStyle: { textTransform: 'capitalize' },
    },
  ];
  return (
    <MaterialTable
      columns={columns}
      data={tableData}
      title=""
      options={{
        sorting: false,
        selection: false,
        search: false,
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        grouping: false,
        draggable: false,
        debounceInterval: 0,
        toolbar: false,
      }}
      components={{
        Action: ({ data, action }) => {
          const { tooltip, onClick } =
            typeof action === 'function' ? action(data) : action;
          return (
            <Button
              variant="text"
              color={
                tooltip === 'Edit' || tooltip === 'Save'
                  ? 'primary'
                  : 'secondary'
              }
              onClick={(e) => onClick(e, data)}
            >
              {tooltip}
            </Button>
          );
        },
      }}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(async () => {
              const { name, id, role } = newData;
              const promises = [];
              if (name !== oldData.name) {
                promises.push(onUpdateOrganisation(name, id));
              }
              if (role !== oldData.role) {
                promises.push(onUpdateOrganisationRole(id, role));
              }
              await Promise.all(promises);
              resolve();
            }, 1000);
          }),
        onRowDelete: (newData) =>
          new Promise((resolve) => {
            setTimeout(async () => {
              await deleteRecord({
                ...newData,
                type: 'organization',
              });
              resolve();
            }, 1000);
          }),
      }}
    />
  );
};

ManageOrganisationTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  deleteRecord: PropTypes.func,
  onUpdateOrganisation: PropTypes.func,
  onUpdateOrganisationRole: PropTypes.func,
};

ManageOrganisationTable.defaultProps = {
  data: [],
  deleteRecord: () => {},
  onUpdateOrganisation: () => {},
  onUpdateOrganisationRole: () => {},
};

export default ManageOrganisationTable;
