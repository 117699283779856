/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
};

const slice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    createPortfolio(state): void {
      state.isLoading = true;
    },
    createPortfolioSuccess(state): void {
      state.isLoading = false;
    },
    createPortfolioFail(state): void {
      state.isLoading = false;
    },
    deletePortfolio(state): void {
      state.isLoading = true;
    },
    deletePortfolioSuccess(state): void {
      state.isLoading = false;
    },
    deletePortfolioFail(state): void {
      state.isLoading = false;
    },
    updatePortfolio(state): void {
      state.isLoading = true;
    },
    updatePortfolioSuccess(state): void {
      state.isLoading = false;
    },
    updatePortfolioFail(state): void {
      state.isLoading = false;
    },
    updateInvestment(state): void {
      state.isLoading = true;
    },
    updateInvestmentSuccess(state): void {
      state.isLoading = false;
    },
    updateInvestmentFail(state): void {
      state.isLoading = false;
    },
    deleteInvestment(state): void {
      state.isLoading = true;
    },
    deleteInvestmentSuccess(state): void {
      state.isLoading = false;
    },
    deleteInvestmentFail(state): void {
      state.isLoading = false;
    },
  },
});

const { actions } = slice;

export const {
  createPortfolio,
  createPortfolioSuccess,
  createPortfolioFail,
  deletePortfolio,
  deletePortfolioSuccess,
  deletePortfolioFail,
  updatePortfolio,
  updatePortfolioSuccess,
  updatePortfolioFail,
  updateInvestment,
  updateInvestmentSuccess,
  updateInvestmentFail,
  deleteInvestment,
  deleteInvestmentSuccess,
  deleteInvestmentFail,
} = actions;
