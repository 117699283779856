import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../constants';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles((theme) => ({
  grid: {
    minHeight: '225px',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  subHeader: {
    fontSize: 14,
    margin: '5px 0',
    color: '#363636',
    letterSpacing: 1,
  },
  divider: {
    margin: `${theme.spacing(4)}px 0`,
  },
  fullWidth: {
    width: '100%',
  },

  addButton: {
    width: '120px !important',
  },
  icon: {
    ...theme.custom.iconSize,
  },
  detailsWrapper: {
    padding: '15px 0px 0px 0px',
    fontWeight: 600,
    color: '#000',
  },
  detaislContainer: { display: 'flex', flexDirection: 'row' },
  dropArrow: {
    padding: '12px 0px 0px 7px',
  },
  contactGridContainer: {
    marginBottom: 16,
  },
  selected: {
    backgroundColor: '#E8EAF6!important',
  },
  root: {
    // list root
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    width: '75%',
    maxHeight: 180,
    overflowY: 'auto',
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  labelStyle: {
    minWidth: '40%',
    marginRight: '10px',
    color: '#363636',
  },
  selectInput: {
    width: '100%',
    '& fieldset': {
      borderColor: Colors.TEXT,
    },
    text: {
      wordBreak: 'break-all',
    },
  },
  text: {
    overflow: 'hidden',
  },
}));
