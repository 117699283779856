/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import * as React from 'react';
import {
  Select,
  Box,
  MenuItem,
  InputLabel,
  ListItemText,
} from '@material-ui/core';

import { Checkbox } from 'components/Common/Checkbox';
import { OptionsProps, SelectInputProps } from './type';
import { useStyles } from './styles';

export const SelectInput = ({
  selectClass = '',
  fullWidth = false,
  isDisabled = false,
  onChange,
  value,
  options,
  label,
  lableStyle,
  labelType = 'inline',
  style = {},
  multiSelect,
  wrapperClassName = '',
  useId = false,
  renderKey = 'id',
  variant,
  id = 'select',
}: SelectInputProps): JSX.Element => {
  const classes = useStyles();
  const styles = {
    root: {
      inline: classes.inlineRoot,
      top: classes.topRoot,
    },
    label: {
      inline: classes.inlineLabel,
      top: classes.topLabel,
    },
    select: {
      inline: classes.inlineSelect,
      top: '',
    },
  };

  const renderValue = (selected: unknown): React.ReactNode => {
    let returnValue = selected;
    if (Array.isArray(selected)) {
      const normalizedValue = selected
        .sort((a, b) => a - b)
        .slice(0, 2)
        .map((elem: any) => {
          const object = options.find((obj) => obj.id === elem.id) as
            | OptionsProps
            | undefined;
          return object?.name || object?.value;
        })
        .join(',');
      returnValue =
        selected.length > 2
          ? `${normalizedValue}..+${selected.length - 2}`
          : normalizedValue;
    }
    return (returnValue || 'Select') as React.ReactNode;
  };

  const handleChange = (
    event: React.ChangeEvent<{ id?: string | undefined; value: any }>
  ): void => {
    if (!multiSelect) {
      onChange(event);
      return;
    }
    const {
      target: { value: eventValue },
    } = event;
    const element = eventValue.slice(0, eventValue.length - 1);
    const selectedElementId = eventValue[eventValue.length - 1];
    const selectedElement = element.find(
      (ele: any) => ele.id === selectedElementId
    );
    if (selectedElement) {
      event.target.value = element.filter(
        (ele: any) => ele.id !== selectedElement.id
      );
    } else {
      const newElement = options.find(
        (ele: any) => ele.id === selectedElementId
      );
      element.push(newElement);
      event.target.value = element;
    }
    onChange(event);
  };

  return (
    <Box
      className={`${classes.root} ${styles.root[labelType]} ${wrapperClassName}`}
      style={style}
    >
      {label && (
        <InputLabel
          className={`${classes.formLabel} ${styles.label[labelType]} ${lableStyle}`}
          disabled={isDisabled}
        >
          {label}
        </InputLabel>
      )}
      <Select
        id={id}
        variant={variant || 'outlined'}
        className={`${styles.select[labelType]} ${selectClass}`}
        classes={{
          outlined: classes.selectOutLined,
          icon: classes.icon,
        }}
        multiple={multiSelect}
        displayEmpty
        disabled={isDisabled}
        renderValue={renderValue}
        fullWidth={fullWidth}
        onChange={handleChange}
        value={value}
        defaultValue="select"
      >
        {options.map((item: OptionsProps, index: number) => (
          <MenuItem
            key={`${item.id || item.value}_${index}`}
            value={useId ? item.id : item.name}
          >
            {Array.isArray(value) && multiSelect ? (
              <>
                <Checkbox
                  name="check1"
                  checked={value.some(
                    (ele: any) => ele[renderKey] === index + 1
                  )}
                />
                <ListItemText primary={item.value} />
              </>
            ) : useId ? (
              item.value
            ) : (
              item.name
            )}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
