import { gql } from 'apollo-boost';

import {
  COMPANY_PAYLOAD_SHORT,
  PORTFOLIO_PAYLOAD,
  INVESTMENT_PAYLOAD,
  INVESTMENT_COUNT_PAYLOAD,
} from './payloads';

export const CREATE_PORTFOLIO = gql`
  mutation(
    $organisationId: ID!
    $name: String!
    $size: Float
    $sizeCurrency: String!
    $creationYear: Int
    $targetNumberOfInvestments: Int
  ) {
    createPortfolio(
      input: {
        organisationId: $organisationId
        name: $name
        size: $size
        sizeCurrency: $sizeCurrency
        creationYear: $creationYear
        targetNumberOfInvestments: $targetNumberOfInvestments
      }
    ) {
      ${PORTFOLIO_PAYLOAD}
      investments {
        ${INVESTMENT_COUNT_PAYLOAD}
        items {
          ${INVESTMENT_PAYLOAD}
        }
      }
    }
  }
`;

export const DELETE_PORTFOLIOS = gql`
  mutation($portfolioIds: [ID!]!) {
    deletePortfolio(portfolioIds: $portfolioIds)
  }
`;

export const GET_PORTFOLIOS_QUERY = gql`
  query($organisationId: ID!) {
    organisation(organisationId: $organisationId) {
      id
      name
      portfolios {
        totalCount
        items {
          ${PORTFOLIO_PAYLOAD}
        }
      }
      companies {
        ${COMPANY_PAYLOAD_SHORT}
        portfolio {
          id
          name
        }
      }
    }
  }
`;

export const GET_PORTFOLIO_QUERY = gql`
  query getPortfolioQuery($portfolioId: ID!) {
    portfolio(portfolioId: $portfolioId) {
      ${PORTFOLIO_PAYLOAD}
      investments {
        ${INVESTMENT_COUNT_PAYLOAD}
        items {
          ${INVESTMENT_PAYLOAD}
          company {
            id
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_PORTFOLIO = gql`
  mutation($input: UpdatePortfolioInput) {
    updatePortfolio(input: $input) {
      name
    }
  }
`;
