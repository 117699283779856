import { defineMessages } from 'react-intl';

export default defineMessages({
  downloadModel: {
    id: 'Company.Reusable.CompanyHeader.DownloadModel',
    defaultMessage: 'Google Sheet Link',
  },
  close: {
    id: 'Company.Reusable.CompanyHeader.DownloadModel',
    defaultMessage: 'Close',
  },
  copyToClipboard: {
    id: 'Company.Reusable.CompanyHeader.DownloadModel',
    defaultMessage: 'Copy Link',
  },
  import: {
    id: 'Company.Reusable.CompanyHeader.Import',
    defaultMessage: 'Import',
  },
  export: {
    id: 'Company.Reusable.CompanyHeader.Export',
    defaultMessage: 'Export',
  },
  file: {
    id: 'Company.Reusable.CompanyHeader.File',
    defaultMessage: 'File',
  },
  source: {
    id: 'Company.Reusable.CompanyHeader.Source',
    defaultMessage: 'Source',
  },
  sections: {
    id: 'Company.Reusable.CompanyHeader.Sections',
    defaultMessage: 'Sections',
  },
  excelImportError: {
    id: 'Company.Reusable.CompanyHeader.FileUpload',
    defaultMessage: 'Please select a spreadsheet file',
  },
  defaultDriveLinkMessage: {
    id: 'Company.Reusable.CompanyHeader.FileUpload',
    defaultMessage:
      'Make sure file is publicly accessible or shared with keytrack-staging-files-api@keytrack-246312.iam.gserviceaccount.com',
  },
  driveLinkMessage: {
    id: 'Company.Reusable.CompanyHeader.FileUpload',
    defaultMessage: `Make sure file is publicly accessible or shared with keytrack-production-files-api@keytrack-246312.iam.gserviceaccount.com`,
  },
  importSuccess: {
    id: 'Company.Reusable.CompanyHeader.Import',
    defaultMessage: 'Import Successfull',
  },
  importFail: {
    id: 'Company.Reusable.CompanyHeader.Import',
    defaultMessage: 'Some thing went wrong',
  },
  dataFrom: {
    id: 'Company.Reusable.CompanyCount.dataFrom',
    defaultMessage: 'Data from',
  },
  selectCompanies: {
    id: 'Company.Reusable.ExportImport',
    defaultMessage: 'Select companies',
  },
  format: {
    id: 'Company.Reusable.ExportImport',
    defaultMessage: 'Format',
  },
  add: {
    id: 'Company.Reusable.add',
    defaultMessage: 'Add',
  },
  files: {
    id: 'Company.Reusable.files',
    defaultMessage: 'Companies / Files',
  },
  ok: {
    id: 'Company.Reusable.ok',
    defaultMessage: 'Ok',
  },
  selectContentDescription: {
    id: 'Reusable.CompanyHeader.popover',
    defaultMessage: 'Select the content to show\non this page',
  },
  sharedWith: {
    id: 'Reusable.CompanyFeed.sharedWith',
    defaultMessage: 'Shared with',
  },
  shared: {
    id: 'Reusable.CompanyFeed.shared',
    defaultMessage: 'Shared',
  },
  manageUser: {
    id: 'Reusable.CompanyHeader.manageUser',
    defaultMessage: 'Manage Users',
  },
  requestUpdate: {
    id: 'Reusable.CompanyHeader.requestUpdate',
    defaultMessage: 'Request Update',
  },
  investmentManagers: {
    id: 'Reusable.CompanyHeader.investmentManagers',
    defaultMessage: 'Investment Managers:',
  },
  select: {
    id: 'Reusable.CompanyHeader.select',
    defaultMessage: 'Select',
  },
  discard: {
    id: 'Reusable.CompanyHeader.discard',
    defaultMessage: 'DISCARD',
  },
  requestData: {
    id: 'Reusable.CompanyHeader.requestData',
    defaultMessage: 'Request Update',
  },
  manageUsers: {
    id: 'Reusable.CompanyHeader.manageUsers',
    defaultMessage: 'Manage Users',
  },
  uploadMultipleFile: {
    id: 'Reusable.CompanyHeader.Import',
    defaultMessage: 'Select multiple files',
  },
  companyName: {
    id: 'Reusable.CompanyHeader.CompanyName',
    defaultMessage: 'Name',
  },
  companyWebsite: {
    id: 'Reusable.CompanyHeader.CompanyWebsite',
    defaultMessage: 'Website',
  },
  companyDescription: {
    id: 'Reusable.CompanyHeader.CompanyDescription',
    defaultMessage: 'Description',
  },
  uploadFile: {
    id: 'Reusable.CompanyHeader.Import',
    defaultMessage: 'Select file',
  },
});
