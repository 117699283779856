import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  Button,
  MenuItem,
  TextField,
  ListItemSecondaryAction,
  InputAdornment,
} from '@material-ui/core';
import {
  Share,
  PieChartRounded,
  BusinessOutlined,
  Search,
  ArrowDropUp,
  ArrowDropDown,
  PostAdd,
} from '@material-ui/icons';
import useStyles from './Styles';

const ButtonDropDownWithSearch = ({
  id,
  open,
  noArrow,
  onClose,
  anchorEl,
  menuItems,
  subHeader,
  subHeader2,
  onBtnPress,
  onItemPress,
  dropdownTitle,
  searchRequired,
  subHeaderIcon,
  subHeader2Icon,
  onSubHeaderClick,
  onSubHeader2Click,
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const getIcon = (icon) => {
    switch (icon) {
      case 'PieChartRounded':
        return PieChartRounded;
      case 'BusinessOutlined':
        return BusinessOutlined;
      case 'PostAddIcon':
        return PostAdd;
      default:
        return Share;
    }
  };

  const SubHeaderIconElement = getIcon(subHeaderIcon);
  const SubHeader2IconElement = getIcon(subHeader2Icon);

  const handleSearchChange = (event) => {
    const {
      target: { value },
    } = event;
    setSearch(value);
  };

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        aria-haspopup="true"
        aria-controls={id}
        className={classes.dropdownButton}
        onClick={onBtnPress}
        endIcon={noArrow ? null : open ? <ArrowDropUp /> : <ArrowDropDown />}
      >
        {dropdownTitle}
      </Button>

      <Menu
        id={id}
        keepMounted
        open={open}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        onKeyDown={(e) => e.stopPropagation()}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {searchRequired && (
          <MenuItem
            key="textinput"
            value="textinput"
            onKeyDown={(e) => e.stopPropagation()}
            style={{ paddingLeft: '0px' }}
          >
            <TextField
              size="small"
              variant="outlined"
              placeholder="Search"
              className={classes.searchTextInput}
              value={search}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </MenuItem>
        )}
        {subHeader && (
          <MenuItem
            className={classes.SubHeader}
            key="subheader"
            value="subheader"
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(event) => {
              onSubHeaderClick(event, id);
              onClose();
            }}
          >
            {subHeader}
            <ListItemSecondaryAction className={classes.SubHeaderIconElement}>
              <SubHeaderIconElement edge="end" fontSize="small" />
            </ListItemSecondaryAction>
          </MenuItem>
        )}
        {subHeader2 && (
          <MenuItem
            className={classes.SubHeader}
            key="subheader2"
            value="subheader"
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(event) => {
              onSubHeader2Click(event, id);
              onClose();
            }}
          >
            {subHeader2}
            <ListItemSecondaryAction className={classes.SubHeaderIconElement}>
              <SubHeader2IconElement edge="end" fontSize="small" />
            </ListItemSecondaryAction>
          </MenuItem>
        )}
        {menuItems
          .filter(
            (ele) =>
              !searchRequired ||
              ele.name.toUpperCase().includes(search.toUpperCase())
          )
          .map((item) => (
            <MenuItem
              key={JSON.stringify(item.value)}
              value={item.value}
              disabled={item.disabled}
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(event) => {
                onItemPress(event, item.url);
                onClose();
              }}
            >
              {item.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

ButtonDropDownWithSearch.propTypes = {
  open: PropTypes.bool,
  noArrow: PropTypes.bool,
  searchRequired: PropTypes.bool,
  id: PropTypes.string,
  subHeader: PropTypes.string,
  dropdownTitle: PropTypes.object,
  onBtnPress: PropTypes.func,
  onItemPress: PropTypes.func,
  onClose: PropTypes.func,
  onSubHeader2Click: PropTypes.func,
  subHeaderIcon: PropTypes.string,
  subHeader2Icon: PropTypes.string,
  subHeader2: PropTypes.string,
  onSubHeaderClick: PropTypes.func,
  anchorEl: PropTypes.object,
  menuItems: PropTypes.arrayOf(PropTypes.object),
};

ButtonDropDownWithSearch.defaultProps = {
  id: '',
  open: false,
  noArrow: false,
  searchRequired: false,
  anchorEl: null,
  subHeader: '',
  dropdownTitle: '',
  menuItems: [],
  onBtnPress: () => {},
  onItemPress: () => {},
  onClose: () => {},
  onSubHeader2Click: () => {},
  subHeader2: '',
  subHeaderIcon: '',
  subHeader2Icon: '',
  onSubHeaderClick: () => {},
};

export default ButtonDropDownWithSearch;
