import { defineMessages } from 'react-intl';

export default defineMessages({
  onSuccessUpdateContact: {
    id: 'User.ManageUsers.onSuccessUpdateContact',
    defaultMessage: 'Contact successfully updated!',
  },
  onSuccessUpdateOrganisation: {
    id: 'User.ManageUsers.onSuccessUpdateOrganisation',
    defaultMessage: 'Organisation updated successfully!',
  },
  onSuccessUpdateUserRole: {
    id: 'User.ManageUsers.onSuccessUpdateUserRole',
    defaultMessage: 'User role updated successfully!',
  },
  onSelectPersonalOrganisation: {
    id: 'User.ManageUsers.onSelectPersonalOrganisation',
    defaultMessage:
      "Unfortunately, you can't manage Users & Access for Personal organisation",
  },
  onSuccessShareOrganisation: {
    id: 'User.ManageUsers.onSuccessShareOrganisation',
    defaultMessage: 'Your request accepted.',
  },
  onSuccessUnshareOrganisation: {
    id: 'User.ManageUsers.onSuccessUnshareOrganisation',
    defaultMessage: 'Organisation successfully unshared!',
  },
  manageUsers: {
    id: 'User.ManageUsers.manageUsers',
    defaultMessage: 'Users & Access',
  },
});
