import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { PortfolioThunk } from 'containers/Portfolio';
import { setAlert } from 'containers/Config/ducks';
import PortfolioScreen from 'components/Portfolio/PortfolioScreen';
import { selectSelectedOrganization } from 'containers/Organisation';
import buildUrn from 'common/buildUrn';
import messages from './messages';

// setAlert,
// portfolio,
// actionGetSelectedPortfolio,
// organisation,
// updateInvestment,
// deleteInvestments,

const PotfolioScreenContainer = () => {
  const params = useParams();
  const { portfolioId } = params;
  const selectedOrganization = useSelector(selectSelectedOrganization);
  const [portfolio, setPortfolio] = useState(null);
  const dispatch = useDispatch();

  const onUpdateInvestment = (obj) => {
    // todo, this should be done in portfolio thunk
    dispatch(PortfolioThunk.updateInvestment(obj));
  };

  const onDeleteInvestments = async (investmentIds) => {
    // todo, this should be done in portfolio thunk
    dispatch(
      PortfolioThunk.deleteInvestment(
        investmentIds.map((ele) => ({
          id: ele,
          portfolioId: buildUrn('portfolio', portfolioId),
        }))
      )
    );
  };

  const handleShowAlertMessage = () => {
    setAlert({
      open: true,
      severity: 'success',
      message: messages.onShowAlertMessage,
    });
  };

  useEffect(() => {
    if (!selectedOrganization) return;
    const { portfolios: { items = [] } = {} } = selectedOrganization;
    if (!items.length) return;
    if (portfolioId) {
      const selectedPortfolio =
        items.find((obj) => obj.id === buildUrn('portfolio', portfolioId)) ||
        null;
      setPortfolio(JSON.parse(JSON.stringify(selectedPortfolio)));
    }
  }, [portfolioId, selectedOrganization]);

  const companiesInvestments = {};
  let companies = [];

  if (portfolio) {
    portfolio.investments.items.forEach(({ company, ...investment }) => {
      if (!companiesInvestments[company.id])
        companiesInvestments[company.id] = [];
      companiesInvestments[company.id].push(investment);
    });
    companies = Object.entries(companiesInvestments).map((entry) => ({
      id: entry[0],
      name: entry[1][0].name,
      investments: entry[1],
    }));
  }

  return (
    <>
      <PortfolioScreen
        selectedOrganisation={selectedOrganization || {}}
        portfolio={portfolio || {}}
        onDeleteInvestments={onDeleteInvestments}
        onUpdateInvestment={onUpdateInvestment}
        companies={companies || []}
        handleShowAlertMessage={handleShowAlertMessage}
      />
    </>
  );
};

export default PotfolioScreenContainer;
