const userRoles = {
  ADMIN: 'admin',
  EDITOR: 'editor',
  VIEWER: 'viewer',
};

export {
  // eslint-disable-next-line import/prefer-default-export
  userRoles,
};
