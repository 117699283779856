/* eslint-disable operator-linebreak */
import React from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import CompanyApiTab from 'components/Company/CompanyApiTab';
import translations from 'translations';
import { setAlert } from 'containers/Config';
import { CompanyThunk } from './ducks';
import messages from './messages';

import {
  GET_COMPANIES_FROM_SHARED,
  DISCARD_SHARED_REQUEST,
  GET_PORTFOLIOS_QUERY,
} from '../../graphql';

const CompanyApiTabContainer = ({
  showShared,
  onCreateCompany,
  selectedOrganisation,
  portfolios,
  investmentManagers,
}) => {
  const [sharedCompanies, setSharedCompanies] = React.useState([]);
  const params = useParams();
  const dispatch = useDispatch();
  const { id } = params;

  const { data: { organisation = {} } = {} } = useQuery(GET_PORTFOLIOS_QUERY, {
    variables: { organisationId: id },
  });

  const [
    getCompaniesFromShared,
    { data: { getCompanyFromShared = [] } = {} },
  ] = useLazyQuery(GET_COMPANIES_FROM_SHARED, {
    fetchPolicy: 'network-only',
  });

  const [discardSharedRequest] = useMutation(DISCARD_SHARED_REQUEST, {
    onCompleted: () =>
      setAlert({
        open: true,
        message: messages.onSuccesDiscardCompanyShare,
        severity: 'success',
      }),
  });

  function createSharedCompanyData(name, action, companyId) {
    return { name, action, id: companyId };
  }

  const handleDiscardShareRequest = async (targetOrganisation) => {
    try {
      if (!targetOrganisation || !targetOrganisation.id) {
        throw new Error('Origin organization not provided');
      }
      const organisationIdTarget = targetOrganisation.id;
      await discardSharedRequest({
        variables: {
          organisationIdOrigin: id,
          organisationIdTarget,
        },
      });
      getCompaniesFromShared({
        variables: {
          organisationId: id,
        },
      });
    } catch (error) {
      const errorMessage =
        error.graphQLErrors &&
        error.graphQLErrors[0] &&
        error.graphQLErrors[0].code;
      setAlert({
        open: true,
        message: errorMessage || error.message,
        severity: 'error',
      });
    }
  };

  const handleAddCompanyFromShared = async (
    targetOrganisation,
    investmentData
  ) => {
    await new Promise((resolve, reject) => {
      dispatch(
        CompanyThunk.addCompanyFromShared(
          {
            investmentData,
            targetOrganisation,
          },
          {
            reject,
            resolve,
            successMsg: (
              <FormattedMessage
                {...translations.Company.ManageCompanies.Add
                  .onSuccesShareCompanyShare}
              />
            ),
          }
        )
      );
    });
    getCompaniesFromShared({
      variables: {
        organisationId: id,
      },
    });
  };

  React.useEffect(() => {
    getCompaniesFromShared({
      variables: {
        organisationId: id,
      },
    });
  }, []);

  React.useEffect(() => {
    if (Array.isArray(getCompanyFromShared)) {
      const newSharedCompanies = getCompanyFromShared.map((company) =>
        createSharedCompanyData(company.name, 'Discard', company.id)
      );
      setSharedCompanies(JSON.parse(JSON.stringify(newSharedCompanies)));
    }
  }, [JSON.stringify(getCompanyFromShared)]);

  return (
    <>
      <CompanyApiTab
        showShared={showShared}
        sharedCompanies={showShared ? sharedCompanies : []}
        organisation={organisation}
        onCreateCompany={onCreateCompany}
        selectedOrganisation={selectedOrganisation}
        unshareOrganisations={showShared ? handleDiscardShareRequest : null}
        addCompanyFromShared={showShared ? handleAddCompanyFromShared : null}
        portfolios={portfolios}
        setAlert={(alert) => dispatch(setAlert(alert))}
        investmentManagers={investmentManagers}
      />
    </>
  );
};

export default CompanyApiTabContainer;

CompanyApiTabContainer.propTypes = {
  selectedOrganisation: PropTypes.object,
  onCreateCompany: PropTypes.func,
  showShared: PropTypes.bool,
  setAlert: PropTypes.func.isRequired,
  addNewAllCompanies: PropTypes.func.isRequired,
  portfolios: PropTypes.arrayOf(PropTypes.object).isRequired,
  investmentManagers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

CompanyApiTabContainer.defaultProps = {
  selectedOrganisation: {},
  onCreateCompany: null,
  showShared: false,
};
