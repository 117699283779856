import React from 'react';
import MaterialTable from 'material-table';
import { DefaultKpiTableProps, DefaultKpi } from 'types';
import { METRIC_UNIT } from 'constants/index';

const DefaultKpiTable = ({
  defaultKpiValues,
  handleUpdateDefaultKpi,
  handleAddDefaultkpi,
  handledeleteDefaultKpi,
}: DefaultKpiTableProps): JSX.Element => {
  const columns = [
    {
      title: 'Metric',
      field: 'metric',
    },
    {
      title: 'Unit',
      field: 'unit',
      lookup: METRIC_UNIT,
    },
  ];

  return (
    <MaterialTable
      columns={columns}
      title=""
      data={defaultKpiValues}
      options={{
        sorting: true,
        search: false,
        emptyRowsWhenPaging: false,
        addRowPosition: 'last',
        grouping: false,
        draggable: false,
        actionsColumnIndex: -1,
        thirdSortClick: false,
        pageSize: 10,
      }}
      editable={{
        onRowUpdate: (newData, oldData: any): Promise<DefaultKpi> => {
          return handleUpdateDefaultKpi({
            ...newData,
            id: oldData.tableData.id,
          });
        },
        onRowAdd: (newData) => {
          return handleAddDefaultkpi(newData);
        },
        onRowDelete: (oldData: any): Promise<DefaultKpi> => {
          return handledeleteDefaultKpi(oldData.tableData.id);
        },
      }}
    />
  );
};

export default DefaultKpiTable;
