/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable operator-linebreak */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { ArrowDropUp } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { grey } from '@material-ui/core/colors';
import messages from './messages';
import { normalizeContacts } from '../../common/normalizeContactName';

import { COMPANY_CONTACT_TYPES } from '../../constants';

const themeSelector = createMuiTheme({
  palette: {
    primary: {
      main: '#3F51B5',
    },
    secondary: {
      main: 'rgba(0,0,0,0.56)',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  trow: {
    '& td': {
      borderBottom: 'none',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  label: {
    '& svg': {
      width: '25%',
      height: '25%',
    },
  },
  draftText: {
    color: themeSelector.palette.primary.main,
    cursor: 'pointer',
  },
  checkbox: {
    '&.Mui-checked': {
      color: grey[600],
    },
  },
}));

const columnStyle = {
  cellStyle: {
    paddingRight: 0,
    paddingLeft: 5,
  },
  headerStyle: {
    paddingRight: 0,
    paddingLeft: 5,
  },
};
const textColumnStyle = {
  cellStyle: {
    paddingRight: 0,
  },
  headerStyle: {
    paddingRight: 0,
  },
};

export default function CompaniesTable({
  data,
  duration,
  handleDraftRequestClick,
  handleClick,
  handleSelectAllClick,
  loading,
}) {
  const tableRef = React.useRef();
  useEffect(
    () => () => {
      tableRef.current.onAllSelected(false);
    },
    []
  );
  const classes = useStyles();
  const intl = useIntl();
  const renderDays = (object) => {
    if (!object) {
      return 'N/A';
    }
    if (object.time === 0) {
      return object.type;
    }
    return `${object.time} ${object.type}`;
  };
  const columns = [
    {
      field: 'name',
      title: intl.formatMessage(messages.name),
      ...textColumnStyle,
      defaultSort: 'asc',
      customSort: (a, b) => (a.name < b.name ? -1 : 1),
    },
    {
      field: 'feed',
      title: intl.formatMessage(messages.feed),
      customSort: (a, b) => {
        if (!a.data[duration].feed) {
          return -1;
        }
        if (!b.data[duration].feed) {
          return 1;
        }
        return a.data[duration].feed.days - b.data[duration].feed.days;
      },
      render: (rowData) => (
        <Typography>{renderDays(rowData.data[duration].feed)}</Typography>
      ),
      ...columnStyle,
    },
    {
      field: 'reports',
      title: intl.formatMessage(messages.reports),
      customSort: (a, b) => {
        if (!a.data[duration].report || !b.data[duration].report) {
          return -1;
        }
        if (!b.data[duration].report) {
          return 1;
        }
        return a.data[duration].report.days - b.data[duration].report.days;
      },
      render: (rowData) => (
        <Typography>{renderDays(rowData.data[duration].report)}</Typography>
      ),
      ...columnStyle,
    },
    {
      field: 'metrics',
      title: intl.formatMessage(messages.metrics),
      customSort: (a, b) => {
        if (!a.data[duration].metrics || !b.data[duration].metrics) {
          return -1;
        }
        if (!b.data[duration].metrics) {
          return 1;
        }
        return a.data[duration].metrics.days - b.data[duration].metrics.days;
      },
      render: (rowData) => (
        <Typography>{renderDays(rowData.data[duration].metrics)}</Typography>
      ),
      ...columnStyle,
    },
    {
      field: 'companyContacts',
      title: intl.formatMessage(messages.contact),
      render: (rowData) => {
        const { contacts } = rowData;
        if (!Array.isArray(contacts)) {
          return null;
        }
        const contactsData = contacts.filter(
          (obj) => obj.contactType === COMPANY_CONTACT_TYPES.CONTACT
        );
        // const contactData = normalizeContacts(contactsData, 1);
        // contactsData
        //   .filter((_, index) => (index < 1))
        //   .map(normalizeContactName).join(',');

        return (
          <div>
            {normalizeContacts(contactsData, 1)}
            {/* {contactsData.length > 1 ? `${contactData}..+${contactsData.length - 1}` : contactData} */}
          </div>
        );
      },
      ...textColumnStyle,
    },
    // {
    //   field: 'secCompanyContact',
    //   title: intl.formatMessage(messages.contact),
    //   render: (rowData) => (
    //     <div>
    //       {
    //         `${rowData.secContactName ? rowData.secContactName : ''}${rowData.secContactName && rowData.secCompanyContact ? ', ' : ''}  ${rowData.secCompanyContact ? rowData.secCompanyContact : ''}`
    //       }
    //     </div>
    //   ),
    //   ...textColumnStyle,
    // },
    {
      field: 'firstInvestMgr',
      title: intl.formatMessage(messages.investmentManager),
      render: (rowData) => {
        const { contacts } = rowData;
        if (!Array.isArray(contacts)) {
          return null;
        }
        const managersData = contacts.filter(
          (obj) => obj.contactType !== COMPANY_CONTACT_TYPES.CONTACT
        );
        // const manager = normalizeContacts(managersData, 1);
        // managersData
        //   .filter((_, index) => (index < 1))
        //   .map(normalizeContactName).join(',');

        return (
          <div>
            {normalizeContacts(managersData, 1)}
            {/* {managersData.length > 1 ? `${manager}..+${managersData.length - 1}` : manager} */}
          </div>
        );
      },
      ...textColumnStyle,
    },
    {
      field: 'action',
      title: intl.formatMessage(messages.action),
      render: (rowData) => (
        <IconButton
          component="span"
          onClick={() => {
            handleDraftRequestClick(rowData);
          }}
        >
          <MailOutlineIcon
            style={{
              fontSize: 30,
              color: '#212121',
            }}
          />
        </IconButton>
      ),
    },
  ];

  const icons = {
    SortArrow: (props) => <ArrowDropUp {...props} />,
  };

  const handleOnSelectionChange = (rows, rowData) => {
    if (!rowData) {
      handleSelectAllClick(rows, rowData);
      return;
    }
    handleClick(rows, rowData);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={data}
          title=""
          icons={icons}
          options={{
            sorting: true,
            toolbar: false,
            search: false,
            emptyRowsWhenPaging: false,
            thirdSortClick: false,
            selection: true,
            selectionProps: {
              className: classes.checkbox,
            },
          }}
          isLoading={loading}
          onSelectionChange={handleOnSelectionChange}
        />
      </Paper>
    </div>
  );
}

CompaniesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleDraftRequestClick: PropTypes.func.isRequired,
  duration: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleSelectAllClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
