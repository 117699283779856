import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import IntlProvider from 'components/Common/IntlProvider';
import { getLocale } from './ducks/selector';

interface IntlProps {
  children: ReactNode;
}

const IntlProviderContainer = ({ children }: IntlProps): JSX.Element => {
  const state = useSelector(getLocale);
  return <IntlProvider locale={state}>{children}</IntlProvider>;
};

export default IntlProviderContainer;
