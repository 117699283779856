import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { ACTION_TYPES_PORTFOLIO } from '../actionTypes';

const initialState = {
  portfolios: [],
  selectedPortfolio: null,
};

const portfolio = handleActions(
  {
    [ACTION_TYPES_PORTFOLIO.GET_PORTFOLIOS]: (state) => ({
      ...state,
      portfolios: [],
    }),
    [ACTION_TYPES_PORTFOLIO.GET_PORTFOLIOS_SUCCESS]: (state, { payload }) => ({
      ...state,
      portfolios: payload,
    }),
    [ACTION_TYPES_PORTFOLIO.GET_PORTFOLIOS_FAIL]: (state) => ({
      ...state,
      portfolios: [],
    }),
    [ACTION_TYPES_PORTFOLIO.CREATE_PORTFOLIO_SUCCESS]: (
      state,
      { payload }
    ) => ({
      ...state,
      portfolios: state.portfolios.concat({ ...payload }),
    }),
    [ACTION_TYPES_PORTFOLIO.GET_SELECTED_PORTFOLIO]: (state, { payload }) => ({
      ...state,
      selectedPortfolio: payload,
    }),
    [ACTION_TYPES_PORTFOLIO.UPDATE_PORTFOLIO_SUCCESS]: (
      state,
      { payload: { selectedPortfolio, portfolios } = {} }
    ) => ({
      ...state,
      selectedPortfolio: selectedPortfolio || state.selectedPortfolio,
      portfolios,
    }),
  },
  initialState
);

const getRoot = (state) => state.portfolio;

const getOrganizationPortfolios = createSelector(
  getRoot,
  (state) => state.portfolios
);

const getSelectedPortfolio = createSelector(
  getRoot,
  (state) => state.selectedPortfolio
);

export { portfolio, getOrganizationPortfolios, getSelectedPortfolio };
