import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  OrganizationThunk,
  selectSelectedOrganization,
} from 'containers/Organisation';
import { FormattedMessage } from 'react-intl';

import { PortfolioThunk } from 'containers/Portfolio';
import { useSubscriptionContainer } from 'containers/Subscription';
import OrganizationSettings from 'components/Organisation/OrganizationSettings';
import DefaultKpiContainer from 'containers/Organisation/DefaultKpiContainer';
import { userRoles } from 'user/userConstants';
import { UpdateOrganisationPayload } from 'types';
import translations from 'translations';

const SettingsScreenContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const organisation = useSelector(selectSelectedOrganization);
  const history = useHistory();
  const {
    updateSubscription,
    subscriptions,
    cancelSubscription,
    subscriptionAlert,
  } = useSubscriptionContainer();

  const deleteOrganisation = async ({ id }: { id: string }): Promise<void> => {
    await new Promise((resolve, reject) => {
      dispatch(
        OrganizationThunk.deleteOrganisation(id, {
          resolve,
          reject,
        })
      );
    });
    history.push('/');
  };

  const updateOrganisation = (payload: UpdateOrganisationPayload): void => {
    dispatch(OrganizationThunk.updateOrganisation(payload));
  };

  const updateDefaultPortfolio = async ({
    portfolioId,
  }: {
    portfolioId: string;
  }): Promise<void> => {
    dispatch(
      PortfolioThunk.updatePortfolio(
        {
          defaultPortfolioId: portfolioId,
          organisationId: organisation.id,
        },
        {
          successMsg: (
            <FormattedMessage
              {...translations.OrganisationSettings
                .onSuccessMakeOrganisationDefault}
            />
          ),
        }
      )
    );
  };

  return organisation.role !== userRoles.VIEWER ? (
    <>
      <OrganizationSettings
        organisation={organisation}
        updateDefaultPortfolio={updateDefaultPortfolio}
        updateOrganisation={updateOrganisation}
        deleteOrganisation={deleteOrganisation}
        updateSubscription={updateSubscription}
        cancelSubscription={cancelSubscription}
        subscriptions={subscriptions}
        subscriptionAlert={subscriptionAlert}
      />
      <DefaultKpiContainer />
    </>
  ) : (
    <Redirect to={`${organisation.url}/updates`} />
  );
};

export default SettingsScreenContainer;
