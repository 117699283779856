import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Select, MenuItem, Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useParams } from 'react-router-dom';

import translations from 'translations';
import { getDownloads } from 'common/getDownloads';
import { useStyles } from './styles';
import { VIEW_CONTENT_TYPE } from '../../../constants';
import { setDownloads } from '../../../reducers';

const CompanyReportRow = ({
  item,
  isEdit,
  handleChangeUpdateData,
  handleDeleteReport,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const setDownloadRedux = (payload) => {
    dispatch(setDownloads(payload));
  };

  const { formatMessage } = useIntl();
  const { companyId } = useParams();
  const [state, setState] = useState({
    peroid: '',
    view: '',
  });

  useEffect(() => {
    setState({
      period: item.period,
      view: item.view,
    });
  }, [item]);

  const handleChange = (key, value) => {
    const newState = {
      ...state,
      [key]: value,
    };
    setState(newState);
    return handleChangeUpdateData(item.id, newState);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.flexBox}
    >
      <p className={classes.text}>{item.name}</p>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ width: 300 }}
      >
        {isEdit ? (
          <>
            <Select
              value={state.period}
              onChange={(e) => handleChange('period', e.target.value)}
            >
              <MenuItem value="cs">Coming Soon</MenuItem>
            </Select>
            <Select
              value={state.view}
              onChange={(e) => handleChange('view', e.target.value)}
            >
              {VIEW_CONTENT_TYPE.map((elem) => (
                <MenuItem value={elem.name.toLowerCase()}>{elem.name}</MenuItem>
              ))}
            </Select>
            <Button
              variant="text"
              className={classes.deleteBtn}
              onClick={() => handleDeleteReport({ id: item.id })}
            >
              {`${formatMessage(translations.Common.common.delete)}`}
            </Button>
          </>
        ) : (
          <div style={{ display: 'flex', flexGrow: 1 }} />
        )}
        {!isEdit && (
          <IconButton
            onClick={async () => {
              await getDownloads(
                item.url,
                item.name,
                companyId,
                undefined,
                setDownloadRedux
              );
            }}
            aria-label="show"
            className={classes.iconBtn}
            size="small"
          >
            <GetAppIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

CompanyReportRow.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    period: PropTypes.string,
    view: PropTypes.string,
    id: PropTypes.string,
  }),
  isEdit: PropTypes.bool.isRequired,
  handleChangeUpdateData: PropTypes.func.isRequired,
  handleDeleteReport: PropTypes.func.isRequired,
};

CompanyReportRow.defaultProps = {
  item: {},
};

// eslint-disable-next-line import/prefer-default-export
export { CompanyReportRow };
