/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { METRIC_CURRENCY_DICT } from '../../constants/metrics';

const CompaniesEditRow = ({
  column,
  currencyVal,
  columnValue,
  handleChangeInputValue,
  handleCashFlow,
  handleBalanceSheet,
}) => (
  <TableCell align="left">
    {column[0] === 'cashFlow' ? (
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {METRIC_CURRENCY_DICT[currencyVal]}
            </InputAdornment>
          ),
        }}
        defaultValue={column[1].slice(1)}
        onChange={handleChangeInputValue(column[0])}
        onKeyUp={() => handleCashFlow('Cashflow')}
      />
    ) : column[0] === 'cash' ? (
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {METRIC_CURRENCY_DICT[currencyVal]}
            </InputAdornment>
          ),
        }}
        defaultValue={column[1].slice(1)}
        onChange={handleChangeInputValue(column[0])}
        onKeyUp={() => handleBalanceSheet('BalanceSheet')}
      />
    ) : (
      columnValue
    )}
  </TableCell>
);

export default CompaniesEditRow;

CompaniesEditRow.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  currencyVal: PropTypes.string,
  columnValue: PropTypes.string,
  handleChangeInputValue: PropTypes.func,
  handleCashFlow: PropTypes.func,
  handleBalanceSheet: PropTypes.func,
};

CompaniesEditRow.defaultProps = {
  column: [],
  currencyVal: '',
  columnValue: '',
  handleChangeInputValue: () => {},
  handleCashFlow: () => {},
  handleBalanceSheet: () => {},
};
