import { defineMessages } from 'react-intl';

export default defineMessages({
  addNewTab: {
    id: 'User.ManageCompanyTabs.addNewTab',
    defaultMessage: 'Add New',
  },
  addFromSharedTab: {
    id: 'User.ManageCompanyTabs.addFromSharedTab',
    defaultMessage: 'Add From Shared',
  },
});
