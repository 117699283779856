import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'Company.CompanyTable.name',
    defaultMessage: 'Company Name',
  },
  investedAmount: {
    id: 'Company.CompanyTable.investedAmount',
    defaultMessage: 'Invested amount',
  },
  ownership: {
    id: 'Company.CompanyTable.ownership',
    defaultMessage: 'Ownership',
  },
  involvement: {
    id: 'Company.CompanyTable.involvement',
    defaultMessage: 'Involvement',
  },
  editCompaniesTitle: {
    id: 'Company.CompanyTable.editCompaniesTitle',
    defaultMessage: 'Edit Companies',
  },
  portfolio: {
    id: 'Company.CompanyTable.portfolio',
    defaultMessage: 'Portfolio',
  },
  lowInvolvement: {
    id: 'Company.CompanyTable.lowInvolvement',
    defaultMessage: 'Low',
  },
  middleInvolvement: {
    id: 'Company.CompanyTable.middleInvolvement',
    defaultMessage: 'Middle',
  },
  highInvolvement: {
    id: 'Company.CompanyTable.highInvolvement',
    defaultMessage: 'High',
  },
});
