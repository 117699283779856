import { Button as MateriaButton } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { Colors } from 'constants/colors';

export const Button = withStyles(() => ({
  root: {
    minWidth: 120,
    color: Colors.WHITE,
  },
  label: {
    color: Colors.WHITE,
    textTransform: 'capitalize',
  },
  disabled: {
    color: Colors.DISALBED,
    background: Colors.BACKGROUND_DISALBED,
    '& hover': {
      background: Colors.BACKGROUND_DISALBED,
    },
  },
}))(MateriaButton);

export const useStyles = makeStyles(() => ({
  root: {
    minWidth: 80,
    color: Colors.WHITE,
  },
  typeDefault: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      background: Colors.DEFAULT_HOVER,
    },
    '& > span': {
      color: Colors.DEFAULT,
    },
  },
  typeCommon: {
    background: Colors.PRIMARY,
    '&:hover': {
      background: Colors.HOVER,
    },
  },
  typeDelete: {
    background: Colors.DELETE,
    '&:hover': {
      background: Colors.DELETE_DARK,
    },
  },
  typePrimary: {
    background: Colors.DEFAULT,
    '&:hover': {
      background: Colors.DEFAULT,
    },
  },
  typeGray: {
    color: Colors.TEXT,
    background: Colors.DARK_GREY,
    '&:hover': {
      background: Colors.DISALBED,
    },
  },
  square: {
    borderRadius: 0,
  },
  round: {
    borderRadius: 20,
  },
}));
