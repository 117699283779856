import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { endOfDay } from 'date-fns';

import { Input } from 'components/Common/Input';
import { OnChangeEvent } from 'types';
import { TableUtils } from 'utils/index';
import { RowEditField } from './type';

export const EditableField = ({
  onChange,
  placeholder,
  unit,
  value,
  id,
  onKeydown,
  onValidate = async () => ({ isValid: true, errorText: '', validValue: '' }),
}: RowEditField) => {
  const [data, setData] = useState<{
    value: string;
    formattedValue: string;
  }>({
    value: '',
    formattedValue: '',
  });
  const [valid, setValid] = useState({
    isValid: true,
    errorText: '',
  });

  const handleValidate = async (validValue: string) => {
    const { errorText, isValid } = await onValidate(validValue);
    setValid({ isValid, errorText });
  };

  const handleOnChange = (event: OnChangeEvent): void => {
    const { value: changedVal, formattedValue } = event.target as any;
    setData({
      value: changedVal,
      formattedValue,
    });
    handleValidate(unit === 'Date' ? formattedValue : `${changedVal}`.trim());
  };

  const handleSave = (): void => {
    if (data.value === value) return;
    if (unit === 'Date' && Boolean(data.value)) {
      const date = moment(data.formattedValue, 'DD/MM/YYYY');
      if (date.isValid()) {
        onChange({ target: { value: endOfDay(date.toDate()).toISOString() } });
        return;
      }
    }
    if (unit === '%') {
      onChange({ target: { value: (Number(data.value) / 100).toFixed(2) } });
      return;
    }
    onChange({ target: { value: `${data.value}`.trim() } });
  };

  useEffect(() => {
    if (unit === 'Date') {
      const date = moment(value);
      if (!date.isValid()) {
        setData({
          value: '',
          formattedValue: '',
        });
        return;
      }
      const dateFormate = date.format('DD/MM/YYYY');
      setData({
        value: dateFormate.split('/').join(''),
        formattedValue: dateFormate,
      });
      return;
    }
    if (unit === '%') {
      setData({
        value: (Number(value) * 100).toFixed(2),
        formattedValue: '',
      });
      return;
    }
    setData({
      value,
      formattedValue: '',
    });
  }, [value]);

  return (
    // <Tooltip
    //   disableFocusListener
    //   disableHoverListener
    //   disableTouchListener
    //   open={!valid.isValid}
    //   title={valid.errorText}
    //   PopperProps={{
    //     disablePortal: true,
    //   }}
    // >
    <Input
      id={id}
      error={!valid.isValid}
      onBlur={handleSave}
      onChange={handleOnChange}
      placeholder={placeholder}
      InputProps={TableUtils.getInputProps(unit)}
      value={data.value}
      onKeydown={onKeydown}
      errorText={valid.errorText}
    />
    // </Tooltip>
  );
};
