/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from 'react';
import { Modal } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  selectOrganizationLoading,
  selectOrganizations,
  selectSelectedOrganization,
} from 'containers/Organisation';

import { OnboardingMain } from '../OnboardingMain';
import { useStyles } from './styles';

const OnboadingModal = () => {
  const [onboadingModal, setOnboadingModal] = useState(false);
  const classes = useStyles();
  const organisationLoading = useSelector(selectOrganizationLoading);
  const propOrganizations = useSelector(selectOrganizations);
  const selectedOrganisation = useSelector(selectSelectedOrganization);

  useEffect(() => {
    if (onboadingModal || organisationLoading) return;
    setOnboadingModal(!organisationLoading && !propOrganizations.length);
  }, [organisationLoading]);

  return (
    <Modal
      open={onboadingModal}
      aria-labelledby="onbording_modal"
      aria-describedby="onbording_modal"
      BackdropProps={{ className: classes.modalOverlay }}
      className={classes.modal}
      disableBackdropClick
      disableEscapeKeyDown
      id="onbording_modal"
    >
      <div className={classes.modalContent}>
        <OnboardingMain
          onClose={() => {
            setOnboadingModal(false);
          }}
          selectedOrganisation={selectedOrganisation}
        />
      </div>
    </Modal>
  );
};

export { OnboadingModal };
