/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
// import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import { useQuery } from '@apollo/react-hooks';
import { useStyles } from './styles';
import messages from './message';
import CompaniesTable from './CompaniesTable';
import ModalBody from './MetricsModalBody';
import { METRIC_CURRENCY_DICT } from '../../constants/metrics';
import { GET_METRIC_NAMES_GROUPED_BY_CATEGORY } from '../../graphql/company';

const KpiPage = ({
  companiesByOrganisation,
  metricsData,
  columns,
  getUpdateKpiData,
  getUpdateKpiPeriodicity,
  getUpdateKpiPeriodicityDate,
  deleteKpiColumn,
  addKpiDataColumn,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const currentDate = `Latest`;

  const [
    filteredcompaniesByOrganisation,
    setFilteredcompaniesByOrganisation,
  ] = React.useState([]);

  const [modalTypes, setModalTypes] = React.useState('Periodicity');
  const [open, setOpen] = React.useState(false);
  const [currentColumnId, setCurrentColumnId] = React.useState('');

  const [actionMetrics, setActionMetrics] = React.useState({});
  const [actionPeriodicity, setActionPeriodicity] = React.useState({});
  const [periodicityDate, setPeriodicityDate] = React.useState(currentDate);

  const thisColumnDate = columns.find((item) => item.id === currentColumnId);

  const {
    loading,
    data: { getMetricNamesGroupedByCategory = [] } = {},
  } = useQuery(GET_METRIC_NAMES_GROUPED_BY_CATEGORY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      companyIds: companiesByOrganisation.map((item) => item.id),
      periodicity: thisColumnDate ? thisColumnDate.periodicity : 'Monthly',
      date: thisColumnDate ? thisColumnDate.date : currentDate,
    },
  });

  const metricsCategory = !loading ? getMetricNamesGroupedByCategory : 'Null';

  const handleOpen = (typeModal, columnId) => {
    setModalTypes(typeModal);
    if (modalTypes) {
      setOpen(true);
    }
    setCurrentColumnId(columnId);
    setActionPeriodicity({
      value: 'Monthly',
      key: 'periodicity',
      currentColumnId: columnId,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleColumnFilter = (value, key, categoryValue, category) => {
    setActionMetrics({
      value,
      key,
      categoryValue,
      category,
      currentColumnId,
    });
    setOpen(false);
  };

  const handleChangePereodicity = (value, key) => {
    setActionPeriodicity({ value, key, currentColumnId });
  };

  const handleChangePereodicityDate = (date, key) => {
    let value;
    if (date === 'Latest') {
      value = currentDate;
      setPeriodicityDate({ value, key, currentColumnId });
    } else if (actionPeriodicity.value === 'Annual') {
      value = `${'01/'}${date}`;
      setPeriodicityDate({ value, key, currentColumnId });
    } else {
      value = date;
      setPeriodicityDate({ value, key, currentColumnId });
    }
  };
  const getRowsValues = () => {
    const result = metricsData.map((items) => {
      const metricsValue = [];
      const metricsUnit = [];
      const setDate = [];
      if (items.metrics) {
        items.metrics.map((metric) => {
          columns.find((col, idx) => {
            const arrayMetricDate = metric.date.slice(0, 7);
            const refactMetricDate = arrayMetricDate.split('-');
            const date = col.date ? col.date : currentDate;
            if (col.metricName === metric.metricName) {
              if (
                (date === 'Latest' &&
                  (!setDate[idx] ||
                    moment(metric.date).valueOf() >
                      moment(setDate[idx]).valueOf())) ||
                date === `${refactMetricDate[1]}/${refactMetricDate[0]}`
              ) {
                setDate[idx] = metric.date;
                metricsValue[+idx] = intl.formatNumber(metric.metricValue);
                metricsUnit[+idx] = METRIC_CURRENCY_DICT[metric.metricUnit];
              }
            }
          });
          // setDate = null;
        });
      }
      const rowObject = (columnsArray, metrics, name) => {
        let res = {};
        for (let i = 1; i < columnsArray.length; i++) {
          const value = `${metricsUnit[i]}${metrics[i]}`;
          res = { ...res, name, [`Column_${i}`]: metrics[i] ? value : '-' };
        }
        return res;
      };
      return rowObject(columns, metricsValue, items.name);
    });
    return result;
  };

  const handleSubmitData = () => {
    getUpdateKpiPeriodicity(actionPeriodicity);
    getUpdateKpiPeriodicityDate(periodicityDate);
    setOpen(false);
  };

  useEffect(() => {
    getUpdateKpiData(actionMetrics);
  }, [actionMetrics]);

  useEffect(() => {
    const rowsValues = getRowsValues();
    setFilteredcompaniesByOrganisation(rowsValues);
  }, [columns]);

  useEffect(() => {
    getUpdateKpiPeriodicity(actionPeriodicity);
    getUpdateKpiPeriodicityDate(periodicityDate);
  }, [companiesByOrganisation]);

  const modalStyles = useMemo(
    () =>
      modalTypes === 'Periodicity'
        ? classes.paperModalMini
        : classes.paperModal,
    [modalTypes]
  );
  return (
    <>
      <Grid item xs={12} className={classes.marginBottom}>
        <Typography
          component="h2"
          variant="h5"
          color="primary"
          gutterBottom
          className={classes.title}
        >
          {intl.formatMessage(messages.title)}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => addKpiDataColumn()}
        >
          ADD COLUMN
        </Button>
      </Grid>
      <CompaniesTable
        open={open}
        columns={columns}
        data={filteredcompaniesByOrganisation}
        handleOpenModal={handleOpen}
        deleteKpiColumn={deleteKpiColumn}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
      >
        <Fade in={open}>
          <div className={modalStyles}>
            <ModalBody
              currentColumnId={currentColumnId}
              modalTypes={modalTypes}
              actionPeriodicity={actionPeriodicity}
              metricsData={metricsCategory}
              handleColumnFilter={handleColumnFilter}
              handleChangePereodicity={handleChangePereodicity}
              handleChangePereodicityDate={handleChangePereodicityDate}
              loading={loading}
              handleSubmitData={handleSubmitData}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
};

KpiPage.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  companiesByOrganisation: PropTypes.arrayOf(PropTypes.object).isRequired,
  metricsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  getUpdateKpiData: PropTypes.func,
  getUpdateKpiPeriodicity: PropTypes.func,
  getUpdateKpiPeriodicityDate: PropTypes.func,
  deleteKpiColumn: PropTypes.func,
  addKpiDataColumn: PropTypes.func,
};

KpiPage.defaultProps = {
  getUpdateKpiData: () => {},
  getUpdateKpiPeriodicity: () => {},
  getUpdateKpiPeriodicityDate: () => {},
  deleteKpiColumn: () => {},
  addKpiDataColumn: () => {},
};

export default KpiPage;
