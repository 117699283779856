import { AppDispatch, RootState, AppThunk } from 'store';
import {
  GraphqlService,
  logActionError,
  handleGraphqlServerError,
} from 'utils/api';
import {
  CreateCompanyPayload,
  GraphqlResponse,
  Company,
  OnSuccessPayload,
  UpdateCompanyPayload,
  AddCompanyFromSharedPayload,
  Investment,
} from 'types';
import {
  OrganizationThunk,
  updateCompany as updateCompanyAction,
  updateCompanySuccess,
  updateCompanyFail,
  updateContacts as updateContactsAction,
  updateContactsSuccess,
  updateContactsFail,
  selectSelectedOrganization,
  addCompanyFromShared as addCompanyFromSharedAction,
  addCompanyFromSharedSuccess,
  addCompanyFromSharedFail,
  deleteCompanies as deleteCompaniesAction,
  deleteCompaniesSuccess,
  deleteCompaniesFail,
  addInvestmentsReducer as addInvestmentsAction,
} from 'containers/Organisation';
import { setAlert } from 'containers/Config';
import { CREATE_USERS } from 'graphql/user';
import {
  CREATE_COMPANY_MUTATION,
  UPDATE_COMPANY_MUTATION,
  UPDATE_CONTACTS_MUTATION,
  ADD_COMPANY_FROM_SHARED,
  DELETE_COMPANIES_MUTATION,
} from './query';
import {
  createCompany as createCompanyAction,
  createCompanySuccess,
  createCompanyFail,
} from './reducer';

const updateContact = (payload: any, onSuccess: OnSuccessPayload) => {
  return async (
    dispatch: AppDispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { reject, resolve, successMsg } = onSuccess;
    try {
      const service = new GraphqlService(global.apolloClient);
      dispatch(updateContactsAction());
      if (!Array.isArray(payload)) {
        await service.mutation(UPDATE_CONTACTS_MUTATION, {
          input: {
            ...payload,
          },
        });
      }
      if (resolve) {
        resolve();
        return;
      }
      const state = getState();
      const { companies } = selectSelectedOrganization(state) || {
        companies: [],
      };
      const { companyId } = payload[0];
      const filteredCompanyIndex = companies.findIndex(
        (company) => company.id === companyId
      );
      const filteredCompany = companies[filteredCompanyIndex];
      if (!filteredCompany || !Object.keys(filteredCompany).length) {
        throw new Error('No company found');
      }
      const updatedCompany = {
        ...filteredCompany,
        contacts: {
          ...payload,
        },
      };
      const newCompanies = JSON.parse(JSON.stringify(companies));
      newCompanies[filteredCompanyIndex] = updatedCompany;
      dispatch(updateContactsSuccess(newCompanies));
      dispatch(
        setAlert({
          open: true,
          message: successMsg || 'Operation Successful',
          severity: 'success',
        })
      );
    } catch (error) {
      logActionError('error', error, 'updateContact');
      dispatch(updateContactsFail());
      if (reject) {
        reject();
      }
    }
  };
};

const createCompany = (
  payload: { company: CreateCompanyPayload; contactDetails?: Array<any> },
  onSuccess: OnSuccessPayload
): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { company: companyPayload, contactDetails = [] } = payload;
    const { successMsg, reject, resolve } = onSuccess || {};
    try {
      dispatch(createCompanyAction());
      const service = new GraphqlService(global.apolloClient);
      const {
        data: { createCompany: newCompany },
      } = await service.mutation<GraphqlResponse<{ createCompany: Company }>>(
        CREATE_COMPANY_MUTATION,
        {
          ...companyPayload,
        }
      );
      const { organisation } = newCompany;
      const newOrg = {
        ...organisation,
        direct: true,
      };
      const investmentsWithCompanies =
        newCompany.investments?.map(
          (investment: Investment): Investment => ({
            ...investment,
            portfolioId: investment.portfolio?.id,
            company: {
              name: newCompany.name,
              id: newCompany.id,
            },
          })
        ) || [];
      dispatch(addInvestmentsAction(investmentsWithCompanies));
      dispatch(
        OrganizationThunk.addOrganization(
          JSON.parse(JSON.stringify(newOrg)),
          false
        )
      );
      dispatch(createCompanySuccess());
      dispatch(
        setAlert({
          message: successMsg || 'Company create success',
          open: true,
          severity: 'success',
        })
      );
      if (resolve) {
        resolve(JSON.parse(JSON.stringify(newCompany)));
      }
      if (contactDetails.length) {
        const {
          data: { createUsers } = { createUsers: [] },
        }: any = await service.mutation(CREATE_USERS, {
          input: contactDetails.map((elem) => ({
            ...elem,
            organisationId: newOrg.id,
            contactType: elem.contactType || 'contact',
            updateNameContionally: true,
          })),
        });
        const contactsPayload = createUsers.map((elem: any, index: number) => ({
          orgId: newOrg.id,
          userId: elem.id,
          email: contactDetails[index].email,
          firstName: contactDetails[index].firstName,
          lastName: contactDetails[index].lastName,
          contactType: 'contact',
          companyId: newCompany.id,
        }));
        dispatch(updateContact(contactsPayload, {}) as any);
      }
      // }
    } catch (error) {
      logActionError('log', error, 'createCompany');
      dispatch(createCompanyFail());
      dispatch(
        setAlert({
          open: true,
          severity: 'error',
          message: handleGraphqlServerError(error),
        })
      );
      if (reject) {
        reject(error);
      }
    }
  };
};

const updateCompany = (
  payload: UpdateCompanyPayload,
  onSuccess: OnSuccessPayload
): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { reject, resolve, successMsg } = onSuccess;
    try {
      dispatch(updateCompanyAction());
      const service = new GraphqlService(global.apolloClient);
      const {
        data: { updateCompany: updatedCompany },
      } = await service.mutation<GraphqlResponse<{ updateCompany: Company }>>(
        UPDATE_COMPANY_MUTATION,
        {
          input: {
            ...payload,
          },
        }
      );
      dispatch(
        updateCompanySuccess(JSON.parse(JSON.stringify(updatedCompany)))
      );
      dispatch(
        setAlert({
          message: successMsg || 'update company success',
          open: true,
          severity: 'success',
        })
      );
      if (resolve) {
        resolve();
      }
    } catch (error) {
      logActionError('error', error, 'updateCompany');
      dispatch(
        setAlert({
          open: true,
          severity: 'error',
          message: handleGraphqlServerError(error),
        })
      );
      dispatch(updateCompanyFail());
      if (reject) {
        reject();
      }
    }
  };
};

const addCompanyFromShared = (
  payload: AddCompanyFromSharedPayload,
  onSuccess: OnSuccessPayload
): any => {
  return async (
    dispatch: AppDispatch,
    getState: () => RootState
  ): Promise<void> => {
    const { resolve, successMsg, reject } = onSuccess;
    const { investmentData, targetOrganisation } = payload;
    try {
      const service = new GraphqlService();
      dispatch(addCompanyFromSharedAction());
      const state = getState();
      const organisationOrigin = selectSelectedOrganization(state);
      const apiData = {
        organisationIdOrigin: organisationOrigin.id,
        organisationIdTarget: targetOrganisation.id,
        investments: investmentData,
      };
      const {
        data: { addCompanyFromShared: createdOrg = {} },
      }: any = await service.mutation(ADD_COMPANY_FROM_SHARED, {
        ...apiData,
      });
      dispatch(
        addCompanyFromSharedSuccess(JSON.parse(JSON.stringify(createdOrg)))
      );
      dispatch(
        setAlert({
          message: successMsg || 'Add company from shared success',
          open: true,
          severity: 'success',
        })
      );
      if (resolve) {
        resolve();
      }
    } catch (error) {
      logActionError('error', error, 'addFromShared');
      dispatch(
        setAlert({
          open: true,
          severity: 'error',
          message: handleGraphqlServerError(error),
        })
      );
      dispatch(addCompanyFromSharedFail());
      if (reject) {
        reject();
      }
    }
  };
};

const deleteCompanies = (
  payload: Array<{
    id: string;
    organisation: string;
  }>,
  onSuccess: OnSuccessPayload
): AppThunk => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const { resolve, reject, successMsg } = onSuccess;
    if (!payload.length) {
      if (resolve) resolve();
      return;
    }
    try {
      const service = new GraphqlService();
      dispatch(deleteCompaniesAction());
      await service.mutation(DELETE_COMPANIES_MUTATION, {
        input: payload,
      });
      dispatch(deleteCompaniesSuccess(payload));
      dispatch(
        setAlert({
          message: successMsg || 'Delete company success',
          open: true,
          severity: 'success',
        })
      );
      if (resolve) resolve();
    } catch (error) {
      logActionError('error', error, 'deleteCompany');
      dispatch(
        setAlert({
          open: true,
          severity: 'error',
          message: handleGraphqlServerError(error),
        })
      );
      dispatch(deleteCompaniesFail());
      if (reject) reject();
    }
  };
};

const CompanyThunk = {
  createCompany,
  updateCompany,
  updateContact,
  addCompanyFromShared,
  deleteCompanies,
};

export { CompanyThunk };
