import React, { useState } from 'react';
import { Typography, Grid, Button, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { SelectInput } from 'components/Common/Select';
import { OrganizationThunk } from 'containers/Organisation';
import { useDispatch } from 'react-redux';
import messages from '../messages';
import { useStyle } from '../styles';
import CompanySpecificSelector from './CompanySpecificDropDown';
import {
  SECTIONS,
  IMPORT_FROMAT,
  EXCEL_FILE_TYPE,
} from '../../../../constants';
import FileInputField from '../FileInputField';
import FileList from '../ListItemWithDelete';
import ImportConfirmModal from './ImportConfirmModal';
import getGraphqlErrorMessage from '../../../../common/getGraphqlErrorMessage';

const sections = SECTIONS;
const format = IMPORT_FROMAT;

const Import = ({ handleBulkImportExcel, handleBulkImportGS, setAlert }) => {
  const [value, setValue] = React.useState('All');
  const [optionValue, setOptionsValue] = useState(sections);
  const [formatOption, setFormatOptions] = useState(format[0].name);
  const [files, setFiles] = useState([]);
  const [linkList, setLinkList] = useState([]);
  const [link, setLink] = useState('');
  const { id: orgId } = useParams();
  const [open, setOpen] = useState(false);
  const [res, setRes] = useState([]);
  const [disable, setDisable] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleOptionsChange = (event) => {
    setOptionsValue(event.target.value);
  };
  const handleFormatChange = (event) => {
    setFormatOptions(event.target.value);
  };

  const classes = useStyle();
  const { formatMessage } = useIntl();

  const handleImport = async () => {
    setDisable(true);
    let fields = [];
    if (value === 'Specific') {
      fields = optionValue.map((ele) => ele.value);
    } else {
      fields = [value];
    }
    switch (formatOption) {
      case format[0].name: {
        try {
          const data = await handleBulkImportExcel({
            fields,
            files,
            orgId,
          });
          setOpen(true);
          setRes(
            data.map((ele) => ({ name: ele.filename, status: ele.status }))
          );
          dispatch(OrganizationThunk.getOrganizations());
        } catch (err) {
          const errorMessage = getGraphqlErrorMessage(err);
          setAlert({
            open: true,
            message: errorMessage,
            severity: 'error',
          });
        }
        break;
      }
      case format[1].name: {
        try {
          const data = await handleBulkImportGS({
            fields,
            orgId,
            links: linkList,
          });
          setOpen(true);
          setRes(
            data.map((ele) => ({ name: ele.filename, status: ele.status }))
          );
          dispatch(OrganizationThunk.getOrganizations());
        } catch (error) {
          const errorMessage = getGraphqlErrorMessage(error);
          setAlert({
            open: true,
            message: errorMessage,
            severity: 'error',
          });
        }
        break;
      }
    }
    setDisable(false);
  };

  const onFileChangeHandler = (event) => {
    const {
      target: { files: newFiles },
    } = event;
    const newFilesArray = Object.values(newFiles);
    if (!newFilesArray[0]) return;
    let errorStatus = false;
    newFilesArray.forEach((elem) => {
      if (elem.type !== EXCEL_FILE_TYPE) {
        errorStatus = true;
      }
    });
    if (errorStatus) {
      setAlert({
        open: true,
        message: messages.excelImportError,
        severity: 'error',
      });
      return;
    }
    setFiles(newFilesArray);
  };

  const setFileName = () => {
    if (files.length) {
      if (files.length > 1) {
        return `${files[0].name} +${files.length - 1}`;
      }
      return files[0].name;
    }
    return formatMessage(messages.uploadMultipleFile);
  };

  const deleteLink = (index) => {
    const newLinkList = linkList.filter((_, i) => i !== index);
    setLinkList(newLinkList);
  };

  const handleAdd = () => {
    setLinkList([...linkList, link]);
    setLink('');
  };

  const handleModalClose = () => {
    setOpen(false);
    setLinkList([]);
    setRes([]);
  };
  return (
    <div style={{ width: 580, minHeight: 288 }}>
      <ImportConfirmModal
        open={open}
        list={res}
        handleModalClose={handleModalClose}
      />
      <Grid
        container
        className={`${classes.modelWidth} `}
        direction="column"
        spacing={1}
      >
        <Grid container xs={12} style={{ marginBottom: 15 }}>
          <Typography variant="h5">{formatMessage(messages.import)}</Typography>
        </Grid>

        <Grid container style={{ marginBottom: 10 }}>
          <Grid item xs={3}>
            <Typography>{formatMessage(messages.format)}</Typography>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.selectInput}>
              <SelectInput
                value={formatOption}
                fullWidth
                onChange={handleFormatChange}
                options={format}
                customClasses={{
                  select: classes.select,
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            marginBottom: 10,
            alignItems:
              formatOption !== format[0].name ? 'flex-start' : 'center',
          }}
        >
          <Grid style={{ marginTop: 8 }} item xs={3}>
            <Typography>{formatMessage(messages.source)}</Typography>
          </Grid>
          <Grid item xs={8}>
            {formatOption === format[0].name ? (
              <div style={{ marginTop: 6 }}>
                <FileInputField
                  fileChangeHandler={onFileChangeHandler}
                  fileName={setFileName()}
                />
              </div>
            ) : (
              <>
                <TextField
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
                <FileList
                  title=""
                  list={linkList}
                  deleteOption={deleteLink}
                  fullWidth
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    className={classes.roundedButton}
                    style={{ width: 110 }}
                    classes={{
                      label: classes.lowerCapText,
                    }}
                    onClick={handleAdd}
                    variant="contained"
                    color="primary"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    {formatMessage(messages.add)}
                  </Button>
                </div>
              </>
            )}
          </Grid>
        </Grid>
        <CompanySpecificSelector
          value={value}
          handleChange={handleChange}
          optionValue={optionValue}
          handleOptionsChange={handleOptionsChange}
        />
        <br />
        <br />
        <Grid item>
          <Button
            className={classes.roundedButton}
            classes={{
              label: classes.lowerCapText,
            }}
            onClick={handleImport}
            variant="contained"
            color="primary"
            aria-controls="simple-menu"
            aria-haspopup="true"
            disabled={disable}
          >
            {formatMessage(messages.import)}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

Import.propTypes = {
  handleBulkImportExcel: PropTypes.func,
  handleBulkImportGS: PropTypes.func.isRequired,
};

Import.defaultProps = {
  handleBulkImportExcel: () => {},
};

export default Import;
