import './bootstrap';
import React from 'react';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import Entrypoint from 'components/Common/Entrypoint';
import { store } from 'store';

const history = createBrowserHistory();

ReactDOM.render(
  <Entrypoint store={store} history={history} />,
  document.getElementById('root')
);
