import React from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';

import { AllCompaniesScreen } from 'components/CashRunway';
import { GET_SELECTED_ORGANISATION_WITH_INVESTMENT_QUERY } from '../../graphql';

const AllCompaniesScreenContainer = () => {
  const { id: orgId } = useParams();
  const [
    getCompanies,
    { data: { organisation: { companies = [] } = {} } = {} },
  ] = useLazyQuery(GET_SELECTED_ORGANISATION_WITH_INVESTMENT_QUERY, {
    fetchPolicy: 'no-cache',
  });
  React.useEffect(() => {
    getCompanies({
      variables: {
        organisationId: orgId,
      },
    });
  }, []);

  return (
    <>
      <AllCompaniesScreen companies={companies} />
    </>
  );
};

export { AllCompaniesScreenContainer };
