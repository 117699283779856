import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectSelectedOrganization,
  OrganizationThunk,
} from 'containers/Organisation';
import { CompanySettingsChange } from 'types';

import CompanyPageSettings from 'components/Organisation/OrganizationSettings/CompanyPageSettings';

export default (): JSX.Element => {
  const organisation = useSelector(selectSelectedOrganization);
  const dispatch = useDispatch();
  const handleChange = (payload: Array<CompanySettingsChange>) => {
    const companySettingsData = JSON.parse(
      JSON.stringify(organisation.companySettings)
    );
    payload.forEach((ele) => {
      const { index, data } = ele;
      companySettingsData[index] = data;
    });
    dispatch(
      OrganizationThunk.updateCompanySettings({
        orgId: organisation.id,
        data: companySettingsData,
      })
    );
  };

  return (
    <CompanyPageSettings
      organisation={organisation}
      handleChange={handleChange}
    />
  );
};
