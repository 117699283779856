import { INVALID_TEXT } from '../../Investment/InvestmentTable';

const isTextValid = (text) => Boolean(text && `${text}`.trim().length);
const isWebsiteValid = (text) => {
  // eslint-disable-next-line no-useless-escape
  const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  const regex = new RegExp(expression);
  return Boolean(text.match(regex));
};

const validateInput = (key, value) => {
  switch (key) {
    case 'companyName-crunchbase':
      if (!isTextValid(value)) {
        return {
          companyNameValid: true,
          companyNameHelperText: INVALID_TEXT.CRUNCHBASE_COMPANY,
        };
      }
      return {
        companyNameValid: false,
        companyNameHelperText: '',
      };

    case 'companyName':
      if (!isTextValid(value)) {
        return {
          companyNameValid: true,
          companyNameHelperText: INVALID_TEXT.COMPANY_NAME,
        };
      }
      return {
        companyNameValid: false,
        companyNameHelperText: '',
      };

    case 'website':
      if (!isWebsiteValid(value)) {
        return {
          websiteValid: true,
          websiteHelperText: INVALID_TEXT.WEBSITE,
        };
      }
      return {
        websiteValid: false,
        websiteHelperText: '',
      };

    case 'location':
      if (!isTextValid(value)) {
        return {
          locationValid: true,
          locationHelperText: INVALID_TEXT.LOCATION,
        };
      }
      return {
        locationValid: false,
        locationHelperText: '',
      };

    default:
      return {};
  }
};

// eslint-disable-next-line import/prefer-default-export
export { validateInput };
