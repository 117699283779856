import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles((theme) => ({
  thumb: {
    backgroundColor: '#00696B',
  },
  track: {
    backgroundColor: '#00696B',
  },
  selectOption: {
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '30px 40px 30px 15px',
    '& >div': {
      flexBasis: '45%',
    },
    marginBottom: theme.spacing(3),
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flexRowStart: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexRowFullwidth: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchTextInput: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  lastUpdateLabel: {
    marginRight: '20px',
  },
  draftRequestButton: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    borderRadius: '40px',
    textTransform: 'none',
  },
  title: {
    margin: '5px 0',
    color: '#363636',
    letterSpacing: 1,
    fontWeight: '400',
  },
  paper: {
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    flexDirection: 'row',
    margin: `${theme.spacing(2)}px auto`,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  grid: {
    minHeight: '225px',
    padding: theme.spacing(2),
  },
  sectionTitles: {
    color: '#212121',
    fontWeight: '400',
    letterSpacing: 0.2,
  },
  sectionSubtitles: {
    fontSize: '1.1rem',
    color: '#212121',
    fontWeight: '400',
    marginTop: '10px',
  },
  label: {
    margin: '5px 0',
    color: '#363636',
    letterSpacing: 1,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: `${theme.spacing(2)}px 0`,
  },
  fullWidth: {
    width: '100%',
  },
  modalInput: {
    border: '1px solid black',
    padding: '5px',
    marginRight: '20px',
    borderRadius: '10px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subjectInput: {
    border: '1px solid black',
    padding: '5px',
    marginLeft: '15px',
    width: '100%',
    borderRadius: '10px',
  },
  checkPointFirst: {
    marginRight: '20px',
  },
  subjectRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '30px',
  },
  mainTextField: {
    margin: '30px 0',
    borderRadius: '10px',
    border: '1px solid black',
    width: '100%',
    padding: '10px',
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    outlineColor: 'transparent',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '50%',
  },
  paperModalMini: {
    backgroundColor: theme.palette.background.paper,
    outlineColor: 'transparent',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '25%',
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  periodicityContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  metricNames: {
    cursor: 'pointer',
  },
  metricNamesContainer: {
    paddingBottom: '3px',
  },
  loadingCircle: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  modalColumn: {
    padding: '10px',
    width: '25%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '&:not(:last-child)': {
      borderRight: '1px solid #80808047',
    },
  },
  kpiColumn: {
    padding: '10px',
    width: '25%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
