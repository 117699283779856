/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Menu,
  MenuItem,
  TextField,
  Paper,
  Grid,
  // Button,
  Typography,
  Avatar,
  Badge,
  Box,
  Modal,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import EventIcon from '@material-ui/icons/Event';
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import PinDropRoundedIcon from '@material-ui/icons/PinDropRounded';
import AddIcon from '@material-ui/icons/Add';
import DateFnsUtils from '@date-io/date-fns';
import { endOfDay } from 'date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useIntl } from 'react-intl';

import { Checkbox } from 'components/Common/Checkbox';
import translations from 'translations';
import { MultiSelectMenu } from 'components/Common/Inputs';
import { userRoles } from 'user/userConstants';
import { Button } from 'components/Common/Buttons';
import { VIEW_CONTENT_TYPE, COMPANY_CONTACT_TYPE } from 'constants/company';
import { AllowedOrganisationTypes } from 'common/commonContants';
import { normalizeContacts } from 'common/normalizeContactName';
import Export from './Export';
import Import from './Import';
import DownloadModal from './DownloadModal';
import MailPopup from '../../../containers/RequestData/MailPopup';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '40px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
  },
  discardButton: {
    display: 'none',
  },
  actionButtonWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  gridPadding: {
    paddingLeft: '35px',
  },
  invite: {
    display: 'flex',
    flex: 0.7,
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridMargin: {
    marginTop: '10px',
  },
  imageContainer: {
    display: 'flex',
    border: '1px solid',
    flex: 0.3,
    height: '100%',
    width: '100%',
  },
  sideDetails: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignSelf: 'flex-end',
    height: '100%',
    maxHeight: '135px',
  },
  events: {
    paddingTop: 5,
    display: 'flex',
    height: '35px',
    alignContent: 'center',
  },
  avatar: {
    height: '100%',
    width: '100%',
  },
  placeholder: {
    color: '#a2a2a2',
    fontStyle: 'italic',
  },
  uploadButton: {
    cursor: 'pointer',
  },
  hidden: {
    display: 'none',
  },
  headerButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8,
    alignItems: 'center',
  },
  manageUserButtonWrapper: {
    width: 180,
    marginRight: -33,
  },
  lowerCapText: {
    textTransform: 'initial',
  },
  whiteSpaces: {
    whiteSpace: 'break-spaces',
  },
  dropDownPaper: {
    marginRight: '30px',
  },
  menuTitle: {
    paddingLeft: '17px',
    paddingTop: '4px',
    '&:focus': {
      outline: 'none',
    },
  },
  viewButton: {
    borderRadius: 20,
    width: 155,
  },
  exportImportButton: {
    width: 230,
  },
  contentWrapper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  modalOverlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.48) !important',
  },
}));

const editStyle = {
  padding: '0px',
  paddingLeft: '10px',
  paddingRight: '10px',
};

const eventInput = { width: '100%', height: '100%' };
const inputIcon = { marginRight: '2%' };
const inputIconContainer = { width: '58%' };

const body1 = {
  fonSize: '16px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15008px',
  height: '120px',
  marginBottom: '5.6px',
  maxHeight: '120px',
  padding: '0px',
};

const h4 = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif.MuiTypography-h4',
  cursor: 'text',
  fontSize: '34px',
  fontWeight: 400,
  letterSpacing: '0.2499px',
  lineHeight: '41.99px',
  height: '41px',
};

const subtile1 = {
  fonSize: '16px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 400,
  lineHeight: '28px',
  letterSpacing: '0.15008px',
  height: '28px',
  marginBottom: '0.35em',
};

const overline = {
  height: '30px',
};

const avatar = {
  height: '100%',
  maxHeight: '135px',
  width: '100%',
  maxWidth: '200px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
};

const CompanyHeader = ({
  companyLocation: companyLocationProps,
  companyIndustry: companyIndustryProps,
  event3: propEvent3,
  company,
  onEditSave,
  role,
  loading,
  view,
  setView,
  users,
  orgType,
  updateContact,
  deleteContact,
  showData,
  exportDataToGS,
  importFromExcelSheet,
  importFromGS,
  sendRequestDataEmail,
}) => {
  const classes = useStyle();
  const intl = useIntl();
  const [enableEdit, setEnableEdit] = useState(false);
  const [name, setName] = useState('');
  const [subtile, setSubtitle] = useState('');
  const [body, setBody] = useState('');
  const [image, setImage] = useState('');
  const [companyLocation, setCompanyLocation] = useState(companyLocationProps);
  const [companyIndustry, setCompanyIndustry] = useState(companyIndustryProps);
  const [companyFoundedDate, setCompanyFoundedDate] = useState(propEvent3);
  const [viewContent, setViewContent] = useState('All');
  const [anchorElPopover, setAnchorElPopover] = React.useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const uploadRef = useRef();
  const history = useHistory();
  const params = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [investmentManagers, setInvestmentManagers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [didInfoChanged, setDidInfoChanged] = useState(false);
  const headerWrapperRef = useRef();
  const [anchorModal, setAnchorModal] = React.useState(null);
  const [sheetUrl, setSheetUrl] = useState([]);
  const [modalOpenState, setModalOpenState] = useState(false);
  const [draftRequestItem, setDraftRequestItem] = React.useState(undefined);

  const handleClickExport = (event) => {
    setAnchorModal(event.currentTarget);
  };

  const handleCloseExport = () => {
    setAnchorModal(null);
  };
  const [open, setOpen] = useState(false);
  const [importExport, setImportExport] = useState(false);
  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setSheetUrl([]);
    setOpen(false);
  };

  const handleClose = () => {
    setAnchorElPopover(null);
  };

  const checkImportExport = (response) => {
    setImportExport(response);
    handleModalOpen();
    handleClose();
    handleCloseExport();
  };
  const initializeData = async (companyData) => {
    const {
      name: companyName,
      websiteUrl,
      shortDescription,
      logoUrl,
      location,
      industryId,
      foundedDate,
    } = companyData;
    setName(companyName);
    setSubtitle(websiteUrl);
    setBody(shortDescription);
    setImage(logoUrl);
    setCompanyLocation(location);
    setCompanyIndustry(industryId);
    setCompanyFoundedDate(foundedDate);
  };

  useEffect(() => {
    if (enableEdit) {
      setEnableEdit(false);
    }
    if (company && Object.keys(company).length) {
      initializeData(company);
    }
  }, [company]);

  const renderPlaceholder = (text) => (
    <span className={classes.placeholder}>{text}</span>
  );

  const renderName = (style) => {
    let item = null;
    if (loading) {
      item = <Skeleton variant="text" height={28} width={680} />;
    } else if (!name) {
      item = renderPlaceholder(
        intl.formatMessage(translations.Company.Header.name)
      );
    } else {
      item = name;
    }
    return (
      <Typography id="company-name" style={style} variant="h4" gutterBottom>
        {item}
      </Typography>
    );
  };

  const renderSub = () => {
    if (loading) {
      return (
        <Typography id="company-website" variant="subtitle1" gutterBottom>
          <Skeleton variant="text" height={24} width={680} />
        </Typography>
      );
    }
    if (!subtile) {
      return (
        <Typography id="company-website" variant="subtitle1" gutterBottom>
          {renderPlaceholder(
            intl.formatMessage(translations.Company.Header.website)
          )}
        </Typography>
      );
    }
    return (
      <a id="company-website" href={subtile}>
        {subtile}
      </a>
    );
  };

  const renderBody = (style) => {
    let item = null;
    if (loading) {
      item = <Skeleton variant="text" height={28} width={680} />;
    } else if (!body) {
      item = renderPlaceholder(
        intl.formatMessage(translations.Company.Header.description)
      );
    } else {
      item = body;
    }
    return (
      <Typography
        id="company-description"
        style={style}
        variant="body1"
        gutterBottom
      >
        {item}
      </Typography>
    );
  };

  const renderEvent = (eventNumber) => (
    <Typography
      style={{ paddingLeft: '10px', marginTop: '2px' }}
      id={['company-location', 'company-industry'][eventNumber - 1]}
      variant="body1"
    >
      {loading ? (
        <Skeleton variant="rect" width={300} height={28} />
      ) : (
        [companyLocation, companyIndustry, companyFoundedDate][eventNumber - 1]
      )}
    </Typography>
  );

  const onSave = async () => {
    await onEditSave({
      companyUrnId: company.id,
      name,
      websiteUrl: subtile,
      shortDescription: body,
      location: companyLocation,
      industryId: companyIndustry,
      foundedDate: companyFoundedDate,
      file: selectedFile,
    });
  };

  const handleInvestmentManagerUpdates = async () => {
    const updateOnContacts = [];
    const deleteOnContacts = [];
    investmentManagers.forEach((investmentManager) => {
      const userData = users.find((obj) => obj.id === investmentManager.id);
      if (investmentManager.checked && !userData.checked) {
        updateOnContacts.push(investmentManager.id);
      }
      if (
        !investmentManager.checked &&
        userData.checked &&
        investmentManager.investmentManager
      ) {
        deleteOnContacts.push(investmentManager.investmentManager.id);
      }
    });
    let promises = [];
    if (updateOnContacts.length) {
      promises = updateOnContacts.map(updateContact);
    }
    if (deleteOnContacts.length) {
      promises.push(deleteContact(deleteOnContacts));
    }
    await Promise.all(promises);
  };

  const handleActionClick = async () => {
    if (enableEdit) {
      if (didInfoChanged) {
        await onSave();
      }
      setEnableEdit(false);
    } else {
      setEnableEdit(true);
    }
  };

  const onUploadChange = (event) => {
    const {
      target: { files },
    } = event;
    const imageUrl = URL.createObjectURL(event.target.files[0]);
    setImage(imageUrl);
    setSelectedFile(files[0]);
  };

  const intializeUsers = (data) => {
    if (Array.isArray(data)) {
      setInvestmentManagers(JSON.parse(JSON.stringify(data)));
    }
  };

  const handleDiscard = () => {
    if (company && Object.keys(company).length) {
      initializeData(company);
      setEnableEdit(false);
      intializeUsers(users);
    }
  };

  const handleManageUserClick = () => {
    const { organisation: { id: companyOrganisation } = { id: '' } } = company;
    // was companyOrganisation, now replaced with organisation_id = TypeCompany(ID)
    const { id } = params;
    // https://trello.com/c/AbhdxLLQ/244-keep-org-selected-when-going-to-manage-users
    const url = `/organisations/${id}/users`;
    return history.push(url, {
      companyOrganisation,
    });
  };

  const handleRequestUpdateClick = () => {
    setModalOpenState((prev) => !prev);
  };

  const handleClick = (event) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handleOnClickInvestmentManager = (item) => {
    setInvestmentManagers((prevState) => {
      if (!Array.isArray(prevState)) {
        return prevState;
      }
      const index = prevState.findIndex((obj) => obj.id === item.id);
      if (index < 0) {
        return prevState;
      }
      const newState = JSON.parse(JSON.stringify(prevState));
      newState[index] = {
        ...item,
        checked: !item.checked,
      };
      return newState;
    });
  };

  const handleOnSearchInvestmentManager = (event) => {
    const text = `${event.target.value}`.trim();
    setSearchText(text);
    setInvestmentManagers((prevState) => {
      let state = prevState;
      const searchValue = text.toLocaleLowerCase();
      if (!Array.isArray(prevState) || !prevState.length) {
        state = users;
      }
      if (!searchValue) {
        return JSON.parse(JSON.stringify(users));
      }
      const fileredManagers = state.filter((obj) =>
        `${obj.firstName || obj.lastName || obj.email}`
          .toLocaleLowerCase()
          .includes(searchValue)
      );
      return JSON.parse(JSON.stringify(fileredManagers));
    });
  };

  const handleCheckInvestmentManagersChange = (filterArray) =>
    users.filter((obj) => {
      const element = filterArray.find((ele) => ele.id === obj.id);
      return element && Boolean(element.checked) !== Boolean(obj.checked);
    });

  const handleOnCloseMenu = async () => {
    setAnchorEl(null);
    const filteredArray = handleCheckInvestmentManagersChange(
      investmentManagers
    );
    if (!filteredArray.length) {
      return;
    }
    await handleInvestmentManagerUpdates();
  };

  const handleOnOpenMenu = () => {
    // e.currentTarget
    setAnchorEl(headerWrapperRef.current);
  };

  const handleOnChangeDate = (event) => {
    if (event) {
      setCompanyFoundedDate(endOfDay(event));
    } else {
      setCompanyFoundedDate(event);
    }
  };

  useEffect(() => {
    if (company && Object.keys(company).length && !enableEdit) {
      initializeData(company);
      const requestData = {
        contacts: company.contacts
          .filter((ele) => ele.contactType === COMPANY_CONTACT_TYPE.CONTACT)
          .map((ele) => ({
            contactType: ele.contactType,
            email: ele.email,
            id: ele.id,
            firstName: ele.firstName,
            lastName: ele.lastName,
          }))
          .concat(
            investmentManagers
              .filter((ele) => ele.checked)
              .map((ele) => ({
                contactType: COMPANY_CONTACT_TYPE.INVESTOR,
                email: ele.email,
                id: ele.id,
                firstName: ele.firstName,
                lastName: ele.lastName,
              }))
          ),
      };
      setDraftRequestItem(requestData);
    }
  }, [company.contacts, enableEdit, investmentManagers]);

  useEffect(() => {
    intializeUsers(users);
  }, [users]);

  useEffect(() => {
    const didChange =
      (Boolean(Object.keys(company).length) &&
        enableEdit &&
        name !== company.name) ||
      subtile !== company.websiteUrl ||
      body !== company.shortDescription ||
      companyLocation !== company.location ||
      companyIndustry !== company.industryId ||
      companyFoundedDate !== company.foundedDate ||
      image !== company.logoUrl;
    setDidInfoChanged(didChange);
    // setDidInvesmentManagersChanged(handleCheckInvestmentManagersChange(investmentManagers));
  }, [
    name,
    subtile,
    body,
    companyLocation,
    companyIndustry,
    companyFoundedDate,
    image,
    JSON.stringify(investmentManagers),
  ]);

  const handleSubmit = async (data) => {
    await sendRequestDataEmail({
      variables: {
        ...data,
        companyId: company.id,
      },
    });
    setModalOpenState((prev) => !prev);
  };

  const renderInvestmentManagers = (data) =>
    normalizeContacts(
      data.filter((obj) => obj.checked),
      2
    );
  return (
    <div className={classes.root}>
      <MailPopup
        companyContacts={
          draftRequestItem && Array.isArray(draftRequestItem.contacts)
            ? draftRequestItem.contacts
            : []
        }
        handleSubmit={handleSubmit}
        draftRequestItem={draftRequestItem}
        open={modalOpenState}
        handleToggleModal={handleRequestUpdateClick}
      />
      <input
        type="file"
        className={classes.hidden}
        onChange={onUploadChange}
        ref={uploadRef}
      />
      <div className={classes.actionButtonWrapper}>
        <div className={classes.headerButtonContainer}>
          {role === userRoles.ADMIN && (
            <div className={classes.manageUserButtonWrapper}>
              <Button
                disabled={!(company.organisation && company.organisation.id)}
                typeColor="common"
                style={{
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
                onClick={handleManageUserClick}
              >
                {intl.formatMessage(
                  translations.Company.CompanyScreen.manageUsers
                )}
              </Button>
            </div>
          )}
          {!role ||
            (role !== userRoles.VIEWER && (
              <div className={classes.manageUserButtonWrapper}>
                <Button
                  typeColor="primary"
                  style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                  classes={{
                    label: classes.lowerCapText,
                  }}
                  onClick={handleRequestUpdateClick}
                  variant="contained"
                  color="primary"
                >
                  {intl.formatMessage(
                    translations.Company.CompanyScreen.requestData
                  )}
                </Button>
              </div>
            ))}
          <div className={classes.manageUserButtonWrapper}>
            <Button
              classes={{
                label: classes.lowerCapText,
              }}
              style={{
                paddingLeft: 15,
                paddingRight: 15,
              }}
              typeColor="primary"
              variant="contained"
              color="primary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClickExport}
            >
              {intl.formatMessage(translations.Company.Header.ExportImport)}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorModal}
              keepMounted
              open={Boolean(anchorModal)}
              getContentAnchorEl={null}
              onClose={handleCloseExport}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuItem
                className={classes.exportImportButton}
                onClick={() => checkImportExport(true)}
              >
                {intl.formatMessage(translations.Company.Header.Export)}
              </MenuItem>
              <MenuItem
                className={
                  !role || role === userRoles.VIEWER ? classes.hidden : ''
                }
                onClick={() => checkImportExport(false)}
              >
                {intl.formatMessage(translations.Company.Header.Import)}
              </MenuItem>
            </Menu>
          </div>
          {orgType === AllowedOrganisationTypes.FUND &&
            (!role || role !== userRoles.VIEWER) && (
              <>
                <div>
                  <Typography
                    variant="subtitle2"
                    ref={(e) => {
                      headerWrapperRef.current = e;
                    }}
                  >
                    {intl.formatMessage(
                      translations.Company.CompanyScreen.investmentManagers
                    )}
                    {': '}
                    {renderInvestmentManagers(investmentManagers) ||
                      `${'None'}`}
                  </Typography>
                </div>
                <div>
                  <Button
                    onClick={handleOnOpenMenu}
                    typeColor="default"
                    classes={{
                      label: classes.lowerCapText,
                    }}
                  >
                    {intl.formatMessage(
                      translations.Company.CompanyScreen.select
                    )}
                  </Button>
                  <MultiSelectMenu
                    anchorEl={anchorEl}
                    onClose={handleOnCloseMenu}
                    searchInput
                    searchInputProps={{
                      searchText,
                      onSearchChange: handleOnSearchInvestmentManager,
                    }}
                    onMenuItemClick={handleOnClickInvestmentManager}
                    options={investmentManagers}
                    renderText={(obj) =>
                      obj.firstName || obj.lastName || obj.email
                    }
                    popupProps={{
                      style: {
                        top: '160px',
                      },
                    }}
                  />
                </div>
              </>
            )}
        </div>
        <Modal
          open={open}
          aria-labelledby="onbording_modal"
          aria-describedby="onbording_modal"
          className={classes.modal}
          onClose={handleModalClose}
          onBackdropClick={handleModalClose}
          style={{ className: classes.paper }}
          BackdropProps={{ className: classes.modalOverlay }}
        >
          <div className={classes.paper}>
            {importExport ? (
              sheetUrl.length ? (
                <DownloadModal
                  sheetUrl={sheetUrl}
                  closeModal={() => {
                    handleModalClose();
                  }}
                />
              ) : (
                <Export
                  showData={showData}
                  exportDataToGS={async (obj) => {
                    const sheetUrlResponse = await exportDataToGS(obj);
                    if (!sheetUrlResponse) {
                      handleModalClose();
                    } else {
                      setSheetUrl([sheetUrlResponse]);
                    }
                  }}
                />
              )
            ) : (
              <Import
                importFromExcelSheet={importFromExcelSheet}
                importFromGS={importFromGS}
                onClose={handleModalClose}
              />
            )}
          </div>
        </Modal>
        <div className={classes.headerButtonContainer}>
          <Button
            aria-describedby="view-content-popover-toggle"
            classes={{
              label: classes.lowerCapText,
              root: classes.viewButton,
            }}
            style={{
              paddingRight: 15,
              paddingLeft: 15,
              width: 160,
            }}
            onClick={handleClick}
            variant="contained"
            color="primary"
            typeColor="primary"
          >
            {`View: ${viewContent}`}
          </Button>
          <Menu
            id="view-content-popover-content"
            keepMounted
            open={Boolean(anchorElPopover)}
            anchorEl={anchorElPopover}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div className={classes.menuTitle} key="description">
              <Typography
                style={{ whiteSpace: 'break-spaces' }}
                variant="body1"
                gutterBottom
              >
                {intl.formatMessage(
                  translations.Company.CompanyScreen.selectContentDescription
                )}
              </Typography>
            </div>
            {VIEW_CONTENT_TYPE.map((elem, index) => (
              <MenuItem
                onClick={() => {
                  const newView = JSON.parse(JSON.stringify(view));
                  newView[index] = !view[index];
                  setView(newView);
                  const checkedLength = newView.filter((ele) => ele).length;
                  if (checkedLength >= 3) {
                    setViewContent('All');
                  } else if (checkedLength === 2) {
                    setViewContent('Specific ');
                  } else if (newView[0]) {
                    setViewContent(VIEW_CONTENT_TYPE[0].name);
                  } else if (newView[1]) {
                    setViewContent(VIEW_CONTENT_TYPE[1].name);
                  } else if (newView[2]) {
                    setViewContent(VIEW_CONTENT_TYPE[2].name);
                  } else {
                    setViewContent('None');
                  }
                }}
                key={elem.name}
                style={{ paddingLeft: 0 }}
              >
                <Checkbox
                  color="primary"
                  name={elem.name}
                  checked={view[index]}
                  onChange={() => {}}
                />
                <Typography
                  className={classes.whiteSpaces}
                  variant="body1"
                  gutterBottom
                >
                  {`${elem.name}: ${elem.detail}`}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
          {!role ||
            (role !== userRoles.VIEWER && (
              <Button
                onClick={handleDiscard}
                className={classes.discardButton}
                typeColor="default"
                style={{
                  display: enableEdit
                    ? didInfoChanged
                      ? 'block'
                      : 'none'
                    : 'none',
                }}
              >
                {intl.formatMessage(translations.Company.CompanyScreen.discard)}
              </Button>
            ))}
          {!role ||
            (role !== userRoles.VIEWER && (
              <Button
                id="edit-company-header"
                onClick={handleActionClick}
                color="primary"
                typeColor="default"
                shape="square"
                style={{
                  minWidth: 64,
                }}
              >
                {enableEdit ? (didInfoChanged ? 'SAVE' : 'STOP EDIT') : 'EDIT'}
              </Button>
            ))}
        </div>
      </div>
      <Paper
        elevation={4}
        className={classes.paper}
        // ref={(e) => {
        //   headerWrapperRef.current = e;
        // }}
      >
        <Grid container>
          <Grid item xs={12}>
            {enableEdit ? (
              <TextField
                id="company-name"
                variant="outlined"
                value={name}
                style={{ width: '45%', fontStyle: name ? 'inherit' : 'italic' }}
                placeholder={intl.formatMessage(
                  translations.Company.Header.name
                )}
                onChange={(event) => setName(event.target.value)}
                InputProps={{
                  style: h4,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  style: {
                    ...editStyle,
                  },
                }}
              />
            ) : (
              renderName({ marginBottom: 0 })
            )}
          </Grid>
          <Grid item xs={12}>
            {enableEdit ? (
              <TextField
                id="company-website"
                variant="outlined"
                value={subtile}
                onChange={(event) => setSubtitle(event.target.value)}
                placeholder={intl.formatMessage(
                  translations.Company.Header.website
                )}
                style={{
                  width: '45%',
                  fontStyle: subtile ? 'inherit' : 'italic',
                }}
                InputProps={{
                  style: subtile1,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  style: {
                    ...editStyle,
                  },
                }}
              />
            ) : (
              renderSub()
            )}
          </Grid>
          <Grid item xs={7}>
            {enableEdit ? (
              <TextField
                id="company-description"
                style={{
                  width: '100%',
                  fontStyle: body ? 'inherit' : 'italic',
                }}
                variant="outlined"
                multiline
                rowsMax={4}
                value={body}
                placeholder={intl.formatMessage(
                  translations.Company.Header.description
                )}
                onChange={(event) => setBody(event.target.value)}
                InputProps={{
                  style: {
                    ...body1,
                  },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  style: {
                    ...editStyle,
                  },
                }}
              />
            ) : (
              renderBody({ maxHeight: 120, overflow: 'auto', minHeight: 120 })
            )}
          </Grid>
          <Grid item xs={5}>
            <div className={classes.sideDetails}>
              <div className={classes.invite}>{/* empty space  */}</div>
              <div className={classes.imageContainer}>
                <Badge
                  overlap="circle"
                  className={classes.avatar}
                  invisible={!enableEdit}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={
                    <AddIcon
                      className={classes.uploadButton}
                      onClick={() => {
                        if (uploadRef && enableEdit) {
                          uploadRef.current.click();
                        }
                      }}
                    />
                  }
                >
                  {image ? (
                    <div
                      style={{
                        ...avatar,
                        backgroundImage: `url(${image})`,
                      }}
                    />
                  ) : (
                    <Avatar
                      src={image}
                      variant="square"
                      className={classes.avatar}
                      style={{ maxHeight: '135px', maxWidth: '200px' }}
                    />
                  )}
                </Badge>
              </div>
            </div>
          </Grid>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: 1,
            }}
          >
            <Box style={inputIconContainer}>
              {(companyLocation !== null || loading) && (
                <Box className={classes.events}>
                  {companyLocation !== '' || enableEdit ? (
                    <div style={inputIcon}>
                      <PinDropRoundedIcon />
                    </div>
                  ) : null}
                  {enableEdit ? (
                    <TextField
                      id="company-location"
                      variant="outlined"
                      value={companyLocation}
                      fullWidth
                      style={eventInput}
                      onChange={(event) =>
                        setCompanyLocation(event.target.value)
                      }
                      inputProps={{
                        style: {
                          ...editStyle,
                        },
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                        style: overline,
                      }}
                    />
                  ) : (
                    renderEvent(1)
                  )}
                </Box>
              )}
              {(companyIndustry !== null || loading) && (
                <Box className={classes.events}>
                  {companyIndustry !== '' || enableEdit ? (
                    <div style={inputIcon}>
                      <AccountBalanceRoundedIcon />
                    </div>
                  ) : null}
                  {enableEdit ? (
                    <TextField
                      id="company-industry"
                      variant="outlined"
                      value={companyIndustry}
                      fullWidth
                      style={eventInput}
                      onChange={(event) =>
                        setCompanyIndustry(event.target.value)
                      }
                      inputProps={{
                        style: {
                          ...editStyle,
                        },
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                        style: overline,
                      }}
                    />
                  ) : (
                    renderEvent(2)
                  )}
                </Box>
              )}
            </Box>
            <Box style={{ alignSelf: 'flex-end' }}>
              <Box
                style={{
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
                className={classes.events}
              >
                {enableEdit ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="date-picker-inline"
                      placeholder="YYYY-MM-DD"
                      value={companyFoundedDate}
                      onChange={handleOnChangeDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                ) : companyFoundedDate || loading ? (
                  <>
                    <EventIcon />
                    {renderEvent(3)}
                  </>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Paper>
    </div>
  );
};

CompanyHeader.propTypes = {
  company: PropTypes.object,
  companyLocation: PropTypes.string,
  role: PropTypes.string,
  companyIndustry: PropTypes.string,
  event3: PropTypes.string,
  onEditSave: PropTypes.func,
  loading: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  orgType: PropTypes.string.isRequired,
  updateContact: PropTypes.func.isRequired,
  deleteContact: PropTypes.func.isRequired,
  showData: PropTypes.bool,
  exportDataToGS: PropTypes.func,
  importFromGS: PropTypes.func,
  importFromExcelSheet: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
  sendRequestDataEmail: PropTypes.func.isRequired,
};

CompanyHeader.defaultProps = {
  company: {},
  companyLocation: null,
  role: '',
  companyIndustry: null,
  event3: null,
  onEditSave: () => {},
  loading: false,
  showData: false,
  exportDataToGS: async () => {},
  importFromGS: async () => {},
};

export default CompanyHeader;
