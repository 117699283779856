import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
// import { createMuiTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Paper } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { CompanySelector } from 'components/Common/Selector';

import { useStyles, BootstrapInput } from './styles';
import messages from './messages';
import CompaniesTable from './CompaniesTable';
import MailPopup from './MailPopup';
import AlertPopup from './AlertPopup';

const RequestData = ({
  getRequestDataByCompany,
  handleSubmit,
  refetchRequestData,
  loading,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [filteredTableData, setFilteredTableData] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [draftRequestItem, setDraftRequestItem] = React.useState(undefined);
  const [duration] = React.useState(0);

  const [modalOpenState, setModalOpenState] = React.useState(false);
  const [alertOpenState, setAlertOpenState] = React.useState(false);

  useEffect(() => {
    setFilteredTableData(getRequestDataByCompany);
  }, [getRequestDataByCompany]);

  const handleSelectAllClick = (rows) => {
    if (!rows.length) {
      setSelected([]);
      return;
    }
    setSelected(JSON.parse(JSON.stringify(rows)));
  };

  const handleSelect = (event, data) => {
    const selectedIndex = selected.findIndex((obj) => obj.id === data.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, data);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(JSON.parse(JSON.stringify(newSelected)));
  };

  const handleToggleModal = () => {
    if (modalOpenState) {
      setDraftRequestItem(undefined);
    }
    setModalOpenState((prev) => !prev);
  };

  const switchAlert = () => {
    setAlertOpenState(!alertOpenState);
  };

  const handleDraftRequestClick = (item) => {
    if (item) {
      setSelected([]);
      setFilteredTableData(
        filteredTableData.map((ele) => {
          if (ele.tableData) {
            // eslint-disable-next-line no-param-reassign
            ele.tableData.checked = false;
            return ele;
          }
          return ele;
        })
      );
      setDraftRequestItem(item);
      setModalOpenState((prev) => !prev);
    } else if (selected.length === 1) {
      setDraftRequestItem(selected[0]);
      setModalOpenState((prev) => !prev);
    } else if (selected.length > 1) {
      setModalOpenState((prev) => !prev);
    } else {
      switchAlert();
    }
  };

  const onHandleSubmit = async (data) => {
    const status = await handleSubmit(data);
    if (status) setModalOpenState((prev) => !prev);
  };

  return (
    <div className={classes.RequestDataWrapper}>
      {alertOpenState && (
        <AlertPopup open={alertOpenState} handleToggleModal={switchAlert} />
      )}
      <Grid item xs={12} className={classes.marginBottom}>
        <Typography
          component="h2"
          variant="h5"
          color="primary"
          gutterBottom
          className={classes.title}
        >
          {intl.formatMessage(messages.requestData)}
        </Typography>
      </Grid>
      <Typography
        component="h5"
        variant="h6"
        color="primary"
        gutterBottom
        className={classes.sectionTitles}
      >
        {intl.formatMessage(messages.filter)}
      </Typography>
      <Paper className={classes.filterContainer} variant="outlined">
        <Box className={classes.flexRowFullwidth} flexBasis="70%">
          <div style={{ width: 200 }}>
            <CompanySelector
              fullWidth
              onChangeHandler={(values) =>
                refetchRequestData({
                  companyId: values.map((comp) => comp.id),
                })
              }
            />
          </div>
          <FormControl
            style={{
              marginLeft: 25,
            }}
            className={classes.flexRow}
          >
            <ListItemText
              className={classes.lastUpdateLabel}
              primary={intl.formatMessage(messages.lastUpdate)}
            />
            <Select
              className={classes.selectOption}
              value={duration}
              onChange={() => {
                /* to be implemented */
              }}
              input={<BootstrapInput />}
            >
              <MenuItem value={0}>All</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box flexBasis="30%">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className={classes.draftRequestButton}
            onClick={() => handleDraftRequestClick()}
          >
            {intl.formatMessage(messages.multipleRequest)}
          </Button>
        </Box>
      </Paper>
      <Typography
        component="h5"
        variant="h6"
        color="primary"
        gutterBottom
        className={classes.sectionTitles}
      >
        {intl.formatMessage(messages.companies)}
      </Typography>
      <CompaniesTable
        duration={duration}
        selected={selected}
        data={filteredTableData}
        handleClick={handleSelect}
        handleSelectAllClick={handleSelectAllClick}
        handleDraftRequestClick={handleDraftRequestClick}
        loading={loading}
      />
      <MailPopup
        companyContacts={
          draftRequestItem && Array.isArray(draftRequestItem.contacts)
            ? draftRequestItem.contacts
            : []
        }
        handleSubmit={onHandleSubmit}
        draftRequestItem={draftRequestItem}
        open={modalOpenState}
        handleToggleModal={handleToggleModal}
        selectedBulkRequest={selected}
      />
    </div>
  );
};

export default RequestData;

RequestData.propTypes = {
  getRequestDataByCompany: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
