/* eslint-disable react/prop-types */
import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    marginBottom: '20px',
    marginTop: '10px',
  },
  Alert: {
    '& .MuiAlert-message': {
      display: 'inline',
    },
  },
}));

export default function OrganisationKeyDisplay(props) {
  const { organisation, orgkey } = props;
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <Alert className={classes.Alert} severity="info">
          Share your organisation key with companies to request access:&nbsp;
          <span style={{ fontWeight: 'bold' }}>{orgkey}</span> (Key for
          {organisation})
        </Alert>
      </div>
    </div>
  );
}
