import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import messages from './messages';
import { useStyles, DeleteButton } from './styles';

const OrgTable = ({ organisations, onLeaveOrganization, user }) => {
  const [data, setData] = useState([]);
  const intl = useIntl();
  const classes = useStyles();

  useEffect(() => {
    if (organisations && organisations.length) {
      setData(
        organisations.map((elem) => ({
          name: elem.name,
          id: elem.id,
          role: elem.role,
        }))
      );
    }
  }, [organisations]);

  const columns = [
    {
      title: intl.formatMessage(messages.nameLabel),
      field: 'name',
      editable: 'never',
    },
    {
      title: intl.formatMessage(messages.idLabel),
      field: 'id',
      editable: 'never',
    },
    {
      title: intl.formatMessage(messages.roleLabel),
      field: 'role',
      editable: 'never',
    },
    {
      title: intl.formatMessage(messages.actionLabel),
      cellStyle: {
        padding: 0,
      },
      render: (row) => (
        <DeleteButton
          variant="contained"
          color="primary"
          id={`delete-org-${row.name}`.replace(' ', '-').toLowerCase()}
          onClick={() =>
            onLeaveOrganization({
              userId: user.id,
              organisationId: row.id,
            })
          }
        >
          Leave Organization
        </DeleteButton>
      ),
    },
  ];
  return (
    <div className={classes.tableRoot}>
      <Typography
        component="h6"
        variant="h5"
        color="primary"
        gutterBottom
        className={classes.sectionTitles}
      >
        {intl.formatMessage(messages.manageOrganization)}
      </Typography>
      <MaterialTable
        columns={columns}
        data={data}
        title=""
        options={{
          search: false,
          selection: false,
          emptyRowsWhenPaging: false,
          sorting: false,
          grouping: false,
          draggable: false,
          debounceInterval: 0,
        }}
      />
    </div>
  );
};

OrgTable.propTypes = {
  organisations: PropTypes.arrayOf(PropTypes.object),
  onLeaveOrganization: PropTypes.func,
  user: PropTypes.object,
};

OrgTable.defaultProps = {
  organisations: [
    {
      id: '1',
      name: 'orhg',
      role: 'admin',
    },
  ],
  onLeaveOrganization: null,
  user: {},
};

export default OrgTable;
