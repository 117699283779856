/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '40px',
  },
  headers: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  paper: {
    padding: '25px',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    minWidth: '820px',
    overflow: 'auto',
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.3,
    maxWidth: '200px',
    minWidth: '100px',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.7,
    padding: '20px 0px 20px 0px',
    height: '350px',
    maxWidth: '1000px',
    minWidth: '500px',
  },
  selectLabel: {
    fontWeight: 800,
  },
  tooltipWrapper: {
    backgroundColor: '#fff',
    padding: '2px',
    paddingRight: 5,
    border: '1px solid #00000033',
    borderRadius: '2px',
    opacity: 0.8,
  },
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: '14px',
    padding: '8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const periodicityOptions = [
  'Monthly',
  'Quarterly',
  'Annual',
  'Quarterly YTD',
  'Half-Year',
];

const metricTypeOptions = ['PL', 'BalanceSheet', 'Cashflow', 'KPI'];

const dropDown = (text, value, onChange, classes, Options) => (
  <>
    <span className={classes.selectLabel}> {text} </span>
    <FormControl variant="outlined" className={classes.formControl}>
      <NativeSelect
        input={<BootstrapInput />}
        style={{ maxWidth: '200px', marginTop: '10px' }}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        {Options.map((ele) => (
          <option value={ele} key={ele}>
            {ele}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  </>
);

let domainY = [0, 0];
const domainX = [0, 0];
let updated = false;

const CompanyVisual = ({
  getCompanyVisual,
  matricOptions,
  getCompanyMetricNames,
  visualData,
}) => {
  const classes = useStyles();
  const [metric, setMetric] = useState('');
  const [periodicity, setPeriodicity] = useState(periodicityOptions[0]);
  const [chartData, setChartData] = useState([]);
  const [metricType, setMetricType] = useState(metricTypeOptions[0]);
  const intl = useIntl();
  const { companyId } = useParams();

  const formatData = (datapoints, metric, periodicity) =>
    datapoints.map((ele) => {
      if (+ele.y < domainY[0]) {
        domainY[0] = +ele.y;
      } else if (+ele.y > domainY[1]) {
        domainY[1] = +ele.y;
      }
      return {
        [periodicity]: moment(ele.x).format('MM-YYYY'),
        [metric]: +ele.y,
      };
    });

  const getData = async (metric, periodicity, type) => {
    updated = true;
    const {
      data: { getCompanyVisual: datapoints = [] } = {},
    } = await getCompanyVisual({
      input: {
        companyId,
        periodicity,
        metricName: metric,
        type,
      },
    });
    if (datapoints.length) {
      domainY = [+datapoints[0].y, +datapoints[0].y];
      return formatData(datapoints, metric, periodicity);
    }
    return [];
  };

  useEffect(() => {
    if (!updated) {
      setChartData(formatData(visualData, metric, periodicity));
    }
    updated = false;
  }, [JSON.stringify(visualData)]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload[0]) {
      return (
        <div className={classes.tooltipWrapper}>
          <p style={{ marginLeft: 5 }}>{`${periodicity} ${label}`}</p>
          <p style={{ color: '#3f51b5', marginLeft: 5 }}>
            {`${metric} ${payload[0].value}`}
          </p>
        </div>
      );
    }
    return null;
  };

  const changeMetricType = async (value) => {
    const {
      data: { getCompanyMetricNames: newMeticOptions } = {},
    } = await getCompanyMetricNames({
      companyId,
      type: value,
    });
    setMetricType(value);
    if (newMeticOptions.length) {
      const data = await getData(newMeticOptions[0], periodicity, value);
      setChartData(data);
      setMetric(newMeticOptions[0]);
    }
  };

  const changeMetric = async (value) => {
    const data = await getData(value, periodicity, metricType);
    setChartData(data);
    setMetric(value);
  };
  const changePeriodicity = async (value) => {
    const data = await getData(metric, value, metricType);
    setChartData(data);
    setPeriodicity(value);
  };
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    matricOptions.length && changeMetric(matricOptions[0]);
  }, [matricOptions]);
  return (
    <div className={classes.root}>
      <div className={classes.headers}>
        <Typography variant="h6" gutterBottom>
          Visualize
        </Typography>
      </div>
      <Paper className={classes.paper} elevation={4}>
        <div className={classes.selectContainer}>
          {dropDown(
            'Type',
            metricType,
            changeMetricType,
            classes,
            metricTypeOptions
          )}
          <br />
          {dropDown('Metric', metric, changeMetric, classes, matricOptions)}
          <br />
          {dropDown(
            'Periodicity',
            periodicity,
            changePeriodicity,
            classes,
            periodicityOptions
          )}
        </div>
        <div className={classes.chartContainer}>
          <ResponsiveContainer>
            <LineChart
              data={chartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid
                fillOpacity={0.05}
                horizontalFill={['#FAFAFA']}
                stroke="#fff"
                style={{
                  border: '10px solid',
                }}
                shapeRendering="crispEdges"
              />
              <XAxis
                padding={{ left: 20, right: 20 }}
                domain={domainX}
                dataKey={periodicity}
              />
              <YAxis
                tickFormatter={(tick) => intl.formatNumber(tick)}
                padding={{ top: 20, bottom: 20 }}
                domain={domainY}
              />
              <Tooltip content={<CustomTooltip />} />
              <Line type="monotone" dataKey={metric} stroke="#3f51b5" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Paper>
    </div>
  );
};

CompanyVisual.propTypes = {
  getCompanyVisual: PropTypes.func,
  matricOptions: PropTypes.arrayOf(PropTypes.string),
  getCompanyMetricNames: PropTypes.func,
  visualData: PropTypes.arrayOf(PropTypes.object),
};

CompanyVisual.defaultProps = {
  getCompanyVisual: () => {},
  matricOptions: [],
  getCompanyMetricNames: () => {},
  visualData: [],
};

export default CompanyVisual;
