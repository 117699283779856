import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

// import NumberFormatCustom from 'common/NumberFormatCustom';
import { emailRegex } from 'utils';
import translations from 'translations';
import { OnChangeEvent, UpdateSubscriptionPayload, Subscription } from 'types';
import { Button } from 'components/Common/Buttons';
import { Input } from 'components/Common/Input';
import { LabelWithRadioGroup } from 'components/Common/Radio';
import { GridTitle } from 'components/Common/Grid';
import { useStyles } from './styles';
import { BillingComponentProps } from './type';
import { Subscriptions, paymentMethod } from './utils';

const BillingComponent = ({
  updateSubscription,
  selectedOrganization,
  paidSubscription,
  onCancelSubscription,
}: BillingComponentProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const [validValues, setValidValue] = useState({
    emailValid: false,
    emailHelperText: '',
    selectedSubscriptionValid: false,
    selectedSubscriptionHelperText: '',
  });
  const [state, setState] = useState({
    subscriptionId: '',
    selectedSubscription: '',
    contactEmail: '',
    billingInformation: '',
  });

  const handleUpdateSubscription = async (): Promise<void> => {
    const {
      billingInformation,
      contactEmail,
      selectedSubscription,
      subscriptionId,
    } = state;
    const apiObject: UpdateSubscriptionPayload = {
      organisationId: selectedOrganization.id,
      type: selectedSubscription,
      billingInfo: billingInformation,
      contactEmail,
      id: subscriptionId || null,
    };
    await updateSubscription(apiObject);
  };

  const handleChange = (fieldName: string) => (event: OnChangeEvent): void => {
    const { value } = event.target;
    setState({
      ...state,
      [fieldName]: value,
    });
    switch (fieldName) {
      case 'selectedSubscription':
        if (value === Subscriptions[0].value) {
          setValidValue({
            ...validValues,
            selectedSubscriptionValid: true,
            selectedSubscriptionHelperText: 'Please select a subscrition type!',
          });
        } else {
          setValidValue({
            ...validValues,
            selectedSubscriptionValid: false,
            selectedSubscriptionHelperText: '',
          });
        }
        break;
      case 'contactEmail':
        if (!emailRegex.test(value) && Boolean(value)) {
          setValidValue({
            ...validValues,
            emailValid: true,
            emailHelperText: 'Please enter a valid email!',
          });
        } else {
          setValidValue({
            ...validValues,
            emailValid: false,
            emailHelperText: '',
          });
        }
        break;
      default: {
        break;
      }
    }
  };

  const handleOnCancelSubscription = () => {
    if (paidSubscription?.id) {
      onCancelSubscription(paidSubscription);
    }
  };

  const handlePopulateData = (subscription: Subscription | null) => {
    if (subscription) {
      const {
        type,
        id,
        booking: { billingInfo = '', contactEmail = '' } = {},
      } = subscription;
      setState({
        selectedSubscription: type,
        billingInformation: billingInfo,
        contactEmail,
        subscriptionId: id,
      });
      return;
    }
    setState({
      selectedSubscription: '',
      billingInformation: '',
      contactEmail: '',
      subscriptionId: '',
    });
  };

  useEffect(() => {
    if (paidSubscription && paidSubscription.id) {
      handlePopulateData(paidSubscription);
      return;
    }
    if (!paidSubscription && state.subscriptionId) {
      handlePopulateData(null);
    }
  }, [JSON.stringify(paidSubscription)]);

  return (
    <Grid item xs={12}>
      <Grid container direction="row" alignItems="center" item xs={12}>
        <GridTitle
          textId="OrganizationSettings.Subscriptions.UpdateSubscription"
          text="Update Subscription"
        />
        <div className={classes.space} />
        <LabelWithRadioGroup
          name="subscriptionType"
          label={`${formatMessage(translations.Common.common.select)}`}
          radios={Subscriptions}
          value={state.selectedSubscription}
          onChange={handleChange('selectedSubscription')}
          wrapperClassName={classes.formControl}
        />
        <div className={classes.space} />
        <Input
          variant="outlined"
          label={`${formatMessage(
            translations.OrganisationSettings.Subscription.Billing
              .contactEmailLabel
          )}`}
          value={state.contactEmail}
          error={validValues.emailValid}
          errorText={validValues.emailHelperText}
          onChange={handleChange('contactEmail')}
          wrapperClassName={classes.formControl}
        />
        <div className={classes.space} />
        <Input
          variant="outlined"
          label={`${formatMessage(
            translations.OrganisationSettings.Subscription.Billing
              .billingAddress
          )}`}
          value={state.billingInformation}
          onChange={handleChange('billingInformation')}
          multiline
          rows={5}
          wrapperClassName={classes.formControl}
        />
        <div className={classes.space} />
        <Grid container direction="row">
          <div className={classes.space} />
          <LabelWithRadioGroup
            name="paymentMethod"
            label={`${formatMessage(
              translations.OrganisationSettings.Subscription.Billing
                .paymentMethod
            )}`}
            radios={[paymentMethod]}
            value={paymentMethod.value}
            wrapperClassName={`${classes.formControl} ${classes.paymentMethod}`}
            onChange={() => {
              //
            }}
          />
          <div className={classes.space} />
          <Grid item md={2} sm={4}></Grid>
          <Grid item md={10} sm={8}>
            <Typography variant="body2" color="textPrimary">
              {formatMessage(
                translations.OrganisationSettings.Subscription.Billing
                  .subscriptionTerms
              )}
              <a
                id="terms"
                style={{ display: 'inline' }}
                href="https://keytrack.io/terms"
              >
                {formatMessage(
                  translations.OrganisationSettings.Subscription.Billing
                    .subscriptionLinkTitle
                )}
              </a>
            </Typography>
          </Grid>
          <div className={classes.space} />
          <div className={classes.space} />
          <Grid container item xs={8} sm={4} md={8} lg={8} justify="flex-start">
            <Button
              typeColor="common"
              shape="round"
              className={classes.actionBtn}
              onClick={handleUpdateSubscription}
            >
              {formatMessage(translations.Common.common.subscribe)}
            </Button>
            {state.subscriptionId ? (
              <Button
                typeColor="delete"
                shape="round"
                className={classes.actionBtn}
                onClick={handleOnCancelSubscription}
              >
                {formatMessage(translations.Common.common.cancel)}
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { BillingComponent };
