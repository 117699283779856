import { defineMessages } from 'react-intl';

export default defineMessages({
  emailId: {
    id: 'User.ManageUser.ManageUserTable.emailId',
    defaultMessage: 'Email / ID',
  },
  firstName: {
    id: 'User.ManageUser.ManageUserTable.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'User.ManageUser.ManageUserTable.lastName',
    defaultMessage: 'Last Name',
  },
  role: {
    id: 'User.ManageUser.ManageUserTable.role',
    defaultMessage: 'Role',
  },
  companyContact: {
    id: 'User.ManageUser.ManageUserTable.companyContact',
    defaultMessage: 'Company Contact',
  },
  action: {
    id: 'User.ManageUser.ManageUserTable.action',
    defaultMessage: 'Action',
  },
  manageUser: {
    id: 'User.ManageUser.manageUser',
    defaultMessage: 'Manage User',
  },
  addUser: {
    id: 'User.ManageUser.addUser',
    defaultMessage: 'Add User',
  },
});
