import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectOrganizations,
  selectSelectedOrganization,
} from 'containers/Organisation';
import { useQuery } from '@apollo/react-hooks';
import {
  GET_DETAILED_COMPANIES_BY_USER_QUERY,
  GET_COMPANIES_QUERY,
} from '../../graphql';
import StatisticsScreen from '../../components/Statistics';

const StatisticsScreenContainer = () => {
  const organisations = useSelector(selectOrganizations);
  const { companies: selectOrgCompanies } = useSelector(
    selectSelectedOrganization
  );

  const [group, setGroup] = useState(
    selectOrgCompanies.map((company) => company.id)
  );

  const {
    data: { companiesByUser: allCompanies = [] } = {},
    loading: loadingCompaniesByUser,
    refetch: refetchCompaniesByUser,
  } = useQuery(GET_DETAILED_COMPANIES_BY_USER_QUERY, {
    fetchPolicy: 'no-cache',
  });
  const {
    data: { companies = [] } = {},
    loading: loadingCompaniesByOrg,
    refetch: refetchCompaniesByOrg,
  } = useQuery(GET_COMPANIES_QUERY, {
    variables: { companiesId: group },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!loadingCompaniesByUser && allCompanies) {
      refetchCompaniesByUser();
    }
    if (!loadingCompaniesByOrg && companies) {
      refetchCompaniesByOrg();
    }
  }, []);

  const [locationData, locationDataLength] = useMemo(() => {
    const locations = companies.reduce((prev, next) => {
      const country =
        (next.location &&
          next.location.slice(next.location.lastIndexOf(',') + 2)) ||
        'No Data';
      if (country === 'No Data') return prev;
      return prev[country]
        ? { ...prev, [country]: prev[country] + 1 }
        : { ...prev, [country]: 1 };
    }, {});

    const data = Object.entries(locations).map(([key, value]) => ({
      name: key,
      Count: value,
    }));
    let count = 0;
    if (Array.isArray(data)) {
      count = data.reduce((prev, next) => prev + next.Count, 0);
    }
    return [data, count];
  }, [group, loadingCompaniesByOrg, loadingCompaniesByUser]);

  const [employeeData, employeeDataLength] = useMemo(() => {
    const CLUSTER_LIST = [
      '1-10',
      '11-50',
      '51-500',
      '501-1000',
      '1001-5000',
      '5001-10000',
      '10000+',
    ];
    const locations = companies.reduce((prev, next) => {
      const employees =
        next.employees || (next.employees === 0 ? 0 : 'No Data');
      if (employees === 'No Data') return prev;
      return prev[employees]
        ? { ...prev, [employees]: prev[employees] + 1 }
        : { ...prev, [employees]: 1 };
    }, {});

    const data = CLUSTER_LIST.reduce((prev, key) => {
      if (!locations[key]) return prev;
      return [...prev, { name: key, Count: locations[key] }];
    }, []);
    let count = 0;
    if (Array.isArray(data)) {
      count = data.reduce((prev, next) => prev + next.Count, 0);
    }
    return [data, count];
  }, [group, loadingCompaniesByOrg, loadingCompaniesByUser]);

  const [FoundingData, FoundingDataLength] = useMemo(() => {
    const years = companies.reduce((prev, next) => {
      const foundedYear =
        (next.foundedDate && next.foundedDate.slice(0, 4)) || 'No Data';
      if (foundedYear === 'No Data') return prev;
      return prev[foundedYear]
        ? { ...prev, [foundedYear]: prev[foundedYear] + 1 }
        : { ...prev, [foundedYear]: 1 };
    }, {});

    const data = Object.entries(years).map(([key, value]) => ({
      name: key,
      value,
    }));

    let count = 0;
    if (Array.isArray(data)) {
      count = data.reduce((prev, next) => prev + next.value, 0);
    }
    return [data, count];
  }, [group, loadingCompaniesByOrg, loadingCompaniesByUser]);

  const [industryData, industryDataLength] = useMemo(() => {
    const locations = companies.reduce((prev, next) => {
      const industry = next.industryId || 'No Data';
      if (industry === 'No Data') return prev;
      // eslint-disable-next-line max-len
      return prev[industry]
        ? { ...prev, [industry]: prev[industry] + 1 }
        : { ...prev, [industry]: 1 };
    }, {});

    const data = Object.entries(locations).map(([key, value]) => ({
      name: key,
      count: value,
    }));
    console.log({ data });
    let count = 0;
    if (Array.isArray(data)) {
      count = data.reduce((prev, next) => prev + next.count, 0);
    }
    return [data, count];
  }, [group, loadingCompaniesByOrg, loadingCompaniesByUser]);

  return (
    <>
      <StatisticsScreen
        getCompanyFromShared={[]}
        organisations={organisations}
        setGroup={(newGroup) => setGroup(newGroup)}
        group={group}
        locationData={locationData}
        locationDataLength={locationDataLength}
        employeeData={employeeData}
        employeeDataLength={employeeDataLength}
        FoundingData={FoundingData}
        FoundingDataLength={FoundingDataLength}
        industryData={industryData}
        industryDataLength={industryDataLength}
      />
    </>
  );
};

export default StatisticsScreenContainer;
