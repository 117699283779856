import React from 'react';
import { TextField, InputLabel, Box } from '@material-ui/core';
import { useStyles } from './styles';
import { Props } from './type';

export const Input = ({
  id = 'basic-input',
  className = '',
  wrapperClassName = '',
  variant,
  fullWidth,
  placeholder,
  value,
  error,
  onChange,
  onBlur,
  errorText,
  multiline,
  rows,
  InputProps,
  formControlStyle,
  lableStyle,
  label,
  labelType = 'inline',
  disabled,
  onKeydown,
}: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Box
      display={labelType === 'inline' ? 'flex' : 'block'}
      alignItems="center"
      justifyContent="space-between"
      className={wrapperClassName}
    >
      {label && (
        <InputLabel
          className={`${classes.formLabel} ${lableStyle}`}
          style={
            labelType === 'inline'
              ? {
                  marginRight: '24px',
                }
              : {
                  marginBottom: '20px',
                }
          }
        >
          {label}
        </InputLabel>
      )}
      <TextField
        className={className}
        id={id}
        size="small"
        variant={variant as any}
        value={value}
        fullWidth={fullWidth}
        placeholder={placeholder}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeydown}
        helperText={errorText}
        multiline={multiline}
        rows={rows}
        InputProps={InputProps}
        classes={{
          root: `${classes.formControlCol} ${formControlStyle}`,
        }}
        disabled={disabled}
      />
    </Box>
  );
};
