/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { memo, useEffect, useState, useRef } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { TableUtils } from 'utils/index';
import { useStyles } from './styles';
import { EnhancedTableRow } from './TableRow';
import { EnhancedTableBodyProps } from './type';

const { getComparator, stableSort } = TableUtils;

const DroppableComponent = memo(
  (props: any) => {
    if (props.reset) {
      props.setReset();
    }
    return (
      <DragDropContext key="dragable" onDragEnd={props.handleOnDragEnd}>
        <Droppable key="dragable" droppableId="1" direction="vertical">
          {(provided: any) => {
            return (
              <TableBody
                key="dragable"
                id="kpi-table-body"
                ref={provided.innerRef}
                {...provided.droppableProps}
                {...props}
              >
                {props.children}
                {provided.placeholder}
              </TableBody>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  },
  (prev, next) => {
    const didSort = prev.didSortRef.current;
    if (didSort) prev.didSortRef.current = false;
    return (
      JSON.stringify(prev.tableData) === JSON.stringify(next.tableData) &&
      prev.enableEdit === next.enableEdit &&
      JSON.stringify(prev.columns) === JSON.stringify(next.columns) &&
      !didSort &&
      !next.reset
    );
  }
);

const EnhancedTableBody = memo(
  ({
    order,
    orderBy,
    selected,
    setSelected,
    rowsPerPage,
    columns,
    enableEdit,
    onUpdate,
    onAddRow,
    page,
    // onAddColumns,
    tableData,
    setTableData,
    handleUpdate,
    handleDelete,
    deleteEmptyRows,
    reset,
    setReset,
  }: EnhancedTableBodyProps): JSX.Element => {
    const [doEdit, setDoEdit] = useState<boolean | undefined>(false);
    const didSortRef = useRef(false);
    const classes = useStyles();

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    };

    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, tableData.length - page * rowsPerPage);

    useEffect(() => {
      if (enableEdit === doEdit) return;
      if (!enableEdit) {
        deleteEmptyRows();
      } else {
        onAddRow.current();
      }
      setDoEdit(enableEdit);
    }, [enableEdit]);

    // useEffect(() => {
    //   setTableData(JSON.parse(JSON.stringify(data)));
    // }, [data]);

    const reorder = (list: any, startIndex: any, endIndex: any) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      const newData = result.map((row: any) => ({
        ...row,
        meta: {
          ...row.meta,
        },
      }));
      newData[startIndex].meta.sortingNumber = startIndex;
      newData[endIndex].meta.sortingNumber = endIndex;
      return newData;
    };

    const handleOnDragEnd = (result: any) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      const items = reorder(
        tableData,
        result.source.index,
        result.destination.index
      ) as any[];
      onUpdate(items[result.source.index]);
      onUpdate(items[result.destination.index]);
      didSortRef.current = true;
      setTableData(items);
    };

    const TableRows = () => (
      <>
        {stableSort(tableData, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            const {
              meta: { metricName } = {
                metricName: '',
              },
            } = row;
            return (
              <EnhancedTableRow
                data={row}
                columns={columns}
                onRowSelect={handleClick}
                key={`${metricName || 'anonymousMetricName'}_${index}`}
                enableEdit={enableEdit}
                isSelected={isSelected}
                onUpdate={handleUpdate}
                rowIndex={index}
                onDelete={handleDelete}
                // onAddColumns={onAddColumns}
                onAddRow={onAddRow}
                tableData={tableData}
                reset={reset}
              />
            );
          })}
        {emptyRows > 0 && !tableData.length && !enableEdit &&  (
          <TableRow className={classes.head}>
            <TableCell colSpan={columns.length + 1}/>
          </TableRow>
        )}
      </>
    );

    return enableEdit ? (
      <DroppableComponent
        handleOnDragEnd={handleOnDragEnd}
        columns={columns}
        tableData={tableData}
        enableEdit={enableEdit}
        didSortRef={didSortRef}
        reset={reset}
        setReset={setReset}
      >
        {TableRows()}
      </DroppableComponent>
    ) : (
      <TableBody>{TableRows()}</TableBody>
    );
  }
);

export { EnhancedTableBody };
