import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TableCell, createMuiTheme } from '@material-ui/core';
import { Colors } from '../../../constants/colors';

export const StyledTableCell = withStyles({
  body: {
    borderBottom: 'none',
  },
  head: {
    fontWeight: 'bold',
  },
  root: {
    fontSize: '14px',
    padding: '14px',
  },
})(TableCell);

export const inputsTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: 'transparent',
      },
      input: {
        padding: '0px 6px',
      },
    },
  },
});

// eslint-disable-next-line import/prefer-default-export
export const useStyle = makeStyles((theme) => ({
  downloadModalTypography: {
    wordBreak: 'break-all',
    width: 'calc(100% - 160px)',
  },
  roundedButton: {
    borderRadius: 20,
    width: 160,
    textTransform: 'capitalize',
    height: '35px',
  },
  fileInputWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  uploadButtonRoot: {
    padding: 0,
    paddingLeft: 10,
  },
  modelWidth: {
    maxWidth: '580px !important',
  },

  lowerCapText: {
    textTransform: 'initial',
  },
  labelStyle: {
    minWidth: '0%',
    marginRight: '10px',
    color: '#363636',
  },
  selectInput: {
    alignSelf: 'center',
    width: '40.5%',
    '& >div': {
      // height: 25,
    },
    '& fieldset': {
      borderColor: Colors.TEXT,
    },
  },
  select: {
    maxWidth: '94%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
  },
  paperColored: {
    padding: '25px',
    marginBottom: '25px',
    backgroundColor: '#f3f3f3',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rootExportTab: {
    flexGrow: 1,
  },
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '40px',
  },
  headers: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  paperWrapper: {
    padding: '25px',
  },
  headline: {
    fontSize: '19px',
    fontWeight: 600,
  },
  body: {
    maxHeight: '74px',
    overflow: 'auto',
  },
  inputRoot: {
    margin: 2,
  },
  input: {
    padding: '2px 10px 2px 10px',
    fontSize: '17px',
    fontWeight: 500,
  },
  description: {
    padding: '2px 10px 4px 10px',
    fonSize: '16px',
    margin: 2,
  },
  icon: {
    cursor: 'pointer',
  },
  sharedWithContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  sharedWithText: {
    marginLeft: 10,
    marginRight: 10,
    fontWeight: 800,
    color: '#0000008a',
  },
  sharedText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 500,
  },
  sharedTextContainer: {
    width: 'calc(100% - 22px)',
  },
  iconContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  commentHeader: {
    color: '#002f33',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  iconFontSize: {
    fontSize: '2em',
  },
  exitsHeader: {
    display: 'inline',
    paddingLeft: '12px',
    fontWeight: 'bold',
    fontSize: '14px',
    color: 'black',
    userSelect: 'none',
  },
  upsideDown: {
    transform: 'rotate(180deg)',
  },
  cellTypography: {
    fontSize: '14px',
  },
  inputStyles: {
    fontSize: '14px',
    width: 'auto',
    height: '22px',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
    '& > *': { whiteSpace: 'normal' },
  },
  table: {
    tableLayout: 'fixed',
  },
  removeCell: {
    fontSize: '12px',
    padding: '0px',
  },
  summaryContainer: {
    height: 'fit-content',
    paddingBottom: '16px',
  },
  summaryPaper: {
    paddingBottom: '8px',
  },
  summaryLabel: {
    fontWeight: 600,
    fontSize: '14px',
    textTransform: 'uppercase',
    marginBottom: '5px',
  },
  summaryContent: {
    fontSize: '14px',
  },
  summarySkeleton: {
    marginBottom: '20px',
    height: '50px',
  },
  feedSkeleton: {
    marginBottom: '25px',
    height: '130px',
  },
  selectorWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 38,
  },
}));
