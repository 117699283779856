import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles((theme) => ({
  markLabel: {
    fontSize: '9px',
  },
  label: {
    ...theme.typography.fontSize,
    margin: '5px 0',
    color: '#363636',
    letterSpacing: 1,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  col: {
    width: '30%',
    alignItems: 'center',
  },
  half: {
    width: '50%',
    alignItems: 'center',
  },
  icon: {
    ...theme.custom.iconSize,
  },
  iconButton: {
    paddingRight: '0px !important',
    marginRight: -8,
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  addPortfolioLink: {
    textDecoration: 'none',
    color: 'rgb(13, 60, 97)',
  },
}));
