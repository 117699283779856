import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import translations from 'translations';
import { PortfolioThunk } from 'containers/Portfolio/ducks';
import { selectSelectedOrganization } from 'containers/Organisation/ducks';
import ManagePortfolio from 'components/Portfolio/ManagePortfolio';
import { userRoles } from 'user/userConstants';
import { Portfolio, PortfolioPayload, UpdatePortfolioPayload } from 'types';

const ManagePortfolioContainer = (): JSX.Element => {
  const organisation = useSelector(selectSelectedOrganization);
  const dispatch = useDispatch();
  const onDeletePortfolios = (portfoliosRemove: Array<Portfolio>): boolean => {
    const portfolioIds = portfoliosRemove.map(
      (portfolio: Portfolio) => portfolio.id
    );
    dispatch(
      PortfolioThunk.deletePortfolios(portfolioIds, {
        successMsg: (
          <FormattedMessage {...translations.Portfolio.DeletePortfolio} />
        ),
      })
    );
    return false;
  };

  const handleCreatePortfolio = (porfolioData: PortfolioPayload): void => {
    dispatch(
      PortfolioThunk.createPortfolio(porfolioData, {
        successMsg: (
          <FormattedMessage {...translations.Portfolio.addPortfolio} />
        ),
      })
    );
  };

  const handleOnUpdatePortfolio = (
    updatedPortfolioData: UpdatePortfolioPayload
  ): void => {
    dispatch(
      PortfolioThunk.updatePortfolio(updatedPortfolioData, {
        successMsg: (
          <FormattedMessage {...translations.Portfolio.UpdatePortfolio} />
        ),
      })
    );
  };
  return organisation.role !== userRoles.VIEWER ? (
    <>
      <ManagePortfolio
        selectedOrganisation={organisation}
        onDeletePortfolios={onDeletePortfolios}
        createPortfolio={handleCreatePortfolio}
        updatePortfolio={handleOnUpdatePortfolio}
      />
    </>
  ) : (
    <Redirect to={`${organisation.url}/updates`} />
  );
};

export default ManagePortfolioContainer;
