/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { useStyles } from './styles';

const CustomBox2 = ({ item, spanClass, handleOnAction }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      className={classes.flexBox}
    >
      <p className={classes.text}>
        <span className={classes.bolder}>{item.company}</span>
        updated <span className={classes.bolder}>{item.source}</span>
        <span className={`${classes.bolder} ${spanClass}`}>
          {item.time} days ago
        </span>
      </p>
      <Box display="flex" flexDirection="row" className={classes.actions}>
        <div
          className={classes.actionBtn}
          onClick={() => handleOnAction('updates', 'dismiss', item)}
        >
          view
        </div>
      </Box>
    </Box>
  );
};
// eslint-disable-next-line import/prefer-default-export
export { CustomBox2 };

CustomBox2.propTypes = {
  spanClass: PropTypes.string,
  item: PropTypes.shape({
    company: PropTypes.string,
    source: PropTypes.string,
    time: PropTypes.any,
  }).isRequired,
  handleOnAction: PropTypes.func.isRequired,
};

CustomBox2.defaultProps = {
  spanClass: '',
};
