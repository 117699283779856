import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Grid, Paper, Typography, InputAdornment } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { selectSubscriptionAccessLimit } from 'containers/Subscription';
import { setAlert } from 'containers/Config';
import translations from 'translations';
import { Input } from 'components/Common/Input';
import { Button } from 'components/Common/Buttons';
import { SelectInput } from 'components/Common/Select';
import NumberFormatCustom from 'common/NumberFormatCustom';
import { CurrencyOptions, PortfolioYears } from 'common/commonContants';
import { CreatePortfolioProps } from 'types';
import { useStyles } from './styles';

const initialState = {
  name: '',
  sizeCurrency: CurrencyOptions[0].name,
  creationYear: new Date().getFullYear(),
  size: '',
  targetNumberOfInvestments: '',
};

export const CALLING_FROM = {
  PORTFOLIO_ONBOARDING: 'onboarding',
  PORTFOLIO_PORTFOLIO: 'portfolio',
};

const CreatePortfolio = ({
  from,
  selectedOrganisation,
  onCreatePortfolio,
  setCurrentStep,
}: CreatePortfolioProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [values, setValue] = useState(initialState);
  const [hasErrors, setHasErrors] = useState(false);
  const intl = useIntl();
  const { portfolios, hasTrial } = useSelector(selectSubscriptionAccessLimit);
  const canCreatePortfolio = portfolios || hasTrial;

  useEffect(() => {
    setValue((previous) => ({
      ...previous,
      sizeCurrency: selectedOrganisation.currency
        ? selectedOrganisation.currency
        : CurrencyOptions[0].value,
    }));
  }, [selectedOrganisation.currency]);

  const handleChange = (fieldName: string) => (
    event: React.ChangeEvent<HTMLInputElement | { id?: string; value: any }>
  ): void => {
    setValue({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  const onAddPortfolio = (): void => {
    if (!canCreatePortfolio) {
      dispatch(
        setAlert({
          open: true,
          message: intl.formatMessage(
            translations.OrganisationSettings.Subscription.alert
              .portfolioLimitExceeded
          ),
          severity: 'error',
        })
      );
      return;
    }
    const { name } = values;
    if (!name) {
      setHasErrors(true);
      return;
    }
    const currentValue = (CurrencyOptions.find(
      (ele) => ele.name === values.sizeCurrency
    ) as { name: string; value: string }).value;
    onCreatePortfolio({ ...values, sizeCurrency: currentValue });
    setValue({
      ...initialState,
      sizeCurrency: selectedOrganisation.currency || 'USD',
    });
    if (hasErrors) {
      setHasErrors(false);
    }
  };
  return (
    <>
      {from === CALLING_FROM.PORTFOLIO_PORTFOLIO && (
        <Typography
          component="h4"
          variant="h6"
          color="primary"
          gutterBottom
          className={classes.sectionTitles}
        >
          {intl.formatMessage(
            translations.Portfolio.CreatePortfolio.addPortfolio
          )}
        </Typography>
      )}
      <Paper className={classes.paper} variant="outlined">
        <Grid
          container
          className={classes.grid}
          alignItems="flex-start"
          justify="space-between"
        >
          <Grid item xs={12} className={classes.spacing}>
            {hasErrors && (
              <Typography
                className={classes.validationErrorTextMain}
                component="h6"
              >
                {intl.formatMessage(
                  translations.Portfolio.CreatePortfolio.validationErrorMain
                )}
              </Typography>
            )}
          </Grid>
          <Grid item xs={9}>
            <Input
              label={`${intl.formatMessage(
                translations.Portfolio.CreatePortfolio.name
              )}`}
              value={values.name}
              placeholder="Portfolio Name"
              formControlStyle={classes.inputField}
              variant="outlined"
              onChange={handleChange('name')}
              id="portfolio-name"
            />

            <div className={classes.spacing} />

            <SelectInput
              label={`${intl.formatMessage(
                translations.Portfolio.CreatePortfolio.sizeCurrency
              )}`}
              value={values.sizeCurrency}
              fullWidth
              useId={false}
              onChange={handleChange('sizeCurrency')}
              options={CurrencyOptions}
            />

            <SelectInput
              label={`${intl.formatMessage(
                translations.Portfolio.CreatePortfolio.creationYear
              )}`}
              value={values.creationYear}
              fullWidth
              useId={false}
              onChange={handleChange('creationYear')}
              options={PortfolioYears}
              wrapperClassName={classes.margin}
            />

            <Input
              label={`${intl.formatMessage(
                translations.Portfolio.CreatePortfolio.size
              )}`}
              value={values.size.toString()}
              formControlStyle={classes.inputField}
              onChange={handleChange('size')}
              wrapperClassName={classes.margin}
              variant="outlined"
              id="size"
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                startAdornment: (
                  <InputAdornment position="start">
                    {values.sizeCurrency || ' '}
                  </InputAdornment>
                ),
              }}
            />

            <div className={classes.spacing} />

            <Input
              label={`${intl.formatMessage(
                translations.Portfolio.CreatePortfolio.targetNumberOfInvestments
              )}`}
              value={values.targetNumberOfInvestments.toString()}
              formControlStyle={classes.inputField}
              onChange={handleChange('targetNumberOfInvestments')}
              id="targetNumberOfInvestments"
              variant="outlined"
            />

            <Grid
              className={classes.margin}
              container
              item
              xs={12}
              justify="flex-end"
            >
              <Button
                variant="contained"
                color="primary"
                typeColor="common"
                className={classes.actionBtn}
                onClick={onAddPortfolio}
                id="create-portfolio-Button"
              >
                {`${intl.formatMessage(translations.Common.common.add)}`}
              </Button>
              {from === CALLING_FROM.PORTFOLIO_ONBOARDING && (
                <Button
                  variant="contained"
                  color="primary"
                  className={`${classes.actionBtn} ${classes.grayBtn}`}
                  onClick={() => {
                    if (setCurrentStep) setCurrentStep(3);
                  }}
                >
                  {`${intl.formatMessage(translations.Common.common.next)}`}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export { CreatePortfolio };

CreatePortfolio.propTypes = {
  from: PropTypes.string,
  selectedOrganisation: PropTypes.object,
  onCreatePortfolio: PropTypes.func,
  setCurrentStep: PropTypes.func,
};

CreatePortfolio.defaultProps = {
  from: '',
  selectedOrganisation: {},
  onCreatePortfolio: null,
  setCurrentStep: null,
};
