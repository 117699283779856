/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import DeleteForever from '@material-ui/icons/DeleteForever';
import MomentUtils from '@date-io/moment';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import translations from 'translations';

import { useIntl } from 'react-intl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { CompanyTop } from './CompanyTop';
import { CompanyDefaultText } from './CompanyDefault';
import { userRoles } from '../../../user/userConstants';
import { useStyle } from './styles';
import { VIEW_CONTENT_TYPE } from '../../../constants';
import getSharedWith from '../../../common/getSharedColor';

const newFeed = {
  date: new Date(),
  title: '',
  description: '',
  view: VIEW_CONTENT_TYPE[0].name.toLowerCase(),
};

const CompanyFeed = ({
  feeds: propsFeed,
  onUpdateFeed,
  onDeleteFeed,
  role,
  viewContent,
  organisationName,
  title,
  loading,
}) => {
  const classes = useStyle();
  const [feeds, setFeeds] = useState([]);
  const [enableEdit, setEnableEdit] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorText, setIsErrorText] = useState('');
  const intl = useIntl();

  const onFeedChange = (key, value, index) => {
    if (key === 'title') {
      if (value === '') {
        setIsErrorText('Required Field');
        setIsError(true);
      } else {
        setIsErrorText('');
        setIsError(false);
      }
    }
    const data = JSON.parse(JSON.stringify(feeds));
    data[index][key] = value;
    setFeeds(data);
  };

  const onFeedDelete = (feed, index) => {
    const data = JSON.parse(JSON.stringify(feeds));
    data.splice(index, 1);
    onDeleteFeed(feed.id);
    setFeeds(data);
    if (!data.length) setEnableEdit(false);
  };

  const filterFeed = (data) =>
    data.filter((elem) => viewContent.includes(elem.view));

  const handleDiscard = () => {
    setEnableEdit(false);
    const viewableFeeds = filterFeed(propsFeed);
    setFeeds(viewableFeeds);
  };

  const handleAddActionClick = () => {
    setFeeds((feed) => [newFeed, ...feed]);
  };

  const handleActionClick = () => {
    if (enableEdit) {
      // Remove if any feed does not have title
      const updatedFeeds = feeds.filter((f) => f.title);
      setFeeds(updatedFeeds);
      onUpdateFeed(updatedFeeds);
      setEnableEdit(false);
    } else {
      // eslint-disable-next-line no-use-before-define
      if (!feeds.length) handleAddActionClick();
      setEnableEdit(true);
    }
  };

  useEffect(() => {
    if (JSON.stringify(propsFeed) !== JSON.stringify(feeds)) {
      const viewableFeeds = filterFeed(propsFeed);
      setFeeds(viewableFeeds);
    }
  }, [propsFeed]);

  useEffect(() => {
    if (enableEdit) {
      setEnableEdit(false);
    }
    const viewableFeeds = filterFeed(propsFeed);
    setFeeds(viewableFeeds);
  }, [viewContent]);

  const feedResponse = (response) => {
    if (!response && feeds.length && !feeds[0].title)
      setFeeds(feeds.slice(1, feeds.length));
    setEnableEdit(response);
  };

  const Feed = (feed, index, onChange, onDelete) => (
    <Paper
      elevation={4}
      className={classes.paperColored}
      key={`${feed.id || ''}_${index}`}
    >
      <Grid container>
        <Grid container item>
          <Grid item xs={9} direction="row">
            {enableEdit ? (
              <div className={classes.sharedWithContainer}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    margin="none"
                    format="YYYY-MM-DD"
                    id={`date-picker-inline-${index}`}
                    value={moment(feed.date).format('YYYY-MM-DD')}
                    onChange={(event) =>
                      onChange('date', event.toISOString(), index)
                    }
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
                <Typography className={classes.sharedWithText} variant="body1">
                  {intl.formatMessage(
                    translations.Company.CompanyScreen.sharedWith
                  )}
                </Typography>
                <Select
                  value={feed.view}
                  id={`feed-view-type-${index}`}
                  onChange={(event) => {
                    onChange('view', event.target.value, index);
                  }}
                >
                  {VIEW_CONTENT_TYPE.map((elem) => {
                    const value = elem.name.toLowerCase();
                    return (
                      <MenuItem
                        id={`view-type-${value}-${index}`}
                        value={value}
                      >
                        {elem.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            ) : (
              <Typography variant="body1" gutterBottom>
                {moment(feed.date).format('YYYY-MM-DD')}
              </Typography>
            )}
          </Grid>
          <Grid container item justify="space-between" xs={3}>
            <div
              style={{ color: getSharedWith(feed.view, organisationName)[1] }}
              className={classes.sharedTextContainer}
            >
              {!enableEdit && (
                <Typography
                  id={`feed-view-type-${index}`}
                  className={classes.sharedText}
                  variant="subtitle1"
                >
                  {`${intl.formatMessage(
                    translations.Company.CompanyScreen.sharedWith
                  )}: ${getSharedWith(feed.view, organisationName)[0]}`}
                </Typography>
              )}
            </div>
            {enableEdit === true
              ? feed.id && (
                  <DeleteForever
                    onClick={() => {
                      onDelete(feed, index);
                    }}
                    className={classes.icon}
                  />
                )
              : null}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {enableEdit ? (
            <TextField
              error={isError}
              variant="outlined"
              value={feed.title}
              helperText={errorText}
              id={`feed-title-${index}`}
              onChange={(event) => onChange('title', event.target.value, index)}
              fullWidth
              rowsMax={3}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  input: classes.input,
                },
              }}
            />
          ) : (
            <Typography id={`feed-title-${index}`} variant="h6">
              {feed.title}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {enableEdit ? (
            <TextField
              variant="outlined"
              value={feed.description}
              id={`feed-description-${index}`}
              onChange={(event) =>
                onChange('description', event.target.value, index)
              }
              fullWidth
              multiline
              rowsMax={3}
              InputProps={{
                classes: {
                  root: classes.description,
                },
              }}
            />
          ) : (
            <Typography
              id={`feed-description-${index}`}
              className={classes.body}
              variant="body1"
              gutterBottom
            >
              {feed.description}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <div className={classes.root}>
      <CompanyTop
        didChange={enableEdit && feeds[0].title && !isError}
        handleDiscard={handleDiscard}
        setFeedResponse={feedResponse}
        enableEdit={enableEdit}
        showAdd={enableEdit && feeds[0].title && !isError}
        title={title}
        handleActionClick={handleActionClick}
        handleAddActionClick={handleAddActionClick}
        role={role}
        btnText={enableEdit ? 'Save' : 'Edit'}
      />
      <Paper className={classes.paperWrapper} elevation={2}>
        {loading ? (
          <>
            <Skeleton
              width="100%"
              variant="rect"
              className={classes.feedSkeleton}
            />
            <Skeleton
              width="100%"
              variant="rect"
              className={classes.feedSkeleton}
            />
          </>
        ) : feeds.length || enableEdit ? (
          feeds.map((elem, index) =>
            Feed(elem, index, onFeedChange, onFeedDelete)
          )
        ) : (
          <CompanyDefaultText text="No Updates added yet" />
        )}
      </Paper>
    </div>
  );
};

CompanyFeed.propTypes = {
  feeds: PropTypes.arrayOf(PropTypes.object),
  onDeleteFeed: PropTypes.func.isRequired,
  onUpdateFeed: PropTypes.func.isRequired,
  role: PropTypes.string,
  viewContent: PropTypes.arrayOf(PropTypes).isRequired,
  organisationName: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

CompanyFeed.defaultProps = {
  feeds: [],
  role: userRoles.VIEWER,
};

export default CompanyFeed;
