import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import { Typography, IconButton } from '@material-ui/core';
import Email from '@material-ui/icons/Email';
import { useStyles } from '../CompanyApiTab/styles';

const ListWithDelete = ({ title, list, deleteOption, width, fullWidth }) => {
  const classes = useStyles();
  return (
    <>
      {title && (
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
      )}
      <div style={{ maxWidth: width }}>
        <div
          style={{ width: fullWidth ? '100%' : '75%' }}
          className={classes.demo}
        >
          <List dense={false}>
            {list.map((elem, index) => (
              <ListItem key={elem.id}>
                <ListItemAvatar>
                  <Avatar>
                    <Email />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={elem.email || elem}
                  className={classes.text}
                  secondary={null}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => deleteOption(index)}
                    edge="end"
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    </>
  );
};

ListWithDelete.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteOption: PropTypes.func.isRequired,
  width: PropTypes.number,
  fullWidth: PropTypes.bool,
};

ListWithDelete.defaultProps = {
  width: 580,
  fullWidth: false,
};

export default ListWithDelete;
