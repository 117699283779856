import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import messages from './messages';
import CompaniesTable from './CompaniesTable';
import InfoBox from './InfoBox';
import MailPopup from '../../containers/RequestData/MailPopup';
import { METRIC_CURRENCY_DICT } from '../../constants/metrics';

export const CashRunway = ({
  companiesByOrganisation,
  onSendRequestDataEmail,
  onUpdateMetricValue,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const columns = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.name),
    },
    {
      id: 'investment',
      numeric: true,
      disablePadding: false,
      label: intl.formatMessage(messages.investment),
    },
    {
      id: 'ownership',
      numeric: true,
      disablePadding: false,
      label: intl.formatMessage(messages.ownership),
    },
    {
      id: 'Cash',
      numeric: true,
      disablePadding: false,
      label: intl.formatMessage(messages.cash),
    },
    {
      id: 'cashflow',
      numeric: true,
      disablePadding: false,
      label: intl.formatMessage(messages.cashflow),
    },
    {
      id: 'runway',
      numeric: true,
      disablePadding: false,
      label: intl.formatMessage(messages.runway),
    },
    {
      id: 'lastUpdate',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.lastUpdate),
    },
    {
      id: 'contact',
      numeric: true,
      disablePadding: false,
      label: intl.formatMessage(messages.contact),
    },
    {
      id: 'investmentManager',
      numeric: true,
      disablePadding: false,
      label: intl.formatMessage(messages.investmentManager),
    },
    {
      id: 'Action',
      numeric: true,
      disablePadding: false,
      label: intl.formatMessage(messages.action),
    },
  ];

  const [
    filteredcompaniesByOrganisation,
    setFilteredcompaniesByOrganisation,
  ] = React.useState([]);
  const [draftRequestItem, setDraftRequestItem] = React.useState(undefined);
  const [modalOpenState, setModalOpenState] = React.useState(false);

  const handleDraftRequestClick = (item) => {
    console.log({ item });
    if (item) {
      setDraftRequestItem(item);
      setModalOpenState((prev) => !prev);
    }
  };

  const handleToggleModal = () => {
    if (modalOpenState) {
      setDraftRequestItem(undefined);
    }
    setModalOpenState((prev) => !prev);
  };

  const handleSubmit = async (data) => {
    await onSendRequestDataEmail({
      variables: {
        ...data,
      },
    });
    setModalOpenState((prev) => !prev);
  };

  useEffect(() => {
    setFilteredcompaniesByOrganisation(
      companiesByOrganisation.map((elem) => {
        let investment = 0;
        let ownership = 0;
        let investedCurrency;
        const investmentManager = elem.contacts.find(
          (item) => item.contactType === 'investor'
        );
        const contacts = elem.contacts.find(
          (item) => item.contactType === 'contact'
        );
        if (elem.investments) {
          elem.investments.map(
            ({
              totalInvestments,
              ownership: newOwnership,
              investedAmount,
              // eslint-disable-next-line array-callback-return
            } = {}) => {
              investment += totalInvestments;
              ownership += newOwnership * 100;
              investedCurrency = investedAmount;
            }
          );
        }

        return {
          name: elem.name,
          investment: investment
            ? `${
                METRIC_CURRENCY_DICT[investedCurrency.currency]
              }${intl.formatNumber(investment)}`
            : 'N/A',
          ownership: ownership
            ? `${ownership / elem.investments.length}%`
            : 'N/A',
          cash: elem.runwayData
            ? `${
                METRIC_CURRENCY_DICT[elem.runwayData.currency]
              }${intl.formatNumber(elem.runwayData.cash)}`
            : 'N/A',
          cashFlow: elem.runwayData
            ? `${
                METRIC_CURRENCY_DICT[elem.runwayData.currency]
              }${intl.formatNumber(elem.runwayData.cashFlow)}`
            : 'N/A',
          runway: elem.runwayData ? Math.abs(elem.runwayData.runway) : 'N/A',
          lastUpdate: elem.runwayData ? elem.runwayData.lastUpdate : 'N/A',
          contacts: contacts
            ? `${contacts.firstName} ${contacts.lastName}`
            : 'N/A',
          firstInvestMgr: investmentManager
            ? `${investmentManager.firstName} ${investmentManager.lastName}`
            : 'N/A',
        };
      })
    );
  }, [companiesByOrganisation]);

  return (
    <>
      <Grid item xs={12} className={classes.marginBottom}>
        <Typography
          component="h2"
          variant="h5"
          color="primary"
          gutterBottom
          className={classes.title}
        >
          {intl.formatMessage(messages.title)}
        </Typography>
      </Grid>
      <CompaniesTable
        columns={columns}
        data={filteredcompaniesByOrganisation}
        companies={companiesByOrganisation}
        handleDraftRequestClick={handleDraftRequestClick}
        onUpdateMetricValue={onUpdateMetricValue}
      />
      <MailPopup
        companyContacts={
          draftRequestItem && Array.isArray(draftRequestItem.contacts)
            ? draftRequestItem.contacts.map((ele) => ({
                id: ele.id,
                contactType: ele.contactType,
                email: ele.email,
                firstName: ele.firstName,
                lastName: ele.lastName,
              }))
            : []
        }
        draftRequestItem={draftRequestItem}
        open={modalOpenState}
        handleToggleModal={handleToggleModal}
        handleSubmit={handleSubmit}
      />
      <InfoBox
        data={filteredcompaniesByOrganisation}
        style={{ message: classes.message }}
      />
    </>
  );
};

CashRunway.propTypes = {
  companiesByOrganisation: PropTypes.object,
  onSendRequestDataEmail: PropTypes.func,
  onUpdateMetricValue: PropTypes.func,
};

CashRunway.defaultProps = {
  companiesByOrganisation: {},
  onSendRequestDataEmail: () => {},
  onUpdateMetricValue: () => {},
};

export default CashRunway;
