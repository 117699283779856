/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectOrganizations,
  selectSelectedOrganization,
} from 'containers/Organisation';
import { Companies, CompanySelectorProps, CompanyOptions } from 'types';
import translations from 'translations';
import { useIntl } from 'react-intl';
import { ORGANISATION_TYPES } from 'constants/index';
import Selector from './selector';
// import lodash from 'lodash'; // temporary need to add @types-lodash than import module
// eslint-disable-next-line @typescript-eslint/no-var-requires
const lodash = require('lodash');

const CompanySelector = ({
  onChangeHandler,
  fullWidth,
}: CompanySelectorProps): JSX.Element => {
  const organisations = useSelector(selectOrganizations);
  const selectedOrganisation = useSelector(selectSelectedOrganization);
  const [selected, setSelected] = useState<Array<number>>([]);
  const [options, setOptions] = useState<Array<CompanyOptions>>([]);
  const [portfoliosLength, setPortfoliosLength] = useState<number>(0);
  const intl = useIntl();

  useEffect(() => {
    if (Array.isArray(organisations) && organisations.length) {
      let countIndex = 0;
      const newCompanies: Array<CompanyOptions> = [
        {
          name: 'All companies',
          value: 'All companies',
          id: 0,
          key: 0,
          companies: selectedOrganisation.companies,
        },
      ];
      const portfolioCompanies: Array<CompanyOptions> = [];
      setOptions(JSON.parse(JSON.stringify(newCompanies)));
      setSelected([0]);
      onChangeHandler(newCompanies[0].companies);
      const companies: Array<CompanyOptions> = [];
      organisations.forEach((ele) => {
        countIndex += 1;
        if (
          ele.portfolios &&
          ele.portfolios.items.length &&
          ele.id === selectedOrganisation.id
        ) {
          ele.portfolios.items.forEach((elem) => {
            const portfolio: Companies = [];
            elem.investments.items.forEach((investment) => {
              if (
                !portfolio.some(
                  (element) => investment.company.id === element.id
                )
              ) {
                portfolio.push(investment.company);
              }
            });
            if (portfolio.length) {
              portfolioCompanies.push({
                id: elem.id,
                name: elem.name,
                value: elem.name,
                companies: portfolio,
                key: countIndex,
              });
              countIndex += 1;
            }
          });
        }
        if (ele.type === ORGANISATION_TYPES.COMPANY)
          companies.push({ ...ele, value: ele.name, key: countIndex });
      });
      setPortfoliosLength(portfolioCompanies.length);
      setOptions(newCompanies.concat(portfolioCompanies.concat(companies)));
    }
  }, [organisations]);

  const handleChange = (companiesIndex: Array<number>): void => {
    const addedElement = companiesIndex[companiesIndex.length - 1];
    console.log({companiesIndex})
    if (addedElement === -1 || !companiesIndex.length) {
      setSelected([0]);
      onChangeHandler(options[0].companies);
    } else {
      let newIndex = companiesIndex;
      if (companiesIndex[0] === 0 && companiesIndex.length > 1) {
        newIndex = companiesIndex.slice(1, companiesIndex.length);
        console.log({ newIndex })
      }
      const selectedOption = lodash.uniqBy(
        // send only unique companies to change handler
        newIndex.map((index: number) => options[index].companies).flat(1),
        'id'
      );
      setSelected(newIndex);
      onChangeHandler(selectedOption);
    }
  };

  const renderValue = (opts: any): string => {
    if (opts.length < 1) {
      return 'Select';
    }
    if (opts.length === 1) {
      return options[opts[0]].name;
    }
    return `${options[opts[0]].name} +${opts.length - 1}`;
  };

  const displayOptions = useMemo(() => {
    if (options.length) {
      const newOptions = [];
      newOptions.push({
        header: intl.formatMessage(translations.Header.portfolios),
        options: options.slice(1, portfoliosLength + 1).map((ele) => ({
          ...ele,
          data: ele.companies,
        })),
      });
      newOptions.push({
        header: intl.formatMessage(translations.Header.dividerCompany),
        options: [options[0]]
          .concat(options.slice(portfoliosLength + 1))
          .map((ele) => ({
            ...ele,
            data: ele.companies,
          })),
      });
      return newOptions;
    }
    return [];
  }, [options]);

  return (
    <Selector
      options={displayOptions}
      selected={selected}
      handleChange={handleChange}
      renderValue={renderValue}
      reset
      fullWidth={fullWidth}
    />
  );
};

export { CompanySelector };
