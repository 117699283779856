import { handleActions, createAction } from 'redux-actions';
import { createSelector } from 'reselect';

import { ACTION_TYPES_LOADING, ACTION_TYPES_DOWNLOAD } from '../actionTypes';

const initialState = {
  loading: false,
  progress: 0,
  progressOpen: false,
  message: '',
};

const loading = handleActions(
  {
    [ACTION_TYPES_LOADING.SET_LOADING]: (state, { payload }) => ({
      ...state,
      loading: payload,
    }),
    [ACTION_TYPES_DOWNLOAD.SET_DOWNLOAD]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initialState
);

const getRoot = (state) => state.loading;

const getLoading = createSelector(getRoot, (state) => state.loading);

const getDownloadStatus = createSelector(getRoot, (state) => ({
  progress: state.progress,
  progressOpen: state.progressOpen,
  message: state.message,
}));

const setLoading = createAction(ACTION_TYPES_LOADING.SET_LOADING);

const setDownloads = createAction(ACTION_TYPES_DOWNLOAD.SET_DOWNLOAD);

export {
  loading,
  getRoot,
  setLoading,
  getLoading,
  getDownloadStatus,
  setDownloads,
};
