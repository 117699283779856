/* eslint-disable import/named */
import React, { useEffect, useState } from 'react';
import translations from 'translations';
import { useIntl } from 'react-intl';
import { COMPANY_CONTACT_TYPE } from 'constants/index';
import { ContactSelectorProps, SelectorOptions, SelectorProps } from 'types';
import { normalizeNameList } from 'utils/index';
import Selector from './selector';

export const ContactSelector = ({
  contacts,
  handleChange,
  placeholder,
  isContact,
  bulkSelect = false,
  fullWidth = false,
}: ContactSelectorProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const [selected, setSelected] = useState<Array<number>>([]);
  const [options, setOptions] = useState<Array<SelectorOptions>>([]);
  const [displayOptions, setDisplayOptions] = useState<
    SelectorProps['options']
  >([]);

  const onChangeHandler = (value: any) => {
    if (value.length > 1 && value[0] === -1) value.splice(0, 1);
    setSelected(value.length ? value : [-1]);
    const selectedContacts =
      value[0] === -1 ? [] : value.map((ele: number) => options[ele].data);
    handleChange(selectedContacts);
  };

  useEffect(() => {
    const companyContacts: Array<SelectorOptions> = [];
    const companyInvestors: Array<SelectorOptions> = [];
    if (!bulkSelect) {
      contacts.forEach((ele, index) => {
        const data = {
          key: index,
          id: ele.id,
          value: ele.email as string,
          name: (ele.firstName || ele.lastName || ele.email) as string,
          data: ele,
        };
        if (ele.contactType === COMPANY_CONTACT_TYPE.CONTACT) {
          companyContacts.push(data);
        } else {
          companyInvestors.push(data);
        }
      });
    } else {
      companyContacts.push({
        key: 0,
        id: '0',
        name: formatMessage(translations.Company.CompanyScreen.companyContact),
        value: '',
        data: 0,
      });
      companyInvestors.push({
        key: 1,
        id: '1',
        name: formatMessage(
          translations.Company.CompanyScreen.investmentManagers
        ),
        value: '',
        data: 1,
      });
    }
    const newDisplayOptions: SelectorProps['options'] = [];
    newDisplayOptions.push({
      header: formatMessage(translations.Company.CompanyScreen.companyContact),
      options: companyContacts,
    });

    newDisplayOptions.push({
      header: formatMessage(
        translations.Company.CompanyScreen.investmentManagers
      ),
      options: companyInvestors,
    });
    setDisplayOptions(newDisplayOptions);
    setOptions(companyContacts.concat(companyInvestors));
    const newSelected: Array<number> = [];
    const offset = isContact ? 0 : companyContacts.length;
    handleChange(
      bulkSelect
        ? isContact
          ? [0]
          : [1]
        : (isContact ? companyContacts : companyInvestors).map((ele, index) => {
            newSelected.push(index + offset);
            return ele.data;
          })
    );
    setSelected(
      bulkSelect
        ? isContact
          ? [0]
          : [1]
        : newSelected.length
        ? newSelected
        : [-1]
    );
  }, [contacts]);

  const renderValue = (opts: any) => {
    if (opts[0] !== -1 && opts.length && options.length) {
      return normalizeNameList(opts.map((ele: number) => options[ele].name));
    }
    return placeholder || 'Select';
  };

  return (
    <Selector
      options={displayOptions}
      selected={selected}
      handleChange={onChangeHandler}
      renderValue={renderValue}
      showHeader={!bulkSelect}
      fullWidth={fullWidth}
    />
  );
};
