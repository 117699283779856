import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import translations from 'translations';
import { Button } from 'components/Common/Buttons';
import { InvestmentType } from 'constants/index';
import { useStyles } from './styles';
import { ComponentsData } from './type';

interface OnboardingStepFourProps {
  componentsData: ComponentsData;
  onClose(): null;
}

const OnboardingStepFour = ({
  componentsData,
  onClose,
}: OnboardingStepFourProps): JSX.Element => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();

  return (
    <Grid item xs={12} className={classes.step4Grid}>
      <Grid item xs={12} className={classes.gridWrapper}>
        <Typography variant="subtitle2" color="textPrimary" gutterBottom>
          {`${formatMessage(
            translations.Onboarding.onBoardingDialog3DoneInstruction
          )}`}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.gridWrapper}>
        <Typography variant="subtitle2" color="textPrimary" gutterBottom>
          {componentsData.investmentType === InvestmentType[0].value
            ? `${formatMessage(
                translations.Onboarding.onBoardingDialog3DoneInstruction3
              )}`
            : ''}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.gridWrapper}>
        <Button
          typeColor="common"
          variant="contained"
          color="primary"
          id="onboarding-done"
          onClick={(): void => {
            history.go(1);
            onClose();
          }}
          style={{ marginTop: 30 }}
        >
          {`${formatMessage(translations.Onboarding.onBoardingStartLabel)}`}
        </Button>
      </Grid>
    </Grid>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { OnboardingStepFour };
