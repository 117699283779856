import { gql } from 'apollo-boost';

import {
  ORGANISATION_PAYLOAD,
  COMPANY_PAYLOAD_SHORT,
  PORTFOLIO_PAYLOAD,
  INVESTMENT_PAYLOAD,
} from 'graphql/payloads';

import { COMPANIES_BY_USER_PAYLOAD } from 'graphql/company';

export const CREATE_COMPANY_MUTATION = gql`
  mutation(
    $organisationId: ID!
    $name: String!
    $file: Upload
    $shortDescription: String
    $investments: [CreateInvestmentInput]
    $apiCompanyId: ID
  ) {
    createCompany(
      input: {
        organisationId: $organisationId
        name: $name
        file: $file
        shortDescription: $shortDescription
        investments: $investments
        apiCompanyId: $apiCompanyId
      }
    ) {
      id
      name
      shortDescription
      logoUrl
      investments {
        ${INVESTMENT_PAYLOAD}
        portfolio {
          id
        }
      }
      ${COMPANY_PAYLOAD_SHORT}
      organisation {
        ${ORGANISATION_PAYLOAD}
        companies {
          ${COMPANY_PAYLOAD_SHORT}
        }
      }
    }
  }
`;

export const UPDATE_COMPANY_MUTATION = gql`
  mutation($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      id
      name
      shortDescription
      team
      location
      websiteUrl
      industryId
      legalName
      employees
      foundedDate
      contactEmail
      url
    }
  }
`;

export const UPDATE_CONTACTS_MUTATION = gql`
  mutation($input: UpdateContactInput!) {
    updateContact(input: $input)
  }
`;

export const ADD_COMPANY_FROM_SHARED = gql`
  mutation(
    $organisationIdOrigin: ID!
    $organisationIdTarget: ID!
    $investments: [CreateInvestmentInput]
  ) {
    addCompanyFromShared(
      input: {
        organisationIdOrigin: $organisationIdOrigin
        organisationIdTarget: $organisationIdTarget
        investments: $investments
      }
    ) {
      ${ORGANISATION_PAYLOAD}
      portfolios {
        totalCount
        items {
          ${PORTFOLIO_PAYLOAD}
        }
      }
      companies {
        ${COMPANY_PAYLOAD_SHORT}
        ${COMPANIES_BY_USER_PAYLOAD}
      }
    }
  }
`;

export const DELETE_COMPANIES_MUTATION = gql`
  mutation($input: [DeleteCompanyInput!]!) {
    deleteCompanies(input: $input)
  }
`;
