import React, { useMemo, useState, useEffect } from 'react';
import { GridTitle } from 'components/Common/Grid';
import { Paper } from '@material-ui/core';
import translations from 'translations';
import { SelectInput } from 'components/Common/Select';
import { useIntl } from 'react-intl';
import { OnChangeEvent, DefaultKpiScreenProps } from 'types';
import { Periodicity } from 'constants/index';
import DefaultKpiTable from './DefaultKpiTable';
import { useStyles } from './styles';

const DefaultKpiScreen = ({
  handleUpdateDefaultKpi,
  handleAddDefaultkpi,
  handledeleteDefaultKpi,
  UpdatePeriodicity,
  defaultKpiValues,
}: DefaultKpiScreenProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const periodicityOptions = useMemo(
    () =>
      Object.values(Periodicity).map((ele, index) => ({
        id: `${index}`,
        name: ele,
      })),
    [Periodicity]
  );

  const [selectedPeriodicity, setSelectedPeriodicity] = useState(
    Periodicity.MONTHLY
  );
  useEffect(() => {
    setSelectedPeriodicity(defaultKpiValues.periodicity);
  }, [defaultKpiValues.periodicity]);

  const handleChangePeriodicity = (event: OnChangeEvent): void => {
    const {
      target: { value },
    } = event;
    setSelectedPeriodicity(value);
    UpdatePeriodicity(value);
  };

  return (
    <div className={classes.root}>
      <GridTitle
        textId={translations.Common.kpis.title.id}
        text={translations.Common.kpis.title.defaultMessage}
        classStyles={classes.title}
      />
      <Paper className={classes.paper} elevation={4}>
        <SelectInput
          style={{
            width: '50%',
          }}
          fullWidth
          label={formatMessage(
            translations.OrganisationSettings.defaultPeriodicity
          )}
          onChange={handleChangePeriodicity}
          options={periodicityOptions}
          value={selectedPeriodicity}
        />
        <br />
        <DefaultKpiTable
          defaultKpiValues={defaultKpiValues.data}
          handleUpdateDefaultKpi={handleUpdateDefaultKpi}
          handleAddDefaultkpi={handleAddDefaultkpi}
          handledeleteDefaultKpi={handledeleteDefaultKpi}
        />
      </Paper>
    </div>
  );
};

export default DefaultKpiScreen;
