import { handleActions, createAction } from 'redux-actions';
import { createSelector } from 'reselect';

import {
  SET_EDITING_INVESTMENT,
  SET_INVESTMENTS_LOADING,
} from '../actionTypes';

const initialState = {
  investmentsTable: {
    isEditing: false,
    isLoading: false,
  },
};

const utils = handleActions(
  {
    [SET_EDITING_INVESTMENT]: (
      state,
      { payload = initialState.isEditing }
    ) => ({
      ...state,
      investmentsTable: {
        ...state.investmentsTable,
        isEditing: payload,
      },
    }),
    [SET_INVESTMENTS_LOADING]: (state, { payload }) => ({
      ...state,
      investmentsTable: {
        ...state.investmentsTable,
        isLoading: payload,
      },
    }),
  },
  initialState
);

const getUtils = (state) => state.utils;
const getInvestmentsTable = createSelector(
  getUtils,
  (state) => state.investmentsTable
);
const getIsEditingInvestments = createSelector(
  getInvestmentsTable,
  (state) => state.isEditing
);
const getIsLoadingInvestments = createSelector(
  getInvestmentsTable,
  (state) => state.isLoading
);

const setIsEditingInvestments = createAction(SET_EDITING_INVESTMENT);
const setIsLoadingInvestments = createAction(SET_INVESTMENTS_LOADING);

export {
  utils,
  getUtils,
  getInvestmentsTable,
  getIsEditingInvestments,
  getIsLoadingInvestments,
  setIsEditingInvestments,
  setIsLoadingInvestments,
};
