import { createSelector } from '@reduxjs/toolkit';
import { Organization, Companies } from 'types';
import { selectSelectedOrganization } from 'containers/Organisation/ducks';

export const selectorOrganization = (state: Organization | null): Companies => {
  if (!state) {
    return [];
  }
  return state.companies;
};

export const selectSelectedOrgCompanies = createSelector(
  selectSelectedOrganization,
  selectorOrganization
);
