import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1400,
    margin: 'auto',
  },
  grid: {
    minHeight: '90px',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  paper: {
    margin: theme.spacing(2),
    padding: `0 ${theme.spacing(3)}px`,
  },
  paperTable: {
    margin: theme.spacing(2),
  },
  infoValue: {
    fontSize: 17,
    color: '#363636',
    letterSpacing: 1,
    fontWeight: '400',
    marginTop: theme.spacing(2),
  },

  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  title: {
    color: '#212121',
    margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  sectionTitle: {
    color: '#363636',
    letterSpacing: 1,
    fontWeight: '400',
    margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  infoLabel: {
    fontSize: 14,
    margin: '5px 0',
    color: '#363636',
    letterSpacing: 1,
    fontWeight: '500',
  },
  fullWidth: {
    width: '100%',
    margin: 20,
  },
  addButton: {
    ...theme.custom.buttonLabel,
    width: '120px !important',
  },
}));

export const EditButton = withStyles({
  label: {
    textTransform: 'capitalize',
  },
})(Button);
