/* eslint-disable comma-dangle */
/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    overflowY: 'scroll',
  },
  modalContent: {
    outline: 'none',
    margin: `${theme.spacing(12)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px ${theme.spacing(33)}px`,
  },
  modalOverlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.48) !important',
  },
}));
