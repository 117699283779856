/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    padding: theme.spacing(3),
  },
  spacing: {
    height: `${theme.spacing(2)}px`,
  },
  title: {
    marginTop: 0,
  },
  gridWrapper: {
    marginBottom: 20,
  },
  actionBtn: {
    width: '100px !important',
  },
  grayBtn: {
    width: '100px !important',
    background: 'rgba(0,0,0,0.6) !important',
  },
  paper: {
    marginBottom: '50px',
    boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.2)',
  },
  label: {
    fontSize: 13,
    color: '#000',
  },
  inputLabelTop: {
    marginBottom: theme.spacing(1.3),
  },
  formControlCol: {
    width: '44%',
  },
  stepWrapper: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  radio: {
    fontSize: 13,
    '&$checked': {
      color: 'rgba(0,0,0,0.6)',
    },
  },
  checked: {
    color: 'rgba(0,0,0,0.6)',
  },
  flexRowMiddle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flexColumnMiddle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  companyInfoWrapper: {
    minHeight: '95px',
  },
  companyInfoContainer: {
    paddingTop: theme.spacing(2.2),
  },
  companyInfoCol1: {
    textAlign: 'center',
    width: '20%',
  },
  companyBasicInfoWrapper: {
    width: '70%',
    height: 65,
  },
  imageUploadLabel: {
    paddingBottom: theme.spacing(0.7),
    fontSize: 14,
  },
  imageUploadBtn: {
    width: '100%',
    height: '75%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
    boxShadow: 'none',
  },
  companyBasicInfoCol1: {
    width: '45%',
  },
  companyBasicInfoCol2: {
    width: '50%',
  },
  description: {
    paddingLeft: theme.spacing(1.2),
  },
  actionWrapper: {
    display: 'flex',
    marginTop: theme.spacing(4.2),
  },
  simpleFlex: {
    display: 'flex',
  },
  switchBase: {
    color: '#0C696A',
    '&$checked': {
      color: '#0C696A',
    },
    '&$checked + $track': {
      backgroundColor: '#0C696A',
    },
  },
  track: {},
  inputWrapper: {
    marginLeft: '30px',
  },
  footer: {
    color: '#7b7b7b',
    fontStyle: 'italic',
    marginLeft: '7px',
  },
  step4Grid: {
    padding: theme.spacing(3),
    paddingLeft: 0,
  },
  logout: {
    color: theme.palette.text.secondary,
    fontSize: 'small',
  },
}));
