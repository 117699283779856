/* eslint-disable no-param-reassign */
/* eslint-disable operator-linebreak */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { flatten } from 'lodash';
import translations from 'translations';

import { useStyles } from './styles';
import messages from './messages';
import { CompaniesTable } from './AllCompaniesTable';

export const AllCompaniesScreen = ({ companies }) => {
  const intl = useIntl();
  const classes = useStyles();

  const columns = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.company),
    },
    {
      id: 'investment',
      numeric: true,
      disablePadding: false,
      label: intl.formatMessage(
        translations.Company.AllCompanies.totalInvestments
      ),
    },
    // {
    //   id: 'ownership',
    //   numeric: true,
    //   disablePadding: false,
    //   label: intl.formatMessage(messages.ownership),
    // },
    {
      id: 'report',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.report),
    },
    {
      id: 'investmentManager',
      numeric: true,
      disablePadding: false,
      label: intl.formatMessage(messages.investmentManager),
    },
  ];

  const [
    filteredcompaniesByOrganisation,
    setFilteredcompaniesByOrganisation,
  ] = React.useState([]);

  useEffect(() => {
    const flattenCompanies = flatten(
      companies.map((elem) => {
        const obj = {
          id: elem.id,
          name: elem.name,
          contacts: elem.contacts,
          report: elem.report,
        };

        if (elem.investments) {
          obj.investment = elem.investments.reduce((acc, nextElem) => {
            acc += nextElem.totalInvestments;
            return acc;
          }, 0);
          obj.currency = elem.investments[0].investedAmount.currency;
          return obj;
        }

        return [obj];
      })
    ).filter((elem) => elem && elem.id);

    if (flattenCompanies) {
      setFilteredcompaniesByOrganisation(flattenCompanies);
    }
  }, [companies]);

  return (
    <>
      <Grid item xs={12} className={classes.marginBottom}>
        <Typography
          component="h2"
          variant="h5"
          color="primary"
          gutterBottom
          className={classes.title}
        >
          {intl.formatMessage(messages.allCompaniesTitle)}
        </Typography>
      </Grid>
      <CompaniesTable
        columns={columns}
        data={filteredcompaniesByOrganisation}
      />
    </>
  );
};

export default AllCompaniesScreen;

AllCompaniesScreen.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object),
};

AllCompaniesScreen.defaultProps = {
  companies: [],
};
