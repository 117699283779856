import { gql } from 'apollo-boost';
import {
  INVESTMENT_COUNT_PAYLOAD,
  PORTFOLIO_PAYLOAD,
  INVESTMENT_PAYLOAD,
} from 'graphql/payloads';

export const CREATE_PORTFOLIO = gql`
  mutation(
    $organisationId: ID!
    $name: String!
    $size: Float
    $sizeCurrency: String!
    $creationYear: Int
    $targetNumberOfInvestments: Int
  ) {
    createPortfolio(
      input: {
        organisationId: $organisationId
        name: $name
        size: $size
        sizeCurrency: $sizeCurrency
        creationYear: $creationYear
        targetNumberOfInvestments: $targetNumberOfInvestments
      }
    ) {
      ${PORTFOLIO_PAYLOAD}
      investments {
        ${INVESTMENT_COUNT_PAYLOAD}
        items {
          ${INVESTMENT_PAYLOAD}
        }
      }
    }
  }
`;

export const DELETE_PORTFOLIOS = gql`
  mutation($portfolioIds: [ID!]!) {
    deletePortfolio(portfolioIds: $portfolioIds)
  }
`;

export const UPDATE_PORTFOLIO = gql`
  mutation($input: UpdatePortfolioInput) {
    updatePortfolio(input: $input) {
      name
    }
  }
`;

export const UPDATE_INVESTMENTS_MUTATION = gql`
  mutation($companyId: ID!, $investments: [CreateInvestmentInput!]) {
    updateInvestments(
      input: { companyId: $companyId, investments: $investments }
    ) {
      ${INVESTMENT_PAYLOAD}
      company {
        id
      }
      portfolio {
        id
      }
    }
  }
`;

export const UPDATE_INVESTMENT_MUTATION = gql`
  mutation($companyId: ID!, $investment: CreateInvestmentInput!) {
    updateInvestment(
      input: { companyId: $companyId, investment: $investment }
    ) {
      ${INVESTMENT_PAYLOAD}
      company {
        id
      }
    }
  }
`;

export const DELETE_INVESTMENTS_MUTATION = gql`
  mutation($investmentIds: [ID!]!) {
    deleteInvestments(investmentIds: $investmentIds)
  }
`;