import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { setAlert } from 'containers/Config';
import translations from 'translations';
import getGraphqlErrorMessage from 'common/getGraphqlErrorMessage';

import Alert from '../../components/Common/UI/Alert';
import {
  SEND_REQUEST_DATA_EMAIL,
  UPDATE_COMPANY_METRIC_MUTATION,
  GET_SELECTED_ORGANISATION_WITH_INVESTMENT_QUERY,
} from '../../graphql';

import { CashRunway } from '../../components/CashRunway';

const CashRunwayScreenContainer = () => {
  const { id: orgId } = useParams();
  const dispatch = useDispatch();
  const { data: { organisation: { companies = [] } = {} } = {} } = useQuery(
    GET_SELECTED_ORGANISATION_WITH_INVESTMENT_QUERY,
    {
      variables: {
        organisationId: orgId,
      },
      fetchPolicy: 'no-cache',
    }
  );
  const [sendRequestDataEmail] = useMutation(SEND_REQUEST_DATA_EMAIL);
  const [updateMetricValue] = useMutation(UPDATE_COMPANY_METRIC_MUTATION);

  const onSendRequestDataEmail = async (data) => {
    console.log({ data });
    try {
      const {
        data: { sendRequestDataEmail: response } = {},
      } = await sendRequestDataEmail(data);
      if (!response) throw new Error(response);
      dispatch(
        setAlert({
          open: true,
          message: translations.RequestData.mailSendSuccessfully,
          severity: 'success',
        })
      );
      return true;
    } catch (error) {
      const errorMessage = getGraphqlErrorMessage(error);
      dispatch(
        setAlert({
          open: true,
          message:
            errorMessage || translations.Common.common.defaultErrorMessage,
          severity: 'success',
        })
      );
      return false;
    }
  };

  return (
    <>
      <CashRunway
        companiesByOrganisation={companies}
        onSendRequestDataEmail={onSendRequestDataEmail}
        onUpdateMetricValue={updateMetricValue}
      />
      <Snackbar open={false} autoHideDuration={4000} onClose={() => {}}>
        <Alert onClose={() => {}} severity="success" />
      </Snackbar>
    </>
  );
};

export { CashRunwayScreenContainer };
