export const SUBSCRIPTION_TYPES_PREV = {
  FREE: 'free',
  PREMIUM: 'premium',
  MONTHLY: 'monthly',
  ANNUAL: 'annual',
};

export const SUBSCRIPTION_TYPES = {
  FREE: 'free',
  MONTHLY: 'monthly',
  ANNUAL: 'annual',
  MONTHLY_PRO: 'monthlypro',
  ANNUAL_PRO: 'annualpro',
  ENTERPRISE: 'enterprise',
};

export const SUBSCRIPTION_STATUS_CODES = {
  TRIAL_EXPIRING: 1,
  NO_PAID: 2,
  LIMIT_EXCEEDING: 3,
  ALL_UPDATED: 4,
  TYPE_COMPANY: 5,
};
