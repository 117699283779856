/* eslint-disable @typescript-eslint/explicit-function-return-type */
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
  Collapse,
  InputLabel,
  TextField,
  Typography,
  Select,
  MenuItem,
  ThemeProvider,
  Button,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useSelector } from 'react-redux';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { selectSelectedOrganization } from 'containers/Organisation/ducks';
import { useStyle, inputsTheme, StyledTableCell } from './styles';
import FormattedTextField from './FormattedTextField';
import { CompanyDefaultText } from './CompanyDefault';

const initInvestment = {
  id: '',
  date: Date.now(),
  portfolio: '',
  portfolioId: '',
  description: '',
  totalInvestments: '',
  shares: '',
  sharePrice: '',
  roundSize: '',
  companyValuation: '',
  investors: '',
  exitStatus: '',
  edited: false,
};

const InvestmentTable = ({
  investments = [],
  edit,
  hideExits,
  onSave,
  handleInvesmentChange,
  onRemove,
}) => {
  const classes = useStyle();
  const intl = useIntl();
  const [isExitsOpen, setIsExitsOpen] = useState(
    investments &&
      investments.filter((i) => i.exitStatus !== 'current').length > 0
  );
  const selectedOrganization = useSelector(selectSelectedOrganization);
  const [portfolios, setPortfolios] = useState([]);
  useEffect(() => {
    if (!selectedOrganization) {
      return;
    }
    if (
      selectedOrganization.portfolios &&
      Array.isArray(selectedOrganization.portfolios.items)
    ) {
      setPortfolios(selectedOrganization.portfolios.items || []);
    }
  }, [selectedOrganization]);
  const [tableInvestments, setTableInvestments] = useState([]);

  useEffect(() => {
    if (investments === null) {
      return;
    }
    setIsExitsOpen(
      (investments || []).filter((i) => i.exitStatus !== 'current').length > 0
    );
    setTableInvestments(
      (investments || []).map((i) => ({
        ...initInvestment,
        ...i,
        id: i.id,
        portfolio: i.portfolio.name,
        sharePrice: i.shares ? i.totalInvestments / i.shares : null,
        portfolioId: i.portfolio.id,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investments]);

  const format = {
    date: (x) => intl.formatDate(Number(x)),
    currency: (x) =>
      intl.formatNumber(x, { style: 'currency', currency: 'USD' }),
    number: (x) => x,
    string: (x) => x,
  };

  const renderFormattedTextField = (investment, handleChange, column) => (
    <FormattedTextField
      key={`${investment.id}${column.accessor}_formatted`}
      investment={investment}
      handleChange={handleChange}
      column={column}
      isCreationgField={tableInvestments.includes(investment)}
    />
  );

  const renderTextField = (
    investment,
    handleChange,
    { accessor, isRequired }
  ) => (
    <TextField
      onChange={(event) =>
        handleChange(investment.id, {
          [accessor]: event.target.value,
        })
      }
      required={isRequired}
      error={
        !investment[accessor] &&
        isRequired &&
        tableInvestments.includes(investment)
      }
      variant="outlined"
      value={investment[accessor] || ''}
      InputProps={{
        className: classes.inputStyles,
      }}
    />
  );

  const handleRemove = ({ id, portfolioId }) => {
    if (!id.includes('new:')) {
      onRemove(id, portfolioId);
    }
    // setTableInvestments((prevTableInvestments) => [
    //   ...prevTableInvestments.filter((i) => i.id !== id),
    // ]);
  };

  const columns = [
    {
      title: 'Date',
      type: 'date',
      accessor: 'date',
      isRequired: true,
      width: '10%',
      edit: (investment, handleChange) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            value={Number(investment.date)}
            inputVariant="outlined"
            invalidDateMessage={null}
            InputProps={{ className: classes.inputStyles }}
            onChange={(date) => handleChange(investment.id, { date })}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      title: 'Portfolio',
      type: 'string',
      accessor: 'portfolio',
      isRequired: true,
      width: '10%',
      edit: (investment, handleChange, { isRequired }) => (
        <Select
          value={investment.portfolioId || ''}
          onChange={(event) =>
            handleChange(investment.id, {
              portfolioId: event.target.value,
              portfolio:
                portfolios.find((p) => p.id === event.target.value) &&
                portfolios.find((p) => p.id === event.target.value).name,
            })
          }
          required={isRequired}
          error={
            !investment.portfolioId &&
            isRequired &&
            tableInvestments.includes(investment)
          }
          variant="outlined"
          className={classes.inputStyles}
          classes={{ notchedOutline: classes.hideOutlinedInputs }}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem style={{ fontSize: 14 }} key="none" value="">
            None
          </MenuItem>
          {portfolios.map((p) => (
            <MenuItem style={{ fontSize: 14 }} key={p.id} value={p.id}>
              {p.name}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      title: 'Description',
      type: 'string',
      accessor: 'description',
      isRequired: false,
      width: '20%',
      edit: renderTextField,
    },
    {
      title: 'Investment Amount',
      exitTitle: 'Proceeds',
      type: 'currency',
      accessor: 'totalInvestments',
      isRequired: true,
      width: '12%',
      edit: renderFormattedTextField,
    },
    {
      title: 'Shares',
      type: 'number',
      accessor: 'shares',
      isRequired: true,
      width: '8%',
      edit: renderTextField,
    },
    {
      title: 'Shareprice',
      type: 'currency',
      accessor: 'sharePrice',
      isRequired: false,
      width: '8%',
      edit: ({ shares, totalInvestments }) => (
        <Typography className={classes.cellTypography}>
          {+shares && +totalInvestments
            ? format.currency(totalInvestments / shares)
            : '-'}
        </Typography>
      ),
    },
    {
      title: 'Round Size',
      type: 'currency',
      accessor: 'roundSize',
      isRequired: false,
      width: '12%',
      edit: renderFormattedTextField,
    },
    {
      title: 'Company valuation',
      type: 'currency',
      accessor: 'companyValuation',
      isRequired: false,
      width: '12%',
      edit: renderFormattedTextField,
    },
    {
      title: 'Investors',
      exitTitle: 'Buyer',
      type: 'string',
      accessor: 'investors',
      isRequired: false,
      width: '10%',
      edit: renderTextField,
    },
    {
      title: '',
      type: 'action',
      accessor: 'remove',
      isRequired: false,
      width: '70px',
      edit: (investment) => {
        return (
          tableInvestments.includes(investment) && (
            <Button
              color="secondary"
              variant="text"
              onClick={() => handleRemove(investment)}
            >
              Remove
            </Button>
          )
        );
      },
    },
  ];

  const validateInvestments = () => {
    let validation = true;
    tableInvestments.forEach((investment) => {
      columns.forEach((c) => {
        if (c.isRequired && !investment[c.accessor] && c.type !== 'action')
          validation = false;
      });
    });
    return validation;
  };

  useEffect(() => {
    if (edit === false) {
      if (validateInvestments()) {
        onSave(
          tableInvestments
            .filter(({ edited }) => edited)
            .map(
              ({
                id,
                portfolioId,
                ownership,
                invested,
                involvement,
                reserved,
                exitStatus,
                date,
                description,
                shares,
                companyValuation,
                roundSize,
                investors,
                totalInvestments,
              }) => {
                return {
                  id: id.includes('new:') ? null : id,
                  portfolioId,
                  ownership: ownership || 0,
                  invested: {
                    amount: (invested && invested.amount) || 0,
                    currency: (invested && invested.currency) || 'USD',
                  },
                  involvement: involvement || 0,
                  reserved: reserved || 0,
                  exitStatus: exitStatus || 'current',
                  date: new Date(Number(date)),
                  description,
                  shares: Number(shares) || 0,
                  companyValuation: Number(companyValuation) || 0,
                  roundSize: Number(roundSize),
                  investors,
                  totalInvestments: Number(totalInvestments),
                };
              }
            )
        );
      } else {
        onSave(null);
      }
      setTableInvestments(
        (investments || []).map((i) => ({
          ...initInvestment,
          ...i,
          id: i.id,
          portfolio: i.portfolio.name,
          sharePrice: i.shares ? i.totalInvestments / i.shares : null,
          portfolioId: i.portfolio.id,
        }))
      );
      if (handleInvesmentChange) handleInvesmentChange(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  const handleInput = (investmentId, difference) => {
    if (handleInvesmentChange) handleInvesmentChange(true);
    setTableInvestments((prevTableInvestments) => {
      const prevState = prevTableInvestments.find((i) => i.id === investmentId);
      const isNew = investmentId.includes('new:');
      const exitStatus = isNew
        ? investmentId.split(':')[1]
        : prevState.exitStatus;
      const subTable = prevTableInvestments.filter(
        (i) => i.exitStatus === exitStatus
      );
      const index =
        (subTable.indexOf(prevState) === -1
          ? subTable.length
          : subTable.indexOf(prevState)) +
        (exitStatus === 'exited'
          ? prevTableInvestments.length - subTable.length
          : 0);
      const newInvestments = [
        ...prevTableInvestments.filter((i) => i.id !== investmentId),
      ];
      newInvestments.splice(index, 0, {
        ...initInvestment,
        ...prevState,
        exitStatus: isNew ? investmentId.split(':')[1] : prevState.exitStatus,
        id: investmentId,
        ...difference,
        edited: true,
      });
      return newInvestments;
    });
  };

  const renderRow = (investment, options = { columnsFilters: [] }) => (
    <TableRow key={investment.id}>
      {columns
        .filter((c) => c.accessor !== 'remove' || edit)
        .map((c) => (
          <StyledTableCell key={c.accessor}>
            {!options.columnsFilters.includes(c.accessor) &&
              (edit ? (
                c.edit && c.edit(investment, handleInput, c)
              ) : (
                <Typography className={classes.cellTypography}>
                  {investment[c.accessor]
                    ? format[c.type](investment[c.accessor])
                    : '-'}
                </Typography>
              ))}
          </StyledTableCell>
        ))}
    </TableRow>
  );

  return (
    <ThemeProvider theme={inputsTheme}>
      {tableInvestments.length || edit ? (
        <>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {columns.map(
                  (c) =>
                    (c.accessor !== 'remove' || edit) && (
                      <StyledTableCell width={c.width} key={c.accessor}>
                        {c.title}
                      </StyledTableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableInvestments
                .filter((i) => i.exitStatus === 'current')
                .map((i) => renderRow(i))}
              {edit &&
                renderRow({
                  ...initInvestment,
                  id: `new:current:${tableInvestments.length}`,
                  exitStatus: 'current',
                })}
              <TableRow>
                <StyledTableCell style={{ fontWeight: 'bold' }} colSpan={3}>
                  Total
                </StyledTableCell>
                <StyledTableCell style={{ fontWeight: 'bold' }}>
                  {format.currency(
                    tableInvestments.reduce(
                      (total, investment) =>
                        total +
                        (investment.exitStatus === 'current') *
                          Number(investment.totalInvestments),
                      0
                    )
                  )}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
          {!hideExits && (
            <>
              <InputLabel className={classes.exitsHeader}>
                Exits
                <IconButton
                  size="small"
                  onClick={() => setIsExitsOpen(!isExitsOpen)}
                >
                  <ArrowDropDownIcon
                    className={`${classes.iconFontSize} ${
                      isExitsOpen ? classes.upsideDown : ''
                    }`}
                  />
                </IconButton>
              </InputLabel>
              <Collapse in={isExitsOpen}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {columns.map(
                        (c) =>
                          (c.accessor !== 'remove' || edit) && (
                            <StyledTableCell width={c.width} key={c.accessor}>
                              {c.exitTitle || c.title}
                            </StyledTableCell>
                          )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableInvestments
                      .filter((i) => i.exitStatus !== 'current')
                      .map((i) => renderRow(i))}
                    {edit &&
                      renderRow({
                        ...initInvestment,
                        id: `new:exited:${tableInvestments.length}`,
                        exitStatus: 'exited',
                      })}
                    <TableRow>
                      <StyledTableCell
                        style={{ fontWeight: 'bold' }}
                        colSpan={3}
                      >
                        Total
                      </StyledTableCell>
                      <StyledTableCell style={{ fontWeight: 'bold' }}>
                        {format.currency(
                          tableInvestments.reduce(
                            (total, investment) =>
                              total +
                              (investment.exitStatus !== 'current') *
                                Number(investment.totalInvestments),
                            0
                          )
                        )}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Collapse>
            </>
          )}
        </>
      ) : (
        <CompanyDefaultText text="No Investments added yet" />
      )}
    </ThemeProvider>
  );
};

InvestmentTable.propTypes = {
  investments: PropTypes.arrayOf(PropTypes.object),
  edit: PropTypes.bool,
  hideExits: PropTypes.bool,
  onSave: PropTypes.func,
  handleInvesmentChange: PropTypes.func,
  onRemove: PropTypes.func,
};

InvestmentTable.defaultProps = {
  investments: [],
  edit: false,
  hideExits: false,
  onSave: () => undefined,
  handleInvesmentChange: () => undefined,
  onRemove: () => undefined,
};

export default InvestmentTable;
