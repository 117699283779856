/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Paper, Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useIntl } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';

import translations from 'translations';
import { GridTitle } from 'components/Common/Grid';
import { Button, UploadButton } from 'components/Common/Buttons';
import { CompanyDefaultText } from 'components/Company/Reuseable/CompanyDefault';
import { userRoles } from 'user/userConstants';
import { useStyles } from './styles';
import { CompanyReportRow } from './CompanyReportsRow';
import AddReportModal from './AddReportModal';

const CompanyReports = ({
  handleCreateReport,
  companyReports,
  role,
  handleUpdateReport,
  handleDeleteReport,
  viewContent,
  loading,
}) => {
  const uploadRef = React.createRef();
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const dataRef = useRef(data);
  const [updateData, setUpdateData] = useState([]);
  const { formatMessage } = useIntl();

  const filterFeed = (reportData) =>
    reportData.filter((elem) => viewContent.includes(elem.view));

  useEffect(() => {
    const viewableReports = filterFeed(companyReports);
    setData(viewableReports);
    dataRef.current = viewableReports;
  }, [companyReports.length]);

  useEffect(() => {
    if (isEdit) {
      setIsEdit(false);
    }
    const viewableReports = filterFeed(companyReports);
    setData(viewableReports);
    dataRef.current = viewableReports;
  }, [viewContent]);

  const handleOnChangeUpload = (event) => {
    setModalOpen(true);
    setFile(event.target.files[0]);
    event.target.value = '';
  };

  const onClose = () => {
    setModalOpen(false);
  };

  const handleChangeUpdateData = (id, reportData) => {
    const newData = JSON.parse(JSON.stringify(updateData));
    const index = updateData.findIndex((elem) => elem.id === id);
    if (index < 0) {
      newData.push({
        ...reportData,
        id,
      });
    } else {
      newData[index] = {
        ...reportData,
        id,
      };
    }
    setUpdateData(newData);
  };

  return (
    <>
      <div className={`${classes.root}`}>
        <div className={classes.headers}>
          <AddReportModal
            onClose={onClose}
            isOpened={modalOpen}
            file={file}
            handleCreateReport={handleCreateReport}
          />
          <Grid container spacing={3}>
            {/* ROW ONE */}
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
              className={classes.titleBox}
            >
              <GridTitle
                textId={translations.Report.reports.id}
                text={translations.Report.reports.defaultMessage}
                classStyles={classes.gridTitle}
              />
              {role !== userRoles.VIEWER ? (
                <Button
                  className={classes.editBtn}
                  style={{
                    minWidth: 63,
                  }}
                  typeColor="default"
                  variant="text"
                  id="edit-company-report"
                  onClick={() => {
                    if (isEdit) {
                      handleUpdateReport(updateData);
                    }
                    setIsEdit(!isEdit);
                  }}
                >
                  {`${formatMessage(
                    isEdit
                      ? translations.Common.common.save
                      : translations.Common.common.edit
                  )}`}
                </Button>
              ) : (
                <div />
              )}
            </Box>
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={3}>
                {companyReports.length || isEdit ? (
                  <div>
                    <Grid
                      style={{
                        display: dataRef.current.length ? 'block' : 'none',
                      }}
                      item
                      xs={12}
                    >
                      {loading ? (
                        <Skeleton
                          variant="rect"
                          style={{ width: 225, height: 37 }}
                        />
                      ) : (
                        <TextField
                          style={{ width: 225 }}
                          value={search}
                          onChange={(event) => setSearch(event.target.value)}
                          size="small"
                          label="Search"
                          variant="outlined"
                        />
                      )}
                    </Grid>
                    <br />
                    <br />
                    <Grid item xs={12} md={10} lg={10}>
                      <Box
                        display={
                          !role || role === userRoles.VIEWER ? 'none' : 'flex'
                        }
                        justifyContent="space-between"
                        flexDirection="row"
                      >
                        {loading ? (
                          <Skeleton
                            variant="rect"
                            style={{ width: '120px', height: '110px' }}
                          />
                        ) : (
                          <UploadButton
                            uploadRef={uploadRef}
                            handleOnChangeUpload={handleOnChangeUpload}
                            onClickHandler={() => {
                              if (uploadRef.current) uploadRef.current.click();
                            }}
                          />
                        )}
                        <div className={classes.scrollable}>
                          {loading ? (
                            <>
                              <Skeleton
                                variant="rect"
                                style={{
                                  height: 50,
                                  width: '100%',
                                  marginBottom: 25,
                                }}
                              />
                              <Skeleton
                                variant="rect"
                                style={{ height: 50, width: '100%' }}
                              />
                            </>
                          ) : (
                            dataRef.current
                              .filter((elem) =>
                                elem.name
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              )
                              .map((item, index) => (
                                <CompanyReportRow
                                  item={item}
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={`${item.text}_${index}`}
                                  isEdit={isEdit}
                                  handleDeleteReport={(value) => {
                                    handleDeleteReport(value);
                                    setIsEdit(false);
                                  }}
                                  handleChangeUpdateData={
                                    handleChangeUpdateData
                                  }
                                />
                              ))
                          )}
                        </div>
                      </Box>
                    </Grid>
                  </div>
                ) : (
                  <CompanyDefaultText text="No Report added yet" />
                )}
              </Paper>
            </Grid>
            {/* END OF ROW ONE */}
          </Grid>
        </div>
      </div>
    </>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { CompanyReports };

CompanyReports.propTypes = {
  role: PropTypes.string,
  handleUpdateReport: PropTypes.func,
  handleDeleteReport: PropTypes.func,
  companyReports: PropTypes.shape([]),
  handleCreateReport: PropTypes.func,
  viewContent: PropTypes.arrayOf(PropTypes.string),
};

CompanyReports.defaultProps = {
  role: userRoles.VIEWER,
  handleUpdateReport: null,
  handleDeleteReport: null,
  companyReports: [],
  handleCreateReport: () => {},
  viewContent: [],
};
