import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  title: {
    color: '#212121',
  },
}));
