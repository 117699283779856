import { createSelector } from '@reduxjs/toolkit';
import { Organization, PortfolioShort } from 'types';
import { selectSelectedOrganization } from 'containers/Organisation/ducks';

export const selectorPortfolio = (
  state: Organization | null
): PortfolioShort => {
  if (!state)
    return {
      items: [],
      totalCount: 0,
    };
  return state.portfolios;
};

export const selectSelectedOrgPortfolios = createSelector(
  selectSelectedOrganization,
  selectorPortfolio
);
