import { gql } from 'apollo-boost';
import {
  ORGANISATION_PAYLOAD,
  COMPANY_PAYLOAD_SHORT,
  CONTACT_PAYLOAD,
  INVESTMENT_PAYLOAD,
  PORTFOLIO_PAYLOAD,
  INVESTMENT_COUNT_PAYLOAD,
  COMPANIED_PAYLOAD,
  USER_PAYLOAD,
} from 'graphql/payloads';

export const GET_SELECTED_ORGANIZATION_QUERY = gql`
  query getSelectedOrganisation($organisationId: ID!) {
    organisation(organisationId: $organisationId) {
      ${ORGANISATION_PAYLOAD}
      companySettings {
        field
        status
        sorting
      }
      companies {
        ${COMPANY_PAYLOAD_SHORT}
        report{
          name
          url
        }
        contacts{
          ${CONTACT_PAYLOAD}
        }
        investments {
          ${INVESTMENT_PAYLOAD}
          portfolio{
            ${PORTFOLIO_PAYLOAD}
          }
        }
      }
      portfolios {
        totalCount
        url
        items {
          ${PORTFOLIO_PAYLOAD}
          investments {
            ${INVESTMENT_COUNT_PAYLOAD}
            items {
              ${INVESTMENT_PAYLOAD}
              company {
                id
                name
              }
            }
          }
        }
      }
      users {
        ${USER_PAYLOAD}
        role
      }
    }
  }
`;

export const GET_ORGANISATIONS_QUERY = gql`
  query getOrganisations {
    organisations {
      ${COMPANIED_PAYLOAD}
    }
  }
`;

export const CREATE_ORGANISATION = gql`
  mutation($name: String!, $type: String!) {
    createOrganisation(name: $name, type: $type) {
      ${ORGANISATION_PAYLOAD}
      portfolios {
        totalCount
        url
        items {
          ${PORTFOLIO_PAYLOAD}
          investments {
            ${INVESTMENT_COUNT_PAYLOAD}
          }
        }
      }
      companies {
        ${COMPANY_PAYLOAD_SHORT}
        organisation{
          id
          name
        }
        portfolio {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_ORGANISATION = gql`
  mutation($id: ID!, $isActive: Boolean, $currency: String, $name: String) {
    updateOrganisation(
      id: $id
      isActive: $isActive
      currency: $currency
      name: $name
    ) {
      ${ORGANISATION_PAYLOAD}
    }
  }
`;

export const UPDATE_COMPANY_SETTINGS = gql`
  mutation updateCompanySetting($input: UpdateCompanySettingInput!) {
    updateCompanySetting(input: $input)
  }
`;

export const GET_KPI_DEFAULT_VALUES = gql`
  query getKpiDefaultValues($input: GetKpiValueBulkInput!) {
    getKpiDefaultValues(input: $input) {
      data {
        metric
        unit
      }
      periodicity
    }
  }
`;

export const UPDATE_DEFAULT_KPI = gql`
  mutation updateDefaultKPI($input: kpiDefaultValuesPayload!) {
    updateDefaultKPI(input: $input) {
      data {
        metric
        unit
      }
      periodicity
    }
  }
`;
