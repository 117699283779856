import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  UpdateSubscriptionPayload,
  Subscription,
  Subscriptions,
  CancelSubscriptionPaylod,
  SubscriptionStatusPayload,
} from 'types';
import {
  selectSelectedOrganization,
  setAccessLimit,
} from 'containers/Organisation';
import translations from 'translations';
import {
  showSubscriptionAlert,
  checkLimitExceeded,
} from 'components/Subscriptions/SubscriptionStatus';
import { ORGANISATION_TYPES, SUBSCRIPTION_TYPES } from 'constants/index';
import {
  selectSubscriptionsByOrg,
  SubscriptionThunk,
  selectSubscriptionAccessLimit,
} from './ducks';

export const useSubscriptionContainer = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const selectedOrganization = useSelector(selectSelectedOrganization);
  const subscriptionByOrganisation = useSelector(selectSubscriptionsByOrg);
  const accessLimit = useSelector(selectSubscriptionAccessLimit);
  const [subscriptions, setSubscriptions] = useState<Subscriptions>([]);
  const [
    subscriptionAlert,
    setSubscriptionAlert,
  ] = useState<SubscriptionStatusPayload | null>(null);

  const handleSubscriptionAlert = (paramSubscriptions: Subscriptions): void => {
    const alert = showSubscriptionAlert(
      paramSubscriptions,
      selectedOrganization.type,
      {
        companies: selectedOrganization.companies?.length || 0,
        portfolios: selectedOrganization.portfolios?.items?.length || 0,
        users: selectedOrganization.users?.length || 0,
      }
    );
    setSubscriptionAlert(alert);
  };

  const updateSubscription = async (
    payload: UpdateSubscriptionPayload
  ): Promise<Subscription | null> => {
    try {
      const res = (await new Promise((resolve, reject) =>
        dispatch(
          SubscriptionThunk.updateSubscription(payload, {
            successMsg: formatMessage(
              translations.OrganisationSettings.Subscription.Billing
                .onSuccessUpdateSubscription
            ),
            reject,
            resolve,
          })
        )
      )) as Subscription;
      return res;
    } catch (error) {
      return null;
    }
  };

  const cancelSubscription = async ({
    subscriptionId,
  }: CancelSubscriptionPaylod): Promise<boolean> => {
    try {
      const res = (await new Promise((resolve, reject) =>
        dispatch(
          SubscriptionThunk.cancelSubscription(
            { subscriptionId },
            {
              successMsg: formatMessage(
                translations.OrganisationSettings.Subscription.Billing
                  .onSuccessCancelSubscription
              ),
              reject,
              resolve,
            }
          )
        )
      )) as boolean;
      return res;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (!selectedOrganization?.id || !Array.isArray(subscriptionByOrganisation))
      return;
    setSubscriptions(JSON.parse(JSON.stringify(subscriptionByOrganisation)));
    handleSubscriptionAlert(subscriptionByOrganisation);
    const paidSub = subscriptionByOrganisation.find(
      (val) => val.type !== SUBSCRIPTION_TYPES.FREE
    );
    const freeSub = subscriptionByOrganisation.find(
      (val) => val.type === SUBSCRIPTION_TYPES.FREE
    );
    const limit = checkLimitExceeded({
      freeSubscription: freeSub || null,
      paidSubscription: paidSub || null,
      companies: selectedOrganization.companies?.length,
      portfolios: selectedOrganization.portfolios?.items?.length,
      users: selectedOrganization.users?.length,
    });
    dispatch(setAccessLimit(limit));
  }, [subscriptionByOrganisation, JSON.stringify(selectedOrganization)]);

  useEffect(() => {
    if (
      selectedOrganization.id &&
      selectedOrganization.type !== ORGANISATION_TYPES.COMPANY
    ) {
      dispatch(
        SubscriptionThunk.getSubscriptionsByOrganization(
          selectedOrganization.id
        )
      );
    }
  }, [selectedOrganization?.id]);

  return {
    updateSubscription,
    subscriptions,
    cancelSubscription,
    subscriptionAlert,
    accessLimit,
  };
};
