import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './styles';
import cutCamelCase from '../../../common/cutCamelCase';
import BlockTitle from './BlockTitle';

const SummaryBlock = ({ completeSummary, loading }) => {
  const classes = useStyles();

  const SummaryField = ({ title, value }) => (
    <Grid
      className={classes.summaryContainer}
      container
      direction="column"
      xs={3}
      item
    >
      <Typography className={classes.summaryLabel}>{title}</Typography>
      <Typography className={classes.summaryContent}>{value}</Typography>
    </Grid>
  );

  SummaryField.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
  };
  SummaryField.defaultProps = {
    title: '',
    value: '',
  };

  return (
    <div className={classes.root}>
      <BlockTitle title="Summary" />
      <Paper
        className={`${classes.paperWrapper} ${classes.summaryPaper}`}
        elevation={2}
      >
        <Grid container direction="row">
          {!loading ? (
            Object.entries(completeSummary).map((item) => (
              <SummaryField
                title={cutCamelCase(item[0])}
                value={item[1]}
                key={item[0]}
              />
            ))
          ) : (
            <Skeleton
              width="100%"
              variant="rect"
              className={classes.summarySkeleton}
            />
          )}
        </Grid>
      </Paper>
    </div>
  );
};

SummaryBlock.propTypes = {
  completeSummary: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

SummaryBlock.defaultProps = {
  loading: false,
};

export default SummaryBlock;
