import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import i18n from 'translations';
import {
  ORGANISATION_TYPES,
  Colors,
  SUBSCRIPTION_TYPES,
  SUBSCRIPTION_STATUS_CODES,
} from 'constants/index';
import {
  SubscriptionStatusPayload,
  Subscriptions,
  Subscription,
  SubscriptionLimitResponse,
} from 'types';

const getDateDiff = (
  dateExpire: Date
): { dateDiffDays: number; dateDiffHours: number } => {
  const today = moment();
  const expired = moment(dateExpire);
  const dateDiffDays = expired.diff(today, 'days') + 1;
  const dateDiffHours = expired.diff(today, 'hours');
  return { dateDiffDays, dateDiffHours };
};

const getAlertResponse = (
  color: string,
  message: ReactNode,
  statusCode?: number,
  days?: number,
  hours?: number
): SubscriptionStatusPayload => ({
  color,
  message,
  statusCode,
  days,
  hours,
});

export const checkLimitExceeded = ({
  freeSubscription,
  paidSubscription,
  companies,
  portfolios,
  users,
}: {
  paidSubscription: Subscription | null;
  freeSubscription: Subscription | null;
  users: number;
  companies: number;
  portfolios: number;
}): SubscriptionLimitResponse => {
  const response: SubscriptionLimitResponse = {
    companies: false,
    users: false,
    portfolios: false,
    hasLimit: false,
    hasTrial: false,
  };

  if (!freeSubscription) return response;

  const { dateExpire } = freeSubscription;
  const { dateDiffDays, dateDiffHours } = getDateDiff(dateExpire);
  response.hasTrial =
    (dateDiffDays > 0 || dateDiffHours > 0) && !paidSubscription;

  if (!paidSubscription) return response;

  const { type } = paidSubscription;
  switch (type) {
    case SUBSCRIPTION_TYPES.ENTERPRISE: {
      response.users = true;
      response.portfolios = true;
      response.companies = true;
      break;
    }
    case SUBSCRIPTION_TYPES.MONTHLY:
    case SUBSCRIPTION_TYPES.ANNUAL: {
      // starter pack
      response.users = users <= 1;
      response.portfolios = portfolios <= 1;
      response.companies = companies <= 10;
      break;
    }
    case SUBSCRIPTION_TYPES.MONTHLY_PRO:
    case SUBSCRIPTION_TYPES.ANNUAL_PRO: {
      // pro pack
      response.users = users <= 5;
      response.portfolios = portfolios <= 1;
      response.companies = companies <= 20;
      break;
    }
    default: {
      response.users = false;
      response.portfolios = false;
      response.companies = false;
    }
  }
  response.hasLimit =
    response.companies && response.portfolios && response.users;
  return response;
};

export const showSubscriptionAlert = (
  subscriptions: Subscriptions,
  orgType: string,
  limitPaylod: {
    users: number;
    companies: number;
    portfolios: number;
  }
): SubscriptionStatusPayload | null => {
  if (orgType === ORGANISATION_TYPES.COMPANY) {
    // case 5: If it is type company
    return getAlertResponse(
      Colors.TEA_GREEN,
      <FormattedMessage
        {...i18n.OrganisationSettings.Subscription.alert.five}
      />,
      SUBSCRIPTION_STATUS_CODES.TYPE_COMPANY
    );
  }

  if (!Array.isArray(subscriptions) || !subscriptions.length) {
    return null;
  }

  let freeSubscription: Subscription | null = null;
  let paidSubscription: Subscription | null = null;

  subscriptions.forEach((subscription) => {
    if (subscription.type === SUBSCRIPTION_TYPES.FREE) {
      freeSubscription = subscription;
    } else {
      paidSubscription = subscription;
    }
  });

  if (!freeSubscription) return null;

  const { dateExpire } = freeSubscription;
  const { dateDiffDays, dateDiffHours } = getDateDiff(dateExpire);
  let dateDiff = '';

  if (dateDiffDays > 0) {
    dateDiff = `${dateDiffDays} days`;
  } else if (dateDiffHours > 0) {
    dateDiff = `${dateDiffHours} hours`;
  }

  if (!paidSubscription && dateDiff) {
    // case 1 show trial days remaining
    return getAlertResponse(
      Colors.TEA_GREEN,
      <FormattedMessage
        id="OrganisationSettings.Subscription.alert.one"
        defaultMessage={`You are on a trial subscription expiring in ${dateDiff} - Remaining trial days will be added to any new subscriptions`}
      />,
      SUBSCRIPTION_STATUS_CODES.TRIAL_EXPIRING,
      dateDiffDays,
      dateDiffHours
    );
  }

  if (!paidSubscription) {
    // case 2, no paid subscription and trial expired
    return getAlertResponse(
      Colors.DUTCH_WHITE,
      <FormattedMessage
        {...i18n.OrganisationSettings.Subscription.alert.two}
      />,
      SUBSCRIPTION_STATUS_CODES.NO_PAID,
      dateDiffDays,
      dateDiffHours
    );
  }

  const { hasLimit, hasTrial } = checkLimitExceeded({
    ...limitPaylod,
    freeSubscription,
    paidSubscription,
  });

  // cancel trial if paid to subscription
  // so not show the trial status
  // if (!hasLimit && hasTrial) {
  //   // case 1 show trial days remaining
  //   return getAlertResponse(
  //     Colors.TEA_GREEN,
  //     <FormattedMessage
  //       id="OrganisationSettings.Subscription.alert.one"
  //       defaultMessage={`You are on a trial subscription expiring in ${dateDiff} - Remaining trial days will be added to any new subscriptions`}
  //     />,
  //     SUBSCRIPTION_STATUS_CODES.TRIAL_EXPIRING,
  //     dateDiffDays,
  //     dateDiffHours
  //   );
  // }

  // case 3 check the limit exceeding
  if (!hasLimit) {
    return getAlertResponse(
      Colors.DUTCH_WHITE,
      <FormattedMessage
        {...i18n.OrganisationSettings.Subscription.alert.three}
      />,
      SUBSCRIPTION_STATUS_CODES.LIMIT_EXCEEDING,
      dateDiffDays,
      dateDiffHours
    );
  }

  // case 4, all subscriptions is upto date
  return getAlertResponse(
    Colors.TEA_GREEN,
    <FormattedMessage {...i18n.OrganisationSettings.Subscription.alert.four} />,
    SUBSCRIPTION_STATUS_CODES.ALL_UPDATED,
    dateDiffDays,
    dateDiffHours
  );
};
