import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import ManageOrganisationTable from './ManageOrganisationTable';
import { Button } from '../../Common/Buttons';
import messages from './messages';

const ManageOrganisation = ({
  openModal,
  data,
  deleteRecord,
  onUpdateOrganisation,
  onUpdateOrganisationRole,
}) => {
  const intl = useIntl();
  return (
    <>
      <Typography variant="h6" gutterBottom>
        {intl.formatMessage(messages.manageOrganisation)}
      </Typography>
      <div style={{ width: 185, marginBottom: 20 }}>
        <Button onClick={openModal}>
          {intl.formatMessage(messages.addOrganisation)}
        </Button>
      </div>
      <ManageOrganisationTable
        showContacts
        data={data}
        deleteRecord={deleteRecord}
        onUpdateOrganisation={onUpdateOrganisation}
        onUpdateOrganisationRole={onUpdateOrganisationRole}
      />
    </>
  );
};

ManageOrganisation.propTypes = {
  openModal: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  deleteRecord: PropTypes.func,
  onUpdateOrganisation: PropTypes.func,
  onUpdateOrganisationRole: PropTypes.func,
};

ManageOrganisation.defaultProps = {
  openModal: () => {},
  data: [],
  deleteRecord: () => {},
  onUpdateOrganisation: () => {},
  onUpdateOrganisationRole: () => {},
};

// eslint-disable-next-line import/prefer-default-export
export { ManageOrganisation };
