import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  title: {
    margin: '5px 0',
    color: '#363636',
    letterSpacing: 1,
    fontWeight: 400,
  },
}));
