import React from 'react';
import {
  Select,
  MenuItem,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import { Checkbox } from 'components/Common/Checkbox';
import { SelectorProps } from 'types';
import { EmptyComponent } from 'components/Common/UI/EmptyComponent';
import { useStyle } from './styles';

export default ({
  options,
  selected,
  handleChange,
  reset = false,
  renderValue,
  showHeader = true,
  fullWidth = false,
}: SelectorProps) => {
  const classes = useStyle();

  const renderOptions = (): Array<JSX.Element | null> => {
    const element: Array<JSX.Element | null> = [];
    let index = reset ? 0 : -1;
    options.forEach((ele) => {
      const { header, options: values } = ele;
      if (showHeader && header && values.length)
        element.push(
          <ListSubheader
            key="list-header"
            classes={{
              root: classes.companySelectorOptionHeader,
            }}
          >
            {header}
          </ListSubheader>
        );
      const newOptions = values.map((elem) => {
        if (elem.key === 0 && reset) {
          element.unshift(
            <MenuItem key="0" value={-1}>
              <ListItemText
                classes={{
                  primary: classes.companySelectorOptionHeader,
                }}
                primary={`${elem.name}${
                  elem.data.length > 1 ? ` (${elem.data.length})` : ''
                }`}
              />
            </MenuItem>
          );
          return null;
        }
        index += 1;
        return (
          <MenuItem key={elem.key} value={index}>
            <Checkbox
              name="check1"
              checked={selected.some((el) => el === index)}
            />
            <ListItemText
              primary={`${elem.name}${
                elem.data.length > 1 ? ` (${elem.data.length})` : ''
              }`}
            />
          </MenuItem>
        );
      });
      element.push(...newOptions);
    });
    return element.length ? element : [<EmptyComponent />];
  };

  const filterCorruptData = (event: React.ChangeEvent<{ value: any }>) => {
    const {
      target: { value },
    } = event;
    const filteredSelected = value.filter(
      (ele: number | undefined) => ele !== undefined
    );
    handleChange(filteredSelected);
  };

  return (
    <Select
      id="Company-selector-select"
      value={selected} // because default value does not work when we have a controlled component
      onChange={filterCorruptData}
      renderValue={renderValue}
      variant="outlined"
      multiple
      fullWidth={fullWidth}
      classes={{
        root: classes.companySelectorSelect,
      }}
      // disabled={Boolean(options.length)}
      displayEmpty={false}
      MenuProps={{
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        getContentAnchorEl: null,
        PaperProps: {
          style: {
            maxHeight: 325,
            maxWidth: 250,
          },
        },
      }}
    >
      {renderOptions()}
    </Select>
  );
};
