import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setAlert } from 'containers/Config';
import { Typography, Grid, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SelectInput } from 'components/Common/Select';
import { Colors } from '../../../constants/colors';
import messages from './messages';
import FileInputField from './FileInputField';
import { EXCEL_FILE_TYPE, SECTIONS, IMPORT_FROMAT } from '../../../constants';
import { CompanyDefaultText } from './CompanyDefault';
import CompanySpecificSelector from './ExportImportComponent/CompanySpecificDropDown';

const Import = ({ importFromExcelSheet, importFromGS, onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [value, setValue] = React.useState('All');
  const [optionValue, setOptionsValue] = useState(SECTIONS);
  const [formatOption, setFormatOptions] = useState(IMPORT_FROMAT[0].name);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleOptionsChange = (event) => {
    setOptionsValue(event.target.value);
  };
  const handleFormatChange = (event) => {
    setFormatOptions(event.target.value);
  };
  const useStyle = makeStyles({
    root: {
      flexGrow: 1,
    },
    modelWidth: {
      maxWidth: '580px !important',
    },

    lowerCapText: {
      textTransform: 'initial',
    },
    labelStyle: {
      minWidth: '0%',
      marginRight: '10px',
      color: '#363636',
    },
    selectInput: {
      alignSelf: 'center',
      width: '40.5%',
      '& >div': {
        // height: 25,
      },
      '& fieldset': {
        borderColor: Colors.TEXT,
      },
    },
    select: {
      padding: '5px',
      paddingLeft: '8px',
    },
  });
  const classes = useStyle();
  const [file, setFile] = useState(null);
  const [fileLink, setFileLink] = useState('');

  const onFileChangeHandler = (event) => {
    const {
      target: { files },
    } = event;
    if (files[0].type === EXCEL_FILE_TYPE) {
      setFile(files[0]);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    } else {
      dispatch(
        setAlert({
          open: true,
          message: messages.excelImportError,
          severity: 'error',
        })
      );
    }
  };

  const handleImport = async () => {
    let response = null;
    switch (formatOption) {
      case IMPORT_FROMAT[0].name: {
        response = await importFromExcelSheet({ file, optionValue });
        break;
      }
      case IMPORT_FROMAT[1].name: {
        response = await importFromGS({ link: fileLink, optionValue, value });
        break;
      }
      default:
        break;
    }
    onClose();
    dispatch(
      setAlert({
        open: true,
        message: response.status
          ? messages.importSuccess
          : typeof response.filename === 'string'
          ? response.filename
          : messages.importFail,
        severity: response.status ? 'success' : 'error',
      })
    );
  };

  return (
    <div style={{ width: 580, height: 366 }}>
      <Grid
        container
        // style={useAsModal ? modalStyle : {}}
        className={`${classes.modelWidth} `}
        direction="column"
        spacing={1}
      >
        <Grid container xs={12} style={{ marginBottom: 15 }}>
          <Typography variant="h5">
            {intl.formatMessage(messages.import)}
          </Typography>
        </Grid>

        <Grid container style={{ marginBottom: 20 }}>
          <Grid item xs={2}>
            <Typography>{intl.formatMessage(messages.source)}</Typography>
          </Grid>
          <Grid item xs={9}>
            <div className={classes.selectInput}>
              <SelectInput
                value={formatOption}
                fullWidth
                onChange={handleFormatChange}
                options={IMPORT_FROMAT}
                customClasses={{
                  select: classes.select,
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: 10 }}>
          <Grid item xs={2}>
            <Typography>{intl.formatMessage(messages.file)}</Typography>
          </Grid>
          <Grid item xs={9}>
            {formatOption === IMPORT_FROMAT[1].name ? (
              <>
                <TextField
                  style={{ marginBottom: 10, width: '100%' }}
                  variant="outlined"
                  size="small"
                  value={fileLink}
                  onChange={(event) => setFileLink(event.target.value)}
                />
                <CompanyDefaultText
                  text={`${
                    process.env.NODE_ENV === 'production'
                      ? intl.formatMessage(messages.driveLinkMessage)
                      : intl.formatMessage(messages.defaultDriveLinkMessage)
                  } `}
                />
              </>
            ) : (
              <FileInputField
                fileChangeHandler={onFileChangeHandler}
                fileName={
                  file ? file.name : intl.formatMessage(messages.uploadFile)
                }
              />
            )}
          </Grid>
        </Grid>
        <CompanySpecificSelector
          value={value}
          handleChange={handleChange}
          optionValue={optionValue}
          handleOptionsChange={handleOptionsChange}
        />
        {formatOption !== IMPORT_FROMAT[1].name && (
          <>
            <div style={{ height: 114 }} />
          </>
        )}
        <Grid item>
          <Button
            style={{
              borderRadius: 20,
              width: 160,
              marginTop: 15,
            }}
            classes={{
              label: classes.lowerCapText,
            }}
            variant="contained"
            color="primary"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleImport}
          >
            {intl.formatMessage(messages.import)}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

Import.propTypes = {
  importFromExcelSheet: PropTypes.func.isRequired,
  importFromGS: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Import;
