import { defineMessages } from 'react-intl';

export default defineMessages({
  requestData: {
    id: 'RequestData.RequestData',
    defaultMessage: 'Request Data',
  },
  filter: {
    id: 'RequestData.Filter',
    defaultMessage: 'Filter',
  },
  companies: {
    id: 'RequestData.Companies',
    defaultMessage: 'Companies',
  },
  lastUpdate: {
    id: 'RequestData.LastUpdate',
    defaultMessage: 'Last Update',
  },
  highlightOutstanding: {
    id: 'RequestData.HighlightOutstanding',
    defaultMessage: 'Highlight outstanding',
  },
  draftRequest: {
    id: 'RequestData.DraftRequest',
    defaultMessage: 'Draft Request',
  },
  multipleRequest: {
    id: 'RequestData.multipleRequest',
    defaultMessage: 'Bulk Request',
  },
  name: {
    id: 'RequestData.Name',
    defaultMessage: 'Name',
  },
  investment: {
    id: 'RequestData.Investment',
    defaultMessage: 'Investment',
  },
  ownership: {
    id: 'RequestData.Ownership',
    defaultMessage: 'Ownership',
  },
  involvement: {
    id: 'RequestData.Involvement',
    defaultMessage: 'Involvement',
  },
  feed: {
    id: 'RequestData.Feed',
    defaultMessage: 'Feed Update',
  },
  reports: {
    id: 'RequestData.Reports',
    defaultMessage: 'Reports Update',
  },
  metrics: {
    id: 'RequestData.Metrics',
    defaultMessage: 'Metrics Update',
  },
  contact: {
    id: 'RequestData.Contact',
    defaultMessage: 'Company contacts',
  },
  secContact: {
    id: 'RequestData.Contact',
    defaultMessage: '2nd company contact',
  },
  investmentManager: {
    id: 'RequestData.InvestmentManager',
    defaultMessage: 'Investment Manager',
  },
  recipients: {
    id: 'RequestData.recipients',
    defaultMessage: 'Recipients',
  },
  action: {
    id: 'RequestData.Action',
    defaultMessage: 'Action',
  },
  to: {
    id: 'RequestData.To',
    defaultMessage: 'To',
  },
  cc: {
    id: 'RequestData.CC',
    defaultMessage: 'CC',
  },
  companyContact: {
    id: 'RequestData.CompanyContact',
    defaultMessage: 'Company Contact',
  },
  secondCompanyContact: {
    id: 'RequestData.CompanyContact',
    defaultMessage: '2nd Company Contact',
  },
  requestItems: {
    id: 'RequestData.RequestItems',
    defaultMessage: 'Request Items',
  },
  send: {
    id: 'RequestData.Send',
    defaultMessage: 'Send',
  },
  subject: {
    id: 'RequestData.Subject',
    defaultMessage: 'Subject',
  },
  atLeastOneCompany: {
    id: 'RequestData.AtLeastOneCompany',
    defaultMessage: 'Please select at least one company',
  },
  info: {
    id: 'RequestData.Info',
    defaultMessage: 'Info',
  },
  lowInvolvement: {
    id: 'RequestData.lowInvolvement',
    defaultMessage: 'Low',
  },
  middleInvolvement: {
    id: 'RequestData.middleInvolvement',
    defaultMessage: 'Middle',
  },
  highInvolvement: {
    id: 'RequestData.highInvolvement',
    defaultMessage: 'High',
  },
  contacts: {
    id: 'RequestData.contacts',
    defaultMessage: 'Contacts',
  },
});
