import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';

const InfoBox = ({ data, style }) => {
  const noDataCompanies = data.filter(
    (item) =>
      (item.cashFlow === 'N/A' && item.investment === 'N/A') ||
      (item.investment !== 'N/A' && item.cashFlow === 'N/A')
  );

  return (
    <>
      {noDataCompanies.length ? (
        <Fade in={noDataCompanies} timeout={1000}>
          <Alert classes={style} severity="info">
            There is no data for these companies yet —
            <strong>
              {noDataCompanies.map((item) => item.name).join(', ')}
            </strong>
          </Alert>
        </Fade>
      ) : (
        ''
      )}
    </>
  );
};

export default InfoBox;

InfoBox.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.object,
};

InfoBox.defaultProps = {
  style: [],
};
