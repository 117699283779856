import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  switchBase: {
    color: '#0C696A',
    '&$checked': {
      color: '#0C696A',
    },
    '&$checked + $track': {
      backgroundColor: '#0C696A',
    },
  },
  track: {},
  checked: {
    color: 'rgba(0,0,0,0.6)',
  },
  formControlCol: {
    width: '44%',
  },
}));
