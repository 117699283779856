import {
  DocumentNode,
  ApolloClient,
  NormalizedCache,
  ApolloQueryResult,
} from 'apollo-boost';

type FetchPolicy =
  | 'cache-first'
  | 'network-only'
  | 'cache-only'
  | 'no-cache'
  | 'standby';

export class GraphqlService {
  private client: ApolloClient<NormalizedCache>;

  constructor(client?: ApolloClient<NormalizedCache>) {
    this.client = client || global.apolloClient;
  }

  async mutation<RT>(mutation: DocumentNode, variables: unknown): Promise<RT> {
    return this.client.mutate({ mutation, variables }) as Promise<RT>;
  }

  async query<RT = any, VT = any>(
    query: DocumentNode,
    variables?: VT,
    fetchPolicy: FetchPolicy = 'network-only'
  ): Promise<ApolloQueryResult<RT>> {
    return this.client.query<RT, VT | {}>({
      query,
      fetchPolicy,
      variables: variables || {},
    });
  }
}
