/* eslint-disable no-mixed-operators */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { Paper, Grid, Typography, CssBaseline, Box } from '@material-ui/core';
import { useIntl } from 'react-intl';
import PropType from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

import { GridTitle } from '../Common/Grid';
import { useStyles } from './styles';
import messages from './messages';
import { CustomBox } from './Box';
import { CustomBox2 } from './Box2';

const UpdatesScreen = ({ statusData }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { id } = useParams();
  const history = useHistory();

  const [reports, setReports] = useState([]);
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    if (Object.keys(statusData).length) {
      const totalUpdate =
        (statusData.updates &&
          statusData.updates.reduce(
            (prev, next) => (prev + next && next.time) || 0,
            0
          )) ||
        0;
      const averageUpdate =
        (statusData.updates &&
          statusData.updates.length &&
          Math.round(totalUpdate / statusData.updates.length)) ||
        0;

      setReports([
        { detail: 'companies tracked', total: statusData.companyTracked },
        {
          detail: 'contacts',
          total: statusData.totalManageProfiles,
        },
        { detail: 'datapoints', total: statusData.tolalPoints },
        {
          detail: 'days on average since updates on company page',
          total: averageUpdate,
        },
      ]);
      setUpdates(statusData.updates);
    }
  }, [statusData]);

  const handleOnAction = (source, type, item) =>
    history.push(`/organisations/${id}/companies/${item.companyId}`);

  return (
    <>
      <CssBaseline />
      <div className={`${classes.root}`}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                component="h3"
                variant="h4"
                color="primary"
                gutterBottom
              >
                {`${formatMessage(messages.updatesScreenTitle)}`}
              </Typography>
            </Box>
          </Grid>
          {/* ROW ONE */}
          <Grid item xs={12} className={classes.grid}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <GridTitle
                textId={messages.updatesScreenReportsTitle.id}
                text={messages.updatesScreenReportsTitle.defaultMessage}
              />
              <Paper className={`${classes.paper} ${classes.customScroll}`}>
                {reports.map((item, index) => (
                  <CustomBox
                    key={index}
                    item={{
                      title: `${item.total}`,
                      detail: item.detail,
                      position: index,
                    }}
                    spanClass={classes.count}
                    iconColor="action"
                  />
                ))}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <GridTitle
                textId={messages.updatesScreenRecentUpdatesTitle.id}
                text={messages.updatesScreenRecentUpdatesTitle.defaultMessage}
              />
              <Paper className={`${classes.paper} ${classes.customScroll}`}>
                {updates
                  .filter((ele) => ele.time < 10)
                  .map((item, index) => (
                    <CustomBox2
                      key={index}
                      item={item}
                      index={index}
                      spanClass={classes.statusOk}
                      handleOnAction={handleOnAction}
                    />
                  ))}
              </Paper>
            </Grid>
          </Grid>
          {/* END OF ROW ONE */}

          {/* ROW TWO */}
          <Grid item xs={12} className={classes.grid}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <GridTitle
                textId={messages.updatesScreenFollowUpSuggestionTitle.id}
                text={
                  messages.updatesScreenFollowUpSuggestionTitle.defaultMessage
                }
              />
              <Paper className={`${classes.paper} ${classes.customScroll}`}>
                {updates
                  .filter((ele) => ele.time >= 10)
                  .map((item, index) => (
                    <CustomBox2
                      key={index}
                      item={item}
                      index={index}
                      handleOnAction={handleOnAction}
                      spanClass={
                        item.time > 100 ? classes.alert : classes.warning
                      }
                    />
                  ))}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <GridTitle
                textId={messages.updatesScreenNotificationTitle.id}
                text={messages.updatesScreenNotificationTitle.defaultMessage}
              />
              <Paper className={`${classes.paper} ${classes.customScroll}`}>
                <Typography variant="body2" gutterBottom>
                  {formatMessage(messages.comingSoon)}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          {/* END OF ROW TWO */}
        </Grid>
      </div>
    </>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { UpdatesScreen };

UpdatesScreen.propTypes = {
  statusData: PropType.object,
};

UpdatesScreen.defaultProps = {
  statusData: {},
};
