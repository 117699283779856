import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'KpiPage.title',
    defaultMessage: 'KPIs',
  },
  allCompaniesTitle: {
    id: 'AllCompanies.allCompaniesTitle',
    defaultMessage: 'All Companies',
  },
  requestData: {
    id: 'CashRunway.RequestData',
    defaultMessage: 'Request Data',
  },
  filter: {
    id: 'CashRunway.Filter',
    defaultMessage: 'Filter',
  },
  companies: {
    id: 'CashRunway.Companies',
    defaultMessage: 'Companies',
  },
  lastUpdate: {
    id: 'CashRunway.LastUpdate',
    defaultMessage: 'Last Update',
  },
  highlightOutstanding: {
    id: 'CashRunway.HighlightOutstanding',
    defaultMessage: 'Highlight outstanding',
  },
  draftRequest: {
    id: 'CashRunway.DraftRequest',
    defaultMessage: 'Draft Request',
  },
  name: {
    id: 'KpiPage.Name',
    defaultMessage: 'Company',
  },
  company: {
    id: 'AllCompanies.name',
    defaultMessage: 'Company',
  },
  investment: {
    id: 'CashRunway.Investment',
    defaultMessage: 'Investment',
  },
  ownership: {
    id: 'CashRunway.Ownership',
    defaultMessage: 'Ownership',
  },
  currency: {
    id: 'CashRunway.currency',
    defaultMessage: 'Currency',
  },
  report: {
    id: 'AllCompanies.report',
    defaultMessage: 'Latest Report',
  },
  cash: {
    id: 'CashRunway.Cash',
    defaultMessage: 'Cash',
  },
  cashflow: {
    id: 'CashRunway.Cashflow',
    defaultMessage: 'Cashflow (monthly)',
  },
  runway: {
    id: 'CashRunway.Runway',
    defaultMessage: 'Runway',
  },
  contact: {
    id: 'CashRunway.Contact',
    defaultMessage: 'Contact',
  },
  investmentManager: {
    id: 'CashRunway.InvestmentManager',
    defaultMessage: 'Investment Manager',
  },
  action: {
    id: 'CashRunway.Action',
    defaultMessage: 'Action',
  },
  to: {
    id: 'CashRunway.To',
    defaultMessage: 'To',
  },
  companyContact: {
    id: 'CashRunway.CompanyContact',
    defaultMessage: 'Company Contact',
  },
  requestItems: {
    id: 'CashRunway.RequestItems',
    defaultMessage: 'Request Items',
  },
  send: {
    id: 'CashRunway.Send',
    defaultMessage: 'Send',
  },
  subject: {
    id: 'CashRunway.Subject',
    defaultMessage: 'Subject',
  },
  atLeastOneCompany: {
    id: 'CashRunway.AtLeastOneCompany',
    defaultMessage: 'Please select at least one company',
  },
  info: {
    id: 'CashRunway.Info',
    defaultMessage: 'Info',
  },
});
