import React from 'react';
import {
  Box,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  colors,
  makeStyles,
} from '@material-ui/core';
// import { Colors } from 'constants/colors';
const { grey } = colors;

const useStyles = makeStyles((theme) => ({
  box: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'space-between',
  },
  formLabel: {
    color: '#000',
    marginRight: theme.spacing(2),
    width: '40%',
    fontSize: 13,
  },
  formControlCol: {
    width: '80%',
  },
  // radio: {
  //   fontSize: '19px',
  //   '&$checked': {
  //     color: Colors.TEXT,
  //   },
  // },
  radioWrapper: {
    alignItems: 'baseline',
  },
  radioLabel: {
    marginTop: theme.spacing(1.2),
  },
  radioRoot: {
    color: grey[600],
    '&.checked': {
      color: grey[800],
    },
  },
}));

type RadioProps = {
  id: string | number;
  name: string | React.ReactNode;
  value: string;
};

interface LabelWithRadioProps {
  label: React.ReactNode;
  name: string;
  value?: string;
  radios: Array<RadioProps>;
  onChange(e: React.SyntheticEvent): void;
  wrapperClassName?: string;
  labelClassName?: string;
}

const LabelWithRadioGroup = ({
  name,
  label,
  radios,
  value,
  onChange,
  wrapperClassName,
  labelClassName,
}: LabelWithRadioProps) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.box} ${classes.radioWrapper} ${wrapperClassName}`}
    >
      <InputLabel
        className={`${classes.formLabel} ${classes.radioLabel} ${labelClassName}`}
      >
        {label}
      </InputLabel>
      <RadioGroup
        name={name}
        value={value}
        onChange={onChange}
        className={classes.formControlCol}
      >
        {radios.map((item, index) => (
          <FormControlLabel
            // eslint-disable-next-line react/no-array-index-key
            key={`${item.id}_${index}`}
            value={item.value}
            control={<Radio color="default" className={classes.radioRoot} />}
            label={
              <Typography color="textPrimary" variant="body2">
                {item.name}
              </Typography>
            }
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { LabelWithRadioGroup };
