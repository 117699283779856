/* eslint-disable import/prefer-default-export */
export const normalizeContactName = (contact) => {
  const name = `${contact.firstName || ''} ${contact.lastName || ''}`.trim();
  return name || contact.email;
};

export const normalizeContacts = (contacts, count = 1) => {
  if (!Array.isArray(contacts)) {
    return contacts;
  }
  const data = contacts
    .filter((obj, index) => index < count)
    .map(normalizeContactName)
    .join(',');
  return contacts.length > count
    ? `${data}..+${contacts.length - count}`
    : data;
};
