import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import ManageCompanyTabs from 'components/Company/ManageCompanyTabs';
import CompanyTable from 'components/Company/CompanyTable';
import { selectSelectedOrganization } from 'containers/Organisation';
import { userRoles } from 'user/userConstants';
import translations from 'translations';
import reportError from 'common/reportError';
import { useAuth0 } from 'components/Common/Auth';
import { UpdateCompanyPayload, CompanyShort, CompaniesShort } from 'types';
import { CompanyThunk } from '../ducks';
import { useStyles } from './styles';

const ManageCompanyContainer = ({ type }: { type: string }): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [investmentManagers, setInvestmentManagers] = useState<Array<any>>([]);
  const { user } = useAuth0()!;
  const selectedOrganisation = useSelector(selectSelectedOrganization);

  const onCreateCompany = (
    {
      organisationId,
      name,
      file = null,
      shortDescription = null,
      apiCompanyId = null,
      investments,
      websiteUrl = '',
      location = '',
    }: any,
    contactDetails: any
  ): void => {
    const companyData = {
      organisationId,
      name,
      file,
      shortDescription,
      apiCompanyId,
      websiteUrl,
      location,
      investments,
    };
    dispatch(
      CompanyThunk.createCompany(
        { company: companyData, contactDetails },
        {
          successMsg: (
            <FormattedMessage
              {...translations.Company.ManageCompanies.Add
                .onSuccessCreateCompany}
            />
          ),
        }
      )
    );
  };

  const onEditSave = async (payload: UpdateCompanyPayload): Promise<void> => {
    try {
      await new Promise((resolve, reject) => {
        dispatch(
          CompanyThunk.updateCompany(
            {
              ...payload,
            },
            {
              resolve,
              reject,
              successMsg: (
                <FormattedMessage
                  {...translations.Company.ManageCompanies.Update
                    .onSuccessUpdateCompany}
                />
              ),
            }
          )
        );
      });
    } catch (error) {
      reportError(error);
    }
  };

  const onUpdateContact = async (contactDetails: any) => {
    dispatch(
      CompanyThunk.updateContact(
        {
          ...contactDetails,
        },
        {
          successMsg: (
            <FormattedMessage
              {...translations.Company.ManageCompanies.Update
                .onSuccessUpdateContact}
            />
          ),
        }
      )
    );
  };

  const onDeleteCompanies = async (
    payload: CompaniesShort | CompanyShort
  ): Promise<void> => {
    let data: Array<{
      id: string;
      organisation: string;
    }> = [];
    if (!Array.isArray(payload))
      data.push({
        id: payload.id as string,
        organisation: selectedOrganisation.id,
      });
    else
      data = payload.map((company) => ({
        id: company.id as string,
        organisation: selectedOrganisation.id,
      }));
    const message =
      data.length > 1
        ? translations.Company.ManageCompanies.Delete.onSuccessRemoveCompanies
        : translations.Company.ManageCompanies.Delete.onSuccessRemoveCompany;
    dispatch(
      CompanyThunk.deleteCompanies(data, {
        successMsg: <FormattedMessage {...message} />,
      })
    );
  };

  useEffect(() => {
    if (selectedOrganisation && Array.isArray(selectedOrganisation.users)) {
      const usersData: Array<any> = selectedOrganisation.users
        .filter((userObj) => userObj.id !== user.id)
        .map((userObj, index) => {
          const name = `${userObj.firstName || ''} ${userObj.lastName}`.trim();
          return {
            ...userObj,
            value: name || userObj.email,
            name,
            index: index + 1,
          };
        });
      setInvestmentManagers(usersData);
    }
  }, [user.id, selectedOrganisation]);

  if (selectedOrganisation?.role !== userRoles.VIEWER) {
    const common = translations.Company.ManageCompanies.Common;
    const message =
      type === 'add' ? common.addCompanies : common.manageCompanies;
    return (
      <>
        <Grid item xs={12} className={classes.marginBottom}>
          <Typography
            component="h2"
            variant="h5"
            color="textSecondary"
            gutterBottom
            className={classes.title}
          >
            <FormattedMessage {...message} />
          </Typography>
        </Grid>
        {selectedOrganisation &&
          selectedOrganisation.type !== 'company' &&
          type === 'add' && (
            <ManageCompanyTabs
              selectedOrganisation={selectedOrganisation}
              onCreateCompany={onCreateCompany}
              organisation={selectedOrganisation}
              portfolios={
                selectedOrganisation && selectedOrganisation.portfolios
                  ? selectedOrganisation.portfolios.items
                  : []
              }
              investmentManagers={investmentManagers}
            />
          )}
        {type !== 'add' && (
          <CompanyTable
            companiesByOrganisation={
              selectedOrganisation ? selectedOrganisation.companies : []
            }
            onDeleteCompanies={onDeleteCompanies}
            onEditSave={onEditSave}
          />
        )}
      </>
    );
  }
  return <Redirect to={`${selectedOrganisation?.url}/updates`} />;
};

ManageCompanyContainer.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ManageCompanyContainer;
