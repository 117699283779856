import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    margin: `${theme.spacing(2)}px auto`,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  title: {
    margin: '5px 0',
    color: '#363636',
    letterSpacing: 1,
    fontWeight: 400,
  },
}));
