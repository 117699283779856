import React, { useState } from 'react';
import {
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';

import translations from 'translations';
import { Button } from 'components/Common/Buttons';
import Spinner from 'components/Common/UI/Spinner';
import { AllowedOrganisationTypes } from 'common/commonContants';
import { InvestmentType } from 'constants/index';
import { Organization, CreateOrgPayload } from 'types';
import { useStyles } from './styles';

interface OnboardingStepOneProps {
  mutationLoading: boolean;
  setCurrentStep(number: number): void;
  createPortfolioLoading: boolean;
  onCreateOrganisation(
    obj: CreateOrgPayload,
    makeDefault: boolean
  ): Promise<Organization>;
  onCreatePortfolio(obj: object): Promise<void>;
  setComponentsData(obj: object): void;
}

const OnboardingStepOne = ({
  mutationLoading,
  setCurrentStep,
  createPortfolioLoading,
  onCreateOrganisation,
  onCreatePortfolio,
  setComponentsData,
}: OnboardingStepOneProps): JSX.Element => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [state, setState] = useState(InvestmentType[0].value);
  const [investmentTeam, setInvestmentTeam] = useState({
    name: '',
    valid: false,
    showError: false,
  });

  const createOrganisation = async (
    { name, type }: { name: string; type: string },
    portfolio: boolean
  ): Promise<void> => {
    const resOrg = await onCreateOrganisation({ name, type }, true);
    if (portfolio) {
      await onCreatePortfolio({
        name: 'My Portfolio',
        sizeCurrency: resOrg.currency,
        organisationId: resOrg.id,
      });
      setCurrentStep(3);
    } else {
      setCurrentStep(2);
    }
  };

  const handleInvestmentTeamName = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const {
      target: { value },
    } = event;
    const obj = {
      name: value,
      valid: true,
      showError: false,
    };
    if (!value) {
      obj.valid = false;
      obj.showError = true;
    }
    setInvestmentTeam(obj);
  };

  const gotoStep = async (): Promise<void> => {
    let name = 'Personal Investments';
    let portfolio = true;
    if (state === InvestmentType[0].value) {
      portfolio = false;
      if (!investmentTeam.name) {
        setInvestmentTeam((invst) => ({ ...invst, showError: true }));
        return;
      }
      name = investmentTeam.name;
    }
    const obj = {
      name,
      type: AllowedOrganisationTypes.FUND,
    };
    createOrganisation(obj, portfolio);
    setComponentsData((parentState: object) => ({
      ...parentState,
      investmentType: state,
    }));
  };

  return (
    <Grid container className={classes.stepWrapper}>
      <Grid item xs={12} className={classes.gridWrapper}>
        <RadioGroup
          name="subscription"
          value={state}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement>,
            value: string
          ): void => {
            setState(value);
          }}
          className={classes.formControlCol}
        >
          <FormControlLabel
            key="1"
            value={InvestmentType[0].value}
            control={
              <Radio
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            }
            label={InvestmentType[0].name}
          />
          <div className={classes.inputWrapper}>
            <TextField
              error={investmentTeam.showError}
              id="outlined-basic"
              placeholder="Name of Team"
              variant="outlined"
              helperText={investmentTeam.showError ? 'name is required' : ''}
              value={investmentTeam.name}
              onChange={handleInvestmentTeamName}
              inputProps={{
                style: {
                  padding: '10px',
                  width: '295px',
                },
              }}
            />
          </div>
          <FormControlLabel
            key="2"
            value={InvestmentType[1].value}
            control={
              <Radio
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            }
            label={InvestmentType[1].name}
          />
        </RadioGroup>
      </Grid>
      <Grid item xs={12} className={classes.gridWrapper}>
        <Typography className={classes.footer} variant="subtitle2" gutterBottom>
          {`${formatMessage(translations.Onboarding.onBoardingFootNote)}`}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.gridWrapper}>
        <Button
          typeColor="common"
          variant="contained"
          color="primary"
          disabled={!investmentTeam.valid && state !== InvestmentType[1].value}
          onClick={gotoStep}
          id="step-one-next-button"
        >
          {`${formatMessage(translations.Onboarding.onBoardingNext)}`}
          {(mutationLoading || createPortfolioLoading) && (
            <Spinner size={18} loading />
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { OnboardingStepOne };
