const getGraphqlErrorMessage = (error) => {
  const errorObj =
    error.graphQLErrors &&
    error.graphQLErrors[0] &&
    error.graphQLErrors[0].code;
  const errorMessage = (errorObj && errorObj.message) || errorObj;
  return errorMessage;
};

export default getGraphqlErrorMessage;
