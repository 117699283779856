/* eslint-disable import/no-cycle */
import { ApolloClient, from } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import config from 'config';

export default (logout?: any) => {
  const { auth0Client } = global;
  const link = createUploadLink({ uri: config.api.url });
  const authLink = setContext(async (_, { headers = {} }) => {
    const { __raw: token } = (await auth0Client?.getIdTokenClaims()) || {};
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        ...headers,
      },
    };
  });
  const logoutLink = onError((error) => {
    const { graphQLErrors } = error;
    if (
      graphQLErrors &&
      graphQLErrors[0] &&
      graphQLErrors[0].stack === 'jwt expired'
    )
      logout();
  });
  return new ApolloClient({
    link: from([logoutLink, authLink, link]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
};
