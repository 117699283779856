export const EXPORT_FORMAT = [
  {
    id: 1,
    name: 'PDF',
    value: 'pdf',
  },
  {
    id: 2,
    name: 'Excel',
    value: 'excel',
  },
  {
    id: 3,
    name: 'Google Sheet',
    value: 'gs',
  },
];

export const SECTIONS = [
  { id: 1, name: 'Company', value: 'Company' },
  { id: 2, name: 'Feed', value: 'Feed' },
  // { id: 3, name: 'KPIs', value: 'KPIs' },
  { id: 3, name: 'Metrics', value: 'Metrics' },
  { id: 4, name: 'Profile', value: 'Profile' },
];

export const IMPORT_FROMAT = EXPORT_FORMAT.slice(1, 3);
